import React from "react";
import { PropTypes } from "prop-types";
import { ChatButton, Link } from "../../../components/shared/actions";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import { LINKS } from "../../../constants";
import { envs } from "../../../config";

const ApresentacaoFundosInvestimento = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao
      image={images.apresentacaoFundosInvestimentos}
      title={sectionTitle}
      imageColumn={3}
      right
    >
      <p>
        A quantia que você contribui para a previdência é aplicada em fundos de
        investimento e administrado por especialistas para buscar o melhor
        rendimento. Nesta página você pode acompanhar o rendimento do dinheiro
        que você investe, observando a rentabilidade dos diferentes tipos de
        fundo por data. Você também pode fazer essa consulta no{" "}
        <Link blank href={LINKS.cvm} label="site da CVM" /> - Comissão de
        Valores Mobiliários.
      </p>
      <p>
        Quando quiser, troque o fundo escolhido na sua compra para outros fundos
        pela <Link blank href={LINKS.areaLogada} label="Área do Cliente" />, no{" "}
        <ChatButton chatId={envs.comprarPrevidenciaChatId} label="Chat" /> ou em
        uma{" "}
        <Link blank href={LINKS.encontreAgenciaCaixa} label="agência Caixa" />.{" "}
      </p>
    </Apresentacao>
  );
};

ApresentacaoFundosInvestimento.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

ApresentacaoFundosInvestimento.propTypes = {
  images: PropTypes.objectOf(Object),
  ...Section.propTypes,
};

export default ApresentacaoFundosInvestimento;
