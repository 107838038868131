/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const TableFundos = ({ content }) => {
  const [rendas, setRendas] = useState([]);

  useEffect(() => {
    setRendas(
      content.map((item) => {
        return { ...item, open: false };
      })
    );
  }, [content]);

  const handleOpen = (position) => {
    setRendas(
      rendas.map((item, index) => {
        if (index === position) {
          return { ...item, open: !item.open };
        }

        return { ...item, open: false };
      })
    );
  };

  if (rendas.length > 0) {
    return (
      <>
        <div className="table-fundos d-none d-lg-block">
          {rendas.map(
            ({
              data,
              dataInicio,
              nome,
              taxaAdmin,
              valor,
              rentabilidade: { ultimosDozeMeses },
            }) => (
              <>
                <h6>{nome}</h6>
                <table>
                  <thead>
                    <th>Rent. acumulada últimos 12 meses</th>
                    <th>Início do fundo</th>
                    <th>Taxa de ADM</th>
                    <th>Data da cota</th>
                    <th>Cota</th>
                  </thead>
                  <tbody>
                    <td>{ultimosDozeMeses}</td>
                    <td>{dataInicio}</td>
                    <td>{taxaAdmin}</td>
                    <td>{data}</td>
                    <td>{valor}</td>
                  </tbody>
                </table>
              </>
            )
          )}
        </div>
        <div className="colapse-fundos d-block d-lg-none">
          <div className="area-rent">
            <div className="rent">Rent. acumulada últimos 12 meses</div>
          </div>
          {rendas.map(
            (
              {
                data,
                dataInicio,
                nome,
                taxaAdmin,
                valor,
                open,
                rentabilidade: { ultimosDozeMeses },
              },
              index
            ) => (
              <div className="item-colapse">
                <button
                  type="button"
                  className="colapse-head"
                  onClick={() => handleOpen(index)}
                >
                  <span className="w-50">{nome}</span>
                  <span>
                    {ultimosDozeMeses}
                    <span className="ml-1">
                      {open ? (
                        <AiOutlineMinus size={20} />
                      ) : (
                        <AiOutlinePlus size={20} color="#0066B3" />
                      )}
                    </span>
                  </span>
                </button>
                {open && (
                  <div className="colapse-body">
                    <div className="item-body">
                      <div className="left-item">Início do fundo</div>
                      <div className="right-item">{dataInicio}</div>
                    </div>
                    <div className="item-body">
                      <div className="left-item">Taxa de ADM</div>
                      <div className="right-item">{taxaAdmin}</div>
                    </div>
                    <div className="item-body">
                      <div className="left-item">Data da cota</div>
                      <div className="right-item">{data}</div>
                    </div>
                    <div className="item-body">
                      <div className="left-item">Cota</div>
                      <div className="right-item">{valor}</div>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </>
    );
  }

  return <p>Informações não encontradas!</p>;
};

TableFundos.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableFundos;
