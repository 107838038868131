import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import { Link, PhoneLink } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const faqItems = [
  {
    label: "Como acionar o seguro?",
    content: (
      <p>
        Em caso de sinistro, acione o seu seguro ligando para a nossa{" "}
        <strong>Assistência Dia & Noite e Sinistros</strong> no telefone{" "}
        <PhoneLink phone="08007222492" />. Não esqueça de ter em mãos os dados
        do titular do seguro.
      </p>
    ),
  },
  {
    label: "Como recebo meu número da sorte?",
    content: (
      <p>
        O seu número da sorte será encaminhado por SMS e também pode ser
        consultado na nossa Central de Serviços e Relacionamento pelo telefone{" "}
        <PhoneLink phone="08007024000" />. Caso você seja sorteado, fique
        tranquilo, entraremos em contato com você.
      </p>
    ),
  },
  {
    label: "Condições gerais",
    content: (
      <p>
        Para consultar e baixar as condições gerais do Seguro Prestamista para o
        FIES,{" "}
        <Link href={LINKS.condicoesGeraisFies} blank label="clique aqui" />.
      </p>
    ),
  },
];

const TemAlgumaDuvidaFies = ({ sectionTitle }) => {
  return <SeguridadeFaq title={sectionTitle} faqItems={faqItems} right />;
};

TemAlgumaDuvidaFies.propTypes = {
  ...Section.specializedProps,
};

export default TemAlgumaDuvidaFies;
