import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col } from "react-bootstrap";
import { LINKS } from "@constants";
import { Button } from "@components/shared/actions";
import { Section } from "../../../components/shared/pages";
import { createImageElement } from "../../../asserts/images";

const BaixarInforme = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Row>
      <Col lg={8}>
        <h3 className=" ml-3 px-0 px-md-4 mt-3 mt-md-4 content-title">
          {sectionTitle}
        </h3>
        <ul className="list-unstyled ml-3 px-0 px-md-4 mb-4">
          <li>
            <h6>1) Começamos com o seu cadastro na área do cliente</h6>
            <p>
              Para baixar o seu informe é preciso se cadastrar na área do
              cliente. É simples, seguro, e basta seguir os passos abaixo:
            </p>
            <p>
              <ol className="ml-3 my-3">
                <li>
                  Clique em &quot;Fazer Cadastro&quot; e preencha seu CPF;
                </li>
                <li>
                  Um código de validação será enviado por SMS para seu celular e e-mail cadastrados;
                </li>
                <li>
                  Digite o código recebido e conclua o cadastro. 
                </li>
              </ol>
            </p>
            <p>
              <Button
                href={LINKS.areaLogada}
                className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
                blank
                label="Já tenho cadastro"
              />
              <Button
                href={LINKS.cadastroAreaLogada}
                className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
                blank
                variant="primary"
                outline
                label="Quero me cadastrar"
              />
            </p>
          </li>
          <li>
            <h6>2) Hora de baixar</h6>
            <p>
              Com o cadastro feito, é hora de baixar o seu informe. Vamos lá?
            </p>
            <ol className="ml-3 my-1">
              <li>Faça o login na Área do Cliente com seu CPF e senha;</li>
              <li>
              Abra o certificado clicando em &quot;Mais detalhes&quot;(*);
              </li>
              <li>
              No menu lateral, clique na opção &quot;Imposto de Renda&quot;;
              </li>
              <li>
              Escolha o ano do informe de rendimentos que deseja obter;
              </li>
              <li>
              Selecione como deseja receber o documento:
              baixar PDF ou enviar por e-mail.
              </li> 
                        
            </ol>
            <div className="list-alert">(*) Caso você tenha dois ou mais certificados, escolha
              apenas um deles. O informe de rendimentos é o somatório das contribuições pagas,
              saldos e movimentações vinculados ao CPF e, por isso, contempla todos os certificados.
            </div>   
          </li>
          
          <li>
            <h6>3) Pronto. Agora é só declarar na Receita Federal</h6>
            <p>
              Confira abaixo o guia feito para te ajudar na hora de declarar sua
              Previdência.
            </p>
            <p>
              <Button
                href={LINKS.guiaInformeRendimentos}
                className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
                blank
                label="Baixar guia"
              />
            </p>
          </li>
        </ul>
      </Col>
      <Col className=" mt-5 pt-5 justify-content-start align-items-end d-none d-lg-flex">
        {createImageElement(images.baixarInforme, {
          alt: "Cobertura",
          className: "mb-5",
        })}
      </Col>
    </Row>
  );
};

BaixarInforme.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

BaixarInforme.propTypes = {
  images: PropTypes.objectOf(Object),
  ...Section.propTypes,
};

export default BaixarInforme;
