import envs from "../envs";


export const consultCEPEndPoint = `${envs.giHost}/api/areaaberta/areaaberta_ConsultarCepApicGateway`

export const getResultadoSorteioEndPoint = `${envs.giHost}/api/areaaberta/areaaberta_ConsultarResultadoSorteioApicGateway`;
export const getTabelaRendimentosEndPoint = `${envs.giHost}/api/areaaberta/areaaberta_ConsultarTabelaRendimentoApicGateway`;

export const checarProtocoloAberturaOuvidoriaEndPoint=`${envs.giHost}/api/areaaberta/areaaberta_ChecarProtocoloAberturaOuvidoriaApicGateway`;

export const criarOuvidoria = `${envs.giHost}/api/areaaberta/areaaberta_AbrirOuvidoriaApicGateway`;

export const CriarToken  = `${envs.giHost}/api/GenericBackEnd/GBE_criaTokenEnviaSMS`;

export const ValidarToken =
    `${envs.giHost}/api/GenericBackEnd/GBE_ValidaTokenSMS`;

export const URL_AUTH_TOKEN = () => `${envs.HOST}/oauth-end/oauth2/token`;
