/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { InputControl } from '../../../../../components/shared/forms';

const GenericTextInput = ({
  id,
  name,
  label,
  placeHolder,
  value,
  typeField,
  length,
  formikBag,
  disabled,
}) => {
  let formFieldType = '';

  const maskFields = (fieldValue, type) => {
    let fieldFormatteds = '';

    switch (type) {
      case 'cnpj':
        formFieldType = 'text';
        fieldFormatteds = fieldValue
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1');
        break;
      case 'cpf':
        formFieldType = 'text';
        fieldFormatteds = fieldValue
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2');
        break;
      case 'date':
        formFieldType = 'text';
        fieldFormatteds = fieldValue
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '$1/$2')
          .replace(/(\d{2})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d{1,2})/, '$1/$2');
        break;

      default:
        formFieldType = 'text';
        fieldFormatteds = fieldValue;
    }

    return fieldFormatteds;
  };

  const handleField = (e) => {
    const unmaskedValue = e.target.value
      .replaceAll('.', '')
      .replace('-', '')
      .replace('/', '');
    e.target.value = maskFields(unmaskedValue, typeField);
    formikBag.onChange(e);
  };

  return (
    <InputControl
      inputStyle={{ minHeight: '50px' }}
      controlId={id}
      name={name}
      label={label}
      placeHolder={placeHolder}
      value={value}
      type={formFieldType}
      maxLength={length}
      disabled={disabled}
      {...formikBag}
      onChange={handleField}
    />
  );
};

GenericTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  typeField: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default GenericTextInput;
