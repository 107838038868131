/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react/jsx-no-bind */
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const cpfCnpjRegex = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/;

export const verifyCPF = (num) => {
  return !!cpf.isValid(num);
};

export const verifyCNPJ = (num) => {
  return !!cnpj.isValid(num);
};

export const isValidCpfCnpj = (num) => {
  if (!num) {
    return false;
  }
  const inputNumbers = num.replace(/[^\d]/g, '');
  if (inputNumbers.length === 11) {
    return verifyCPF(num);
  }
  if (inputNumbers.length === 14) {
    return verifyCNPJ(num);
  }
  return false;
};

const isValidDate = (dateString) => {
  if (dateString.length !== 10) {
    return false;
  }
  const [day, month, year] = dateString.split('/');
  const date = new Date(`${month}/${day}/${year}`);
  const currentDate = new Date();
  return date.getTime() < currentDate.getTime();
};

export const validDateBR = (dateString) => {
  if (!dateString || dateString.length !== 10) {
    return null;
  }

  const [day, month, year] = dateString.split('/');
  const parsedDate = new Date(`${month}/${day}/${year}`);

  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  return parsedDate;
};

export const isValidateDate = (dateString) => {
  if (!dateString) {
    return false;
  }
  return isValidDate(dateString);
};

export const cepRegex = /^[0-9]{5}-?[0-9]{3}$/;
export const cepMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const dateMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
};

export const telefoneRegex = /(\(?\d{2}\)?\s)?(\d{4,5}\d{4})/;

export const telefoneCelularRegex = /\(\d{2}\) \d{4,5}-\d{4}/;

export const protocoloRegex = /\d{4,20}/;

export const nomeRegex = /^[a-zA-ZáéíóúàèìòùâêîôûÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛãÃõÕ]{1,40}\s[a-zA-ZáéíóúàèìòùâêîôûÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛãÃõÕ]+/;

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
