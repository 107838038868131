import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const hoverStyles = `
  &:hover {
    background-color: #0039ba;
    border-color: #0039ba;
  }
`;

const BtnPrimary = styled(Button)`
  color: #fff;
  background-color: ${(props) => (props.disabled ? "#e1e1e1 !important" : "#005fc8")};
  border-color: ${(props) => (props.disabled ? "#e1e1e1 !important" : "#005fc8")};
  ${props => (props.disabled ? '' : hoverStyles)}
`;

const ButtonPrimary = props => <BtnPrimary {...props} />;

export default ButtonPrimary;