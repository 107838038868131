import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const CanalDenuncias = ({ sectionTitle }) => {
  return (
    <Container>
      <Title>{sectionTitle}</Title>

      <p>
        Este canal é exclusivo para o registro e acompanhamento de denúncias
        relacionadas a atos ilícitos, violação das normas internas, leis e
        regulamentações vigentes ou desvios de conduta que envolvam os
        funcionários, administradores, prestadores de serviço ou clientes da
        Caixa Vida e Previdência.
      </p>
      <Subtitle>Quem pode utilizar e como funciona?</Subtitle>
      <p>
        O Canal de Denúncias está disponível para todos os públicos, por isso,
        ele pode ser utilizado por qualquer pessoa. As denúncias podem ser
        feitas de forma anônima ou identificada e em todos os casos, a Caixa
        Vida e Previdência tem o compromisso de assegurar a confidencialidade e
        a proteção da identidade do denunciante.
      </p>
      <p>
        Todas as denúncias registradas neste canal são recebidas pela{" "}
        <strong>Shield Compliance</strong>, uma empresa independente e
        especializada, que assegura o sigilo das informações fornecidas e o
        tratamento imparcial, e sem conflito de interesses, de cada relato
        recebido. Após registrar uma denúncia, você receberá um protocolo de
        atendimento, é importante guardá-lo pois é por meio dele que você poderá
        acompanhar o andamento da sua denúncia.
      </p>
      <Button blank href={LINKS.acompanharDenuncia}>
        Registrar / Acompanhar denúncia
      </Button>
    </Container>
  );
};

CanalDenuncias.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;
const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  font-size: 24px;
  margin-top: 40px !important;
`;

export default CanalDenuncias;