/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Backdrop from '../Backdrop';
import * as S from './styled';

const ModalTextoArquivos = ({ show, onClick }) => {
  return show ? (
    <Backdrop onClick={onClick}>
      <S.ModalContainer>
        <S.Modal onClick={(e) => e.stopPropagation()}>
          <S.CloseButton onClick={onClick}>x</S.CloseButton>
          <S.Overlay>
            <S.ModalContent>
              <S.ContentContainer>
                <S.BigTitle>Ops, Tamanho máximo foi excedido!</S.BigTitle>
                <S.Text>
                  Não foi possivel enviar todos os arquivos devido o tamanho
                  total dos anexos é maior que 100MB.
                  <br />
                  <strong>
                    Reduza o tamanho dos arquivos e faça o upload novamente.
                  </strong>
                </S.Text>
                <S.Button onClick={onClick}>Entendi</S.Button>
              </S.ContentContainer>
            </S.ModalContent>
          </S.Overlay>
        </S.Modal>
      </S.ModalContainer>
    </Backdrop>
  ) : null;
};

export default ModalTextoArquivos;
