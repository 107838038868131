import "./style.css";
import React from "react";
import { useSegmentRoute } from "@hooks";
import AreaRoute from "../../components/shared/routes";
import HomePage from "./pages";

export const AcidentesPessoais = () => {
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
  ]);
  return (
    <AreaRoute
      {...routerContext}
      title="Acidentes Pessoais"
    />
  );
};

export default AcidentesPessoais;
