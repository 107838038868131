import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { ContentWithCardList } from "../../../components/blocosCustomizados";
import { Link } from "../../../components/shared/actions";
import { Section } from "../../../components/shared/pages";

export const Assistencia = ({ images, sectionTitle }) => {
  const conteudoAssistencia = [
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Assistência Funeral Familiar</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Garante, em caso de falecimento do segurado principal, seu cônjuge e
            filhos com até 24 anos, por qualquer que tenha sido a causa da
            morte, a prestação dos Serviços de Assistência Funeral (SAF).
          </p>
          <p>
            <Link
              href="/segurodevida/saiba-mais"
              label="Assista como funciona essa assistência"
            />
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Assistência Farmácia</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Oferece desconto de até 40% em medicamentos de marca e até 60% nos
            medicamentos genéricos da Medley. Orientação farmacêutica de segunda
            à sexta, das 09h às 18h. Apresente seu CPF nas farmácias
            credenciadas para garantir seu desconto.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Check-up Lar</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Os seguros com Check-up Lar oferecem uma vez a cada 12 meses:
            serviço de fixação de quadros, prateleiras e persianas, instalação
            de olho mágico, limpeza de caixa d’água, lubrificação de fechaduras
            e dobradiças, revisão na instalação elétrica, serviço de troca de
            lâmpadas e luzes, verificação de vazamentos, troca de vidro e
            retirada de entulho.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Orientação Nutricional</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Sempre que quiser, você contará com atendimento por telefone para
            tirar dúvidas e melhorar seus hábitos alimentares.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Cesta Alimentícia</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Os beneficiários recebem uma cesta básica com 50 kg de alimentos, no
            caso de Morte Acidental do Segurado.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Assistência Educacional</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Estudantes escolares ou universitários sob sua responsabilidade
            podem contar com transporte para o hospital, para sessões de
            fisioterapia e transporte e estada de familiar em caso de
            internamento. Garante também aulas com professor particular para
            repor matérias e até cobertura de despesas médicas no exterior.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Help Desk</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            O segurado pode solicitar um profissional especializado para receber
            orientações e soluções de problemas relacionados ao microcomputador.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>Recolocação Profissional</h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Se você for demitido, sem justa causa ou por término de contrato,
            nossa equipe irá disponibilizar seu currículo, gratuitamente, para
            empresas durante três meses. É preciso estar empregado no momento da
            adesão para que esse serviço esteja disponível.
          </p>
        </>
      ),
    },
    {
      titleConteudo: (
        <>
          <h6 style={{ minHeight: "50px" }}>
            Serviços de Assistência Funeral Individual (Seguro Amparo)
          </h6>
        </>
      ),
      conteudo: (
        <>
          <p>
            Garante, em caso de falecimento do segurado principal, por qualquer
            que tenha sido a causa da morte, a prestação dos Serviços de
            Assistência Funeral (SAF).
          </p>
          <p>
            <Link
              href="/segurodevida/saiba-mais"
              label="Assista como funciona essa assistência"
            />
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <ContentWithCardList listItens={conteudoAssistencia}>
        <Row>
          <Col xs={12}>{sectionTitle}</Col>
          <Col xs={12}>
            <p>Além das coberturas oferecemos alguns benefícios gratuitos.</p>
          </Col>
          <Col xs={12}>
            <Image
              src={images.vidaSaibaMaisAssistencia}
              alt="Nossas coberturas"
              fluid
            />
          </Col>
        </Row>
      </ContentWithCardList>
    </>
  );
};

Assistencia.defaultProps = {
  ...ContentWithCardList.defaultProps,
  ...Section.specializedDefaultProps,
};

Assistencia.propTypes = {
  ...ContentWithCardList.propTypes,
  ...Section.specializedProps,
};
export default Assistencia;
