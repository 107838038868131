import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import "./style.css";

const ContentWithTimeline = ({ data, title }) => (
  <>
    <Row className="mt-2">
      <Col xs="12">
        <h5 className="text-center">{title}</h5>
      </Col>
      <Row className=" box-timeline">
        <Col className="text-right m-0 pl-4 pr-3 col-title">
          <p className="text-body-sm">Tempo de investimento</p>
          <p className="text-body-sm my-2">Tributação</p>
        </Col>
        <Col className="text-center m-0">
          <ul className="timeline justify-content-start">
            {data.map((obj) => {
              return (
                <li className="li complete">
                  <div className="timestamp">
                    {obj.dataTop.map((top) => (
                      <span className="timeAno"> {top} </span>
                    ))}
                  </div>
                  <div className="status">
                    <div className="data-bottom">{obj.dataBottom}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Row>
  </>
);

ContentWithTimeline.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default ContentWithTimeline;
