import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensPrevidencia from "@asserts/images/previdencia/index";
import { BannnerIR } from "../sections";
import ApresentacaoIR from "../sections/ApresentacaoIR";
import DevoDeclarar from "../sections/DevoDeclarar";
import BaixarInforme from "../sections/BaixarInforme";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import "./style.css";

const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackInforme = useTrackEvent(appInsights, "AA_InformeRendimentos");

  useEffect(() => {
    const informeEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Informe de Rendimentos",
      virtualPageURL: "/informe-de-rendimentos",
    };
    trackInforme(informeEvent);
  }, [trackInforme]);

  return (
    <Page
      images={imagensPrevidencia}
      {...props}
      banner={BannnerIR}
      updateCrumb={{
        toRoute: "/previdencia",
        label: "Previdência",
      }}
    >
      <SeguridadePageLayout.Tab eventKey="informe">
        <Section
          as={ApresentacaoIR}
          id="declarar-o-seu-IR"
          title="Declarar o seu IR é simples! :)"
          sectionName="Aqui, voce"
        />

        <Section
          as={DevoDeclarar}
          id="devo-declarar"
          title="Devo declarar minha Previdência?"
          sectionName="Ninguém quer"
        />

        <Section
          as={BaixarInforme}
          id="baixar-informe"
          title="Como baixar meu informe de rendimentos?"
          sectionName="Começamos com"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
