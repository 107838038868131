import styled from "styled-components";
import { media } from "../../../../utils";
import { SubmitControl } from "../../../../components/shared/forms";

export const SpaceButton = styled.div`
  margin-top: 10px;
  margin-bottom: 60px;
`;
export const SpaceForm = styled.div`
  height: 100px;
`;
export const SpaceCaptcha = styled.div`
  height: 40px;
`;

export const ContainerColumnSwitch = styled.div`
  display: grid;
  grid-gap: 5px;
`;
export const ContainerSwitch = styled.div`
  display: flex;
`;

const LabelRadioButtonDesktop = media.desktop`
  cursor: pointer;
`;

export const LabelRadioButton = styled.label`
  ${LabelRadioButtonDesktop}
`;

const RadioButtonDesktop = media.desktop`
  accent-color: #0066b3;
  cursor: pointer;  
`;

export const RadioButtonTipo = styled.input`
  ${RadioButtonDesktop}
  cursor: pointer;
  accent-color: #0066b3;
`;

const FormOneColumnsSinistroGenericDesctop = media.desktop`
  display: grid;
  grid-template-columns: auto;
	grid-template-rows: 100px!important;
  grid-gap: 20px;
  justify-content: flex-start;
  margin-bottom:20px;
`;

export const FormOneColumnGenericInline = styled.div`
  display: flex;
  grid-gap: 20px;
  grid-template-rows: 20px 20px;
  padding-bottom: 40px;
  margin-bottom: 30px;
`;

export const FormOneColumnsGeneric = styled.div`
  ${FormOneColumnsSinistroGenericDesctop}
`;

const FormTwoColumnsSinistroGenericDesctop = media.desktop`
  display: grid;
  grid-template-columns: 0.3fr 0.3fr;
  grid-gap: 20px;
`;

const FormTreeColumnsSinistroGenericDesctop = media.desktop`
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 0.3fr;
  grid-gap: 20px;
`;

export const WarningParagraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0 !important;
`;

const FormRowNameAndCpfDesktop = media.desktop`
  display: grid;
  grid-template-columns: 0.38fr 0.38fr;
  grid-gap: 20px;
`;

const FormColumnsGenericDesktop = media.desktop`
  display: grid;
	grid-template-columns: 0.19fr auto;
	grid-template-rows: 40px 40px;
`;

export const FormColumnsGeneric = styled.div`
  ${FormColumnsGenericDesktop}
`;

export const ContainerRadio = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 10px 20px;
`;

export const FormTreeColumnsGeneric = styled.div`
  ${FormTreeColumnsSinistroGenericDesctop}
`;

export const FormRowNameAndCpf = styled.div`
  ${FormRowNameAndCpfDesktop}
`;

export const FormTwoColumnsGeneric = styled.div`
  ${FormTwoColumnsSinistroGenericDesctop}
`;

const ContactDataFormTitleDesktop = media.desktop`
  font-size: 24px;
`;

export const ContactDataFormTitle = styled.h6`
  font-size: 20px;
  color: #646363;
  line-height: 30px;
  margin: 30px 0 !important;
  ${ContactDataFormTitleDesktop}
`;

const FormRowPhoneAndEmailDesktop = media.desktop`
  display: grid; 
  grid-gap: 20px;
  grid-template-columns: 0.38fr 0.48fr;
`;

export const FormRowPhoneAndEmail = styled.div`
  ${FormRowPhoneAndEmailDesktop}
`;

const FormRowAddress1Desktop = media.desktop`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 0.5fr 0.3fr;
`;

export const FormRowAddress1 = styled.div`
  ${FormRowAddress1Desktop}
`;

const FormRowAddress2Desktop = media.desktop`
  display: grid;  
  grid-gap: 20px;
  grid-template-columns: 0.35fr 0.3fr 0.3fr;
`;

export const FormRowAddress2 = styled.div`
  ${FormRowAddress2Desktop}
`;

export const CEPContainerDesktop = media.desktop`
 align-items: center;
  max-width: 200px; 
`;

export const CEPContainer = styled.div`
  display: flex;
  ${CEPContainerDesktop}
  flex-direction: column;
`;

export const CharactersLeft = styled.span`
  margin-bottom: 50px;
  font-size: 16px;
`;

export const SubmitButton = styled(SubmitControl)`
  max-width: 290px;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const ErrorText = styled(Text)`
  color: #e83131;
  font-size: 1em;
`;
