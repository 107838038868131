import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const CepInput = ({ label, cep, formikBag, ...props }) => (
  <InputControl
    style={{ maxWidth: "400px" }}
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.cep"
    label={label}
    placeHolder="Digite um CEP"
    name="reclamante.cep"
    value={cep}
    {...formikBag}
    {...props}
  />
);

CepInput.propTypes = {
  label: PropTypes.string.isRequired,
  cep: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default CepInput;
