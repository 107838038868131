import React from "react";
import { useSegmentRoute } from "@hooks";
import { HomePage } from "./pages";
import AreaRoute from "../../components/shared/routes";

export const CanalDenuncias = () => {
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      component: HomePage,
      path: "/canal-de-denuncias",
    },
  ]);

  return <AreaRoute {...routerContext} title="Canal de Denúncias" />;
};

export default CanalDenuncias;
