export function buildSegurado(segurado) {
  return {
    origemContato: 'Área Aberta',
    nome: segurado?.nomeSegurado,
    cpf: segurado?.cpfSegurado,
    cnpjEmpresa: segurado?.cnpjSegurado,
    razaoSocialEmpresa: segurado?.razaoSocialSegurado,
    dtNascimento: segurado?.dataNascimentoSegurado ?? '',
    sexo: segurado?.sexoSegurado ?? '',
  };
}
export default buildSegurado;
