/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import { isValid as isValidDate } from "date-fns";
import Form, { DatePickerControl } from "../../../components/shared/forms";
import { applyKey, parseDateString, parseStringDate } from "../../../utils";
import Table from "../../../components/shared/table";
import useTabelaFundoInvestimento from "../../../hooks/previdencia/useTabelaFundoInvestimento";
import SpinnerLoading from "../../../components/shared/layout/SpinnerLoading";

const FormTabelaFundos = ({
  values: { dataConsulta },
  handleBlur,
  handleChange,
  isValid,
  errors,
  dirty,
  touched,
  isSubmitting,
  setFieldValue,
  submitForm,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    onSubmit: submitForm,
  };

  useEffect(() => {
    if (
      dataConsulta &&
      dataConsulta.length === 10 &&
      isValidDate(parseDateString(dataConsulta)) &&
      !isSubmitting
    ) {
      submitForm();
    }
  }, [dataConsulta]);

  return (
    <Row className="text-center py-5">
      <Col xs={12} md={{ offset: 4, span: 4 }}>
        <DatePickerControl
          controlId="formTabelaFundo.dataConsulta"
          label="Informe a data para consulta:"
          name="dataConsulta"
          value={dataConsulta}
          {...formikBag}
          minDate="25/09/2000"
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          onKeyUp={(e) => {
            let fieldValue = e.target.value;
            if (e.key !== "Backspace" && e.key !== "Delete") {
              if (/[^\d/]/g.test(fieldValue)) {
                fieldValue = fieldValue.replace(/[^\d/]/g, "");
              } else {
                const len = fieldValue.length;
                if (len === 2 || len === 5) {
                  fieldValue = `${fieldValue}/`;
                } else if (
                  len >= 3 &&
                  len < 5 &&
                  fieldValue.charAt(2) !== "/"
                ) {
                  fieldValue = `${fieldValue.slice(0, 2)}/${fieldValue.slice(
                    2
                  )}`;
                } else if (len >= 6 && fieldValue.charAt(5) !== "/") {
                  fieldValue = `${fieldValue.slice(0, 5)}/${fieldValue.slice(
                    5
                  )}`;
                }
              }
              e.target.value = fieldValue;
            }
          }}
          inputmode="numeric"
          pattern="\d{2}\/\d{2}\/\d{4}"
          maxLength="10"
        />
      </Col>
    </Row>
  );
};

FormTabelaFundos.defaultProps = {
  ...Form.defaultProps,
};

FormTabelaFundos.propTypes = {
  ...Form.propTypes,
};

const TabelaRendimentosFundos = ({ sectionTitle }) => {
  const {
    tabelaFundos,
    loading,
    setDataConsulta,
  } = useTabelaFundoInvestimento();
  const submitHandle = ({ dataConsulta }) => {
    setDataConsulta(dataConsulta);
  };

  const fundosAgrupados = tabelaFundos?.filter(({ fundos }) => fundos?.length);
  const today = parseStringDate(new Date());

  return (
    <>
      <h1 className="text-center">{sectionTitle}</h1>
      <Form
        form={FormTabelaFundos}
        formId="fomulario-data-consulta-rendimento"
        initialValues={{
          dataConsulta: "",
        }}
        validationSchema={{
          dataConsulta: Yup.date()
            .transform((value, originalvalue) => parseDateString(originalvalue))
            .required("Por favor, forneça uma data válida")
            .typeError("Por favor, forneça uma data válida")
            .max(
              new Date(),
              `Por favor, forneça uma data entre 25/09/2000 e ${today}`
            )
            .min(
              new Date("2000-09-25"),
              `Por favor, forneça uma data entre 25/09/2000 e ${today}`
            ),
        }}
        onSubmit={submitHandle}
      />
      {loading ? (
        <>
          <SpinnerLoading isLoading={loading} />
        </>
      ) : null}
      {!loading ? (
        <Table
          striped={false}
          className="tabela-fundo-investimento text-center text-body-sm"
          header={
            <>
              <tr>
                <th colSpan={5} className="transparent-cell" label="Rendimento dos fundos" />
                <th colSpan={4}>Rentabilidade Acumulada (%)</th>
              </tr>
              <tr className="column-description">
                <th>Classificação do fundo</th>
                <th>Início do fundo</th>
                <th>
                  Taxa de
                  <br /> ADM. (%)
                </th>
                <th>Data da cota</th>
                <th>Cota (R$)</th>
                <th>No dia</th>
                <th>No Mês</th>
                <th>No ano</th>
                <th>Últ. 12 meses</th>
              </tr>
            </>
          }
        >
          {applyKey(fundosAgrupados, ({ name: nomeTipo, fundos }) => (
            <>
              <tr className="tipo-fundo-descricao text-left text-body-normal">
                <th colSpan={9}>{nomeTipo}</th>
              </tr>
              {applyKey(
                fundos,
                ({
                  nome,
                  dataInicio,
                  data,
                  taxaAdmin,
                  valor,
                  rentabilidade: { anual, mensal, diario, ultimosDozeMeses },
                }) => (
                  <tr>
                    <td className="text-left">{nome}</td>
                    <td>{dataInicio}</td>
                    <td className="taxa-admin-bg">{taxaAdmin}</td>
                    <td>{data}</td>
                    <td>{valor}</td>
                    <td>{diario}</td>
                    <td>{mensal}</td>
                    <td>{anual}</td>
                    <td>{ultimosDozeMeses}</td>
                  </tr>
                )
              )}
            </>
          ))}
        </Table>
      ) : null}
    </>
  );
};

TabelaRendimentosFundos.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

export default TabelaRendimentosFundos;
