import React from "react";
import PropTypes from "prop-types";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";

export const BannersPrestamistaProdutorRural = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>   
        <BannerContents.Banner
          backgroundImage={images.bannerPrestamistaRural}
          className="text-white"
          right
        >
          <Row>
            <div id="banner-rural">
              <Col xs={12} className="d-flex align-items-stretch">
                <FaFileInvoiceDollar className="d-none d-md-inline-block cvp-icon-banner" />
                <h3 className="d-md-inline-block">
                  Seguro Vida Produtor Rural
                </h3>
              </Col>
              <Col xs={12}>
                <p className="text-body-md text-banner">
                  Fale com seu gerente ou<br />procure uma agência CAIXA
                </p>
              </Col>{" "}
            </div>
          </Row>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannersPrestamistaProdutorRural.defaultProps = { images: {} };

BannersPrestamistaProdutorRural.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannersPrestamistaProdutorRural;
