import { Image } from "react-bootstrap";
import React from "react";
import icons from "./icons";

import brandingLogo from "./brandding.png";
import branddingSeguridadeLogo from "./brandding-caixa-seguridade.png";
import imagemExclamacao from "./imagem-exclamacao.svg";
import seguridadeBannerMobile from "./seguridade-banner-mobile-backgrund.png";
import suaEmpresaPodeContarIlustracao from "./empresa-pode-contar-iustracao.svg";

export function createImageElement(image, imageProps) {
  return typeof image === "string" || !image
    ? React.createElement(Image, { ...imageProps, src: image })
    : React.createElement(image, {
        className: `svg-image ${imageProps?.className || ""}`,
      });
}

export default {
  ...icons,
  brandingLogo,
  imagemExclamacao,
  suaEmpresaPodeContarIlustracao,
  seguridadeBannerMobile,
  branddingSeguridadeLogo,
};
