import React from "react";
import { Section } from "@components/shared/pages";
import { Advantages } from "../../../components/blocosCustomizados";

const VantagensAcidentesPessoais = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.acidentesPessoaisIndenizacao,
      title: "Indenização aos beneficiários",
      description: "",
    },
    {
      image: images.acidentesPessoaisSorteio,
      title: "Sorteios",
      description: "",
    },
    {
      image: images.acidentesPessoaisCoracao,
      title: "Assistência e serviços para utilização em vida",
      description: "",
    },
  ];
  return <Advantages sectionTitle={sectionTitle} items={conteudoVantagem} />;
};

VantagensAcidentesPessoais.propTypes = {
  ...Section.specializedProps,
};
export default VantagensAcidentesPessoais;
