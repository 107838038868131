import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints, envs } from "../../config";
import usePerformGiRequest from "./usePerformGiRequest";
import useGiRequest, {POST} from "./useGiRequest";

/**
 * This service hook will fetch lottery results from an API endpoint.
 *
 * @component
 * @return  {UseDrawResultHook}
 *
 * @typedef    {Object}    UseDrawResultHook
 *  @property  {function}  getDrawResults     Function used for API call
 *  @property  {number}    status             HTTP status code
 *  @property  {Object}    data               Raw response data
 *  @property  {boolean}   loading            Loading indicator
 *  @property  {boolean}   error              Error indicator
 */
const useDrawResult = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const { getAccessToken } = usePerformGiRequest();
  const {performGiRequest, error} = useGiRequest();

  /**
   * Fetches lottery results from API
   * @param   {string}   drawProduct     Product code
   * @param   {string}   drawYear        Starting year for lookup
   * @param   {string}   user            Service user id
   */
  const getDrawResults = async (drawProduct, drawYear, user) => {
    setLoading(true);
    const shaRsaKey = await getAccessToken('00000000000',endPoints.getResultadoSorteioEndPoint.split('/').pop());
    const payload = {
      UserName: "00000000000",
      SHArsaKey: shaRsaKey,
      Plano: drawProduct,
        Ano: drawYear || envs.resultadoSorteioBaseYear,
        Usuario: user || envs.resultadoSorteioUser,
        NomeProduto: "",
  }
    const response = await performGiRequest({
      method: POST,
      url: endPoints.getResultadoSorteioEndPoint,
      data: payload
    })
    
      setStatus(response?.status);
      setData(response?.data.dados.result);
      setLoading(false);
  };

  return { getDrawResults, status, data, loading, error };
};

useDrawResult.propTypes = {
  getDrawResults: PropTypes.func,
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useDrawResult;
