import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const size = {
  small: css`
    min-width: 160px;
  `,
  medium: css`
    min-width: 200px;
  `,
  large: css`
    min-width: 240px;
  `,
  block: css`
    min-width: 100%;
  `,
};

const getSizeCss = ({ small, medium, large, block }) => {
  if (small) {
    return size.small;
  }
  if (medium) {
    return size.medium;
  }
  if (large) {
    return size.large;
  }
  if (block) {
    return size.block;
  }

  return size.medium;
};

const Btn = styled.button.attrs({
  noprint: 'true'
})`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  background-color: inherit;
  padding: 15px 10px;
  border-radius: 0;
  letter-spacing: 0.2px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props => getSizeCss(props)}
  ${props => props.small && size.small}
  ${props => props.medium && size.medium}
  ${props => props.large && size.large}
  ${props => props.block && size.block}
`;

function handleClick(event, { gacategory, gatype, gafunc }, onClick) {
  if (gacategory) {
    window.dataLayer.push({
      event: 'datalayereventos',
      gacategory,
      gatype,
      gafunc,
    });
  }

  if (onClick) onClick(event);
}

// eslint-disable-next-line react/destructuring-assignment
const Button = ({ className = '', onClick, ...props }) => (
  <Btn
    className={className}
    onClick={event => {
      handleClick(event, props, onClick);
    }}
    {...props}
  />
);

/* eslint-disable react/require-default-props */
Button.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  gacategory: PropTypes.string,
  gatype: PropTypes.string,
  gafunc: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;