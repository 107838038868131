/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import styled from "styled-components";
import FormularioOuvidoria from "./FormularioOuvidoria";
import Form from "../../../components/shared/forms";
import SemProduto from "../../../components/shared/SemProduto/SemProduto";

import {
  cepRegex,
  protocoloRegex,
  nomeRegex,
} from "../../../utils/inputUtils";

import isValidCpfCnpj from '../../../utils/cpfCnpj';
import useOuvidoria from "../../../hooks/requests/useOuvidoria";
import useSinistro from "../../../hooks/requests/useSinistro";

const AbrirOuvidoria = ({
  sectionTitle,
  setNewProtocol,
  setNewProtocolDate,
  setErrorOuvidoria,
}) => {
  const [showError, setShowError] = useState(false);
  const [anexo, setAnexo] = useState({
    nome: "",
    conteudo: "",
    tamanho: "",
    descricao: "Anexo complementar ouvidoria",
  });
  const [productExists, setProductExists] = useState({});
  const {
    createOuvidoria,
    newProtocol,
    newProtocolDate,
    loading: isLoadingOuvidoria,
    error: errorOuvidoria,
  } = useOuvidoria();
  const { verificarProduto, loading: isLoadingProductActive } = useSinistro();
  const formProps = {
    anexo,
    productExists,
    isLoadingProductActive,
    setAnexo,
    isLoadingOuvidoria,
    errorOuvidoria,
  };

  useEffect(() => {
    if (!isLoadingOuvidoria && newProtocol) {
      setErrorOuvidoria(errorOuvidoria);
      setNewProtocol(newProtocol);
      setNewProtocolDate(newProtocolDate);
    }
  }, [isLoadingOuvidoria]);

  return (
    <Container>
      <Title>{sectionTitle}</Title>
      <Subtitle>
        Antes de começar, tenha em mãos o <strong>protocolo anterior</strong>{" "}
        fornecido em um dos nossos canais de atendimento.
      </Subtitle>
      <Form
        formProps={formProps}
        form={FormularioOuvidoria}
        formId="search-panel-form"
        validationSchema={{
          reCaptcha: Yup.string().required("O campo reCAPTCHA é obrigatório."),
          produto: Yup.string().required("O campo Produto é obrigatório."),
          protocolo: Yup.string()
            .matches(protocoloRegex, "Protocolo inválido.")
            .required("O campo Protocolo é obrigatório."),
          ouvidoria: Yup.object()
            .shape({
              nome: Yup.string()
                .max(120, "o Campo nome ultrapassou o limite de caracteres.")
                .matches(nomeRegex, "Nome inválido.")
                .required("O campo nome é obrigatório."),
              cpfCnpj: Yup.string()
                .required("O campo CPF ou CNPJ é obrigatório.")
                .test(
                  "is-cpf-cnpj-valid",
                  "Insira um CPF ou CNPJ válido.",
                  isValidCpfCnpj
                ),
              pedido: Yup.string()
                .min(
                  50,
                  "O detalhe do seu pedido deve ter pelo menos 50 caracteres."
                )
                .max(
                  5000,
                  "O detalhe do seu pedido deve ter no máximo 5000 caracteres."
                )
                .required("O campo detalhes do pedido é obrigatório."),
              anexo: Yup.string(),
              contato: Yup.object()
                .shape({
                  telefone: Yup.string()
                    .min(14, "Telefone inválido.")
                    .required("O campo telefone é obrigatório."),
                  email: Yup.string()
                    .email("E-mail inválido.")
                    .required("O campo e-mail é obrigatório."),
                  cep: Yup.string()
                    .matches(cepRegex, "Insira um CEP válido.")
                    .required("O campo CEP é obrigatório."),
                  endereco: Yup.string().required(
                    "O campo endereço é obrigatório."
                  ),
                  numero: Yup.string().required(
                    "O campo número é obrigatório."
                  ),
                  bairro: Yup.string().required(
                    "O campo bairro é obrigatório."
                  ),
                  complemento: Yup.string(),
                  estado: Yup.string().required(
                    "O campo Estado é obrigatório."
                  ),
                  cidade: Yup.string().required(
                    "O campo cidade é obrigatório."
                  ),
                })
                .required(),
            })
            .required(),
        }}
        initialValues={{
          produto: "",
          protocolo: "",
          ouvidoria: {
            nome: "",
            cpfCnpj: "",
            pedido: "",
            anexo,
            contato: {
              telefone: "",
              email: "",
              cep: "",
              endereco: "",
              numero: "",
              bairro: "",
              complemento: "",
              estado: "",
              cidade: "",
            },
          },
          reCaptcha: "",
        }}
        onSubmit={async (data) => {
          const cpfCnpj = data.ouvidoria.cpfCnpj.replace(/\D/gi, "");
          const telefone = data.ouvidoria.contato.telefone.replace(/\D/gi, "");
          const cep = data.ouvidoria.contato.cep.replace(/\D/gi, "");

          const formData = {
            protocolo: {
              numero: data.protocolo,
              produto: data.produto,
            },
            solicitante: {
              nome: data.ouvidoria.nome,
              cpfCnPj: {
                numero: cpfCnpj,
                tipo: cpfCnpj.length === 14 ? 1 : 0,
              },
              telefone: {
                ddd: telefone.replace(/^(\d{2})(9?\d{8})$/gi, "$1"),
                numero: telefone.replace(/^(\d{2})(9?\d{8})$/gi, "$2"),
              },
              email: data.ouvidoria.contato.email,
              endereco: {
                cep,
                endereco: data.ouvidoria.contato.endereco,
                numero: data.ouvidoria.contato.numero,
                complemento: data.ouvidoria.contato.complemento,
                estado: data.ouvidoria.contato.estado,
                cidade: data.ouvidoria.contato.cidade,
                bairro: data.ouvidoria.contato.bairro,
              },
            },
            comentario: data.ouvidoria.pedido,
            anexos: anexo.conteudo
              ? [
                  {
                    nome: anexo.nome,
                    binario: anexo.conteudo,
                    descricao: anexo.descricao,
                  },
                ]
              : null,
          };
          const jsonData = {
            segurado: {
              docSegurado: cpfCnpj,
            },
          };
          const localReCaptcha = data.reCaptcha;
          const returnProductExists = await verificarProduto(
            jsonData,
            localReCaptcha
          );

          const exists =
            returnProductExists.dados !==
            "Nenhum dado encontrado para este CPF.";
          setProductExists(exists);
          if (exists) {
            createOuvidoria(formData, data.reCaptcha);
          } else {
            setShowError(true);
          }
        }}
      />
      {showError && (
        <SemProduto
          title="Não foi possível registrar sua ouvidoria."
          description="Por favor, entre em contato com a Central de Assistências Dia & Noite e Sinistros no 0800 722 2492, de segunda a sexta-feira, das 8h às 18h (exceto feriados nacionais)."
        />
      )}
    </Container>
  );
};

AbrirOuvidoria.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  setNewProtocol: PropTypes.func.isRequired,
  setNewProtocolDate: PropTypes.func.isRequired,
  setErrorOuvidoria: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;

export default AbrirOuvidoria;
