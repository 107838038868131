import React from "react";
import { Alert as BsAlert } from "react-bootstrap";
import { useMakeClass } from "../../../hooks";

const Alert = (props) => {
  const { className } = props;

  const classNames = useMakeClass(
    "cvp-alert",
    "border-0",
    "rounded-0",
    className
  );

  return <BsAlert {...props} className={classNames} />;
};

Alert.defaultProps = {
  ...BsAlert.defaultProps,
};

Alert.propTypes = {
  ...BsAlert.propTypes,
};

export default Alert;
