import styled from "styled-components";
import { media } from "../../../../utils";
import { SubmitControl } from "../../../../components/shared/forms";

export const WarningParagraph = styled.p`
  line-height: 24px;
  font-size: 16px;
  margin: 30px 0 !important;
`;

export const WarningParagraphItalic = styled.p`
  line-height: 24px;
  font-size: 16px;
  margin: 30px 0 !important;
  font-style: italic;
`;

const FormTreeColumnsSinistroGenericDesctop = media.desktop`
  grid-template-columns: 0.31580fr 0.31580fr 0.31580fr;
  display: grid;
  grid-gap: 20px;
`;

const FormTwoColumnsSinistroGenericDesctop = media.desktop`
  grid-template-columns: 0.31fr 0.31fr;
  display: grid;
  grid-gap: 20px;
`;

const FormOneColumnSinistroGenericDesctop = media.desktop`
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: 20px;
`;

export const FormTreeColumnsGeneric = styled.div`
  ${FormTreeColumnsSinistroGenericDesctop}
`;
export const FormOneColumnGeneric = styled.div`
  ${FormOneColumnSinistroGenericDesctop}
`;

export const FormTwoColumnsGeneric = styled.div`
  ${FormTwoColumnsSinistroGenericDesctop}
`;

const ContactDataFormTitleDesktop = media.desktop`
  font-size: 24px;
`;
export const FormOneColumnGenericInline = styled.div`
  display: flex;
  grid-gap: 20px;
  padding-bottom: 40px;
  grid-template-rows: 20px 20px;
`;

export const CEPContainerDesktop = media.desktop`
  max-width: 200px;
  align-items: center;
`;

export const ContactDataFormTitle = styled.h6`
  color: #646363;
  line-height: 30px;
  font-size: 20px;
  margin: 30px 0 !important;
  ${ContactDataFormTitleDesktop}
`;

export const CEPContainer = styled.div`
  flex-direction: column;
  display: flex;
  ${CEPContainerDesktop}
`;

export const SubmitButton = styled(SubmitControl)`
  max-width: 290px;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const ErrorText = styled(Text)`
  font-size: 1em;
  color: #e83131;
`;

export const CharactersLeft = styled.span`
  margin-bottom: 50px;
  font-size: 16px;
`;

export const ContainerRadio = styled.div`
  grid-gap: 10px;
  display: grid;
  grid-template-rows: 10px 20px;
`;

  export const LabelRadioButtonDesktop = media.desktop`
    cursor: pointer;
  `;

export const LabelRadioButton = styled.label`
  cursor: pointer;
  ${LabelRadioButtonDesktop}
  `;  

  export const RadioButtonDesktop = media.desktop`
    cursor: pointer;  
    accent-color: #0066b3;
  `;

export const RadioButtonTipo = styled.input`
  ${RadioButtonDesktop}
  accent-color: #0066b3;
  cursor: pointer;
  `;


export const TooltipBox = styled.div`
  visibility: hidden;
  position: absolute;
  display: block;
  z-index: 0;
  /* margin: 0; */
  margin-left: 70px;
  margin-top: -6px;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-in-out;
  -moz-transition: opacity 0.6s ease-in-out;
  -ms-transition: opacity 0.6s ease-in-out;
  -o-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;

  &:before {
    width: 0;
    height: 0;
    border-right-color: #f46c04;
    border: 10px solid transparent;
    content: "";
    left: -20px;
    position: absolute;
  }
  `;
  export const MarginTop = styled.div`
    margin-top: 50px;
  `;

export const TooltipCard = styled.div`
  &:hover ${TooltipBox} {
    /* ${TooltipBox} { */
    visibility: visible;
    color: #fff;
    background-color: #f46c04;
    width: 284px;
    padding-left: 4px;
    padding-top: 4px;
    border-radius: 3px 4px 4px 3px;
    height: 29px;
    line-height: 16px;
    font-size: small;
    border: 1px solid transparent;
    box-shadow: 2px 2px 4px 1px rgba(64, 60, 67, 0.16);
    -webkit-touch-callout: none; /* iPhone OS, Safari */
    -webkit-user-select: none; /* Chrome, Safari 3 */
    -khtml-user-select: none; /* Safari 2 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* Possível implementação no futuro */
    opacity: 1;
    /* transition: opacity .6s;  */

    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    -ms-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
  }
`;
