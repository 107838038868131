import styled from "styled-components/macro";
import media from "../../styles/media";
import { themes } from "../../styles/theme";
import {
  grayTextSecondaryLight,
  grayTextSecondaryDark,
  blue,
} from "../../styles/colors";
import { fontSize } from "../../styles/mixins";

import RoundButton from "../RoundButton";

export const ModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 127px;
  left: 0;
  z-index: 99;
`;

const mediaDesktopModal = media.desktop`
width: 886px;
margin: auto;
height: unset;
`;
export const Modal = styled.div`
  background-color: ${themes.default.modalOverlayColcor};
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 90%;
  align-self: center;
  max-height: 100%;
  overflow: visible;

  ${mediaDesktopModal};
`;

export const ModalContent = styled.div`
  margin-top: 15px;
  flex-grow: 1;
`;

export const Overlay = styled.div`
  background-color: ${themes.default.modalOverlayColor};
  padding: 20px 30px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 16px;
`;

export const CloseButton = styled(RoundButton)`
  color: ${themes.default.primaryColor};
  ${fontSize(20)};
  position: absolute;
  height: 35px;
  width: 35px;
  right: -15px;
  top: -15px;
  padding: 0;
  box-shadow: -19px 34px 36px -10px rgba(255, 166, 0, 0.61);
  cursor: pointer;
  span {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    color: ${themes.default.hoverColor};
  }
`;

const modalDesktopFooter = media.desktop`
flex-direction: row;
justify-content: center;
margin-top: 50px;
`;
const modalDesktopFooterButton = media.desktop`
width: 40%;
margin-right: 20px;

&:last-child {
  margin-right: 0;
}
`;
export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${modalDesktopFooter};

  .modalButton {
    width: 100%;
    margin-bottom: 20px;

    ${modalDesktopFooterButton};
  }
`;

export const ContentContainer = styled.div({
  paddingBottom: "130px",
  textAlign: "center",
});

export const SmallTitle = styled.h4({
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: 12,
  textAlign: "center",
  lineHeight: "18px",
  letterSpacing: 3,
  textTransform: "uppercase",
  color: grayTextSecondaryLight,
  margin: "0px 0px 20px",
});

export const BigTitle = styled.h1({
  fontWeight: "normal",
  fontSize: "32px",
  lineHeight: "40px",
  color: grayTextSecondaryDark,
  margin: "0px 0px 20px",
  textAlign: "center",
});

export const Text = styled.p({
  textAlign: "center",
  fontWeight: "300",
  fontSize: "18px",
  lineHeight: "29px",
  color: grayTextSecondaryDark,
  marginBottom: "20px",
});

const desktopButton = media.desktop`
 width: 20%;
  display: inline-block; 
`;
export const Button = styled.a`
  color: blue;
  align-self: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0;
  display: block;
  border-radius: 16px;
  border: 1px solid ${blue};
  padding: 10px 20px;
  cursor: pointer;
  ${desktopButton}
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${grayTextSecondaryDark};
  &:hover {
    color: ${blue};
  }
`;
