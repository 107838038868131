import React from 'react';
import { PropTypes } from 'prop-types';
import { BannerContents } from '../../../components/shared/layout/banners';
import { LINKS } from '../../../constants';
import { BannerIr } from '../../../components/blocosCustomizados';

export const BannerIR = (props) => {
  const { images } = props;
  return (
    <BannerContents {...props}>
      <BannerIr
        backgroundImage={images.bannerIrLandingPage}
        buttonLink={LINKS.areaLogada}
        text={
          <div className="informe-banner">
            <h3>
              O seu informe de rendimentos
              <br />
              já está disponível.
            </h3>
            <br />
            <p>Acesse agora e faça o download</p>
          </div>
        }
      />
    </BannerContents>
  );
};

BannerIR.defaultProps = { images: {} };

BannerIR.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerIR;
