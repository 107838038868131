import React, { useState } from 'react';
import AccordionContext from '../context/AccordionContext';

const AccordionContextProvider = ({ children }) => {
    const [openAccordionDefault, setOpenAccordionDefault] = useState(false);

    const openAccordion = () => setOpenAccordionDefault(true);
    const closeAccordion = () => setOpenAccordionDefault(false);

    const context = {
        openAccordionDefault, 
        openAccordion,
        closeAccordion
    }

    return (<AccordionContext.Provider value={context}>{children}</AccordionContext.Provider>)
}

export default AccordionContextProvider;