import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Form from "../../../components/shared/forms";
import RegulamentosForm from "./RegulamentosForm";

const Regulamentos = ({ sectionTitle }) => {
  return (
    <>
      <h2 className="text-center">{sectionTitle}</h2>
      <Form
        form={RegulamentosForm}
        formId="regulamentos-form"
        validationSchema={{
          cobertura: Yup.string(),
          tipoProduto: Yup.string(),
          produto: Yup.string().when("cobertura", {
            is: (cobertura) => cobertura === "Renda",
            then: Yup.string(),
          }),
          modalidade: Yup.mixed().when("cobertura", {
            is: (cobertura) => cobertura === "Renda",
            then: Yup.string(),
          }),
          fundo: Yup.string().when("cobertura", {
            is: (cobertura) => cobertura === "Renda",
            then: Yup.string(),
            otherwise: Yup.string(),
          }),
        }}
        initialValues={{ cobertura: "", tipoProduto: "", produto: "" }}
      />
    </>
  );
};

Regulamentos.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

export default Regulamentos;
