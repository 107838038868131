import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Section } from "@components/shared/pages";
import { useMakeClass } from "../../../../hooks";

const BannerContent = ({ content, right, customPros }) => {
  const colConfig = right
    ? { md: { colspan: 6, offset: 6 }, lg: { colspan: 4, offset: 8 } }
    : { md: 6, lg: 4 };

  return (
    <Row className="banner-content">
      <Col {...{ xs: 12, ...colConfig, ...customPros }}>{content}</Col>
    </Row>
  );
};

BannerContent.defaultProps = {
  right: false,
  customPros: null,
};

BannerContent.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  right: PropTypes.bool,
  customPros: PropTypes.objectOf(Object),
};

const Banner = (props) => {
  const { right, children, className } = props;

  const classes = useMakeClass(`page-banner`, className);

  return (
    <Section
      as={BannerContent}
      right={right}
      content={children}
      {...props}
      className={classes}
      sectionName="banner produto"
    />
  );
};

Banner.valid = (el) => Banner.name === el.type.name;

Banner.defaultProps = {
  right: false,
  group: null,
  className: null,
  customPros: null,
};

Banner.propTypes = {
  right: PropTypes.bool,
  group: PropTypes.string,
  className: PropTypes.string,
  customPros: PropTypes.objectOf(Object),
};

export default Banner;
