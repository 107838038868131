import React from 'react';
import Apresentacao from '../../../components/blocosCustomizados/Apresentacao';
import { Section } from '../../../components/shared/pages';
import imagensSinistro from '../../../asserts/images/sinistro';

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroAtencao}
      title={sectionTitle}
      imageColumn={4}
      right
    >
      <p>
        Ao escolher o envio{' '}
        <strong>aqui pelo site, APP Caixa Vida e Previdência</strong> ou{' '}
        <strong>WhatsApp</strong>, o prazo de <strong>avaliação</strong> dos
        documentos encaminhados para que sejam acatados é de{' '}
        <strong>até 3 dias úteis.</strong>
      </p>
      <p>
        A avaliação dos documentos encaminhados digitalmente ocorrerá de segunda
        a sexta-feira, das 8h às 18h.
      </p>
      <p>
        O atendimento do <strong>WhatsApp</strong> é realizado de segunda a
        sexta-feira, das 8h às 16h. Para documentos recebidos após os horários
        do respectivo canal, consideraremos a data de recebimento no próximo dia
        útil.
      </p>
      <p>
        Estando a documentação correta e completa, inicia-se o prazo de contagem
        do prazo regulamentar da SUSEP.
      </p>
      <p>
        <strong>IMPORTANTE:</strong> caso seja necessário, a Seguradora poderá
        solicitar o envio da forma física do documento, se achar pertinente para
        a regulação do sinistro.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
