import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";

export const Planejamento = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.PlanejamentoSVG} title={sectionTitle}>
      <p>
        Investir também é cuidar do patrimônio da família. Por isso, um
        planejamento sucessório te dá mais vantagens, pois a previdência é o
        único investimento que possibilita o repasse do recurso para os
        beneficiários do plano em até 30 dias após o falecimento do titular.
        Isso porque os planos não são considerados herança e não integram o
        inventário.
      </p>

      <p>
        Para que isso seja feito sem problemas, é necessário que os
        beneficiários estejam indicados no plano. Caso contrário, o processo vai
        seguir os trâmites normais, como se fosse uma outra modalidade de
        investimento, que pode estar sujeita a bloqueio por inventário e,
        consequentemente, demandar pagamento de honorários advocatícios.
      </p>
    </Apresentacao>
  );
};

Planejamento.defaultProps = {};

Planejamento.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default Planejamento;
