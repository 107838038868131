import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Page, Section } from "@components/shared/pages";
import imagensPrestamista from "@asserts/images/prestamista";
import {
  BannerPrestamista,
  ApresentacaoFies,
  QuantoCusta,
  Sorteios,
  TemAlgumaDuvidaFies,
} from "../sections";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrestamistaFies = useTrackEvent(appInsights, "AA_PrestamistaFies");

  useEffect(() => {
    const prestamistaFiesEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Seguro Dívida Zero FIES",
      virtualPageURL: "/segurodividazerofies",
    };
    trackPrestamistaFies(prestamistaFiesEvent);
  }, [trackPrestamistaFies]);

  return (
    <Page
      images={imagensPrestamista}
      {...props}
      banner={BannerPrestamista}
      pageTitle="Prestamista Fies"
      analyticsPage="prestamistafies"
    >
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoFies}
          id="apresentacao"
          title="Por que contratar o Seguro Dívida Zero para o FIES?"
          sectionName="porque contratar"
        />
        <Section
          as={QuantoCusta}
          id="quanto-custa"
          title="Quanto custa?"
          sectionName="quanto custa"
        />
        <Section
          as={Sorteios}
          id="sorteios"
          title="Sorteios"
          sectionName="sorteios"
        />
        <Section
          as={TemAlgumaDuvidaFies}
          title="Tem alguma dúvida?"
          className="bloco-tem-alguma-duvida background-image-none-sm border-top"
          id="tem-alguma-duvida"
          sectionLayout={null}
          sectionName="duvidas"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
