import "./style.css";
import React from "react";
import { HomePage } from "./pages";
import AreaRoute from "../../components/shared/routes";
import { envs } from "../../config";

export const PrestamistaFies = () => {
  return (
    <AreaRoute
      main={HomePage}
      title="Seguro Dívida Zero Fies"
      chatId={envs.prestamistaChatId}
    />
  );
};

export default PrestamistaFies;
