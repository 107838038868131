import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensSinistro from "@asserts/images/sinistro";
import { BannerSinistro, AbrirSinistro } from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import { SinisterContextProvider } from "../../../context/SinisterContext";

export const AberturaPage = (props) => {
  const [errorSinistro, setErrorSinistro] = useState(false);
  const appInsight = useAppInsightsContext();
  const trackSinistro = useTrackEvent(appInsight, "AA_Sinistro");

  useEffect(() => {
    const sinistroEvent = {
      event: "AA.navegacao",
      virtualPageURL: "/sinistro",
      virtualPageTitle: "Caixa Vida e Previdência - Sinistro",
    };
    trackSinistro(sinistroEvent);
  }, [trackSinistro]);

  return (
    <Page {...props} images={imagensSinistro} banner={BannerSinistro}>
      <SeguridadePageLayout.Tab eventKey="sinistro">
        <SinisterContextProvider>
          <Section
            as={AbrirSinistro}
            sectionLayout={null}
            errorSinistro={errorSinistro}
            title="Faça aqui o comunicado de sinistro."
            id="sinistro-abertura-sinistro"
            setErrorSinistro={setErrorSinistro}
          />
        </SinisterContextProvider>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default AberturaPage;