import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoAcidentesPessoais = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao
      image={images.acidentesPessoaisApresentacao}
      imageColumn={3}
      title={sectionTitle}      
    >      
      <>
        <p>
          Os seguros de acidentes pessoais da Caixa Vida e Previdência cuidam de
          milhões de famílias brasileiras. São soluções pensadas para proteger
          os beneficiários com pagamento de indenização e garantir ótimos
          benefícios para o(a) segurado(a) como redução de preço em consultas e
          exames, remédios e sorteios mensais.
        </p>
        <p>Além dos benefícios para utilização em vida, como:</p>
        <ul>
          <li>Assistência farmácia;</li>
          <li>Assistência funeral familiar;</li>
          <li>
            Acesso a consultas e exames, inclusive odontológicos, com preços
            reduzidos na rede particular;
          </li>
          <li>Assistência cesta básica;</li>
          <li>Sorteios mensais;</li>{" "}
        </ul>
        <p>
          <b>
            Você não precisa ser correntista da CAIXA para adquirir e aproveitar
            os benefícios.
          </b>
        </p>
      </>
    </Apresentacao>
  );
};

ApresentacaoAcidentesPessoais.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

ApresentacaoAcidentesPessoais.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoAcidentesPessoais;
