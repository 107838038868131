import envs from "../envs";

export const getGeneralConditionsMapEndPoint = (product) =>
  `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/${product}/mapeamento-vida.json`;

export const getGeneralConditionsMapEndPointDividaZero = (product) =>
  `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/${product}/mapeamento-seguro-divida-zero.json`;

export const getRegulamentosEndPoint = `${envs.hostStaticCdn}/areaaberta/Previdencia/Regulamentos/mapeamento-v5.json`;
export const getVariaveisSimuladorEndPoint = `${envs.hostStaticCdn}/areaaberta/Previdencia/Simulador/simulador.json`;
export const getCampanhasEndPoint = `${envs.hostStaticCdn}/areaaberta/Campanhas/mapeamento.json`;
export const getCampanhasPrestamistaEndPoint = `${envs.hostStaticCdn}/areaaberta/Campanhas/mapeamento-prestamista.json`;
export const getRegulamentosAcoesPromocionaisEndPoint = `${envs.hostStaticCdn}/areaaberta/Previdencia/Regulamentos/acoes-promocionais.json`;
