/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const GenericPhoneInput = ({ id, name, placeHolder, label, value, formikBag, width }) => {
  const phoneMask = (val) => {
    return val
      .replace(/\D/, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const handlePhoneCellphone = (e) => {
    const unmasked = e.target.value
      .replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
    e.target.value = phoneMask(unmasked);
    formikBag.onChange(e);
  };

  return (
    <InputControl
      inputStyle={{ minHeight: "50px", width}}
      controlId={id}
      name={name}
      label={label}
      placeHolder={placeHolder}
      type="tel"
      value={value}
      {...formikBag}
      onChange={handlePhoneCellphone}
    />
  );
};

GenericPhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default GenericPhoneInput;
