import React from 'react';

import Apresentacao from '../../../components/blocosCustomizados/Apresentacao';
import { Section } from '../../../components/shared/pages';
import imagensSinistro from '../../../asserts/images/sinistro';

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroChecklist}
      title={sectionTitle}
      imageColumn={4}
      right
    >
      <p>
        O CHECKLIST consiste em uma lista de documentos necessários para a
        análise do processo de sinistro. Essa documentação irá variar de acordo
        com a cobertura pleiteada, bem como para os casos de morte, qual o tipo
        de beneficiário que irá receber a documentação: se indicado ou herdeiros
        legais.
      </p>
      <p>
        O nosso CHECKLIST contém todas as informações sobre os documentos e
        tenta ao máximo auxiliar o cliente na localização e disponibilização
        deles. Ela é dividida assim:
      </p>
      <ul>
        <li>
          <p>Tipo de documento: variável conforme cobertura.</p>
        </li>
        <li>
          <p>Descrição: qual documento deverá ser encaminhado.</p>
        </li>
        <li>
          <p>
            Forma do documento e onde obtê-lo: cópia simples ou autenticada, e o
            direcionamento de onde conseguir tal documentação.
          </p>
        </li>
      </ul>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
