import React from "react";
import PropTypes from "prop-types";
import { ContentWithCardListBlock } from "@components/blocosCustomizados";
import { createImageElement } from "../../asserts/images";

const BenefitsBlocks = ({ items, sectionTitle }) => {
  const conteudoVantagem = items?.map(({ title, image, description }) => ({
    imagem: createImageElement(image, {
      alt: title,
      className: "cvp-benefit-image w-100 h-100",
    }),
    conteudo: <div className="conteudo">{description}</div>,
  }));
  return (
    <ContentWithCardListBlock listItens={conteudoVantagem} className="cvp-benefits">
      <p className="content-title"><strong>{sectionTitle}</strong></p>
    </ContentWithCardListBlock>
  );
};

BenefitsBlocks.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default BenefitsBlocks;

