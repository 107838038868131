import styled from 'styled-components';
import media from '../../../styles/media';
import { Field } from 'formik';
import { Col, Modal } from 'react-bootstrap';

export const Countdown = styled.div`
  border-top: 1px solid #e1e1e1;
  width: 100%;
  text-align: center;
  padding-top: 10px;

  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
  }
`;

export const ModalTitle = styled.p`
  font-size: 18px;
  color: "#494949",
  font-weight: normal;
`;

export const InsertCodeInput = styled(Field)`
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #e1e1e1;
  padding: 15px 10px;
`;

export const Footer = styled(Modal.Footer)`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
border: none;
`;

export const ColModal = styled(Col)`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

gap: 10px;

> * {
  width: 100%;
  ${media && media.desktop && 'width: unset'}
}

input,
button {
  ${media && media.desktop && 'margin-right: 10px; width: 280px'}
}
`;
