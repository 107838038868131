import React from "react";
import PropTypes from "prop-types";
import DrawResult, {
  combinacaoSeis,
} from "../../../components/blocosCustomizados/DrawResult";
import resultadosAnteriores from "../data/resultadoSorteio.json";
import { PhoneLink } from "../../../components/shared/actions";

const produtoPrestamista = "860";

const ResultadoSorteios = ({ sectionTitle }) => {
  return (
    <>
      <div className="text-center">
        <h3>{sectionTitle}</h3>
        <p>
          Ao adquirir seu Seguro Dívida Zero você concorre a prêmios em dinheiro
          no último sábado de cada mês. Caso seja contemplado, nós entraremos em
          contato com você para comunicá-lo. :)
        </p>
        <p>
          Para consultar o resultado do sorteio você precisa ter em mãos o seu
          número da sorte.
        </p>
        <DrawResult
          defaultResultAs={combinacaoSeis}
          defaultProductCode={produtoPrestamista}
          staticResults={{ [produtoPrestamista]: resultadosAnteriores }}
          displayResultLabel="1º prêmio"
          title="Selecione a data e o ano para conferir os resultados dos sorteios:"
        />
        <p className="text-brand pt-3">
          <strong>
            É bom lembrar: mesmo que ganhe um sorteio, você continua
            participando nos próximos meses.
          </strong>
        </p>
        <p>
          Caso tenha qualquer dificuldade, fale com a gente na Central de
          Serviços e Relacionamento:
        </p>
        <p>
          <PhoneLink phone="0800 702 4000" />
        </p>
      </div>
    </>
  );
};

ResultadoSorteios.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

export default ResultadoSorteios;
