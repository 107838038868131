import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const QuantoCusta = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.quantoCustaFiesIlustracao} title={sectionTitle}>
      <p>
        O valor que você vai pagar mensalmente pelo Seguro Dívida Zero varia de
        acordo com o curso que você escolheu, sendo cobrado junto com sua
        parcela do contrato do FIES.
      </p>
      <p>
        Medicina: R$ 12,90/mês
        <br />
        Demais cursos R$ 4,60/mês
      </p>
    </Apresentacao>
  );
};

QuantoCusta.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

QuantoCusta.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default QuantoCusta;
