/* eslint-disable import/prefer-default-export */
import styled from "styled-components/macro";
import { transparent } from "../../styles/colors";

export const Backdrop = styled.div`
  background-color: ${transparent};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
`;
