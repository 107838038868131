import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import useStaticRequest from "./useStaticRequest";

/**
 * This service hook will fetch general conditions data from a remote location.
 *
 * @component
 * @return  {UseGeneralConditionsMapHook}
 *
 * @typedef    {Object}    UseGeneralConditionsMapHook
 *  @property  {function}  getGeneralConditions         Function used for API call
 *  @property  {number}    status                       HTTP status code
 *  @property  {Object}    data                         Raw response data
 *  @property  {boolean}   loading                      Loading indicator
 *  @property  {boolean}   error                        Error indicator
 */
const useGeneralConditionsMap = () => {
  const { performStaticRequest, error } = useStaticRequest();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();

  /**
   * Fetches general conditions data
   * @param   {string}   product     Product key for lookup
   */
 
  const getGeneralConditions = (product) => {
    setLoading(true);
    const endPoint = product === 'Prestamista'
    ? endPoints.getGeneralConditionsMapEndPointDividaZero(product)
    : endPoints.getGeneralConditionsMapEndPoint(product);
  
  const response = performStaticRequest(endPoint);

    response.then((responseData) => {
      setStatus(responseData?.status);
      setData(responseData?.data);
      setLoading(false);
    });
  };
  
  return { getGeneralConditions, status, data, loading, error };
};

useGeneralConditionsMap.propTypes = {
  getGeneralConditions: PropTypes.func,
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useGeneralConditionsMap;
