import React from 'react';
import { Section } from '@components/shared/pages';
import { Button } from '../../../components/shared/actions';
import { FazerUmaCompra } from '../../../components/shared/blocks';

export const FacaSeguroVida = ({ sectionTitle, info }) => {
  return (
    <FazerUmaCompra sectionTitle={sectionTitle} info={info}>
      <Button blank secondary href="/sinistro/abertura">
        Comunicar sinistro
      </Button>
      <Button blank secondary href="/sinistro/enviodocumentos">
        Enviar documentação
      </Button>
      <Button blank secondary href="/sinistro/login">
        Acompanhamento
      </Button>
    </FazerUmaCompra>
  );
};

FacaSeguroVida.propTypes = {
  ...Section.specializedProps,
};

export default FacaSeguroVida;
