import React from "react";
import {
  ContainerSemProduto,
  TitleSemProduto,
  DescriptionSemProduto,
} from "./styles";

const SemProduto = ({title, description}) => {
  return (
    <ContainerSemProduto>
      <TitleSemProduto variant="headline-06" align="left">
        {title}
      </TitleSemProduto>
      <DescriptionSemProduto variant="body01-lg" align="left">
        {description}
      </DescriptionSemProduto>
    </ContainerSemProduto>
  );
};
export default SemProduto;
