import React from "react";
import { HomePage } from "./pages";
import AreaRoute from "../../components/shared/routes";
import "./style.css";

export const Home = () => {
  return <AreaRoute main={HomePage} title="Página" />;
};

export default Home;
