import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const AddressComplementInput = ({label, complemento, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.complemento"
    label={label}
    placeHolder="Digite um complemento"
    name="reclamante.complemento"
    value={complemento}
    {...formikBag}
  />
);

AddressComplementInput.propTypes = {
  complemento: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default AddressComplementInput;
