import React from "react";
import { Col, Row } from "react-bootstrap";
import { Section } from "@components/shared/pages";
import { PhoneLink } from "../../../components/shared/actions";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

const conteudoTemAlgumaDuvida = [
  {
    label: "Tenho de esperar pra começar a usar o meu seguro amparo?",
    content: (
      <p>
        Sim, você deve aguardar o prazo de 60 dias após contratar para começar a
        ter a indenização e organização do funeral. Já os sorteios valem desde o
        começo do seu contrato.
      </p>
    ),
  },
  {
    label: "Posso escolher o cemitério?",
    content: (
      <p>
        Sim, se na cidade onde você morar houver disponibilidade em cemitérios
        públicos ou particulares, a família pode escolher. Se for no particular,
        a gente faz um comparativo de valor como se fosse um enterro em um
        cemitério público e pagamos. A diferença a ser paga fica por conta da
        família.
      </p>
    ),
  },
  {
    label: "Existe transporte pra alguém da família ou hospedagem?",
    content: (
      <p>
        Caso a morte ocorra em um lugar que não é onde você mora e precise de
        alguém pra liberação do corpo, fornecemos a essa pessoa o meio de
        transporte mais apropriado. Se necessário, fornecemos também hospedagem
        em hotel, sempre respeitando o serviço da assistência.
      </p>
    ),
  },

  {
    label: "Quem pode e onde avisar o seguro?",
    content: (
      <p>
        Qualquer pessoa pode ligar na assistência funeral no{" "}
        <PhoneLink phone="0800 722 2492" />, ou se estiver fora do País, no{" "}
        <PhoneLink phone=" + 55 11 4199 3344" />, e pedir os serviços. É
        necessário informar o CPF do dono do seguro.
      </p>
    ),
  },
  {
    label: "Se eu morrer fora do Brasil, tenho direito ao serviço?",
    content: (
      <p>
        Sim. Basta ligar no <PhoneLink phone="0800 722 2492" /> e o transporte
        será realizado até o Brasil (local de domicílio), onde será realizado o
        sepultamento.
      </p>
    ),
  },
  {
    label:
      "Pra começar a usar o serviço funeral, eu preciso pagar alguma coisa?",
    content: (
      <p>
        Não é necessário realizar nenhum pagamento ou caução, o seguro garante o
        serviço funeral completo sem custo para os familiares.
      </p>
    ),
  },
  {
    label:
      "Existe um valor máximo pra ter os serviços e as despesas funerárias?",
    content: (
      <p>
        Não. Nós organizamos e pagamos todo o serviço de funeral sem custo para
        os familiares. Todas as despesas são de responsabilidade nossa de acordo
        com os itens cobertos. Lembrando que, não é permitido realizar o funeral
        por empresa particular.
      </p>
    ),
  },
  {
    label:
      "Se minha família decidir por cremação e não existir um crematório na cidade, como é feito?",
    content: (
      <p>
        A gente realiza a cremação na cidade mais próxima (até 100 km) e
        enviamos as cinzas caso a família queira.
      </p>
    ),
  },
  {
    label:
      "Minha família não tem um conjunto de túmulos no cemitério, qual o procedimento?",
    content: (
      <>
        <p>
          A gente cuida do aluguel do jazigo (conjunto de túmulos), nos
          critérios abaixo:
        </p>

        <p>
          Cemitério particular – geralmente não há locação, somente compra. A
          gente faz um comparativo de valor, como se fosse uma locação em um
          cemitério público e pagamos. A diferença a ser paga, fica por conta da
          família. Se houver como fazer a locação no particular, arca-se com o
          valor da locação pelo período de 3 a 5 anos, conforme legislação
          local. Cemitério público/municipal – locação de jazigo por 3 a 5 anos,
          conforme legislação local.
        </p>
      </>
    ),
  },
  {
    label: "Se eu comprar o jazigo por fora, eu tenho reembolso depois?",
    content: (
      <p>Todos os serviços do seguro são sem possibilidade de reembolso.</p>
    ),
  },
  {
    label: "O que o seguro não cobre?",
    content: (
      <Row>
        <Col xs={12} md={6}>
          <ul>
            <li>
              Assistência funeral para cônjuge, filhos, sogro, sogra, pai e mãe;
            </li>
            <li>Assistência para seguros cancelados;</li>
            <li>Despesas com transporte para familiares;</li>
            <li>Despesas com hospedagem;</li>
            <li>Despesas com compra e construção de jazigo;</li>
            <li>Acionamento de funerária particular;</li>
          </ul>
        </Col>
        <Col xs={12} md={6}>
          <ul>
            <li>Taxa de exumação;</li>
            <li>Reembolso de despesas com o funeral;</li>
            <li>Reembolso de qualquer despesa relacionada ao funeral;</li>
            <li>Fraude;</li>
            <li>Omissão de informações.</li>
          </ul>
        </Col>
      </Row>
    ),
  },
  {
    label: "Meu Seguro Amparo pode ser cancelado sem eu pedir?",
    content: (
      <p>
        A gente se dá o direito de cancelar automaticamente o seguro sempre que
        houver omissão e falsificação de informações e fraudes.
      </p>
    ),
  },
  {
    label: "Como cancelar meu seguro?",
    content: (
      <>
        <p>
          Para cancelar o seu seguro você pode ligar pra gente nos seguintes
          telefones:
        </p>
        <p>
          <h6 className="amparo-central-atendimento-cancelar">
            Central de Atendimento
          </h6>
          <PhoneLink phone="0800 702 4000" />
        </p>
        <p>
          <h6 className="amparo-sac-cancelar">SAC</h6>
          <PhoneLink phone="0800 702 4280" />
        </p>
      </>
    ),
  },
];

export const TemAlgumaDuvida = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={conteudoTemAlgumaDuvida}
      variant="seguridade-light"
      right
      columnContent
      numberMaxShowCard={3}
    />
  );
};

TemAlgumaDuvida.propTypes = {
  ...Section.specializedProps,
};

export default TemAlgumaDuvida;
