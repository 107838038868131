import React, { useState, useEffect } from 'react';
import FooterLogin from '../sections/footer-login/FooterLogin';
import ModalLogin from '../sections/footer-login/ModalLogin';
import { useHistory } from 'react-router-dom';
import useSinisterTimeline from '../../../hooks/requests/useSinisterTimeline';
import { verifyCPF } from '../../../utils';
import FormLogin from './FormLogin';

import './style.css';

export const LoginPage = () => {
  const [cpfSegurado, setCpfSegurado] = useState('');
  const [cpfBeneficiario, setCpfBeneficiario] = useState('');
  const [cpfTitular, setCpfTitular] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const { verificarBeneficiarioSinistro, loading } = useSinisterTimeline();
  const [cpfError, setCpfError] = useState(false);
  const history = useHistory();

  const specialCPFs = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  const fetchData = async () => {
    const result = await verificarBeneficiarioSinistro({
      cpfSegurado: cpfSegurado
        .replaceAll('.', '')
        .replace('-', '')
        .replace('/', ''),
      cpfBeneficiario: cpfBeneficiario
        .replaceAll('.', '')
        .replace('-', '')
        .replace('/', ''),
    });

    const titular = cpfSegurado
      .replaceAll('.', '')
      .replace('-', '')
      .replace('/', '');

    if (result?.dados.entidade) {
      history.push('/sinistro/acompanhar', { cpfTitular: titular });
      return;
    }
    setMessage(result?.dados.mensagens[0].descricao);
    setShowMessage(true);
  };

  const setCpf = (inputNumbers, segurado) => {
    if (segurado) {
      setCpfSegurado(inputNumbers);
    }
    setCpfBeneficiario(inputNumbers);
  };

  const isSpecialCPF = (cpf) => specialCPFs.includes(cpf);

  const isCpfValid = (inputNumbers, segurado) => {
    if (!isSpecialCPF(inputNumbers) && verifyCPF(inputNumbers)) {
      setCpf(inputNumbers, segurado);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (cpfTitular) {
      setCpfBeneficiario(cpfSegurado);
      return;
    }
    setCpfBeneficiario('');
  }, [cpfTitular]);

  useEffect(() => {
    setDisabled(
      !(
        cpfSegurado.length === 14 &&
        cpfBeneficiario.length === 14 &&
        cpfSegurado !== '' &&
        cpfBeneficiario !== ''
      )
    );
  }, [cpfSegurado, cpfBeneficiario]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showMessage) {
      const timeoutId = setTimeout(() => {
        setShowMessage(false);
        setMessage('');
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showMessage]);

  return (
    <>
      <FormLogin
        cpfBeneficiario={cpfBeneficiario}
        cpfError={cpfError}
        cpfSegurado={cpfSegurado}
        cpfTitular={cpfTitular}
        disabled={disabled}
        fetchData={fetchData}
        isCpfValid={isCpfValid}
        loading={loading}
        message={message}
        setCpfError={setCpfError}
        setCpfTitular={setCpfTitular}
        setShowModal={setShowModal}
        showMessage={showMessage}
      />
      <ModalLogin
        showModal={showModal}
        setShowModal={setShowModal}
        backdrop="static"
      />
      <div className="footer-login-page">
        <FooterLogin />
      </div>
    </>
  );
};
export default LoginPage;
