import React from 'react';
import { GenericSelectInput } from './FormInputs';

const PessoaSinistradaInput = ({
  isEventoMorte,
  pessoa,
  selectInputOptionsPessoa,
  formikBag,
}) => {
  return (
    <>
      {isEventoMorte && (
        <GenericSelectInput
          controlId="formSinistro.dadosSinistro.pessoa"
          name="dadosSinistro.pessoa"
          label="Pessoa Sinistrada *"
          placeHolder="Selecione a pessoa"
          value={pessoa}
          options={selectInputOptionsPessoa}
          formikBag={formikBag}
        />
      )}
    </>
  );
};

export default PessoaSinistradaInput;
