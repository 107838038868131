import React from "react";
import { Section } from "../../../components/shared/pages";
import FaleCom from "../../../components/shared/blocks/FaleCom";

export const FacaSeguroVidaSenior = ({ sectionTitle }) => {
  return (
    <FaleCom sectionTitle={sectionTitle} />  
  );
};
FacaSeguroVidaSenior.defaultProps = {
  ...Section.defaultProps,
};
FacaSeguroVidaSenior.propTypes = {
  ...Section.propTypes,
};

export default FacaSeguroVidaSenior;
