import React from "react";
import GeneralConditions from "../../../components/blocosCustomizados/GeneralConditions";
import produtos from "../data/produtosPrestamista.json";

const CondicoesGeraisPrestamista = () => {
  return (
    <GeneralConditions
      mainProduct="Prestamista"
      subProducts={produtos}
      title="Condições Gerais e Regulamentos"
      frequency={false}
    />
  );
};

export default CondicoesGeraisPrestamista;
