/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Collapse } from "react-bootstrap";
import { convertFloatInPrice } from "../../../../utils";
import { Button } from "../../../../components/shared/actions";
import SimuladorContext from "./SimuladorContext";

const renderCell = (value) => {
  if (value > 0) {
    return convertFloatInPrice(value || 0);
  }

  return convertFloatInPrice(0);
};

const SimuladorResultado = ({ handleClear }) => {
  const [viewPlus, setViewPlus] = useState(false);

  const {
    results: {
      valorDeducaoFiscal,
      deducaoInss: contribuicaoInss,
      invistaMais,
      impostoRenda,
      impostoRendaDeduzidoPrev,
      totalDeducoes,
      totalDeducoesComPrev,
      economiaComInvestimentoPGBL,
      despesasMedicas,
      investEducacaoPropria,
      investEducacaoDependentes,
      pensaoAlimenticia,
      previdenciaComplementar,
      previdenciaPBGL,
      deducaoDependentesIR,
      rendaBaseIR,
      rendaBaseIRSemPrev,
    },
  } = useContext(SimuladorContext);

  const handleRecalcular = () => {
    handleClear();
    setViewPlus(false);
  };

  return (
    <>
      <h4 className="title-eco">
        Veja o quanto você pode economizar com a Previdência (PGBL)
      </h4>
      <div className="result">
        <div className="top-res">
          <div className="item-res">
            <p className="info-res">
              Valor ideal de contribuição (para atingir o valor máximo de
              dedução do Imposto de Renda)
            </p>
            <p className="value-res">{renderCell(valorDeducaoFiscal)}</p>
          </div>
          <div className="item-res">
            <p className="info-res">Você pode investir ainda mais:</p>
            <p className="value-res">{renderCell(invistaMais)}</p>
          </div>
          <div className="item-res">
            <p className="info-res">Sua economia atual:</p>
            <p className="value-res">
              {renderCell(economiaComInvestimentoPGBL)}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn-plus"
            onClick={() => setViewPlus(!viewPlus)}
          >
            {viewPlus ? (
              <>
                Ver menos <MdKeyboardArrowUp color="#ffffff" size={25} />
              </>
            ) : (
              <>
                Ver mais <MdKeyboardArrowDown color="#ffffff" size={25} />
              </>
            )}
          </button>
        </div>
      </div>
      <Collapse in={viewPlus}>
        <div className="table-result">
          <table>
            <thead>
              <tr>
                <th>Sem previdência</th>
                <th className="blue">Com previdência</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>INSS ou Regime próprio de previdência</td>
                <td>{renderCell(contribuicaoInss)}</td>
                <td>{renderCell(contribuicaoInss)}</td>
              </tr>
              <tr>
                <td>Dependentes de IR</td>
                <td>{renderCell(deducaoDependentesIR)}</td>
                <td>{renderCell(deducaoDependentesIR)}</td>
              </tr>
              <tr>
                <td>Despesas médicas</td>
                <td>{renderCell(despesasMedicas)}</td>
                <td>{renderCell(despesasMedicas)}</td>
              </tr>
              <tr>
                <td>Educação própria</td>
                <td>{renderCell(investEducacaoPropria)}</td>
                <td>{renderCell(investEducacaoPropria)}</td>
              </tr>
              <tr>
                <td>Educação de dependentes</td>
                <td>{renderCell(investEducacaoDependentes)}</td>
                <td>{renderCell(investEducacaoDependentes)}</td>
              </tr>
              <tr>
                <td>Pensão Alimentícia</td>
                <td>{renderCell(pensaoAlimenticia)}</td>
                <td>{renderCell(pensaoAlimenticia)}</td>
              </tr>
              <tr>
                <td>Previdência complementar dedutível</td>
                <td>{renderCell(previdenciaComplementar)}</td>
                <td>{renderCell(previdenciaComplementar)}</td>
              </tr>
              <tr className="end-line">
                <td>Investimento em previdência PBGL</td>
                <td> - </td>
                <td>{renderCell(previdenciaPBGL)}</td>
              </tr>
              <tr className="back-gray">
                <td>Total deduções</td>
                <td>{renderCell(totalDeducoes)}</td>
                <td>{renderCell(totalDeducoesComPrev)}</td>
              </tr>
              <tr className="back-gray-black">
                <td>Base de cálculo IR</td>
                <td>{renderCell(rendaBaseIR)}</td>
                <td>{renderCell(rendaBaseIRSemPrev)}</td>
              </tr>
              <tr className="back-gray c-blue">
                <td>Imposto de Renda anual devido</td>
                <td>{renderCell(impostoRenda)}</td>
                <td>{renderCell(impostoRendaDeduzidoPrev)}</td>
              </tr>
              <tr className="total c-blue">
                <td>Benefício Fiscal</td>
                <td className="big">
                  {renderCell(economiaComInvestimentoPGBL)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Button transparent onClick={handleRecalcular}>
              Recalcular
            </Button>
          </div>
        </div>
      </Collapse>
    </>
  );
};

SimuladorResultado.propTypes = {
  handleClear: PropTypes.func.isRequired,
};

export default SimuladorResultado;
