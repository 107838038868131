import React from "react";
import { Section } from "../../../../components/shared/pages";

import SimuladorResultado from "./SimuladorResultado";
import FormularioRenda from "./FormularioRenda";
import FormularioDeducoes from "./FormularioDeducoes";
import { useSimuladorBeneficioFiscal } from "../../../../hooks/previdencia";

import SimuladorContext from "./SimuladorContext";

const SimuladorBeneficio = (props) => {
  const { sectionTitle } = props;

  const {
    setFatorPeriodicidade,
    rendaBruta,
    setRendaBruta,
    contribuicaoInss,
    setContribuicaoInss,
    previdenciaPBGL,
    setPrevidenciaPBGL,
    previdenciaComplementar,
    setPrevidenciaComplementar,
    despesasMedicas,
    setDespesasMedicas,
    pensaoAlimenticia,
    setPensaoAlimenticia,
    quantidadeDependentes,
    setQuantidadeDependentes,
    totDependentesEstudantes,
    setTotDependentesEstudantes,
    investEducacaoPropria,
    setInvestEducacaoPropria,
    investEducacaoDependentes,
    setInvestEducacaoDependentes,
    errors,
    infos,
    results,
    resetarSimulador,
  } = useSimuladorBeneficioFiscal();

  return (
    <>
      <div className="content-simulador">
        <h3>{sectionTitle}</h3>
        <div className="mb-5">
          <p>
            Na modalidade PGBL da previdência, o valor que você investe pode ser
            declarado no seu Imposto de Renda e, assim, você paga menos imposto
            ou tem uma restituição maior, assim como você faz com as despesas
            médicas e escolares. Se você faz a declaração completa pode deduzir
            até 12% da sua renda bruta anual com a previdência nessa modalidade.
          </p>
          <p>
            Aqui, te ajudamos a identificar quanto você deve investir para
            conseguir o máximo de dedução no Imposto de Renda.
          </p>
        </div>
        <FormularioRenda
          setFatorPeriodicidade={setFatorPeriodicidade}
          rendaBruta={rendaBruta}
          setRendaBruta={setRendaBruta}
          contribuicaoInss={contribuicaoInss}
          setContribuicaoInss={setContribuicaoInss}
          previdenciaPBGL={previdenciaPBGL}
          previdenciaComplementar={previdenciaComplementar}
          setPrevidenciaPBGL={setPrevidenciaPBGL}
          setPrevidenciaComplementar={setPrevidenciaComplementar}
          errors={errors}
          infos={infos}
        />

        <FormularioDeducoes
          despesasMedicas={despesasMedicas}
          setDespesasMedicas={setDespesasMedicas}
          pensaoAlimenticia={pensaoAlimenticia}
          setPensaoAlimenticia={setPensaoAlimenticia}
          quantidadeDependentes={quantidadeDependentes}
          setQuantidadeDependentes={setQuantidadeDependentes}
          totDependentesEstudantes={totDependentesEstudantes}
          setTotDependentesEstudantes={setTotDependentesEstudantes}
          investEducacaoPropria={investEducacaoPropria}
          setInvestEducacaoPropria={setInvestEducacaoPropria}
          investEducacaoDependentes={investEducacaoDependentes}
          setInvestEducacaoDependentes={setInvestEducacaoDependentes}
          errors={errors}
          infos={infos}
        />
        <SimuladorContext.Provider
          value={{
            results: {
              ...results,
              contribuicaoInss,
            },
          }}
        >
          <SimuladorResultado handleClear={resetarSimulador} />
        </SimuladorContext.Provider>
      </div>
    </>
  );
};

SimuladorBeneficio.defaultProps = {
  ...Section.defaultProps,
};

SimuladorBeneficio.propTypes = {
  ...Section.propTypes,
};

export default SimuladorBeneficio;
