const ND = 'Não Disparar';
const tipoPessoaJuridica = 'PessoaJuridica';
const causaAcidental = 'Acidental';
const causaCancer = 'Cancer';
const causaDoenca = 'Doenca';
const causaDoencaProfissional = 'DoencaProfissional';
const causaNatural = 'Natural';
const causaOutrasDoencas = 'OutrasDoencas';
const causaServicosAuxilioFuneral = 'ServicosAuxilioFuneral';
const eventoAfastamentoMedico = 'AfastamentoMedico';
const eventoDiagnosticoDoencaGrave = 'DiagnosticoDoencaGrave';
const eventoInvalidez = 'Invalidez';
const eventoMorte = 'Morte';
const eventoReembolso = 'Reembolso';

const pessoaConjuge = 'Conjuge';

const documentMap = {
  [`${eventoMorte}-${causaNatural}-${pessoaConjuge}`]: 'DocCKL70',
  [`${eventoMorte}-${causaAcidental}-${pessoaConjuge}`]: 'DocCKL58',
  [`${eventoInvalidez}-${causaDoenca}`]: 'DocCKL05',
  [`${eventoInvalidez}-${causaAcidental}`]: 'DocCKL54',
  [`${eventoDiagnosticoDoencaGrave}-${causaCancer}`]: 'DocCKL29',
  [`${eventoDiagnosticoDoencaGrave}-${causaDoencaProfissional}`]: 'DocCKL59',
  [`${eventoDiagnosticoDoencaGrave}-${causaOutrasDoencas}`]: 'DocCKL29',
  [`${eventoAfastamentoMedico}-${causaDoenca}`]: 'DocCKL72',
  [`${eventoAfastamentoMedico}-${causaAcidental}`]: 'DocCKL72',
  [`${eventoReembolso}-${causaServicosAuxilioFuneral}`]: 'DocCKL76',
};

const ReturnDocumentoPJ = (tipoPessoa, evento, causa, pessoa) => {
  if (tipoPessoa !== tipoPessoaJuridica) {
    return ND;
  }

  return (
    documentMap[`${evento}-${causa}-${pessoa}`] ||
    documentMap[`${evento}-${causa}`] ||
    ND
  );
};

export default ReturnDocumentoPJ;
