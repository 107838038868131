import React from "react";
import {
  Link,
} from "../../../components/shared/actions";
import { Section } from "../../../components/shared/pages";
import { LINKS } from "../../../constants";

export const DicaSimulador = (props) => {
  const { sectionTitle } = props;

  return (
    <div className="content-dica">
      <h3 className="orange">{sectionTitle}</h3>
      <p className="mb-4">
        Se você quer investir mais de 12% da sua renda anual, pode aplicar até
        12% em um plano PGBL. O que ultrapassar os 12%, você investe num plano
        VGBL e paga nessa parte apenas o imposto sobre os rendimentos.
      </p>
      <h3>Sobre a simulação</h3>
      <ul>
        <li>
          Os valores desta simulação são meramente informativos e não
          representam obrigação por parte da Caixa Vida e Previdência.
        </li>
        <li>
          A dedução das contribuições efetuadas para a previdência privada na
          Declaração de Ajustes Anual só é possível no modelo de declaração
          completa e você deve contribuir para Previdência Social ou Regime
          Próprio de Previdência.
        </li>
        <li>
          Esta simulação considera valores vigentes da Receita Federal e INSS.
        </li>
      </ul>
      <p>
        Você pode comprar nossos planos nas{" "}
        <Link
          blank
          href={LINKS.encontreAgenciaCaixa}
          label="agências da Caixa"
        />{" "}
        ou{" "}
        <Link blank href={LINKS.autoCompraPrevidencia} label="comprar aqui" />.
        Se preferir, faça um aporte pelo{" "}
        <Link
          blank
          href={LINKS.areaLogada}
          label="Chat da Área do Cliente."
        />
      </p>
    </div>
  );
};

DicaSimulador.defaultProps = {
  ...Section.defaultProps,
};

DicaSimulador.propTypes = {
  ...Section.propTypes,
};

export default DicaSimulador;
