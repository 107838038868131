import React from "react";
import PropTypes from "prop-types";
import { Section } from "@components/shared/pages";
import Campaign from "../../../components/blocosCustomizados/Campaign";
import { Link } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const conteudoCampanha = [
  {
    label: "Proteção & Bem-estar",
    content: (
      <p>
        Os clientes que contratarem o Seguro Prestamista Divida Zero - Pessoa Física, entre os
        dias 11/10 e 31/12/2021 tem o benefício adicional de consultas e exames na rede privada
        com preços reduzidos. Confira <Link href={LINKS.guiaProtecaoEBemEstar} label="aqui" /> o
        regulamento.
      </p>
    ),
    idCampanha: "protecao-e-bem-estar",
  },
  {
    label: "Quinzena do Aposentado",
    content: (
      <p>
        Os clientes Pessoa Física, beneficiários do INSS, que contratarem o Seguro Prestamista Dívida
        Zero entre os dias 24/01/2022 a 07/02/2022 tem o benefício adicional de consultas e exames na
        rede privada com preços reduzidos. Confira <Link href={LINKS.guiaQuinzenaDoAposentado} label="aqui" /> o
        regulamento.
      </p>
    ),
    idCampanha: "quinzena-do-aposentado",
  },
];

export const Campanha = ({ sectionTitle, campanhas }) => {
  return (
    <Campaign
      title={sectionTitle}
      contentCampaign={conteudoCampanha}
      campanhas={campanhas}
      right
    />
  );
};

Campanha.defaultProps = {
  campanhas: [],
};

Campanha.propTypes = {
  ...Section.specializedProps,
  campanhas: PropTypes.arrayOf(Object),
};

export default Campanha;
