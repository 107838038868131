import React from "react";
import PropTypes from "prop-types";
import { Text } from "@cvp/design-system/react";
import styled from "styled-components";
import { Section } from "../../../components/shared/pages";

const SinistroContato = (props) => {
  const { sectionTitle } = props;

  return (
    <Container>
      <h3 className="text-center">{sectionTitle}</h3>
      <NumberStyle align="center">0800 722 2492</NumberStyle>
      <FunctioningTimeText align="center">
        Segunda a sexta-feira das 8h às 18h, exceto feriados nacionais.
      </FunctioningTimeText>
    </Container>
  );
};

const NumberStyle = styled(Text)`
  font-family: "Futura Std Bold";
  font-size: 38px !important;
  font-weight: bold !important;
  color: #000 !important;
  margin: 25px 0 !important;
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
`;

const FunctioningTimeText = styled(Text)`
  font-size: 18px !important;
  line-height: 20px !important;
  color: #000 !important;
  margin-bottom: 35px !important;
`;

const Container = styled.div`
  font-family: "Futura Std";
  padding: 30px 0;
`;

SinistroContato.defaultProps = {
  ...Section.defaultProps,
};

SinistroContato.propTypes = {
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default SinistroContato;
