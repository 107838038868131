import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";


export const MicroSeguroAmparo = (props) => {
  const { images, sectionTitle } = props;

  return (
    <>
      <Apresentacao
        image={images.acidentesPessoaisApresentacaoEsquerda}
        right
        imageColumn={{ span: 5, offset: 0 }}
        title={sectionTitle}
        titleCenter
        contentColumn={{
          className: "d-flex align-items-center basic-color-tangerine marge",
          xs: 12,
          lg: { span: 5, offset: 0 },
        }}
      />
      <Row>
        <Col >          
          <p>
            O seguro de Acidentes Pessoais Bem-estar realiza o pagamento de
            indenização, em caso de morte por acidente do(a) segurado(a).
            <br />É uma forma de você cuidar da sua família e amenizar as
            preocupações.
          </p>
          <p>
            <strong>Quem pode adquirir?</strong>
          </p>
          <p>
            Pessoas com idade entre{" "}
            <strong>16 e 80 anos podem contratar </strong>o seguro Acidentes
            Pessoais Bem-estar.
          </p>
        </Col>
      </Row>  
    </>
  );
};

MicroSeguroAmparo.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default MicroSeguroAmparo;
