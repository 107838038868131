import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoFies = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.porqueContratarIlustracao} title={sectionTitle}>
      <p>
        O seguro prestamista quita o saldo devedor do seu Financiamento
        Estudantil, o FIES, caso uma fatalidade aconteça com o titular do
        seguro, como: morte (por causas naturais ou acidentais), invalidez
        permanente (total ou parcial) por acidente e invalidez funcional
        permanente (total ou parcial) por doença.
      </p>
      <p>
        Qualquer pessoa de 16 a 80 anos que queira ingressar ou que já esteja
        cursando uma universidade particular que aceite o FIES como programa de
        financiamento pode fazer o seguro.
      </p>
    </Apresentacao>
  );
};

ApresentacaoFies.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

ApresentacaoFies.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoFies;
