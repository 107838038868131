/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@cvp/design-system/react";
import styled from "styled-components";
import { media } from "../../../utils";

const SwitchControl = ({ label, name, checked, onChange, onClick }) => {
  return (
    <Container
      onClick={
        !checked
          ? onClick
          : () => {
              /* evento padrão sem efeito */
            }
      }
    >
      <Switch
        id={`${name}-switch`}
        onChange={onChange}
        checked={checked}
        disabled={!checked}
      />
      <StyledLabel
        onClick={
          checked
            ? onClick
            : () => {
                /* evento padrão sem efeito */
              }
        }
      >
        {label}
      </StyledLabel>
    </Container>
  );
};

SwitchControl.defaultProps = {
  label: "Anexar arquivo",
  checked: false,
};

SwitchControl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SwitchControl;

const desktopContaier = media.desktop`
  justify-content: space-around;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  align-items: baseline;
  ${desktopContaier}
`;

const StyledLabel = styled.p`
  cursor: pointer !important;
  margin-left: 20px !important;
  max-width: 1000px;
  word-break: break-word;
`;
