import oQueEIlustracao from './entenda-prestamista-ilustracao.svg';
import quemPodeAdquirirIlustracao from './quem-pode-adquirir-ilustracao.svg';
import pagamentoParcialIlustracao from './pagamento-parcial-ilustracao.svg';
import indenizacaoBeneficiariosIlustracao from './indenizacao-beneficiario-ilustracao.svg';
import sorteiosIlustracao from './sorteios-ilustracao.svg';
import sorteiosIlustracaoFies from './sorteios-fies-ilustracao.svg';
import quantoCustaFiesIlustracao from './quanto-custa-fies-ilustracao.svg';
import porqueContratarIlustracao from './porque-contratar-fies-ilustracao.svg';
import bannerPrestamista from './banner-bg-prestamista.jpg';
import bannerPrestamistaRural from './banner-seguro-produtor-rural.jpg';
import ServicoSinistro from './sinistro.svg';

export default {
  bannerPrestamista,
  bannerPrestamistaRural,
  oQueEIlustracao,
  quemPodeAdquirirIlustracao,
  pagamentoParcialIlustracao,
  indenizacaoBeneficiariosIlustracao,
  sorteiosIlustracao,
  sorteiosIlustracaoFies,
  quantoCustaFiesIlustracao,
  porqueContratarIlustracao,
  ServicoSinistro,
};
