import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import { Button } from "../../../components/shared/actions";

const CentralSinistro = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.ServicoSinistro} title={sectionTitle} >
      <p>
        Sabemos que quando ocorre um sinistro, todo apoio é necessário nesse
        momento difícil.
      </p>

      <p>
        Por isso, disponibilizamos uma área completa, 24h por dia, 7 dias por
        semana, na qual você pode:
      </p>

      <ul>
        <li className="mt-3">Comunicar um sinistro;</li>
        <li className="mt-3">Enviar a documentação solicitada;</li>
        <li className="mt-3">
          Acompanhar o andamento de um processo de sinistro.
        </li>
      </ul>
      <div className=" d-block d-lg-flex">
        <Button className="mt-3" toRoute="/sinistro">
          Acessar Central de Sinistros
        </Button>
      </div>
    </Apresentacao>
  );
};

CentralSinistro.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

CentralSinistro.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default CentralSinistro;
