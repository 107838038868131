import { useState } from 'react';
import PropTypes from 'prop-types';
import { endPoints } from '../../config';
import useGiRequest, { POST } from './useGiRequest';
import usePerformGiRequest from './usePerformGiRequest';

const useSinisterTimelinePrevidencia = () => {
  const { performGiRequest, errorGi } = useGiRequest();
  const [loadingPrev, setLoading] = useState(false);
  const { getAccessToken } = usePerformGiRequest();

  const getAllSinisterAndTimeline = async (cpf, reCaptchaToken1) => {
    setLoading(true);

    const responseGi = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarSinistroPrevidencia(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        userName: cpf,
        codigoEmpresa: '500',
        cpfTitular: cpf,
        xPrevToken: '021A69D433650D22E0630E02660A52DB',
        SHArsaKey: await getAccessToken(cpf, 'PJ_TimelineSinistro'),
      },
    });

    setLoading(false);

    return responseGi?.data;
  };

  return {
    getAllSinisterAndTimeline,
    loadingPrev,
    errorGi,
  };
};

useSinisterTimelinePrevidencia.propTypes = {
  getAllSinisterAndTimeline: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorGi: PropTypes.bool,
};

export default useSinisterTimelinePrevidencia;
