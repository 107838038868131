import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Container, Title, Description, ExclamacaoIcon } from "./styles";

const SinistroPending = () => {
  const appInsights = useAppInsightsContext();
  const trackSinistroPendente = useTrackEvent(
    appInsights,
    "AA_Sinistro_Pendente"
  );

  useEffect(() => {
    const sinistroPendenteEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Sinistro - Pendente",
      virtualPageURL: "/sinistro/pendente ",
    };
    trackSinistroPendente(sinistroPendenteEvent);
  }, [trackSinistroPendente]);

  return (
    <Container>
      <ExclamacaoIcon />
      <Title variant="headline-06" align="center">
        Não foi possível abrir sua ocorrência para o Sinistro.
      </Title>
      <Description variant="body01-lg" align="center">
        O seu protocolo do atendimento anterior ainda está no prazo de análise.
        <br />
        Por favor, aguarde o nosso retorno.
      </Description>
    </Container>
  );
};

export default SinistroPending;
