import React from "react";
import PropTypes from "prop-types";
import SemProduto from "../../../../components/shared/SemProduto/SemProduto";
import SinistroApiError from "../SinistroApiError/SinistroApiError";

const Mensagens = ({ errorSinistro, exibirSemProduto }) => {
  return (
    <>
      {errorSinistro && <SinistroApiError />}
      {exibirSemProduto && (
        <SemProduto
          title="Não foi possível registrar o comunicado de sinistro."
          description="Por favor, entre em contato com a Central de Assistências Dia & Noite e
        Sinistros no 0800 722 2492, de segunda a sexta-feira, das 8h às 18h
        (exceto feriados nacionais)."
        />
      )}
    </>
  );
};

Mensagens.propTypes = {
  errorSinistro: PropTypes.bool.isRequired,
  exibirSemProduto: PropTypes.bool.isRequired,
};

export default Mensagens;
