import React from "react";
import { Benefits } from "@components/blocosCustomizados";
import { Section } from "@components/shared/pages";

export const VantagemVida = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.vidaImagemCabeOrcamento,
      title: "Cabe no seu orçamento",
      description:
       <p>A partir de R$ 9,26 por mês você<br />já pode ter um seguro de vida.</p>,
    },
    {
      image: images.vidaImagemBeneficioGratuito,
      title: "Benefícios gratuitos",
      description: <p>Conte com a Assistência Funeral<br />e o Check-up Lar.</p>,
    },
    {
      image: images.vidaImagemSorteios,
      title: "Sorteios",
      description: <p>Concorra a sorteios mensais<br />de até <strong>R$ 200 mil.</strong></p>,
    },
  ];

  return <Benefits sectionTitle={sectionTitle} items={conteudoVantagem} />;
};

VantagemVida.propTypes = {
  ...Section.specializedProps,
};

export default VantagemVida;