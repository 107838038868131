import React from "react";
import { PropTypes } from "prop-types";
import { createImageElement } from "@asserts/images";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import { Link } from "../../../components/shared/actions";

export const PorquePrecisa = ({ images, sectionTitle }) => {
  return (
    <Apresentacao
      image={images.previdenciaInterrogacao}
      title={sectionTitle}
      imageColumn={2}
      right
    >
      <div className="d-flex justify-content-center w-100">
        {createImageElement(images.previdenciaInterrogacao, {
          fluid: true,
          className: "d-md-none text-center mb-5",
        })}
      </div>
      <p>
        Seja pra conquistar seus objetivos daqui a alguns anos, como uma viagem
        dos sonhos ou um curso no exterior, ou para ter uma renda e complementar
        a aposentadoria, a previdência é ideal pra você. <br />
        Quer só viver o hoje e não sabe ainda o que fazer lá na frente? Nesse
        caso a previdência também é uma boa escolha.
      </p>
      <p>
        Para você que é investidor ou quer investir, existe um
        <Link
          href="/previdencia/investidor"
          label="plano de previdência"
        />{" "}
        ideal pra você. Quanto antes começar, mais possibilidades terá.
      </p>
    </Apresentacao>
  );
};

PorquePrecisa.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

PorquePrecisa.propTypes = {
  images: PropTypes.objectOf(Object),
  ...Section.propTypes,
};

export default PorquePrecisa;
