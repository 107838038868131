import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import SpinnerLoading from "../shared/layout/SpinnerLoading";

const DrawResultData = ({
  isViewProducts,
  displayResultLabel,
  loading,
  loadingResults,
  result,
  resultAs,
}) => {
  const colAlignment = isViewProducts
    ? "justify-content-between"
    : " justify-content-center ";
  return (
    <>
      <Row className="justify-content-center align-items-center">
        {!loading && !loadingResults && result ? (
          <div className="d-flex align-items-center">
            <div
              className={`draw-result-selected d-block d-md-flex w-100  align-items-center px-md-5 mb-md-0
                    ${colAlignment}`}
            >
              <div className="text-center mr-3">{displayResultLabel}</div>
              <div className="draw-number mr-3 text-brand">
                <i>{result[resultAs]}</i>
              </div>
            </div>
          </div>
        ) : (
          <Col xs={12} md="auto">
            <div className="align-items-center justify-content-center">
              <SpinnerLoading isLoading={loading || loadingResults} />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

DrawResultData.defaultProps = {
  result: [],
};

DrawResultData.propTypes = {
  isViewProducts: PropTypes.bool.isRequired,
  displayResultLabel: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  result: PropTypes.objectOf(Object),
  resultAs: PropTypes.string.isRequired,
};

export default DrawResultData;
