import React, { useState, createContext } from "react";

const SinisterContext = createContext();

const SinisterContextProvider = ({ children }) => {
  const [exibirSemProduto, setExibirSemProduto] = useState(false);
  const [stepSinister, setStepSinister] = useState(0);
  const [protocolNumber, setProtocolNumber] = useState('');
  const [formulario, setFormulario] = useState();
  const [localReCaptcha, setLocalReCaptcha] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [arquivos64, setArquivos64] = useState([]);

  const context = {
    exibirSemProduto,
    stepSinister,
    protocolNumber,
    produtos,
    arquivos,
    arquivos64,
    formulario,
    localReCaptcha,
    setFormulario,
    setLocalReCaptcha,
    setExibirSemProduto,
    setStepSinister,
    setProtocolNumber,
    setProdutos,
    setArquivos,
    setArquivos64
  };

  return (
    <SinisterContext.Provider value={context}>
      {children}
    </SinisterContext.Provider>
  );
};

export { SinisterContext, SinisterContextProvider };
