import React from 'react';
import { Link } from '../../../components/shared/actions';
import Apresentacao from '../../../components/blocosCustomizados/Apresentacao';
import { Section } from '../../../components/shared/pages';
import imagensSinistro from '../../../asserts/images/sinistro';

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroComunicar}
      title={sectionTitle}
      imageColumn={4}
    >
      <p>
        Selecione a opção
        <Link
          blank
          href="/sinistro/abertura"
          label="comunicar
        sinistro"
        />{' '}
        ou <strong>baixe</strong> o nosso{' '}
        <strong>aplicativo Caixa Previdência</strong>.
      </p>
      <p>
        Se preferir, pode ligar na nossa Central de Atendimento Dia & Noite e
        Sinistros no 0800 722 2492 (das 8h às 18h, de segunda a sexta-feira,
        exceto feriados nacionais).
      </p>
      <p>
        Não esqueça de ter em mãos os dados do titular do seguro e as
        informações relativas ao sinistro, como por exemplo: certidão de óbito,
        data do acidente...
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
