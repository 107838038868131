import './style.css';
import React from 'react';
import {
  HomePage,
  CoberturaAssistenciaPage,
  ResultadosSorteiosPage,
  AcoesPromocionaisPage,
} from './pages';
import AreaRoute from '../../components/shared/routes';
import { useSegmentRoute } from '../../hooks';
import { envs } from '../../config';

export const Vida = () => {
  const pageTitle = 'Seguro de vida';
  const routeVida = '/segurodevida';

  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      component: CoberturaAssistenciaPage,
      path: '/coberturas-e-assistencias',
      pageTitle: 'Coberturas e Assistências',
      pageProps: {
        updateCrumb: {
          toRoute: routeVida,
          label: pageTitle,
        },
      },
    },
    {
      component: ResultadosSorteiosPage,
      path: '/resultado-de-sorteios',
      pageTitle: 'Resultado de Sorteios',
      pageProps: {
        updateCrumb: {
          toRoute: routeVida,
          label: pageTitle,
        },
      },
    },
    {
      component: AcoesPromocionaisPage,
      path: '/acoes-promocionais',
      pageTitle: 'Ações Promocionais e Campanhas anteriores',
    },
  ]);

  return (
    <AreaRoute
      {...routerContext}
      title={pageTitle}
      chatId={envs.vidaChatId}
      produto="vida"
    />
  );
};

export default Vida;
