import React from "react";

import {
  BannerHome,
  NossosProdutos,
} from "../sections";
import { Page } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {
  return (
    <Page
      id="page-home"
      {...props}
      banner={BannerHome}
      disabledCrumb
    >
      <SeguridadePageLayout.Tab eventKey="voce">
      <NossosProdutos />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
