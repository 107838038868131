import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@components/shared/pages';
import Campaign from '../../../components/blocosCustomizados/Campaign';
import { Link } from '../../../components/shared/actions';
import { LINKS } from '../../../constants';

const regulamento = 'o regulamento';

const conteudoCampanha = [
  {
    label: 'Campanha "Invista para o Futuro com Cashback no Presente"',
    content: (
      <p>
        Durante o período de 01/08/2024 até 31/10/2024, os clientes que realizarem a portabilidade receberão 
        cashback de R$250,00 para cada montante/lote de R$ 50.000,00 transferidos de outras entidades de 
        previdência complementar para plano de previdência administrado pela CVP. A promoção é limitada a 
        R$2.500,00 por CPF. Confira {' '}
        <Link href={LINKS.regulamentoCashBack} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'regulamento-cashback-2024',
  },

];

export const Campanha = ({ sectionTitle, campanhas }) => {
  return (
    <Campaign
      title={sectionTitle}
      contentCampaign={conteudoCampanha}
      campanhas={campanhas}
      right
    />
  );
};

Campanha.defaultProps = {
  campanhas: [],
};

Campanha.propTypes = {
  ...Section.specializedProps,
  campanhas: PropTypes.arrayOf(Object),
};

export default Campanha;
