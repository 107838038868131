import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { BannerContents } from '../../../components/shared/layout';

export const BannerAcoesPromocionaisPrevidencia = (props) => {
  const { images } = props;

  return (
    <BannerContents {...props}>
      <BannerContents.Banner
        backgroundImage={images.banner}
        className="text-white  ouvidoria-banner"
      >
        <Row>
          <Col>
            <Title>Ações Promocionais e Campanhas</Title>
          </Col>
        </Row>
      </BannerContents.Banner>
    </BannerContents>
  );
};

const Title = styled.h4`
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

BannerAcoesPromocionaisPrevidencia.defaultProps = { images: {} };

BannerAcoesPromocionaisPrevidencia.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerAcoesPromocionaisPrevidencia;
