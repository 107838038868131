import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const AddressNumberInput = ({label, numero, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.numero"
    label={label}
    placeHolder="Digite o número"
    name="reclamante.numero"
    value={numero}
    {...formikBag}
  />
);

AddressNumberInput.propTypes = {
  numero: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default AddressNumberInput;
