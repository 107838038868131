import React from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";
import { applyKey } from "../../utils";
import Card from "../shared/cards";

const ContentWithCardListBlock = ({ listItens, children, showItens, className }) => {
  const contentList = applyKey(listItens, (item) => {
    const cardContent = !React.isValidElement(item) ? (
      <Card
       image={item.imagem}
       header={item.titleConteudo}
        className={item.className}              
        footer={item.footer}
      >
        {item.conteudo}        
      </Card>
    ) : (
      React.cloneElement(<Card />, item.props)
    );
    return <Col {...{ xs: 12, lg: 4, ...item.col }}>{cardContent}</Col>;
  });

  return (
    <Row className={`cvp-content-list-card align-items-center text-center ${className}`}>
      <Col xs={12} sm={12} md={12} lg={{ span: 2, offset: 0 }} className="text-center">
        {children}
      </Col>
      <Collapse in={showItens}>
        <Col className="d-sm-flex justify-content-center">
          <Row>{contentList}</Row>
        </Col>
      </Collapse>
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 2, offset: 0 }}
        className="text-center"
      />    
    </Row>    
  );
};

ContentWithCardListBlock.defaultProps = {
  showItens: true,
};

ContentWithCardListBlock.propTypes = {
  showItens: PropTypes.bool,
  listItens: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ContentWithCardListBlock;
