import "./styles";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Header, Main, Footer } from "@components/shared/layout";
import {
  Home,
  CanalDenuncias,
  AcidentesPessoais,
  Vida,
  VidaSenior,
  Previdencia,
  Prestamista,
  PrestamistaFies,
  PrestamistaCreditoRural,
  Ouvidoria,
  Sinistro,
  Informe,
  Page404,
  WhatsApp,
  Login,
  App,
} from "@areas";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import Headers from "../components/headers";
import Footers from "../components/footers";
import { RedirectRoutes } from "../components/shared/routes";
import { envs, redirects } from "../config";
import DSConfig from "./DSConfig";
import AccordionContextProvider from "../providers/AccordionContextProvider";
import TimelinePage from "../areas/sinistro/pages/TimelinePage";

const CashbackRedirect = () => {
  window.location.href = 'https://statics.caixavidaeprevidencia.com.br/areaaberta/Previdencia/Regulamentos/Caixa-Vida-e-Previdencia-Regulamento-Cashback.pdf';
  return null;
};

const AppRoutes = () => {
  const shouldHiddenLayout = () => {
    const allowedRoutes = ["/sinistro/login", "/sinistros/login"];
    return allowedRoutes.some((a) => a === window.location.pathname);
  };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <DSConfig>
        <div className="site-wrapper">
          {!shouldHiddenLayout() && (
            <Header component={Headers.HeaderSeguridade} />
          )}
          <AccordionContextProvider>
            <Main>
              <Switch>         
                <Route path="/home" component={Home} />
                <Route path="/denuncia" component={CanalDenuncias} />
                <Redirect exact from="/canal-de-denuncias" to="/denuncia" />
                <Route exact path="/previdencia/cashback" component={CashbackRedirect} />
                <Redirect exact from="/seguroprestamistarural" to="/segurovidaprodutorrural" />
                <Route
                  path="/acidentespessoais"
                  component={AcidentesPessoais}
                />
                <Route path="/segurodevida" component={Vida} />
                <Route path="/segurovidasenior" component={VidaSenior} />
                <Route
                  path="/microsseguroamparo"
                  exact
                  component={AcidentesPessoais}
                />
                <Route path="/previdencia" component={Previdencia} />
                <Route path="/seguroprestamista" component={Prestamista} />
                <Route path="/segurodividazero" component={Prestamista} />
                <Route
                  path="/segurodividazerofies"
                  component={PrestamistaFies}
                />
                <Route
                  path="/segurovidaprodutorrural"
                  component={PrestamistaCreditoRural}
                />
                <Route path="/informe-de-rendimentos" component={Informe} />
                <Route path="/whatsapp" component={WhatsApp} />
                <Route path="/app" component={App} />
                <Route path="/404" component={Page404} />
                <Route path="/sinistro/acompanhar" component={TimelinePage} />
                <Route path="/sinistros/acompanhar" component={TimelinePage} />
                <Route path="/sinistro/login" component={Login} />
                <Route path="/sinistros/login" component={Login} />
                {envs.enabledOmbudsman ? (
                  <>
                    <Route path="/ouvidoria" component={Ouvidoria} />
                    <Route path="/sinistro" component={Sinistro} />
                    <Route path="/sinistros" component={Sinistro} />
                  </>
                ) : null}
                <RedirectRoutes redirects={redirects} />
                <Redirect from="*" to="/404" />

              </Switch>
            </Main>
          </AccordionContextProvider>
          {!shouldHiddenLayout() && (
            <Footer component={Footers.SeguridadeFooter} />
          )}
        </div>

        {}
      </DSConfig>
    </AppInsightsContext.Provider>
  );
};

export default AppRoutes;
