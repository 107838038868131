import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensPrevidencia from "@asserts/images/previdencia/index";
import {
  BannerPrevidencia,
  SimuladorBeneficio,
  DicaSimulador,
} from "../sections";
import { Page, Section } from "../../../components/shared/pages";

const Simulador = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrevidenciaSimulador = useTrackEvent(
    appInsights,
    "AA_Previdencia_Simulador"
  );

  useEffect(() => {
    const previdenciaSimuladorEvent = {
      event: "AA.navegacao",
      virtualPageTitle:
        "Caixa Vida e Previdência - Previdência - Simulador de Benefício Fiscal",
      virtualPageURL: "/previdencia/simulador-beneficio-fiscal",
    };
    trackPrevidenciaSimulador(previdenciaSimuladorEvent);
  }, [trackPrevidenciaSimulador]);

  return (
    <Page images={imagensPrevidencia} {...props} banner={BannerPrevidencia}>
      <Section
        as={SimuladorBeneficio}
        id="simulador"
        title="Simulador de Benefício Fiscal"
        sectionName="simulador"
      />
      <Section
        as={DicaSimulador}
        id="dica"
        title="Dica"
        sectionName="dica"
        sectionLayout={null}
      />
    </Page>
  );
};

export default Simulador;
