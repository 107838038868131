const patterns = {
  default: {
    in: /^(\d{2})?\s?(9?)(\d{4})(\d{4})$/gi,
    out: (g0, g1, g2, g3, g4) =>
      [g1 ? `(${g1}) ` : "", g2, g3, " ", g4].join(""),
  },
  free: {
    in: /^(0800)(\d{3})(\d{4})$/gi,
    out: `$1 $2 $3`,
  },
};

export const useFormatPhone = (phone) => {
  if (!phone) {
    return phone;
  }

  const pattern = patterns[patterns.free.in.test(phone) ? "free" : "default"];

  return phone.replace(pattern.in, pattern.out);
};

export default { useFormatPhone };
