import React, { useEffect } from 'react';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import imagensVida from '@asserts/images/vida';
import {
  BannerAcoesPromocionais,
  ApresentacaoAcoesPromocionais,
  CampanhaPassada,
} from '../sections';
import { Page, Section } from '../../../components/shared/pages';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { useCampanhas } from '../../../hooks';

const AcoesPromocionaisPage = (props) => {
  const { getCampanhas, campanhas } = useCampanhas();
  const appInsights = useAppInsightsContext();
  const trackVida = useTrackEvent(appInsights, 'AA_Vida_Acoes_Promocionais');

  useEffect(() => {
    const vidaCoberturasEvent = {
      event: 'AA.navegacao',
      virtualPageTitle:
        'Caixa Vida e Previdência - Seguro de Vida - Ações Promocionais e Campanhas anteriores',
      virtualPageURL: '/acoes-promocionais',
    };
    trackVida(vidaCoberturasEvent);
  }, [trackVida]);

  useEffect(() => {
    getCampanhas('vida');
  }, []);

  return (
    <Page {...props} images={imagensVida} banner={BannerAcoesPromocionais}>
      <SeguridadePageLayout.Tab eventKey="coberturas-assistencias">
        <Section
          id="acoes-promocionais"
          as={ApresentacaoAcoesPromocionais}
          title="Ações Promocionais e Campanhas anteriores a 2024"
          sectionLayout={null}
          sectionName="acoes promocionais"
        />
        <div
          style={{
            justifyContent: 'center',
            width: '75%',
            marginTop: '-100px',
          }}
        >
          <Section
            as={CampanhaPassada}
            id="acoes-promocionais"
            sectionLayout={null}
            sectionName="acoes promocionais"
            campanhas={campanhas}
          />
        </div>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default AcoesPromocionaisPage;
