import React from "react";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import imagensSinistro from "../../../asserts/images/sinistro";

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao image={imagensSinistro.sinistroPrazo} title={sectionTitle} imageColumn={4}>
          <p>
        O prazo para regulação é de até{" "}
        <strong>30 dias a contar da entrega completa da documentação{" "}</strong>
        para seguro/previdência. Os microsseguros possuem o prazo de 10 dias.
      </p>
      <p>
        Se o documento encaminhado estiver fora da conformidade, solicitaremos
        via e-mail o reenvio dentro dos padrões necessários para análise.
      </p>
      <p>
        <strong>
          Caso seja necessário o envio de algum documento complementar, o
          cliente receberá um alerta via e-mail e o prazo será paralisado até o
          recebimento do novo documento.
        </strong>
      </p>
      <p>
        O pagamento sendo aprovado, o herdeiro ou beneficiário receberá um SMS
        confirmando a autorização.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;