import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { useAnalytcs } from "../../../../hooks/common/useAnalytcs";
import { useMakeClass } from "../../../../hooks/common/useMakeClass";
import StyledSection from "./styled";

const SectionContent = ({ children, className, backgroundImage }) => {
  return (
    <>
      <div
        className={`section-content ${className || ""} ${
          backgroundImage ? "background-image" : ""
        }`}
      >
        {children}
      </div>
    </>
  );
};

SectionContent.defaultProps = {
  className: null,
  backgroundImage: null,
};

SectionContent.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.string,
};

const Section = (props) => {
  const DEFAULT_AJUSTMENT_ANCOR = -155;
  const {
    title: sectionTitle,
    fluid,
    addPageMenuItem,
    anchor: label,
    anchorConfig,
    enabledAnchor,
    variant,
    sectionLayout,
    backgroundImage,
    sectionName: section,
    className,
    noContainer,
    as: SectionComponent,
    id,
  } = props;
  const ref = useRef(null);

  useEffect(() => {
    const checkAnchor = () => {
      if (enabledAnchor && addPageMenuItem && label) {
        addPageMenuItem({
          ajustment: DEFAULT_AJUSTMENT_ANCOR,
          ...anchorConfig,
          label,
          ref,
        });
      }
    };
    checkAnchor();
  }, [enabledAnchor]);

  const classes = useMakeClass(
    "page-block",
    variant && `background-${variant}`,
    className,
    section && "make-section",
    sectionLayout && `section-layout-${sectionLayout}`
  );
  const analytics = useAnalytcs({ section });

  const contents = React.createElement(SectionComponent, {
    ...props,
    sectionTitle,
  });

  return (
    <StyledSection
      backgroundImage={backgroundImage}
      {...analytics}
      className={classes}
      ref={ref}
      id={id}
      title={sectionTitle}
    >
      {noContainer ? contents : <Container fluid={fluid}>{contents}</Container>}
    </StyledSection>
  );
};

Section.Content = SectionContent;

Section.defaultProps = {
  title: null,
  fluid: false,
  anchor: null,
  anchorConfig: null,
  enabledAnchor: false,
  variant: "neutral",
  sectionLayout: "caixa-seguridade",
  backgroundImage: null,
  sectionName: null,
  className: null,
  noContainer: false,
  images: {},
  as: null,
  id: null,
  addPageMenuItem: null,
};

Section.propTypes = {
  title: PropTypes.string,
  fluid: PropTypes.bool,
  addPageMenuItem: PropTypes.func,
  anchor: PropTypes.string,
  anchorConfig: PropTypes.objectOf(Object),
  enabledAnchor: PropTypes.bool,
  variant: PropTypes.oneOf(["neutral", "dark", "light"]),
  sectionLayout: PropTypes.oneOf(["caixa-seguridade"]),
  backgroundImage: PropTypes.string,
  sectionName: PropTypes.string,
  className: PropTypes.string,
  noContainer: PropTypes.bool,
  images: PropTypes.objectOf(Object),
  id: PropTypes.string,
  as: PropTypes.elementType,
};

Section.specializedProps = {
  sectionTitle: PropTypes.string.isRequired,
};

export default Section;
