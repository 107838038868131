import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Page, Section } from "@components/shared/pages";
import imagensVidaSenior from "@asserts/images/vidasenior";

import {
  BannerVidaSenior,
  ApresentacaoSeguroVidaSenior,
  VantagemSeguroVidaSenior,
  FacaSeguroVidaSenior,
  SaibaMaisSeguroVidaSenior,
} from "../sections";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackVida = useTrackEvent(appInsights, "AA_Vida");

  useEffect(() => {
    const vidaEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Seguro de Vida",
      virtualPageURL: "/segurodevida",
    };
    trackVida(vidaEvent);
  }, [trackVida]);

  return (
    <Page
      {...props}
      images={imagensVidaSenior}
      banner={BannerVidaSenior}
      pageTitle="Seguro Vida Senior"
    >
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoSeguroVidaSenior}
          id="apresentacao-vida-senior"
          title="
          Entenda a importância do Seguro Vida Sênior"
          sectionName="porque contratar"
        />
        <Section
          title="Aproveite as vantagens"
          as={VantagemSeguroVidaSenior}
          id="vantagens-vida-senior"
          sectionName="Vantagens Seguro Vida Sênior"
        />
        <Section
          title="Para contratar, fale com seu gerente ou procure uma agência CAIXA"
          sectionLayout={null}
          as={FacaSeguroVidaSenior}
          id="faca-seguro-vida-senior"
          sectionName="Faca um Seguro Vida Sênior"
        />
        <Section
          as={SaibaMaisSeguroVidaSenior}
          title="Tem alguma dúvida?"
          className="bloco-tem-alguma-duvida background-image-none-sm border-top"
          id="tem-alguma-duvida"
          sectionLayout={null}
          sectionName="Duvidas"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
