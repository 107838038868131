/* eslint-disable import/prefer-default-export */
import React from "react";
import { PropTypes } from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import InfoDuvida from "../../../components/shared/blocks/InfoDuvida";
import { PhoneLink } from "../../../components/shared/actions";

export const BeneficioExlusivoParaMulheres = ({ images }) => {
  const contenteInfoDuvidaComoFacoParaUsarBeneficio = (
    <>
      <h5>Como faço para usar esse benefício?</h5>

      <p>
        Você deve ligar na Central de Serviços e Relacionamento:{" "}
        <PhoneLink phone="0800 702 4000" />
      </p>
    </>
  );
  const contenteInfoDuvidaComoConsultoOsMedicos = (
    <>
      <h5>Como consulto os médicos credenciados e agendo uma consulta?</h5>
      <p>
        Ligue na Central de Serviços e Relacionamento:{" "}
        <PhoneLink phone="0800 702 4000" /> e informe sua cidade. Se na sua
        região não tiver nenhum médico credenciado, nossos consultores irão
        orientar você sobre o pedido de reembolso da consulta.
      </p>
    </>
  );

  const conteudoApresentacao = [
    {
      imagem: {
        src: images.previdenciaExclusivoMulheres,
        alt: "Exclusivos para mulheres",
      },
      tamanhoColImagem: 3,
      conteudo: (
        <>
          <h5 className="sinalizador-titulo">
            Como faço para resgatar minha previdência
          </h5>

          <p>
            Os benefícios são válidos somente para mulheres na contratação de um
            plano VGBL:
          </p>

          <p>
            <strong>Gravidez Premiada: </strong> em caso de gestação múltipla
            (gêmeos, trigêmeos) concebida naturalmente, você receberá R$ 50 mil
            no nascimento dos bebês.
          </p>

          <div>
            <InfoDuvida
              content={contenteInfoDuvidaComoFacoParaUsarBeneficio}
              className="info-duvida info-duvida-main-cuidado-futuro-color-primary"
            />
          </div>

          <p>
            <strong>Prevenção Ginecológica: </strong>
            uma vez por ano, você poderá consultar gratuitamente com
            ginecologista credenciado, realizar o exame Papanicolau e ainda tem
            direito a retorno.
          </p>

          <div>
            <InfoDuvida
              content={contenteInfoDuvidaComoConsultoOsMedicos}
              className="info-duvida info-duvida-main-cuidado-futuro-color-primary"
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Apresentacao contents={conteudoApresentacao} />
    </>
  );
};

BeneficioExlusivoParaMulheres.defaultProps = { images: {} };

BeneficioExlusivoParaMulheres.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BeneficioExlusivoParaMulheres;
