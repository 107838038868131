/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line react/no-array-index-key
// Line 54:21: Do not use Array index in keys

// eslint-disable-next-line react/jsx-curly-brace-presence
// Line 81:31: Curly braces are unnecessary here

// eslint-disable-next-line react/no-array-index-key
// Line 95:21: Do not use Array index in keys

// eslint-disable-next-line jsx-a11y/label-has-associated-control
// Line 107:21: A form label must be associated with a control

// eslint-disable-next-line react/jsx-curly-brace-presence
// Line 117:29: Curly braces are unnecessary here
import React, { useEffect, useState, useContext } from 'react';
import Dropzone from 'react-dropzone';
import css from './file-upload.module.css';
import ImagesfileUpload from '../../asserts/images/fileUpload';
import { SinisterContext } from '../../context/SinisterContext';
import { fileSizeFormat } from '../../utils/fileSizeFormat';

const MAX_SIZE_FILE = 5 * 1024 * 1024;

const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [imageCount, setCountImageCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    IconUPLOAD,
    MinIconZIP,
    MinIconPDF,
    MinIconDOC,
    MinIconDOCX,
    MinIconXLS,
    MinIconXLSX,
    MinIconIMG,
  } = ImagesfileUpload;

  const { setArquivos, setArquivos64 } = useContext(SinisterContext);

  useEffect(() => {
    const images = originalFiles.filter((f) => f.type.startsWith('image'))
      .length;
    setCountImageCount(images - 4);
    setArquivos64(selectedFiles);
    setArquivos(originalFiles);
  }, [originalFiles, selectedFiles]);

  const allowedExtensions = [
    '.gif',
    '.jpeg',
    '.jpg',
    '.png',
    '.bmp',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.zip',
  ];
  const accept = {
    image: allowedExtensions,
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessage(
        'O tamanho máximo por arquivo é de 5 MB e as extensões permitidas são: ZIP, JPG, JPEG, PNG, GIF, BMP, PDF, DOC, DOCX, XLS ou XLSX.'
      );
    } else {
      setErrorMessage('');
      acceptedFiles.forEach((file) => {
        getBase64(file).then((data) => {
          setSelectedFiles((prev) => [...prev, data]);
          setOriginalFiles((prev) => [...prev, file]);
        });
      });
    }
  };

  const handleRemove = (fileToRemove) => {
    const index = originalFiles.indexOf(fileToRemove);
    if (index !== -1) {
      setSelectedFiles((prevSelectedFiles) => {
        const newSelectedFiles = [...prevSelectedFiles];
        newSelectedFiles.splice(index, 1);
        return newSelectedFiles;
      });
      setOriginalFiles((prevOriginalFiles) => {
        const newOriginalFiles = [...prevOriginalFiles];
        newOriginalFiles.splice(index, 1);
        return newOriginalFiles;
      });
    }
  };

  return (
    <div>
      <Dropzone
        onDrop={handleDrop}
        onDropRejected={handleDrop}
        accept={accept}
        maxSize={MAX_SIZE_FILE}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={css.drop} {...getRootProps()}>
            <div className={css.icone}>
              <IconUPLOAD />
            </div>
            <input {...getInputProps()} />
            <p className={css.text}>
              Arraste e solte arquivos aqui ou clique para selecionar
            </p>
          </div>
        )}
      </Dropzone>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div className="">
        {originalFiles.length > 0 && (
          <div style={{ marginTop: '80px', marginBottom: '60px' }}>
            <h3>
              <strong>Arquivos selecionados:</strong>
            </h3>
            <p>
              <strong>{originalFiles.length}</strong> arquivos selecionados{' '}
            </p>
          </div>
        )}
        <div className={css.fileContainer}>
          {originalFiles.map((file, index) => (
            <div key={`file-${file}`}>
              <>
                {index + 1 <= 30 && (
                  <div
                    className={`${
                      index + 1 === 30 && imageCount > 0 && css['last-image']
                    }`}
                  >
                    <div className={`${css['box-image']}`}>
                      {index + 1 === 30 && imageCount > 0 ? (
                        <p style={{ marginBottom: 0 }}> + {imageCount} </p>
                      ) : (
                        <div className={css.svgContainer}>
                          {(() => {
                            switch (file.type) {
                              case 'application/x-zip-compressed':
                                return <MinIconZIP className={css.svgIcon} />;
                              case 'application/pdf':
                                return <MinIconPDF className={css.svgIcon} />;
                              case 'application/excel':
                              case 'application/vnd.ms-excel':
                              case 'application/msexcel':
                                return <MinIconXLS className={css.svgIcon} />;
                              case 'application/doc':
                              case 'application/msword':
                                return <MinIconDOC className={css.svgIcon} />;
                              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                return <MinIconXLSX className={css.svgIcon} />;
                              case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                return <MinIconDOCX className={css.svgIcon} />;
                              case 'image/png':
                              case 'image/jpg':
                              case 'image/jpeg':
                              case 'image/bmp':
                                return <MinIconIMG className={css.svgIcon} />;
                              default:
                                return (
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    className={css.img}
                                  />
                                );
                            }
                          })()}
                        </div>
                      )}
                      <div className={css.infos}>
                        {' '}
                        <p style={{ lineHeight: '0.3rem', fontSize: '18px' }}>
                          <strong>{file.path.split('.')[0]}</strong>
                        </p>
                        <p style={{ lineHeight: '0.4rem', fontSize: '13px' }}>
                          [{fileSizeFormat(file.size)}]
                        </p>
                      </div>
                      <button
                        className={`btn btn-primary ${css.icon}`}
                        data-toggle="tooltip"
                        title="Remover"
                        type="button"
                        onClick={() => handleRemove(file)}
                      >
                        x
                      </button>
                    </div>
                  </div>
                )}
              </>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
