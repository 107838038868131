import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoSeguroVidaSenior = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao
      image={images.seguranca}
      title={sectionTitle}
      imageColumn={3}
      left
    >
      <p>
        O Seguro Vida Sênior da Caixa Vida e Previdência é para quem sempre está
        pensando em proteger a família. Nos momentos que as pessoas que você ama
        mais precisarem, estaremos prontos para oferecer todo apoio.{" "}
      </p>
      <p>
        Despesas extras que chegam de surpresa não são bem-vindas,
        principalmente para quem depende de você financeiramente. Então, conte
        com as nossas soluções e benefícios. Com o Seguro Vida Sénior da Caixa
        Vida e Previdência, você e a sua família serão sempre amparados.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSeguroVidaSenior.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

ApresentacaoSeguroVidaSenior.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoSeguroVidaSenior;
