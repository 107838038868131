import React from "react";
import PropTypes from "prop-types";
import { Button } from "@components/shared/actions";
import imagensAmparo from "../../../../asserts/images/amparo";
import Apresentacao from "../../../../components/blocosCustomizados/Apresentacao";
import CenterImageContent from "../../../../components/shared/contents/CenterImageContent";

export const DocumentosEnviados = (props) => {
  const { sectionTitle, sectionProtocol } = props;

  return (
    <Apresentacao
      image={imagensAmparo.amparoImagemApresentacao}
      imageColumn={4}
    >
      <h2>{sectionTitle}</h2>
      <p className="mt-4">
        O seu protocolo de atendimento é <strong>{sectionProtocol}</strong>
      </p>
      <p className="mt-4">O prazo de avaliação é de até 3 dias úteis;</p>
      <p className="mt-2">
        Se a documentação estiver correta e completa, será iniciado o período de
        contagem do prazo regulamentar da SUSEP;
      </p>
      <p className="mt-2">
        Caso seja necessário, a Caixa Vida e Previdência poderá solicitar o
        envio dos documentos físicos.
      </p>
      <div className="d-flex d-md-none justify-content-center mb-5">
        <CenterImageContent image={imagensAmparo.amparoImagemApresentacao} />
      </div>
      <div className="faq-fotter-buttons d-lg-flex justify-content-center btn-envio-documentos-sinistro">
        <Button href="/sinistro" className="button-documentos-enviados" blank>
          Voltar para a página inicial
        </Button>
      </div>
    </Apresentacao>
  );
};

DocumentosEnviados.defaultProps = {
  ...Apresentacao.defaultProps,
};

DocumentosEnviados.propTypes = {
  ...Apresentacao.propTypes,
  sectionTitle: PropTypes.string.isRequired,
};

export default DocumentosEnviados;
