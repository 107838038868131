import { useState } from 'react';
import PropTypes from 'prop-types';
import { endPoints } from '../../config';
import useGiRequest, { POST } from './useGiRequest';
import usePerformGiRequest from './usePerformGiRequest';

/**
 * This service hook will fetch CEP data from an API endpoint.
 *
 * @component
 * @return  {UseCEPHook}
 *
 * @typedef    {Object}    UseCEPHook
 *  @property  {function}  consultCEP        Function used for API call
 *  @property  {boolean}   foundCep          Indicates whether the CEP code was found
 *  @property  {boolean}   isCepUnico        Indicates whether the CEP code cannot resolve street name
 *  @property  {number}    status            HTTP status code
 *  @property  {Object}    data              Raw response data
 *  @property  {boolean}   loading           Loading indicator
 *  @property  {boolean}   error             Error indicator
 */
const useCEP = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const [isCepUnico, setIsCepUnico] = useState(false);
  const [foundCep, setFoundCep] = useState(false);
  const { performGiRequest, error } = useGiRequest();
  const { getAccessToken } = usePerformGiRequest();

  /**
   * Fetches cep data from API
   * @param    {string}    cep    The CEP code
   */
  const consultCEP = async (cep) => {
    setLoading(true);
    const sanitizedCep = cep.replace('-', '');

    const SHArsaKey = await getAccessToken(
      '00000000000',
      endPoints.consultCEPEndPoint.split('/').pop()
    );
    const payload = {
      UserName: '00000000000',
      SHArsaKey,
      Cep: sanitizedCep,
    };
    const response = await performGiRequest({
      method: POST,
      url: endPoints.consultCEPEndPoint,
      data: payload,
    });

    const dados = response?.data?.dados?.result;
    setStatus(response?.status);
    setData(dados);
    if (dados.indice_tipo_logradouro?.toUpperCase() === 'CENTRO GERAL') {
      setIsCepUnico(true);
    } else {
      setIsCepUnico(false);
    }
    if (
      response?.status === 200 &&
      dados.codigo_retorno === '0' &&
      dados.indice_tipo_logradouro &&
      dados.codigo_uf &&
      dados.nome_localidade
    ) {
      setFoundCep(true);
    } else {
      setFoundCep(false);
    }
    setLoading(false);
  };

  /**
   * Fetches cep data from API GI
   * @param    {string}    cep    The CEP code
   */
  const getAddress = async (cep) => {
    const payload = {
      UserName: "admkpvsol",
      Operacao: "1",
      Cep: cep.replace(/[^0-9]/g, ""),
    };

    const response = await performGiRequest({
      method: POST,
      url: endPoints.buscarCep(),
      data: {
        ...payload,
      },
    });

    return setData(response?.data);
  };

  return {
    consultCEP,
    getAddress,
    foundCep,
    isCepUnico,
    status,
    data,
    loading,
    error,
  };
};

useCEP.propTypes = {
  consultCEP: PropTypes.func,
  foundCep: PropTypes.bool,
  isCepUnico: PropTypes.bool,
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useCEP;