import React from "react";
import { Section } from "@components/shared/pages";
import TableFundos from "./TableFundos";

import useTabelaFundoInvestimento, {
  rendaFixaPosFixado,
    rendaFixa,
    rendaFixaModerado,
    creditoPrivado,
    indicePrecos,
    inflacaoAtiva,
    rendaVariavel15,
    rendaVariavel30,
    rendaVariavel49,
    rendaVariavel70,
    multiMercadoEstrategiaLivreConservador,
    multiMercadoEstrategiaLivreModerado,
    multiMercadoEstrategiaLivreArrojado,
    // eslint-disable-next-line import/named
    multiGestorMultiEstrategia,
    rendaVariavelAcoesLivres,
} from "../../../hooks/previdencia/useTabelaFundoInvestimento";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

export const FundosComposicoes = ({ sectionTitle }) => {
  const { tabelaFundos } = useTabelaFundoInvestimento([
    rendaFixaPosFixado,
    rendaFixa,
    rendaFixaModerado,
    creditoPrivado,
    indicePrecos,
    inflacaoAtiva,
    rendaVariavel15,
    rendaVariavel30,
    rendaVariavel49,
    rendaVariavel70,
    multiMercadoEstrategiaLivreConservador,
    multiMercadoEstrategiaLivreModerado,
    multiMercadoEstrategiaLivreArrojado,
    multiGestorMultiEstrategia,
    rendaVariavelAcoesLivres,
  ]);

  const conteudoFundos = tabelaFundos?.map(({ descricao: label, fundos }) => ({
    label,
    content: <TableFundos content={fundos} />,
  }));

  return tabelaFundos.length ? (
    <SeguridadeFaq title={sectionTitle} faqItems={conteudoFundos} right />
  ) : null;
};

FundosComposicoes.propTypes = {
  ...Section.specializedProps,
};

export default FundosComposicoes;
