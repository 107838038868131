import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";
import { Button } from "../../../components/shared/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaFileInvoiceDollar } from "react-icons/fa";
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons'
import { LINKS } from "@constants";
import imagensVida from '@asserts/images/vida';
import imagensPrestamista from '@asserts/images/prestamista';
import imagensPrevidencia from '@asserts/images/previdencia/index';

const BannerItem = ({ image, title, text, button, icon, position }) => (
  <BannerContents.Banner
    backgroundImage={image}
    className="text-white"
    {...position === 'right' ? { right: true } : { left: true }}
  >
    <Row>
      <Col xs={12} className="d-flex align-items-stretch">
        {icon && (
          <FontAwesomeIcon
            className="d-none d-md-inline-block cvp-icon-banner"
            icon={icon}
          />
        )}
        <h3 className="d-md-inline-block">{title}</h3>
      </Col>
      <Col xs={12}>
        <p className="text-body-md">{text}</p>
        {button && (
          <Button
            className="mt-3"
            blank
            secondary
            href={button.href}
          >
            {button.text}
          </Button>
        )}
      </Col>
    </Row>
  </BannerContents.Banner>
);

export const BannerHome = (props) => {
  const banners = [
    {
      image: imagensVida.bannerVida,
      title: "Seguro de vida",
      text: "Clientes CAIXA agora podem comprar o Seguro de Vida 100% online.",
      button: {
        text: "Compre agora",
        href: LINKS.autoCompraVida,
      },
      position: 'left',
    },
    {
      image: imagensPrestamista.bannerPrestamista,
      title: "Seguro Dívida Zero",
      text: "Garanta o pagamento do seu empréstimo junto à CAIXA em caso de morte ou invalidez total",
      button: {
        text: "Já sou cliente",
        href: LINKS.areaLogada,
      },
      icon: FaFileInvoiceDollar,
      position: 'right',
    },
    {
      image: imagensPrevidencia.bannerPrevidencia,
      title: "Previdência",
      text: "Uma decisão para se orgulhar lá na frente. Faça agora sem precisar sair de casa",
      button: {
        text: "Comprar agora",
        href: LINKS.autoCompraPrevidencia,
      },
      icon: faPiggyBank,
      position: 'right',
    },
  ];

  return (
    <BannerContents {...props}>
      <BannerContents.Banner
        backgroundImage={imagensPrevidencia.bannerCashback}
        className="text-blue"
        left
      >
        <Row>
          <Col xs={12} className="d-flex align-items-stretch">
          <h3 className="d-md-inline-block title-cashback">CASHBACK</h3>
          </Col>
          <Col xs={12}>
            <p className="text-body-md mt-0 mt-md-2 text-cashback">
            <b>TRAGA SEU INVESTIMENTO<br /> PARA</b> A CAIXA VIDA E <br/> PREVIDÊNCIA
              <b>E GANHE ATÉ <br/>R$2.500 </b> DE CASHBACK <br />DIRETAMENTE EM SEU PLANO
            </p>
            <Button
              className="mt-2"
              blank
              secondary
              href={LINKS.regulamentoCashBack}
            >
              Clique Aqui
            </Button>
          </Col>
        </Row>
      </BannerContents.Banner>
      {banners.map((banner) => (
        <BannerItem {...banner} position={banner.position}/>
      ))}
    </BannerContents>
  );
};

BannerHome.defaultProps = { images: {} };

BannerHome.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerHome;