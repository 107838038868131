import { cpfCnpjRegex, verifyCPF, verifyCNPJ } from "./inputUtils";

const isValidCpfCnpj = (cpfCnpj) => {
  if (!cpfCnpj) {
    return false;
  }
  const inputNumbers = cpfCnpj.replace(/[^\d]/g, "");
  const regexObj = new RegExp(cpfCnpjRegex);
  if (
    !regexObj.test(cpfCnpj) ||
    inputNumbers === "00000000000" ||
    inputNumbers === "11111111111" ||
    inputNumbers === "22222222222" ||
    inputNumbers === "33333333333" ||
    inputNumbers === "44444444444" ||
    inputNumbers === "55555555555" ||
    inputNumbers === "66666666666" ||
    inputNumbers === "77777777777" ||
    inputNumbers === "88888888888" ||
    inputNumbers === "99999999999"
  ) {
    return false;
  }
  if (inputNumbers.length === 11) {
    return verifyCPF(cpfCnpj);
  }
  if (inputNumbers.length === 14) {
    return verifyCNPJ(cpfCnpj);
  }
  return false;
};
export default isValidCpfCnpj;