import React from "react";
import {
  ThemeProvider as DSThemeProvider,
  useTheme,
} from "@cvp/design-system/react";
import { ThemeProvider } from "styled-components";

const DSConfig = ({ children }) => {
  const theme = "caixa-seguradora";
  return (
    <DSThemeProvider theme={theme}>
      <ThemeProvider theme={useTheme(theme)}>{children}</ThemeProvider>
    </DSThemeProvider>
  );
};

export default DSConfig;
