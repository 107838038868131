import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";

const BannerEnviar = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerSinistro}
          className="text-white  ouvidoria-banner"
        >
          <Row>
            <Col>
              <TitleSinistro>Envio de documentos</TitleSinistro>
              <p>
                Faça aqui o envio de toda a documentação solicitada após a
                abertura do sinistro.
              </p>
            </Col>
          </Row>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

const TitleSinistro = styled.h4`
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

BannerEnviar.defaultProps = { images: {} };

BannerEnviar.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerEnviar;
