import React from "react";
import { SubmitButton } from "./styles";

const ConditionalSubmitButton = ({
  exibirSemProduto,
  isLoadingSinistro,
  falhaFluxo,
  formikBag,
}) => {
  return (
    <>
      {falhaFluxo || exibirSemProduto ? (
        <SubmitButton label="Próxima Etapa" isValid={false} />
      ) : (
        <SubmitButton
          isLoading={isLoadingSinistro}
          label="Próxima Etapa "
          {...formikBag}
        />
      )}
    </>
  );
};

export default ConditionalSubmitButton;
