import React from 'react';
import styled, { css } from "styled-components";
import ButtonLink from './ButtonLink';


import Button from './Button';

const BtnIcon = styled(Button)`
  color: #494949;
  background-color: transparent;
  border: none;
  width: auto;
  min-width: fit-content;
  font-weight: 100;
`;

const ButtonIcon = props => <BtnIcon {...props} />;

export default ButtonIcon;

export const arrowIconRight = css`
    position: relative;
    &::after {
      font-family: 'cs-icons';
      content: '\\2b';
      position: absolute;
      top: 12px;
      right: -10px;
      z-index: 10;
      font-size: 12px;
      font-weight: 100;
    }
`;

export const ButtonLinkIcon = styled(ButtonLink)`
    ${arrowIconRight}
`;
