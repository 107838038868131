import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaqBeneficios } from "../../../components/blocosCustomizados";
import IconReducaoCusto from "../../../asserts/images/acidentespessoais/icon-reducao-custo.svg";
import IconAssistenciaFuneral from "../../../asserts/images/acidentespessoais/icon-assistencia-funeral.svg";
import IconAssistenciaFarmacia from "../../../asserts/images/acidentespessoais/icon-assistencia-farmacia.svg";
import IconIndenizacaoMorteAcidental from "../../../asserts/images/acidentespessoais/icon-indenizacao-morte-acidental.svg";
import IconSorteios from "../../../asserts/images/acidentespessoais/icon-sorteios.svg";

const BeneficiosBemEstarSeguroAcidentesPessoais = ({ sectionTitle }) => {
const faqItems = [
  {
    texto: ("Redução"),    
    label: (
      <>     
        <IconReducaoCusto />{" "}
        <p className="text-label">Redução de custo para consultas e exames</p>
      </>
    ),
    content: (
      <>
        <p>
          Preços reduzidos que podem chegar em até{" "}
          <strong>65% de economia do valor de mercado.</strong>
        </p>
        <ul className="li-marcador-laranja">
          <li>
            Faça consultas médicas e odontológicas, exames laboratoriais e de
            imagem, em rede particular credenciada, com até 65% de redução de
            preço. Os atendimentos são realizados com hora marcada para você ter
            melhor qualidade de vida e mais flexibilidade.
          </li>
        </ul>
      </>
    ),
  },
  {
    label: (
      <>
        <IconAssistenciaFuneral />{" "}
        <p className="text-label">Assistência Funeral Familiar</p>
      </>
    ),
    content: (
      <>
        <p>
          Prestação dos Serviços de Assistência Funeral em caso de falecimento
          do{" "}
          <strong>
            Segurado titular, cônjuge e filhos com até 24 anos, por qualquer que
            tenha sido a causa da morte.
          </strong>
        </p>
        <ul>
          <li>
            Em caso de falecimento do(a) segurado(a) titular, cônjuge e filhos
            com até 24 anos, por qualquer que tenha sido a causa, garantimos a
            cobertura da assistência funeral familiar:
            <p>
              &#x2022; Atendimento e organização do funeral.
              <br />
              &#x2022; Translato até o domicílio do(a) beneficiário(a).
              <br />
              &#x2022; Transporte do corpo até o local da residência no Brasil,
              caso o falecimento tenha acontecido em local diverso.
              <br />
              &#x2022; Tratamento das formalidades para liberação do corpo e
              registro em cartório.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    label: (
      <>
        <IconAssistenciaFarmacia />{" "}
        <p className="text-label">Assistência Farmácia</p>
      </>
    ),
    content: (
      <>
        <p>
          <strong>Desconto de até R$ 80% </strong>em medicamentos.
        </p>
        <ul>
          <li>
            <p>
              Após a emissão do seguro, o benefício será ativado em até 72
              horas. A assistência garante reduções de preço de até 80% nos
              medicamentos das farmácias e drogarias credenciadas.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    label: (
      <>
        <IconIndenizacaoMorteAcidental />{" "}
        <p className="text-label">Indenização por morte acidental</p>
      </>
    ),
    content: (
      <>
        <p>
          Indenização de <strong>R$ 20 mil </strong>paga ao(s) beneficiário(s)
          pelo(a) segurado(a) titular, em caso de morte acidental.
        </p>
      </>
    ),
  },
  {
    label: (
      <>
        <IconSorteios /> <p className="text-label">Sorteios</p>
      </>
    ),
    content: (
      <>
        <p>
          Com sorteios mensais de <strong>R$ 20 mil.</strong>
        </p>
      </>
    ),
  },
];

  return (
    <SeguridadeFaqBeneficios
      title={sectionTitle}
      faqItems={faqItems}
      variant="seguridade-light"
      left
      columnContent
      twoColumn
    />
  );
};

BeneficiosBemEstarSeguroAcidentesPessoais.propTypes = {
  ...Section.specializedProps,
};

export default BeneficiosBemEstarSeguroAcidentesPessoais;
