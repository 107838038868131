import React from "react";
import PropTypes from "prop-types";
import { Button } from "@components/shared/actions";
import { LINKS } from "@constants";
import images, { createImageElement } from "@asserts/images";
import Apresentacao from "../../blocosCustomizados/Apresentacao";
import { Section } from "../pages";
import CenterImageContent from "../contents/CenterImageContent";

export const SuaEmpresaPodeContar = (props) => {
  const { sectionTitle } = props;

  const imagem = createImageElement(images.suaEmpresaPodeContarIlustracao, {
    alt: "Sua Empresa pode contar com a gente",
  });

  return (
    <Apresentacao image={images.suaEmpresaPodeContarIlustracao} imageColumn={3}>
      <h2>{sectionTitle}</h2>
      <p className="mt-4 ">Se já é cliente, basta acessar a sua área</p>
      <div className="d-flex d-md-none justify-content-center mb-5">
        <CenterImageContent image={imagem} />
      </div>
      <div className="faq-fotter-buttons d-block d-lg-flex justify-content-center justify-content-md-between">
        <Button
          href={LINKS.areaLogada}
          className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
          blank
        >
          Acessar área do cliente
        </Button>
      </div>
    </Apresentacao>
  );
};

SuaEmpresaPodeContar.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

SuaEmpresaPodeContar.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.string.isRequired,
};

export default SuaEmpresaPodeContar;
