/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container, Navbar, Nav, Row, Col, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook,  } from "@fortawesome/free-solid-svg-icons";
import { Section } from "@components/shared/pages";
import images from "@asserts/images";
import { Button, Link, PhoneLink } from "@components/shared/actions";
import { Accordion, AccordionCard } from "../../shared/accordion";
import FooterSection from "./FooterSection";
import envs from "../../../config/envs";

const DefaultFooter = () => {
  return (
    <>
      <Section className="fale-conosco-footer">
        <Accordion name="lista-telefonica">
          <AccordionCard
            label={
              <>
                <FontAwesomeIcon icon={faAddressBook} /> Fale com a Caixa
                Seguradora
              </>
            }
          >
            <FooterSection>
              <Container>
                <Row>
                  <Col sm={4}>Central de Serviços e Relacionamento</Col>
                  <Col>
                    <PhoneLink
                      button
                      block
                      outline
                      phone="08007024000"
                      className="text-justify"
                    />
                  </Col>
                </Row>
              </Container>
            </FooterSection>
            <FooterSection>
              <Container>
                <Row>
                  <Col sm={4}>WhatsApp</Col>
                  <Col>
                    <PhoneLink
                      button
                      block
                      outline
                      phone="6121922020"
                      className="text-justify"
                    />
                    Atendimento de segunda a sexta de 9h às 19h, pelo horário de
                    Brasília. Não há atendimento em feriados nacionais.
                  </Col>
                </Row>
              </Container>
            </FooterSection>
            <FooterSection>
              <Container>
                <Row>
                  <Col sm={4}>Assistências 24h | Sinistros</Col>
                  <Col>
                    <PhoneLink
                      button
                      block
                      outline
                      phone="08007222492"
                      className="text-justify"
                    />
                    Ligue a qualquer hora para comunicar algum incidente ou
                    acionar suas assistências
                  </Col>
                </Row>
              </Container>
            </FooterSection>
          </AccordionCard>
        </Accordion>
      </Section>
      <Container>
        <FooterSection>
          <Navbar expand="xs" collapseOnSelect>
            <Navbar.Brand as={Link}>
              <img src={images.brandingLogo} alt="logo" />
            </Navbar.Brand>
            <Nav className="mr-auto">
              <Button pill phantom>
                Login
              </Button>
            </Nav>
          </Navbar>
          <Nav className="mr-auto flex-column flex-lg-row">
            <LinkContainer to="/">
              <Nav.Link as={Link}>A Caixa Seguradora</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Produtos</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Para empresas</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Atendimento</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Ajuda</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Política de Privacidade</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/">
              <Nav.Link>Segurança</Nav.Link>
            </LinkContainer>
          </Nav>
        </FooterSection>
        <FooterSection className="footer-socials">
          <Row>
            <Col md={3} xs={6}>
              <h6>Redes Sociais</h6>
              <Row>
                <Col xs={{ span: 8 }}>
                  <div className="footer-socials-itens d-flex justify-content-between">
                    <Link>
                      <i className="cs-icon icon-insta" />
                    </Link>
                    <Link>
                      <i className="cs-icon icon-facebook" />
                    </Link>
                    <Link>
                      <i className="cs-icon icon-linkedin" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={3} xs={6}>
              <h6>Aplicativos</h6>
              <div className="footer-socials-itens d-flex justify-content-between">
                <Link className="icon-app">
                  <i className="cs-icon icon-app-store" />
                </Link>
                <Link className="icon-app">
                  <i className="cs-icon icon-play-store" />
                </Link>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <h6>Certificados</h6>
              <div className="footer-socials-itens d-flex justify-content-between">
                <Link className="secured-link">
                  <Image
                    src={images.geotrustIcon}
                    alt="GeoTrust Secured"
                    fluid
                  />
                </Link>
                <Link className="secured-link">
                  <Image src={images.nortontIcon} alt="Norton Secured" fluid />
                </Link>
              </div>
            </Col>
          </Row>
        </FooterSection>
        <FooterSection>
          <Row>
            <Col className="d-flex">
              <address className="align-self-end">
                <p>Caixa Seguradora S/A - CNPJ 34.020.354/0001-10</p>
                <p>Edifício Sede: SHN Quadra 01, Conjunto A, Bloco E</p>
                <p>Brasília/DF - CEP: 70701-050</p>
              </address>
            </Col>
            <Col className="d-flex justify-content-end">
              <span className=" align-self-end copyright">
                © Copyright {envs.copyRightYear || "2021"} Caixa Seguradora
              </span>
            </Col>
          </Row>
        </FooterSection>
      </Container>
    </>
  );
};

DefaultFooter.Section = FooterSection;

export default DefaultFooter;
