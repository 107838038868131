import React, { useState, useEffect } from 'react';
import imagensSinistro from '@asserts/images/sinistro';

import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
  BannerAcompanhamento,
  Acompanhamento,
  SinistroContato,
} from '../sections';

import { Page, Section } from '../../../components/shared/pages';

const AcompanhamentoPage = (props) => {
  const appInsights = useAppInsightsContext();
  const [errorSinistro, setErrorSinistro] = useState(false);
  const trackSinistroEnviarDocumentos = useTrackEvent(
    appInsights,
    'AA_Sinistro_Enviar_Documentos'
  );

  useEffect(() => {
    const AcompanharEvent = {
      event: 'AA.navegacao',
      rtualPageTitle: 'Caixa Vida e Previdência - Sinistro - Acompanhamento',
      rtualPageURL: '/sinistro/acompanhamento',
    };
    trackSinistroEnviarDocumentos(AcompanharEvent);
  }, [trackSinistroEnviarDocumentos]);

  return (
    <Page {...props} images={imagensSinistro} banner={BannerAcompanhamento}>
      <Section
        id="sinistro-acompanhamento"
        as={Acompanhamento}
        title="Acompanhamento de sinistro - desvincular cadastro"
        sectionLayout={null}
        setErrorSinistro={setErrorSinistro}
        errorSinistro={errorSinistro}
      />
      <Section
        id="sinistro-contato"
        as={SinistroContato}
        title="Se preferir, você pode abrir uma ocorrência de Sinistro por telefone:"
        sectionName="solicitar protocolo"
      />
    </Page>
  );
};

export default AcompanhamentoPage;
