import PropTypes from "prop-types";

const ShowIf = ({ children, condition }) => {
  return condition ? children : null;
};

ShowIf.defaultProps = {
  condition: false,
};

ShowIf.propTypes = {
  condition: PropTypes.bool,
};

export default ShowIf;
