import React from "react";
import { PropTypes } from "prop-types";
import { Button, Link } from "@components/shared/actions";
import { LINKS } from "@constants";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoIR = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.declareIr} title={sectionTitle} imageColumn={3}>
      <p className="pr-3 pr-md-2">
        Aqui, você encontra uma explicação de como baixar seu informe na
        <Link blank href={LINKS.areaLogada} label="área do cliente" />, mas se
        preferir, pode acessar o documento no nosso aplicativo Caixa Previdência
        ou no Internet Banking Caixa, pelo seu computador. Além disso, você
        também encontra um guia explicativo de como declarar o seu produto no
        formulário da Receita Federal.
      </p>

      <p>
        <strong>Já sabe como funciona e quer baixar o informe? </strong>
      </p>

      <Button
        href={LINKS.areaLogada}
        className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
        blank
        label="Acesse a área do cliente"
      />
    </Apresentacao>
  );
};

ApresentacaoIR.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

ApresentacaoIR.propTypes = {
  images: PropTypes.objectOf(Object),
  ...Section.propTypes,
};

export default ApresentacaoIR;
