export function buildDadosSinistro(dadosSinistro) {
  return {
    cobertura: dadosSinistro?.cobertura ?? '',
    dthAcidente: dadosSinistro?.dthAcidente ?? '',
    dthSinistro: dadosSinistro?.dthSinistro ?? '',
    nomeConjugeFilho: dadosSinistro?.nomeConjugeFilho ?? '',
    cpfConjugeFilho: dadosSinistro?.cpfConjugeFilho ?? '',
    dthConjugeFilho: dadosSinistro?.dthNascimentoConjugeFilho ?? '',
    historicoSinistro: dadosSinistro?.historicoSinistro ?? '',
    evento: dadosSinistro?.evento ?? '',
    causa: dadosSinistro?.causa ?? '',
    pessoa: dadosSinistro?.pessoa ?? '',
  };
}
export default buildDadosSinistro;
