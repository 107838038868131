import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { Banner } from "../shared/layout/banners";

const BannerAplicativos = (props) => {
  const { text, appleBotao, androidBotao } = props;

  return (
    <Banner
      {...props}
      className="text-white banner-ir-2021"
      right
      customPros={{
        xs: 12,
        md: 12,
      }}
    >
      <Row className="pt-2">
        <Col>
          <h2 className="titulo-banner-botao">APP Caixa Previdência!</h2>
          <p className="text-body-md mt-0 mt-md-2">{text}</p>
          <div className="d-flex">
            <img src={androidBotao} alt="logo" className="botoes-app-banner mt-3 mr-3" />
            <img src={appleBotao} alt="logo" className="botoes-app-banner mt-3" />
          </div>
        </Col>
      </Row>
    </Banner>
  );
};

BannerAplicativos.defaultProps = {
  text: "Faça um aporte ou aumente sua contribuição mensal no plano de Previdência Privada e construa a sua tranquilidade financeira.",
  labelBotao: "Acesse agora!",
};

BannerAplicativos.propTypes = {
  text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  backgroundImage: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  labelBotao: PropTypes.string,
};

export default BannerAplicativos;
