import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Section } from '../../../components/shared/pages';

const DevoDeclarar = (props) => {
  const { sectionTitle } = props;

  return (
    <Row>
      <Col>
        <h3 className=" ml-3 px-0 px-md-4 mt-3 mt-md-4 content-title">
          {' '}
          {sectionTitle}
        </h3>

        <div className="ml-3 px-0 px-md-4 mb-4">
          <p>
            Ninguém quer cair na malha fina, né? Por isso, se você tem uma
            Previdência é importante entender quais situações que existem
            obrigatoriedade ou não para realizar a declaração de acordo com a
            Receita Federal.
          </p>

          <p>
            <strong> É obrigatório </strong>
            <ul>
              <li>
                Se você, em 2023, <strong>resgatou</strong> sua Previdência,{' '}
                <strong>usufruiu</strong> do seu benefício ou recebeu algum
                valor referente a um<strong> sinistro</strong>.
              </li>
            </ul>
          </p>
          <p>
            <strong>É importante</strong>
            <ul>
              <li> Se você possui saldo na Previdência;</li>
              <li>
                Se você possui um Federal Prev ou tem produto PGBL pode contar
                com o benefício fiscal, ou seja, ao declarar o seu produto você
                tem uma dedução do cálculo no seu Imposto de Renda.{' '}
              </li>
            </ul>
          </p>
        </div>
      </Col>
    </Row>
  );
};

DevoDeclarar.defaultProps = {
  ...Section.defaultProps,
};

DevoDeclarar.propTypes = {
  ...Section.propTypes,
};

export default DevoDeclarar;
