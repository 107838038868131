/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { PropTypes } from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import InfoDuvida from "../../../components/shared/blocks/InfoDuvida";
import { PhoneLink } from "../../../components/shared/actions";

export const CoberturaAssistencia = ({ images }) => {
  const contenteInfoDuvidaCuidadoExtra = (
    <>
      <h5>Como faço para usar esse Cuidado Extra?</h5>

      <p>
        Em caso de falecimento do titular do plano, a família deve entrar em
        contato com a nossa Central de Serviços e Relacionamento:{" "}
        <PhoneLink phone="0800 702 4000" />
      </p>
    </>
  );

  const conteudoApresentacao = [
    {
      imagem: {
        src: images.previdenciaCoberturaAssistencia,
        alt: "Cobertura e Assistência",
      },
      tamanhoColImagem: 3,
      imagemNaDireita: true,
      conteudo: (
        <>
          <h1>Nossas coberturas e assistências</h1>
          <h5 className="sinalizador-titulo">Cuidado Extra</h5>
          <p>
            Ao contratar um plano de previdência, você pode contratar também o
            Cuidado Extra. O que é isso?
          </p>
          <p>
            Além do dinheiro que você investe e fica lá rendendo, caso você
            venha a falecer durante o tempo de contribuição do plano, sua
            família ou quem você escolher recebe um valor extra de indenização.
            Você pode escolher se os beneficiários vão receber a indenização de
            uma só vez ou mensalmente, em forma de pensão. É você quem determina
            esse valor, dependendo do quanto quer ou pode pagar.
          </p>

          <p>
            Não importa o tempo que tiver a previdência, você conseguirá dar
            apoio financeiro para a família caso venha a falecer. É importante
            lembrar que essa cobertura tem uma carência de 18 meses para casos
            de morte por causas naturais.
            <LinkContainer to="/previdencia/saiba-mais">
              <a> Entenda melhor sobre Cuidado Extra </a>
            </LinkContainer>
            e o que este serviço pode fazer pra quem você ama.
          </p>

          <div>
            <InfoDuvida
              content={contenteInfoDuvidaCuidadoExtra}
              className="info-duvida info-duvida-main-cuidado-futuro-color-primary"
            />
          </div>

          <h5 className="sinalizador-titulo">Cuidado Garantido</h5>
          <p>
            Em alguns planos do tipo VGBL, se você deixar de contribuir, uma
            parte do seu saldo será usada para que a sua família continue
            contando com o Cuidado Extra. É simples: se você não fizer sua
            contribuição em algum mês, automaticamente o valor dessa cobertura é
            debitado do valor que estava guardado, para dar tranquilidade a sua
            família.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <Apresentacao contents={conteudoApresentacao} />
    </>
  );
};

CoberturaAssistencia.defaultProps = { images: {} };

CoberturaAssistencia.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default CoberturaAssistencia;
