import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ErrorText from "./styles";
import { PhoneLink } from "../../../../components/shared/actions";

const errorMessages = {
  notValid: "O seu protocolo não é um número válido.",
  notFound: "O seu protocolo não foi localizado.",
};
const ProtocolStatusError = ({
  isLoadingProtocol,
  errorProtocol,
  isProtocolValid,
  isProtocolFound,
  hasGeneralError,
  canUseProtocolForOmbudsman,
}) => {
  const history = useHistory();
  if (isLoadingProtocol || !errorProtocol || hasGeneralError) {
    return null;
  }
  if (
    errorProtocol &&
    !hasGeneralError &&
    isProtocolValid &&
    isProtocolFound &&
    !canUseProtocolForOmbudsman
  ) {
    history.push("/ouvidoria/pendente");
  }
  return (
    <ErrorText>
      {errorProtocol && !isProtocolValid && errorMessages.notValid}
      {errorProtocol && !isProtocolFound && errorMessages.notFound}
      <br />
      Confira se você digitou os números corretamente. Se você ainda não possui
      uma solicitação registrada, entre em contato com a nossa Central de
      Atendimento pelos telefones abaixo:
      <br />
      <PhoneLink phone="0800 702 4000" />
    </ErrorText>
  );
};
ProtocolStatusError.defaultProps = {
  errorProtocol: false,
  isLoadingProtocol: false,
  isProtocolValid: false,
  isProtocolFound: false,
  hasGeneralError: false,
  canUseProtocolForOmbudsman: false,
};
ProtocolStatusError.propTypes = {
  errorProtocol: PropTypes.bool,
  isLoadingProtocol: PropTypes.bool,
  isProtocolValid: PropTypes.bool,
  isProtocolFound: PropTypes.bool,
  hasGeneralError: PropTypes.bool,
  canUseProtocolForOmbudsman: PropTypes.bool,
};
export default ProtocolStatusError;
