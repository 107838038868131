import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensVida from "@asserts/images/vida";
import {
  BannerVida,
  Coberturas,
  PassoCoberturas,
  Assistencias,
  PassoAssistencia,
} from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

const CoberturaAssistenciaPage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackVidaCoberturas = useTrackEvent(appInsights, "AA_Vida_Coberturas");

  useEffect(() => {
    const vidaCoberturasEvent = {
      event: "AA.navegacao",
      virtualPageTitle:
        "Caixa Vida e Previdência - Seguro de Vida - Coberturas e Assistências",
      virtualPageURL: "/segurodevida/coberturas-e-assistencias",
    };
    trackVidaCoberturas(vidaCoberturasEvent);
  }, [trackVidaCoberturas]);

  return (
    <Page {...props} images={imagensVida} banner={BannerVida}>
      <SeguridadePageLayout.Tab eventKey="coberturas-assistencias">
        <Section
          id="bloco-cobertura"
          as={Coberturas}
          title="Coberturas"
          sectionName="coberturas"
        />
        <Section
          id="passos-coberturas"
          as={PassoCoberturas}
          title="Para acionar as coberturas, você ou alguém da sua família deve:"
          sectionLayout={null}
          sectionName="acionar coberturas"
        />
        <Section
          id="bloco-assistencias"
          as={Assistencias}
          title="Conheça também nossas assistências"
          sectionName="conheca as assistencias"
          className="border-top pt-5"
        />
        <Section
          id="passos-assistencia"
          as={PassoAssistencia}
          title="Para acionar as assistências, você precisa:"
          sectionLayout={null}
          sectionName="acionar asssistencias"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default CoberturaAssistenciaPage;
