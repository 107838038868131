/* eslint-disable sonarjs/cognitive-complexity */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { endPoints } from '../../config';
import useGiRequest, { POST } from './useGiRequest';
import usePerformGiRequest from './usePerformGiRequest';

import { buildSegurado } from './builds/buildSegurado';
import { buildReclamante } from './builds/buildReclamante';
import { buildDadosSinistro } from './builds/buildDadosSinistro';

/**
 * This service hook will call an API to create a new ombudsman request based on input data
 *
 * @component
 * @return  {UseSinistroHook}
 *
 *  @typedef   {Object}    UseSinistroHook
 *  @property  {function}  createSinistro     Function used for API call
 *  @property  {Object}    segurado
 *  @property  {Object}    reclamante
 *  @property  {Object}    comunicadoSinistro
 *  @property  {boolean}   hasProductSinistro
 *  @property  {Object}    data
 *  @property  {boolean}   loading            Loading indicator
 *  @property  {boolean}   error              Error indicator
 */
const useSinistro = () => {
  const { performGiRequest, errorGi } = useGiRequest();
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = usePerformGiRequest();

  /**
   * Creates new ombudsman request
   * @param   {Object}   jsonData         Input data for request creation
   * @param   {String}   reCaptchaToken   reCaptcha solved challenge token for validation
   */
  const verificarProduto = async (jsonData, reCaptchaToken1) => {
    setLoading(true);

    const responseProduto = await performGiRequest({
      method: POST,
      url: endPoints.verificarProdutoUri(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        UserName: jsonData.segurado.docSegurado,
        SHArsaKey: await getAccessToken(
          jsonData.segurado.docSegurado,
          'GBE_ConsultaContratosResumido_SF'
        ),
        ApiID: '',
        codCpfCnpj: jsonData.segurado.docSegurado,
        // situacao: "ATIVO",
      },
    });
    setLoading(false);
    return responseProduto?.data;
  };

  const abrirComunidadoSinistro = async (
    jsonDataComunicado,
    reCaptchaToken2,
    product,
    documentService
  ) => {
    const cpfCnpj = jsonDataComunicado.segurado.docSegurado;
    const solicitacoes = product.map((m) => {
      let comentarioCaso;
      if (documentService) {
        comentarioCaso = `Envio de Documentos - ${m}`;
      } else {
        comentarioCaso = `Abertura de Sinistro - ${m}`;
      }
      return {
        unidadeNegocio: m,
        comentarioCaso,
        numeroContrato: '',
        indicadorContrato: '',
        codigoAdministrativoAssunto: documentService
          ? '10-604-949'
          : '10-603-11',
        representanteLegal: {
          cpfCnpj,
          telefone: jsonDataComunicado.reclamante.telefoneCelular,
          email: jsonDataComunicado.reclamante.email,
        },
      };
    });

    const payload = {
      canalOrigem: 'Web - Seguradora',
      origemContato: 'Área Aberta',
      numeroDocumento: cpfCnpj,
      cliente: {
        primeiroNome: jsonDataComunicado.segurado.nomeSegurado.split(' ')[0],
        ultimoNome: jsonDataComunicado.segurado.nomeSegurado.split(' ')[
          jsonDataComunicado.segurado.nomeSegurado.split(' ').length - 1
        ],
        email: jsonDataComunicado.reclamante.email,
        telefone: jsonDataComunicado.reclamante.telefoneCelular,
        endereco: {
          bairro: documentService ? '' : jsonDataComunicado.reclamante.bairro,
          numero: documentService ? '' : jsonDataComunicado.reclamante.numero,
          cep: documentService ? '' : jsonDataComunicado.reclamante.cep,
          cidade: documentService ? '' : jsonDataComunicado.reclamante.cidade,
          estado: documentService ? '' : jsonDataComunicado.reclamante.estado,
          rua: documentService ? '' : jsonDataComunicado.reclamante.endereco,
        },
      },
      solicitacoes,
    };

    const responseAberturaSinistro = await performGiRequest({
      method: POST,
      url: endPoints.gerarProtocoloGi(),
      headers: {
        'g-captcha-response': reCaptchaToken2,
      },
      data: {
        ...payload,
      },
    });

    return responseAberturaSinistro?.data;
  };

  const enviarAnexo = async (
    jsonDataAnexo,
    resultAbertura,
    reCaptchaToken3
  ) => {
    const jsonAnexo = {
      codigoSolicitacoes: resultAbertura.dados.entidade.codigoSolicitacoes,
      sinistro: {
        segurado: buildSegurado(jsonDataAnexo?.segurado),
        reclamante: buildReclamante(jsonDataAnexo?.reclamante),
        dadosSinistro: buildDadosSinistro(jsonDataAnexo?.dadosSinistro),
      },
    };

    const responseAnexo = await performGiRequest({
      method: POST,
      url: endPoints.enviarAnexo(),
      data: jsonAnexo,
      headers: {
        'g-captcha-response': reCaptchaToken3,
      },
    });

    setLoading(false);

    return responseAnexo?.data;
  };

  const anexarDocumentosSinistro = async (data) => {
    setLoading(true);

    const responseProduto = await performGiRequest({
      method: POST,
      url: endPoints.EnviarDocumentos(),
      data: {
        UserName: 'AG3391',
        SHArsaKey: await getAccessToken(
          'AG3391',
          'ALOG_AnexarArquivoSolicitacao'
        ),
        arquivoBase64: data.base64,
        descricaoArquivo: 'DESCRIÇÃO DE TESTE DO ANEXO',
        nomeArquivo: data.file.name,
        codigoSolicitacao: data.protocoloAnexo,
      },
    });

    return responseProduto?.data;
  };


  return {
    verificarProduto,
    abrirComunidadoSinistro,
    enviarAnexo,
    anexarDocumentosSinistro,
    loading,
    errorGi,
  };
};

useSinistro.propTypes = {
  verificarProduto: PropTypes.func,
  abrirComunidadoSinistro: PropTypes.func,
  enviarAnexo: PropTypes.func,
  loading: PropTypes.bool,
  errorGi: PropTypes.bool,
};

export default useSinistro;
