import React, { useEffect } from 'react';
import imagensPrevidencia from '@asserts/images/previdencia/index';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
  BannerPrevidencia,
  ApresentacaoPrevidencia,
  PorquePrecisa,
  VantagemPrevidencia,
  JaSouClientePrevidencia,
  TudoQueVoceQueriaSaber,
  FacaPrevidencia,
  Regulamentos,
  CentralSinistro,
  Campanha,
} from '../sections';
import { Page, Section } from '../../../components/shared/pages';

import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { ParaSeuNegocio } from '../../../components/shared/blocks';
import { useCampanhas } from '../../../hooks';

const HomePage = (props) => {
  const { getCampanhas, campanhas } = useCampanhas();
  const appInsights = useAppInsightsContext();
  const trackPrevidencia = useTrackEvent(appInsights, 'AA_Previdencia');

  useEffect(() => {
    const previdenciaEvent = {
      event: 'AA.navegacao',
      virtualPageTitle: 'Caixa Vida e Previdência - Previdência',
      virtualPageURL: '/previdencia',
    };
    trackPrevidencia(previdenciaEvent);
  }, [trackPrevidencia]);

  useEffect(() => {
    getCampanhas('previdencia');
  }, []);

  return (
    <Page images={imagensPrevidencia} {...props} banner={BannerPrevidencia}>
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoPrevidencia}
          id="o-que-e-previdencia"
          title="O que é a previdência?"
          sectionName="o que e"
        />
        <Section
          title="Por que você precisa de uma previdência?"
          as={PorquePrecisa}
          id="porque-fazer"
          sectionName="porque precisa"
        />

        <Section
          title="Vantagens dos nossos planos"
          as={VantagemPrevidencia}
          id="previdencia-vantagens"
          sectionName="vantagens"
        />
        <div id="regulamentos-previdencia" />
        <Section
          title="Faça uma previdência ;)"
          sectionLayout={null}
          as={FacaPrevidencia}
          id="faca-previdencia"
          sectionName="faca uma previdencia"
        />
        <Section
          as={CentralSinistro}
          id="sinitro"
          title="Central de Sinistros"
          className="sinistros-prestamista"
          sectionName="sinistros"
        />
        <Section
          className="py-5 border-top"
          sectionLayout={null}
          title="Regulamentos de Previdência"
          as={Regulamentos}
          id="previdencia"
          sectionName="regulamentos"
        />
        <Section
          as={TudoQueVoceQueriaSaber}
          title="Saiba tudo sobre a previdência"
          className="background-image-none-sm mt-3 border-top"
          sectionLayout={null}
          sectionName="saiba tudo"
        />
        <Section
          as={JaSouClientePrevidencia}
          title="Já sou cliente"
          className="bloco-ja-sou-cliente-vida background-image-none-sm"
          id="ja-sou-cliente"
          sectionLayout={null}
          sectionName="ja sou cliente"
          defaultActiveKey={0}
          anchorConfig={{ ajustment: -690 }}
        />
              <Section
          as={Campanha}
          title="Ações promocionais"
          className={`bloco-campanha${campanhas.length ? ' border-top' : ''}`}
          id="acoes-promocionais"
          sectionLayout={null}
          sectionName="acoes promocionais"
          campanhas={campanhas}
        />
      </SeguridadePageLayout.Tab>
      <SeguridadePageLayout.Tab label="Para sua empresa" eventKey="negocio">
        <ParaSeuNegocio />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;