import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";

export const Investimentos = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.MoneySVG} title={sectionTitle} right>
      <p>
        Em uma época de incertezas e mudanças no mercado financeiro, investir
        seu dinheiro em uma das instituições mais sólidas e tradicionais, como a
        Caixa Vida e Previdência, pode ser a melhor forma de ter boas noites de
        sono.
      </p>

      <p>
        Adquirindo ou mantendo sua previdência com a gente, você tem mais
        segurança para cuidar do restante da sua carteira e do futuro que está
        construindo para quem ama.
      </p>
    </Apresentacao>
  );
};

Investimentos.defaultProps = {};

Investimentos.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default Investimentos;
