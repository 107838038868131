import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import FaqButtonAreaCliente from "../../../components/shared/common/FaqButtonAreaCliente";
import { CONTENTS } from "../../../constants";

const faqItems = [...CONTENTS.faqItemVidaSenior];

const SaibaMaisSeguroVidaSenior = ({ sectionTitle, sectionName }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={faqItems}
      right
      faqFooterContent={
        sectionName === "duvidas" ? <FaqButtonAreaCliente /> : ""
      }
    />
  );
};

SaibaMaisSeguroVidaSenior.propTypes = {
  ...Section.specializedProps,
};

export default SaibaMaisSeguroVidaSenior;
