import { Text } from "@cvp/design-system/react";
import styled from "styled-components";

export const TitleSemProduto = styled(Text)`
  font-family: "Futura Std Bold";
  margin-bottom: 30px;
`;

export const DescriptionSemProduto = styled(Text)``;

export const ContainerSemProduto = styled.div`
  display: block;
  margin: 20px 0 28px 0;
  @media (min-width: 1024px) {
    margin: 40px 0 48px 0;
  }
`;
