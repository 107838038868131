import React from 'react';
import * as S from './styled';

const RoundButton = ({ children, icon, src, alt = '', ...props }) => (
  <S.RoundButton {...props}>
    <S.ContentContainer>
      {(icon && <S.RoundButtonIcon icon={icon} alt={alt} />) ||
        (src && <S.RoundButtonImage src={src} alt={alt} />)}
      {children}
    </S.ContentContainer>
  </S.RoundButton>
);

export default RoundButton;
