import React from "react";

export default React.createContext({
  results: {
    valorDeducaoFiscal: 0,
    contribuicaoInss: 0,
    invistaMais: 0,
    impostoRenda: 0,
    impostoRendaDeduzidoPrev: 0,
    totalDeducoes: 0,
    totalDeducoesComPrev: 0,
    economiaComInvestimentoPGBL: 0,
    despesasMedicas: 0,
    investEducacaoPropria: 0,
    investEducacaoDependentes: 0,
    pensaoAlimenticia: 0,
    previdenciaComplementar: 0,
    previdenciaPBGL: 0,
    deducaoDependentesIR: 0,
    rendaBaseIR: 0,
    rendaBaseIRSemPrev: 0,
  },
});
