import { useState } from 'react';
import PropTypes from 'prop-types';
import { endPoints } from '../../config';
import useGiRequest, { POST } from './useGiRequest';
import usePerformGiRequest from './usePerformGiRequest';

const useSinisterTimelinePrestamista = () => {
  const { performGiRequest, errorGi } = useGiRequest();
  const [loadingPrest, setLoading] = useState(false);
  const { getAccessToken } = usePerformGiRequest();

  const getAllSinisterOpened = async (cpf, reCaptchaToken1) => {
    setLoading(true);

    const responseGi = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarSinistroCpfCnpjPrestamista(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        NOM_SEGURADO: '',
        NUM_CONTRATO: 0,
        NUM_CONTRATO_TERC: 0,
        NUM_CPFCNPJ: cpf,
        NUM_PROTOCOLO: 0,
        NUM_SINISTRO: 0,
        DTA_INICIO: '1960-01-01',
        DTA_FIM: '9999-12-31',
        UserName: cpf,
        SHArsaKey: await getAccessToken(cpf, 'PrestRecuperaDadosSinistro'),
      },
    });

    setLoading(false);

    return responseGi?.data;
  };

  const getTimelineSinister = async (jsonData, reCaptchaToken1) => {
    setLoading(true);

    const responseGi = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarSinistroPrestamista(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        BISinistro: jsonData.BISinistro,
        UserName: jsonData.cpf,
        SHArsaKey: await getAccessToken(
          jsonData.cpf,
          'TimelineSinistrosPrestamista'
        ),
      },
    });

    return responseGi?.data;
  };

  return {
    getAllSinisterOpened,
    getTimelineSinister,
    loadingVida: loadingPrest,
    errorGi,
  };
};

useSinisterTimelinePrestamista.propTypes = {
  getAllSinisterOpened: PropTypes.func,
  getTimelineSinister: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorGi: PropTypes.bool,
};

export default useSinisterTimelinePrestamista;
