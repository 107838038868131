import React from 'react';
import { SubmitButton } from './styles';

const ConditionalSubmitButton = ({
  exibirSemProduto,
  isLoadingSinistro,
  falhaFluxo,
  formikBag,
}) => {
  return (
    <>
      {falhaFluxo || exibirSemProduto ? (
        <SubmitButton label="Abrir comunicado de sinistro" isValid={false} />
      ) : (
        <SubmitButton
          isLoading={isLoadingSinistro}
          label="Abrir comunicado de sinistro"
          {...formikBag}
        />
      )}
    </>
  );
};

export default ConditionalSubmitButton;
