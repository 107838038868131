import React from "react";
import { Container, Title, Description } from "./styles";

const SinistroApiError = () => {
  return (
    <Container>
      <Title variant="headline-06" align="left">
        Desculpe, estamos passando por alguma instabilidade
      </Title>
      <Description variant="body01-lg" align="left">
        Por favor, tente novamente mais tarde.
      </Description>
    </Container>
  );
};
export default SinistroApiError;
