import React from "react";
import PropTypes from "prop-types";
import { BannerContents } from "../../../components/shared/layout";
import { Button } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

export const BannersAcidentesPessoaisBemEstar = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerAcidentesPessoaisBemEstar}
          right
        >
          <Button
            className="btn-banner"
            blank
            secondary
            href={LINKS.autoCompraBemEstar}
          >
            Compre agora
          </Button>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannersAcidentesPessoaisBemEstar.defaultProps = { images: {} };
BannersAcidentesPessoaisBemEstar.propTypes = {
  images: PropTypes.objectOf(Object),
};
export default BannersAcidentesPessoaisBemEstar;
