import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SeguridadeFaq from "./SeguridadeFaq";

const Campaign = ({ title, contentCampaign, campanhas, right }) => {
  const content = useMemo(
    () =>
      contentCampaign.filter(({ idCampanha }) =>
        campanhas.find(({ idCampanha: id }) => idCampanha === id)
      ),
    [campanhas]
  );

  return content.length ? (
    <SeguridadeFaq title={title} faqItems={content} right={right} />
  ) : null;
};

Campaign.defaultProps = {
  title: "Ações promocionais",
  right: false,
  campanhas: [],
};

Campaign.propTypes = {
  title: PropTypes.string,
  right: PropTypes.bool,
  contentCampaign: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.element,
      idCampanha: PropTypes.string.isRequired,
    })
  ).isRequired,
  campanhas: PropTypes.arrayOf(Object),
};

export default Campaign;
