import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { MONTHS } from "../../constants";
import Form, { SelectControl } from "../shared/forms";
import { distinctArray, mapToOptions } from "../../utils";
import { Link } from "../shared/actions";
import { envs } from "../../config";
import CsIcon, { download } from "../shared/icons/csicons";
import ShowIf from "../shared/common/conditional";

const GeneralConditionsPanel = ({
  yearOptions,
  onYearSelect,
  productsByYearOptions,
  handleChange,
  handleBlur,
  setFieldValue,
  values: { product, year, month, condition },
  submitForm,
  errors,
  isValid,
  dirty,
  touched,
  isSubmitting,
  conditions,
  frequency,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    submitForm,
  };
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [avaliableConditions, setAvaliableConditions] = useState([]);
  const [selectedCondiction, setSelectedCondiction] = useState(null);

  function selectYearHandle(evt) {
    formikBag.onChange(evt);
    const yearSelected = evt.currentTarget.value;
    if (onYearSelect) {
      onYearSelect(yearSelected);
      setFieldValue("year", yearSelected);
      setFieldValue("product", "");
      setFieldValue("condition", "");
    }
  }

  function selectProductHandle(evt) {
    formikBag.onChange(evt);
    const productSelected = evt.currentTarget.value;
    setFieldValue("product", productSelected);
    setFieldValue("condition", "");
  }

  function selectMonthHandle(evt) {
    formikBag.onChange(evt);
    const monthSelected = evt.currentTarget.value;
    setFieldValue("month", monthSelected);
  }

  useEffect(() => {
    function filterByYear(gc) {
      return (
        gc.subProduto === product &&
        gc.date.getFullYear() === Number.parseInt(year, 10)
      );
    }
    const months = distinctArray(
      conditions.filter(filterByYear).map(({ date }) => date.getMonth())
    ).map((m) => ({ value: m.toString(), label: MONTHS[m] }));

    setMonthsOptions(months);

    return () => {
      setMonthsOptions([]);
    };
  }, [conditions, product, setFieldValue, year]);

  useEffect(() => {
    const filterByMonth = (gc) =>
      gc.subProduto === product &&
      gc.date.getFullYear() === Number.parseInt(year, 10) &&
      gc.date.getMonth() === Number.parseInt(month, 10);

    const selectedCondictions = conditions.filter(filterByMonth);
    setAvaliableConditions(selectedCondictions);

    setSelectedCondiction(null);

    const defaultCondition = frequency ? "" : selectedCondictions[0]?.id;

    setFieldValue("condition", defaultCondition);

    return () => {
      setAvaliableConditions([]);
    };
  }, [conditions, month, product, setFieldValue, year]);

  useEffect(() => {
    if (condition) {
      const { url, periodicidade, subProduto, urlRegulamento } = condition
        ? conditions.find((gc) => gc.id === Number.parseInt(condition, 10))
        : null;
      setSelectedCondiction({
        url,
        urlRegulamento,
        year,
        month: MONTHS[month],
        periodicidade,
        subProduto,
      });
    }
  }, [condition]);

  function SelectPeriodicidade(){
    return frequency ? ` - ${selectedCondiction.periodicidade}`:" ";
  }

  return (
    <>
      <Row className="p-0 d-flex justify-content-center">
        <Col sm={8} md={3}>
          <SelectControl
            controlId="formGeneralConditions.year"
            label="Ano de Adesão"
            name="year"
            value={year}
            options={mapToOptions(yearOptions)}
            {...formikBag}
            onChange={selectYearHandle}
          />
        </Col>
        <Col xs={6} sm={4} md={3}>
          <SelectControl
            controlId="formGeneralConditions.product"
            label="Produto"
            name="product"
            value={product}
            options={mapToOptions(productsByYearOptions)}
            disabled={!year}
            {...formikBag}
            onChange={selectProductHandle}
          />
        </Col>
        <Col xs={6} sm={6} md={3}>
          <SelectControl
            controlId="formGeneralConditions.month"
            label="Mês"
            name="month"
            value={month}
            options={mapToOptions(monthsOptions)}
            disabled={!product}
            {...formikBag}
            onChange={selectMonthHandle}
          />
        </Col>
        {frequency ? (
          <Col sm={6} md={3}>
            <SelectControl
              controlId="formGeneralConditions.condition"
              label="Periodicidade"
              name="condition"
              value={condition}
              options={mapToOptions(avaliableConditions, {
                propValue: "id",
                propLabel: "periodicidade",
              })}
              disabled={!month}
              {...formikBag}
            />
          </Col>
        ) : null}
      </Row>
      {selectedCondiction ? (
        <div className="d-flex flex-column">
          <div className="text-brand mt-2 mb-3 justify-content-md-center text-md-center  align-items-center">
            <p className="mb-0"><b>Condições Gerais</b></p>
            <Link
              href={`${envs.hostStaticCdn}/areaaberta${selectedCondiction.url}`}
              blank
              className="cvp-download-link d-flex justify-content-md-center text-md-center  align-items-center"
            >
              <span className="cvp-download-link-label">
           
                CG - {selectedCondiction.subProduto} - {selectedCondiction.year} -{" "}
                {selectedCondiction.month}
                {SelectPeriodicidade()}
              </span>
              <span className="mr-2">
                <CsIcon icon={download} className="cvp-download-link-icon" />
              </span>
            </Link>
          </div>
          <ShowIf condition={selectedCondiction.urlRegulamento}>
          <div className="text-brand mt-2 mb-3 justify-content-md-center text-md-center  align-items-center">
          <p className="mb-0"><b>Regulamento de Capitalização</b></p>
            <Link
              href={`${envs.hostStaticCdn}/areaaberta${selectedCondiction.urlRegulamento}`}
              blank
              className="cvp-download-link d-flex justify-content-md-center text-md-center  align-items-center"
            >
              <span className="cvp-download-link-label">  
              CAP - {selectedCondiction.subProduto} - {selectedCondiction.year} -{" "}
                {selectedCondiction.month}
                {SelectPeriodicidade()}
              </span>
              <span className="mr-2">
                <CsIcon icon={download} className="cvp-download-link-icon" />
              </span>
            </Link>
          </div>
         </ShowIf>
          <p className="text-center">
            Caso você não consiga visualizar o arquivo em PDF, faça o download
            do Adobe Acrobat Reader{" "}
            <Link href="http://get.adobe.com/br/reader/" blank label="aqui" />.
          </p>
        </div>
      ) : null}
    </>
  );
};

GeneralConditionsPanel.defaultProps = {
  conditions: [],
  frequency: true,
};

GeneralConditionsPanel.propTypes = {
  ...Form.requiredProps,
  conditions: PropTypes.arrayOf(PropTypes.objectOf(Object)),
  frequency: PropTypes.bool,
};

export default GeneralConditionsPanel;
