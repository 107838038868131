import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";

export const BannerInvestidor = (props) => {
  const { images } = props;
  return (
    <BannerContents {...props}>
      <BannerContents.Banner backgroundImage={images.BannerIvestidor}>
        <Row id="cp-banner-mobile">
          <Col xs={12} className="d-flex align-items-stretch">
            <h3 className="d-md-inline-block mt-5">Planos de previdência</h3>
          </Col>
          <Col xs={24}>
            <p className="text-body-sm mt-0 mt-md-2 ml-3">
              Quanto vale saber que seu patrimônio está bem guardado? Vale
              aquele sentimento de boas escolhas na vida.
              <br />
              <br /> O seu futuro está nas atitudes do agora.
            </p>
          </Col>
        </Row>
      </BannerContents.Banner>
    </BannerContents>
  );
};

BannerInvestidor.defaultProps = { images: {} };

BannerInvestidor.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerInvestidor;
