import React from 'react';
import PropTypes, { element } from 'prop-types';
import { Row, Col } from 'react-bootstrap';

function generateKey(item, index) {
  if (item && item.key) {
    return item.key;
  }
  return `child_${index}`;
}

const Content = ({ children, header, title, widthCol, classNameStep }) => {
  const steps = React.Children.map(children, (item, index) => {
    if (React.isValidElement(item)) {
      return (
        <p key={generateKey(item, index)} className="m-0">
          {item}
        </p>
      );
    }
    return null;
  });
  return (
    <Row className="justify-content-center mb-5">
      <Col xs={12} md={widthCol} className="text-center">
        <Row>
          <Col>{header || <h2 className="text-center p-3">{title}</h2>}</Col>
        </Row>
        <Row>
          <Col className={classNameStep}>{steps}</Col>
        </Row>
      </Col>
    </Row>
  );
};

Content.defaultProps = {
  header: null,
  widthCol: 11,
  classNameStep: '',
};

Content.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(element)]),
  widthCol: PropTypes.number,
  classNameStep: PropTypes.string,
};

export default Content;
