import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Section } from "../../../components/shared/pages";
import Card from "../../../components/shared/cards";
import { applyKey } from "../../../utils";
import { Button, Link } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

import itensCard from "../data/opcoes.json";

export const PagueUmaVezAoAno = (props) => {
  const { sectionTitle } = props;

  return (
    <>
      <Row>
        <Col xs={12} className="text-center">
          <h2 className="h2-f-22">{sectionTitle}</h2>
        </Col>
        <Col xs={12} className="text-center mt-4">
          <p>
            A forma de pagamento é por boleto bancário, que deve ser pago pelo
            Internet Banking Caixa, na opção &quot;outros pagamentos&quot;. Se
            preferir você também pode pagar nas lotéricas ou{" "}
            <Link
              blank
              href={LINKS.encontreAgenciaCaixa}
              label="agências Caixa"
            />
            .
          </p>
        </Col>
      </Row>
      <Row className="m-auto">
        {applyKey(
          itensCard,
          ({ valorAnual, valorIdenizacao, valorSorteio }) => (
            <Col xs={12} sm={6} md={4}>
              <Card
                className="conheca-amparo mx-auto text-left mb-4 mb-md-0"
                header={
                  <h5>
                    R$ {valorAnual} <small>POR ANO</small>
                  </h5>
                }
                footer={
                  <Button
                    block
                    href={LINKS.autoCompraAmparo}
                    blank
                    secondary
                    label="Quero esse"
                  />
                }
              >
                <ul>
                  <li>Organização e pagamento do funeral</li>
                  <li>
                    Indenização de <strong>R$ {valorIdenizacao} mil</strong> em
                    caso de morte por acidente.
                  </li>
                  <li>
                    Sorteios mensais de até{" "}
                    <strong>R$ {valorSorteio} mil</strong>.
                  </li>
                </ul>
              </Card>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

PagueUmaVezAoAno.defaultProps = {
  ...Section.defaultProps,
};

PagueUmaVezAoAno.propTypes = {
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default PagueUmaVezAoAno;
