/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const CpfCnpjInput = ({ cpfCnpj, formikBag }) => {
  const cpfMask = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2");
  };

  const cnpjMask = (value) => {
    value.replace(/./g, "").replace(/-/g, "");
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const handleCpfCnpj = (e) => {
    const unmaskedValue = e.target.value
      .replaceAll(".", "")
      .replace("-", "")
      .replace("/", "");
    if (unmaskedValue.length <= 12) {
      e.target.value = cpfMask(unmaskedValue);
    } else {
      e.target.value = cnpjMask(unmaskedValue);
    }
    formikBag.onChange(e);
  };

  return (
    <InputControl
      inputStyle={{ minHeight: "50px" }}
      controlId="formOuvidoria.ouvidoria.cpfCnpj"
      label="Qual o seu CPF ou CNPJ?"
      placeHolder="Digite um CPF ou CNPJ válido"
      name="ouvidoria.cpfCnpj"
      value={cpfCnpj}
      {...formikBag}
      onChange={handleCpfCnpj}
    />
  );
};

CpfCnpjInput.propTypes = {
  cpfCnpj: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default CpfCnpjInput;
