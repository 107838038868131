import React from "react";
import PropTypes from "prop-types";
import DefaultHeader from "../../../headers/default";

const Header = (props) => {
  const { component } = props;

  return (
    <header
      id="header"
      className="header sticky-top make-section"
      data-section="header navbar"
    >
      {React.createElement(component)}
    </header>
  );
};

Header.defaultProps = {
  component: DefaultHeader,
};

Header.propTypes = {
  component: PropTypes.elementType,
};

export default Header;
