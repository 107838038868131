import "./style.css";
import React from "react";
import { useSegmentRoute } from "@hooks";
import AreaRoute from "../../components/shared/routes";
import {
  FundosInvestimentosPage,
  HomePage,
  Simulador,
  PrevInvestidor,
} from "./pages";
import { envs } from "../../config";

export const Previdencia = () => {
  const pageTitle = "Previdência";
  const routePrevidencia = "/previdencia";

  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      component: FundosInvestimentosPage,
      path: "/rendimento-dos-fundos",
      pageTitle: "Fundos de Investimento",
      pageProps: {
        updateCrumb: {
          toRoute: routePrevidencia,
          label: pageTitle,
        },
      },
    },
    {
      component: PrevInvestidor,
      path: "/investidor",
      pageTitle: "Previdência para investidor",
      pageProps: {
        updateCrumb: {
          toRoute: routePrevidencia,
          label: pageTitle,
        },
      },
    },
    {
      component: Simulador,
      path: "/simulador-beneficio-fiscal",
      pageTitle: "Simulador de Benefício Fiscal",
      pageProps: {
        updateCrumb: {
          toRoute: routePrevidencia,
          label: pageTitle,
        },
      },
    },
  ]);
  return (
    <AreaRoute
      {...routerContext}
      title={pageTitle}
      chatId={envs.previdenciaChatId}
    />
  );
};

export default Previdencia;
