/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import imagensSinistro from '@asserts/images/sinistro';
import TimelineEvent from '../../../components/timeline/TimelineEvent';
import CardProduto from '../../../components/shared/card-produto/CardProduto';
import { Page } from '../../../components/shared/pages';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { BannerAcompanhamento } from '../sections';
import useSinisterTimelineVida from '../../../hooks/requests/useSinisterTimelineVida';
import useSinisterTimelinePrevidencia from '../../../hooks/requests/useSinisterTimelinePrevidencia';
import useSinisterTimelinePrestamista from '../../../hooks/requests/useSinisterTimelinePrestamista';
import SpinnerLoading from '../../../components/shared/layout/SpinnerLoading';
import { parseStringDate } from '../../../utils';

import css from './timeline-page.module.css';

const TimelinePage = () => {
  const location = useLocation();
  const history = useHistory();

  const infoPage = {
    pageTitle: 'Acompanhar',
  };
  const CPF = location.state.cpfTitular;

  if (!CPF) {
    history.push('/sinistro/login');
  }

  const [sinisterName, setSinisterName] = useState('');
  const [
    loadingSinisterTimelineData,
    setLoadingSinisterTimelineData,
  ] = useState(false);

  const [sinistersVidaOpenedData, setSinistersVidaOpenedData] = useState([]);
  const [sinisterTimelineVidaData, setSinisterTimelineVidaData] = useState([]);

  const [sinisterPrevidenciaData, setSinisterPrevidenciaData] = useState([]);
  const [
    sinisterTimelinePrevidenciaData,
    setSinisterTimelinePrevidenciaData,
  ] = useState([]);

  const [
    sinistersPrestamistaOpenedData,
    setSinistersPrestamistaOpenedData,
  ] = useState([]);
  const [
    sinisterTimelinePrestamistaData,
    setSinisterTimelinePrestamistaData,
  ] = useState([]);

  /* #region sinister vida methods */

  const {
    getAllSinisterOpened: getAllSinisterOpenedVida,
    getTimelineSinister: getTimelineSinisterVida,
    loadingPrest: loadingVida,
  } = useSinisterTimelineVida();

  async function getAllVidaSinisterTimeline(cards) {
    const withTimelines = cards.map(async (item) => {
      const jsonData = {
        protocolNumber: item['9'],
        certificateNumber: item['11'],
      };

      const responseGetTimelineSinister = await getTimelineSinisterVida(
        jsonData,
        CPF
      );

      const mostRecent =
        responseGetTimelineSinister.dados[0][
          responseGetTimelineSinister.dados[0].length - 1
        ];

      return {
        ...item,
        timelineName: responseGetTimelineSinister.dados[1].LK_SI115_S_DESCR_CAUSA,
        timeline: responseGetTimelineSinister.dados[0],
        status: mostRecent['7'],
      };
    });

    return Promise.all(withTimelines);
  }

  async function getSinisterOpenedVidaData() {
    const responseGetSinistersOpened = await getAllSinisterOpenedVida(CPF);
    const validarRetornoVida =
      responseGetSinistersOpened?.dados[1]
        ?.LK_SI114_I_CPF_CNPJ; /* devido a api nao tratar a existencia ou nao de produto, validamos aqui */

    if (validarRetornoVida) {
      const sinisters = await getAllVidaSinisterTimeline(
        responseGetSinistersOpened.dados[0]
      );

      setSinistersVidaOpenedData(sinisters);
    }
  }

  async function onShowTimelineVidaHandle(sinister) {
    setSinisterTimelinePrevidenciaData([]);
    setSinisterTimelinePrestamistaData([]);

    setSinisterTimelineVidaData(sinister.timeline);
    setSinisterName(sinister.timelineName);
  }

  /* #endregion */

  /* #region sinister previdencia */

  const {
    getAllSinisterAndTimeline,
    loadingPrev,
  } = useSinisterTimelinePrevidencia();

  async function getSinisterPrevidenciaData() {
    const responsePrevidenciaData = await getAllSinisterAndTimeline(CPF);
    if (
      responsePrevidenciaData.mensagem !==
      'Object reference not set to an instance of an object.'
    ) {
      setSinisterPrevidenciaData(
        responsePrevidenciaData.dados.dados.andamentosCertificados
      );
    }
  }

  async function onShowTimelinePrevidenciaHandle(sinister) {
    setSinisterTimelinePrevidenciaData([]);
    setSinisterTimelineVidaData([]);
    setSinisterTimelinePrestamistaData([]);

    setLoadingSinisterTimelineData(true);
    setSinisterName(sinister.descricaoMotivoSinistro);
    setSinisterTimelinePrevidenciaData(sinister.andamentos);
    setLoadingSinisterTimelineData(false);
  }

  /* #endregion sinister previdencia */

  /* #region sinister prestamista */

  const {
    getAllSinisterOpened: getAllSinisterOpenedPrestamista,
    getTimelineSinister: getTimelineSinisterPrestamista,
    loadingVida: loadingPrest,
  } = useSinisterTimelinePrestamista();

  async function getSinisterOpenedPrestamistaData() {
    const responseGetSinistersOpened = await getAllSinisterOpenedPrestamista(
      CPF
    );
    if (responseGetSinistersOpened.dados.length === 0) {
      return;
    }

    setSinistersPrestamistaOpenedData(responseGetSinistersOpened.dados[0]);
    setSinisterName(responseGetSinistersOpened.dados[0][0]['7']);
  }

  async function onShowTimelinePrestamistaHandle(sinister) {
    setSinisterTimelinePrevidenciaData([]);
    setSinisterTimelineVidaData([]);
    setSinisterTimelinePrestamistaData([]);

    const jsonData = {
      cpf: CPF,
      BISinistro: sinister['2'],
    };
    const responseGetTimelineSinister = await getTimelineSinisterPrestamista(
      jsonData
    );

    setSinisterTimelinePrestamistaData(
      responseGetTimelineSinister.sucesso
        ? responseGetTimelineSinister.dados[0]
        : []
    );
    setLoadingSinisterTimelineData(false);
  }

  /* #endregion */

  function showSinisterName() {
    if (
      sinisterTimelineVidaData?.length > 0 ||
      sinisterPrevidenciaData?.length > 0 ||
      sinisterTimelinePrestamistaData?.length > 0
    ) {
      return (
        <div>
          <h4>
            Etapas do processo de sinistro{' '}
            <span className={css['hiden-mobile']}> - linha do tempo</span>
          </h4>
          <h5>
            Cobertura: <span className={css.blueText}>{sinisterName}</span>
          </h5>
        </div>
      );
    }

    return null;
  }

  useEffect(() => {
    getSinisterOpenedVidaData();
    getSinisterPrevidenciaData();
    getSinisterOpenedPrestamistaData();
  }, []);

  return (
    <Page {...infoPage} images={imagensSinistro} banner={BannerAcompanhamento}>
      <SeguridadePageLayout.Tab eventKey="sinistro">
        <>
          <section className={css.cardSection}>
            <h2>Acompanhamento de sinistro</h2>
            <p className="mt-5">
              Aqui são exibidos os status de cada etapa do processo de sinistro.{' '}
              <br />
              Em caso de dúvidas sobre algum status, clique no ícone
            </p>
            <h4 className="mt-5">
              CPF do segurado: <span className={css.blueText}>{CPF}</span>
            </h4>
            <h5 className="mt-4">
              Selecione o sinistro que deseja acompanhar:
            </h5>
          </section>

          {/* Show Sinisters below */}
          <SpinnerLoading isLoading={loadingVida} />
          <div className={css.cardContainer}>
            {sinistersVidaOpenedData?.map((sinister) => (
              <CardProduto
              key={sinister}
              date={parseStringDate(new Date(sinister['12']))}
              status={sinister.status}
              protocol={`${sinister['8']}${sinister['9']}`}
              certificateNumber={sinister['11']}
              productName="vida"
              onShowTimeline={() => onShowTimelineVidaHandle(sinister)}
              anchorClick={() => console.log()}
              />
            ))}
          </div>

          <SpinnerLoading isLoading={loadingPrev} />
          <div className={css.cardContainer}>
            {sinisterPrevidenciaData?.map((sinister) => (
              <CardProduto
                key={sinister.codigoCertificado}
                date={sinister.dataSinistro.replace(/-/g, '/')}
                status={sinister.statusAvisoSinitro}
                certificateNumber={sinister.codigoCertificado}
                productName="previdencia"
                onShowTimeline={() => onShowTimelinePrevidenciaHandle(sinister)}
                anchorClick={() => console.log()}
              />
            ))}
          </div>

          <SpinnerLoading isLoading={loadingPrest} />
          <div className={css.cardContainer}>
            {sinistersPrestamistaOpenedData?.map((sinister) => (
              <CardProduto
                key={sinister['2']}
                date={parseStringDate(new Date(sinister['5']))}
                status={sinister['7']}
                certificateNumber={sinister['2']}
                productName="prestamista"
                onShowTimeline={() => onShowTimelinePrestamistaHandle(sinister)}
                anchorClick={() => console.log()}
              />
            ))}
          </div>

          {/* Show Timeline below */}
          <SpinnerLoading isLoading={loadingSinisterTimelineData} />

          <div id="time-line" />

          {sinisterTimelineVidaData?.length > 0 && (
            <div id={css.timelinePage}>
              {showSinisterName()}
              <section id={css.timelineSection}>
                <div className={css.timeline}>
                  {sinisterTimelineVidaData.map(
                    (sinisterTimeline, sinisterTimelineKey) => (
                      <div key={sinisterTimeline}>
                        <TimelineEvent
                          date={parseStringDate(
                            new Date(sinisterTimeline['2'])
                          )}
                          title={sinisterTimeline['1']}
                          status
                          toolTip={sinisterTimeline['8']}
                          isLast={
                            sinisterTimelineKey ===
                            sinisterTimelineVidaData.length - 1
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </section>
            </div>
          )}

          {sinisterTimelinePrevidenciaData?.length > 0 && (
            <div id={css.timelinePage}>
              {showSinisterName()}
              <section id={css.timelineSection}>
                <div className={css.timeline}>
                  {sinisterTimelinePrevidenciaData.map(
                    (sinisterTimeline, sinisterTimelineKey) => (
                      <div key={sinisterTimeline}>
                        <TimelineEvent
                          date={sinisterTimeline.data}
                          title={sinisterTimeline.descricaoStatus}
                          status
                          isLast={
                            sinisterTimelineKey ===
                            sinisterTimelinePrevidenciaData.length - 1
                          }
                          toolTip={sinisterTimeline.textoAuxiliarStatus}
                        />
                      </div>
                    )
                  )}
                </div>
              </section>
            </div>
          )}

          {sinisterTimelinePrestamistaData?.length > 0 && (
            <div id={css.timelinePage}>
              {showSinisterName()}
              <section id={css.timelineSection}>
                <div className={css.timeline}>
                  {sinisterTimelinePrestamistaData.map(
                    (sinisterTimeline, sinisterTimelineKey) => (
                      <div key={sinisterTimeline}>
                        <TimelineEvent
                          date={sinisterTimeline.DDataHora.split('T')[0]}
                          title={sinisterTimeline.SDescricao}
                          status
                          isLast={
                            sinisterTimelineKey ===
                            sinisterTimelinePrestamistaData.length - 1
                          }
                          toolTip={sinisterTimeline.SDescricao}
                        />
                      </div>
                    )
                  )}
                </div>
              </section>
            </div>
          )}

          <div className={css.aviso}>
            <div className={css.card}>
              <h6>
                Em caso de dúvidas, fale com nossa equipe através da Central de
                Sinistros
              </h6>
              <h3>0800 7222 2492</h3>
              <p>
                de segunda a sexta, das 8h às 18h, exceto feriados nacionais.
              </p>
            </div>
          </div>
        </>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default TimelinePage;
