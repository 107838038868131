import React from "react";
import { nanoid } from "nanoid";

function applyKey(elementArray, map) {
  if (!elementArray || !(elementArray instanceof Array)) {
    return [];
  }

  return elementArray
    .map(map)
    .map((el) => React.cloneElement(el, { key: nanoid() }));
}

export default applyKey;
