/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import EnviarSinistro from './EnviarSinistro';
import { Section } from '../../../components/shared/pages';
import RelacaoDocumentos from './EnviarDocumentos/RelacaoDocumentos';
import { DocumentosEnviados } from './EnviarDocumentos/DocumentosEnviados';

import { SinistroContext } from '../../../context';
import { SinisterContext } from '../../../context/SinisterContext';

const AcompanharDocumentos = () => {
  const [errorSinistro, setErrorSinistro] = useState(false);
  const [stepUpload, setStepUpload] = useState(0);
  const { stepSinister, protocolNumber } = useContext(SinisterContext);

  return (
    <SinistroContext.Provider
      value={{
        stepUpload,
        setStepUpload,
      }}
    >
      <Container>
        {stepSinister === 0 && (
          <Section
            id="sinistro-abertura-sinistro"
            as={EnviarSinistro}
            title="Envio de documentos"
            sectionLayout={null}
            setErrorSinistro={setErrorSinistro}
            errorSinistro={errorSinistro}
          />
        )}
        {stepSinister === 1 && <RelacaoDocumentos />}
        {stepSinister === 2 && (
          <Border>
            <DocumentosEnviados
              sectionTitle="Documentos enviados com sucesso!"
              sectionProtocol={protocolNumber}
            />
          </Border>
        )}
      </Container>
    </SinistroContext.Provider>
  );
};

AcompanharDocumentos.propTypes = {};

const Container = styled.div`
  padding-bottom: 50px;
`;

const Border = styled.div`
  @media screen and (min-width: 992px) {
    border: 2px solid var(--color-seguridade-neutral-2);
    border-radius: 5px;
    box-shadow: 0px 8px 16px 4px rgba(19, 72, 178, 0.45);
    margin-bottom: 2rem;
  }
`;
export default AcompanharDocumentos;
