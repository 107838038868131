import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Link } from "../actions";
import * as LINKS from "../../../constants/links";

const FaleCom = ({ sectionTitle, children, info }) => {
  return (
    <Row className="justify-content-center fazer-uma-compra">
      <Col xs={12} className="text-center">
        <h1 className="content-title">{sectionTitle}</h1>
        <div className="fazer-uma-compra-body">{children}</div>
        <p className="text-body">{info}</p>
      </Col>
    </Row>
  );
};

FaleCom.defaultProps = {
  info:
    <p>Para encontrar a CAIXA mais próxima, <Link blank href={LINKS.encontreAgenciaCaixa} label="clique aqui" />.</p>,
};

FaleCom.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default FaleCom;
