import bannerHome from "./banner-bg-home.jpg";
import bannerCampanhaViver from "./banner-bg-campanha_viver.png";
import bannerHomeIr from "./banner-bg-home-previdencia-ir.jpg";
import homeJaTemProdutoComAGente from "./home-ja-tem-produto-com-a-gente.svg";
import cardAcidentesPessoais from "./cardAcidentesPessoais.png";
import cardPrestamista from "./cardPrestamista.png";
import cardPrev from "./cardPrev.png";
import cardVida from "./cardVida.png";

const imagensHome = {
  bannerHome,
  bannerCampanhaViver,
  homeJaTemProdutoComAGente,
  bannerHomeIr,
  cardAcidentesPessoais,
  cardPrestamista,
  cardPrev,
  cardVida,
};

export default imagensHome;