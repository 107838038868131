import React from "react";
import PropTypes from "prop-types";
import { Button } from "@components/shared/actions";
import { LINKS } from "@constants";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { CenterImageContent } from "../../../components/shared/contents/index";
import { createImageElement } from "../../../asserts/images";

export const JaTemProdutoComAGente = (props) => {
  const { sectionTitle, images } = props;

  const imagem = createImageElement(images.homeJaTemProdutoComAGente, {
    alt: "Sua Empresa pode contar com a gente",
  });

  return (
    <Apresentacao image={images.homeJaTemProdutoComAGente} imageColumn={3}>
      <div className="pl-0 pl-md-3">
        <h2 className="text-center text-md-left">{sectionTitle}</h2>
        <p className="mt-4 text-center text-md-left ">
          Acesse os serviços disponíveis para você na área do cliente.
        </p>
        <div className="d-flex d-md-none justify-content-center mb-5">
          <CenterImageContent image={imagem} />
        </div>
        <div className="faq-fotter-buttons d-block d-lg-flex justify-content-center justify-content-md-between">
          <Button
            href={LINKS.areaLogada}
            className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
            blank
          >
            Acessar área do cliente
          </Button>
        </div>
      </div>
    </Apresentacao>
  );
};

JaTemProdutoComAGente.defaultProps = {
  images: {},
};

JaTemProdutoComAGente.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  images: PropTypes.objectOf(Object),
};

export default JaTemProdutoComAGente;
