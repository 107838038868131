import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import Info from "../sections/Info";
import { Page, Section } from "../../../components/shared/pages";

const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackWhatsapp = useTrackEvent(appInsights, "AA_WhatsApp");

  useEffect(() => {
    const whatsappEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Atendimento WhatsApp",
      virtualPageURL: "/whatsapp",
    };
    trackWhatsapp(whatsappEvent);
  }, [trackWhatsapp]);

  return (
    <Page className="page-whats" {...props} disabledCrumb>
      <Section
        as={Info}
        title="Aguarde!"
        subTitle="Estamos abrindo o WhatsApp..."
        notifyMessage="Caso você não seja redirecionado"
        clickHereLink=" clique aqui"
        sectionLayout={null}
        fluid
        className="custom-section"
      />
    </Page>
  );
};

export default HomePage;
