import React from "react";
import { useHistory } from "react-router-dom";
import {
  FormOneColumnsGeneric,
  SpaceCaptcha,
  SubmitButton,
  SpaceButton,
} from "./styles";
import  GenericTextInput  from "./FormInputs";
import { GenericPhoneInput } from "../FormularioSinistro/FormInputs";
import { Button } from "../../../../components/shared/actions";

import { ReCaptchaControl } from "../../../../components/shared/forms";
import "./styles.css";

const FormularioAcompanhamentoSinistro = ({
  values: {
    segurado: { cpfSegurado, cpfBeneficiario, telefoneToken },
    reCaptcha,
  },
  handleBlur,
  handleChange,
  isValid,
  errors,
  dirty,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setValues,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldError,
    setValues,
  };
  const history = useHistory();
  return (
    <>
      <FormOneColumnsGeneric>
        <GenericTextInput
          controlId="formAcompanhamentoSinistro.segurado.cpfSegurado"
          name="segurado.cpfSegurado"
          label={
            <>
              Informe o CPF do segurado <small>(pessoa sinistrada)</small>
            </>
          }
          placeHolder="Digite o CPF do segurado titular"
          value={cpfSegurado}
          typeField="cpf"
          length="14"
          inputStyle={{ width: "315px" }}
          formikBag={formikBag}
        />
      </FormOneColumnsGeneric>

      <FormOneColumnsGeneric>
        <GenericTextInput
          controlId="formAcompanhamentoSinistro.segurado.cpfBeneficiario"
          name="segurado.cpfBeneficiario"
          label={
            <>
              CPF do beneficiário / herdeiro / consultante{" "}
              <small>(pessoa que faz as consultas)</small>
            </>
          }
          placeHolder="Digite o CPF do segurado titular"
          value={cpfBeneficiario}
          typeField="cpf"
          length="14"
          inputStyle={{ width: "315px" }}
          formikBag={formikBag}
        />
      </FormOneColumnsGeneric>

      <FormOneColumnsGeneric>
        <GenericPhoneInput
              controlId="formAcompanhamentoSinistro.segurado.telefoneToken"
              name="segurado.telefoneToken"
              label={
                <>
                  Telefone celular{" "}
                  <small><b>(o código de validação será enviado para este número)</b></small>
                </>
              }
              placeHolder="Digite o DDD e o seu telefone celular"
              value={telefoneToken}
              width="315px"
              formikBag={formikBag}
        />
      </FormOneColumnsGeneric>

      <SpaceCaptcha />
      <FormOneColumnsGeneric>
        <ReCaptchaControl
          controlId="formularioSegurado.reCaptcha"
          name="reCaptcha"
          value={reCaptcha}
          size="normal"
          {...formikBag}
        />
      </FormOneColumnsGeneric>
      <FormOneColumnsGeneric>
        <SpaceButton>
          <div className="d-flex">
            <Button
              blank
              onClick={() => history.push("/sinistro/login")}
              transparent
              className="mr-3 d-block d-sm-inline-block"
              label="Voltar"
            />
            <SubmitButton 
              label="Receber código de validação" {...formikBag} 
            />
          </div>
        </SpaceButton>
      </FormOneColumnsGeneric>
    </>
  );
};

FormularioAcompanhamentoSinistro.defaultProps = {
  errorOuvidoria: false,
  isLoadingOuvidoria: false,
};

export default FormularioAcompanhamentoSinistro;
