import React from "react";
import PropTypes from "prop-types";
import { Section } from "@components/shared/pages";
import { LINKS } from "@constants";
import { IconButton, ChatButton } from "../../../components/shared/actions";
import { FazerUmaCompraImage } from "../../../components/shared/blocks";
import { envs } from "../../../config";

export const FacaSeguroVida = ({ sectionTitle, images }) => {
  return (
    <FazerUmaCompraImage
      sectionTitle={sectionTitle}
      image={images.amparoAssistente}
    >
      <IconButton
        className="button margin"
        icon={images.vidaBtnOnline}
        rounded
        secondary
        label=" Comprar online"
        href={LINKS.autoCompraVida}
        iconRight
      >
        Comprar online
      </IconButton>
      
      <ChatButton
        className="button"
        rounded
        icon={images.vidaBtnChat}
        secondary
        chatId={envs.vidaChatId}
        label="Comprar pelo chat"
        button
      >Comprar pelo chat</ChatButton>
    </FazerUmaCompraImage>
  );
};

FacaSeguroVida.propTypes = {
  ...Section.specializedProps,
  images: PropTypes.objectOf(Object).isRequired,
};

export default FacaSeguroVida;