import { convertFloatInPrice } from "../../../utils";

const mensagens = {
  isentoIR: `De acordo com a renda bruta informada, você é isento de IR.
    Não há benefício fiscal. Recomendamos investir em planos VGBL.`,
  limiteBaseINSS: (valorBase) =>
    `Este valor poderá ser modificado, caso haja necessidade. Para efeito desta simulação, foi calculado o valor de ${convertFloatInPrice(
      valorBase
    )} com base na tabela do INSS vigente.`,
  limiteDeducaoEducacao: (valorLimite, fatorMultiplicador = 1) => [
    `O limite é de ${convertFloatInPrice(valorLimite * fatorMultiplicador)}.`,
    "Para a simulação será considerado este valor.",
  ],
  contribuicoesPGBL:
    "Contribuições efetuadas para fundo de pensão ou planos PGBL.",
};

export default ({
  deducaoInss,
  rendaBruta,
  isentoIR,
  investEducacaoPropria,
  valorMaximaDeducaoEducacao,
  fatorPeriodicidade,
  totDependentesEstudantes,
  investEducacaoDependentes,
}) => {
  const errors = {
    ...[
      rendaBruta &&
        isentoIR && {
          isento: mensagens.isentoIR,
        },
      investEducacaoPropria > valorMaximaDeducaoEducacao * fatorPeriodicidade
        ? {
            limiteEducacaoPropria: mensagens.limiteDeducaoEducacao(
              valorMaximaDeducaoEducacao * fatorPeriodicidade
            ),
          }
        : null,
      investEducacaoDependentes >
      valorMaximaDeducaoEducacao * totDependentesEstudantes * fatorPeriodicidade
        ? {
            limiteEducacaoDependentes: mensagens.limiteDeducaoEducacao(
              valorMaximaDeducaoEducacao *
                totDependentesEstudantes *
                fatorPeriodicidade
            ),
          }
        : null,
    ]
      .filter((error) => error)
      .reduce((a, b) => ({ ...a, ...b }), {}),
  };

  const infos = [
    deducaoInss && {
      contribuicaoInssLimite: mensagens.limiteBaseINSS(deducaoInss),
    },
    {
      previdenciaComplementar: mensagens.contribuicoesPGBL,
    },
  ]
    .filter((warning) => warning)
    .reduce((a, b) => ({ ...a, ...b }), {});

  return { infos, errors };
};
