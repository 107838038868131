import React from "react";
import PropTypes from "prop-types";
import { SelectControl } from "../../../../../components/shared/forms";
import { mapToOptions } from "../../../../../utils";

const GenericSelectInput = ({ id, name, label, placeHolder, value, options, formikBag }) => (
  <SelectControl
    inputStyle={{ backgroundPositionX: "95%" }}
    controlId={id}
    label={label}
    placeHolder={placeHolder}
    name={name}
    value={value}
    options={mapToOptions(options)}
    {...formikBag}
  />
);

GenericSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default GenericSelectInput;
