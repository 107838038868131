/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormularioEnviar from './FormularioEnviar';
import Form from '../../../components/shared/forms';
import {
  cpfCnpjRegex,
  emailRegex,
  telefoneCelularRegex,
  nomeRegex,
  isValidCpfCnpj,
} from '../../../utils/inputUtils';
import useSinistro from '../../../hooks/requests/useSinistro';
import { SinisterContext } from '../../../context/SinisterContext';

const EnviarSinistro = ({
  sectionTitle,
  setErrorSinistro,
}) => {
  const [formProps, setFormProps] = useState({
    statusPossuiProduto: false,
    exibirSemProduto: false,
    exibirSucesso: false,
    falhaFluxo: false,
  });

  const [exibirTitulo] = useState(true);

  const nomeInvalido = 'Nome é inválido.';
  const cpfInvalido = 'Insira um CPF válido.';
  const pessoaFisica = 'PessoaFisica';
  const pessoaJuridica = 'PessoaJuridica';
  const cnpjObrigatorio = 'O campo CNPJ é obrigatório.';

  const {
    verificarProduto,
    loading: isLoadingSinistro,
    error: errorSinistro,
  } = useSinistro();

   const { 
    setStepSinister,
    setProdutos,
    setLocalReCaptcha,
    setFormulario,
    setExibirSemProduto
   } = useContext(SinisterContext);

  useEffect(() => {
    if (!isLoadingSinistro) {
      setErrorSinistro(errorSinistro);
      setFormProps((state) => ({ ...state, errorSinistro, isLoadingSinistro }));
    }
  }, [isLoadingSinistro]);

  const handleProduct = (product) => {
    const produto = [];
    product?.forEach((item) => {
      const { segmento } = item;
      if (segmento === 'VIDA') {
        produto.push('VIDA');
      } else if (segmento === 'PREVIDENCIA') {
        produto.push('PREV');
      } else if (segmento === 'PRESTAMISTA') {
        produto.push('PREST');
      }
    });
    return produto;
  };


  return (
    <Container>
      <>
        {exibirTitulo && (
          <>
            <Title>{sectionTitle}</Title>
            <Subtitle>
              Antes de começar, tenha em mãos o CPF/CNPJ do(a) titular do
              contrato de seguro / plano de previdência.
            </Subtitle>
          </>
        )}

        <Form
          formProps={formProps}
          form={FormularioEnviar}
          formId="form-sinistro"
          validationSchema={{
            segurado: Yup.object()
              .shape({
                tipoPessoa: Yup.string().required('O campo é obrigatório.'),

                cpfSegurado: Yup.string()
                  .matches(cpfCnpjRegex, 'CPF é inválido.')
                  .required('O campo CPF é obrigatório.')
                  .test('is-cpf-valid.', cpfInvalido, isValidCpfCnpj),

                cnpjSegurado: Yup.string().when(['tipoPessoa'], {
                  is: (tipoPessoa) => tipoPessoa === pessoaJuridica,
                  then: Yup.string()
                    .matches(cpfCnpjRegex, 'CNPJ é inválido.')
                    .required(cnpjObrigatorio)
                    .test(
                      'is-cnpj-valido',
                      'Insira um CNPJ válido.',
                      isValidCpfCnpj
                    ),
                  otherwise: Yup.string().default('').optional(),
                }),

                nomeSegurado: Yup.string()
                  .matches(nomeRegex, nomeInvalido)
                  .required('O campo Nome é obrigatório.'),

                razaoSocialSegurado: Yup.string().when(['tipoPessoa'], {
                  is: (tipoPessoa) => tipoPessoa === pessoaJuridica,
                  then: Yup.string()
                    .matches(nomeRegex, 'Razão Social é inválida.')
                    .required('O campo Razão Social é obrigatório.'),
                  otherwise: Yup.string().default('').optional(),
                }),
              })
              .required(),

            reclamante: Yup.object()
              .shape({
                telefoneCelular: Yup.string()
                  .matches(telefoneCelularRegex, 'Digite um telefone válido.')
                  .required('O telefone celular é obrigatório.'),

                email: Yup.string()
                  .matches(emailRegex, 'Digite um e-mail válido.')
                  .required('O campo e-mail é obrigatório.'),
                emailValid: Yup.string()
                  .oneOf(
                    [Yup.ref('email'), null],
                    'Os e-mails devem ser iguais.'
                  )
                  .matches(emailRegex, 'Digite um e-mail válido.')
                  .required('O campo de confirmação e-mail é obrigatório.'),
              })
              .required(),
            reCaptcha: Yup.string().required(
              'O campo reCAPTCHA é obrigatório.'
            ),
          }}
          initialValues={{
            segurado: {
              tipoPessoa: pessoaFisica,
              cpfSegurado: '',
              cnpjSegurado: '',
              nomeSegurado: '',
              razaoSocialSegurado: '',
            },
            reclamante: {
              telefoneCelular: '',
              email: '',
              emailValid: '',
            },
            reCaptcha: '',
          }}
          onSubmit={async (data) => {
            const cpfSegurado = data.segurado.cpfSegurado.replace(/\D/gi, '');
            const cnpjSegurado = data.segurado.cnpjSegurado.replace(/\D/gi, '');
            const docSegurado =
              data.segurado.tipoPessoa === pessoaFisica
                ? cpfSegurado
                : cnpjSegurado;

            const telefoneCelular = data.reclamante.telefoneCelular
              .replace(/\D/gi, '')
              .replace(/^\d{2}/, '');
            const dddCelular = data.reclamante.telefoneCelular.match(
              /\((\d{2})\)/
            )[1];
            const telefoneCelularDDD = dddCelular + telefoneCelular;
            setLocalReCaptcha(data.reCaptcha);

            const jsonData = {
              segurado: {
                tipoPessoa: data.segurado.tipoPessoa,
                docSegurado,
                cpfSegurado,
                nomeSegurado: data.segurado.nomeSegurado,
                dataNascimentoSegurado: data.segurado.dthNascimentoSegurado,
                sexoSegurado: data.segurado.sexoSegurado,
                cnpjSegurado:
                  data.segurado.tipoPessoa === pessoaJuridica
                    ? cnpjSegurado
                    : '',
                razaoSocialSegurado:
                  data.segurado.tipoPessoa === pessoaJuridica
                    ? data.segurado.razaoSocialSegurado
                    : '',
              },
              reclamante: {
                cpfReclamante: dddCelular,
                telefoneCelular: telefoneCelularDDD,
                email: data.reclamante.email,
                endereco: {
                  cep: '',
                  rua: '',
                  bairro: '',
                  cidade: '',
                  estado: '',
                  numero: '',
                },
              },
            };
            setFormulario(jsonData);
            const returnPossuiProduto = await verificarProduto(
              jsonData,
              data.reCaptcha
            );

            const productNotFoundMessage =
              'Nenhum dado encontrado para este CPF.';
            const isTrueSet =
              returnPossuiProduto?.dados !== productNotFoundMessage;

              if (isTrueSet && returnPossuiProduto?.dados?.length !== 0) {
                setProdutos(handleProduct(returnPossuiProduto.dados));
              }

            if (isTrueSet) {
              setStepSinister(1);
            }

            setExibirSemProduto(!isTrueSet);

          }}
        />
      </>
    </Container>
  );
};

EnviarSinistro.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  setErrorSinistro: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;
const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;

export default EnviarSinistro;
