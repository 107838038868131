import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensVida from "@asserts/images/vida";
import { BannerVida, ResultadoSorteios } from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

const ResultadosSorteiosPage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackVidaSorteios = useTrackEvent(appInsights, "AA_Vida_Sorteios");

  useEffect(() => {
    const vidaSorteiosEvent = {
      event: "AA.navegacao",
      virtualPageTitle:
        "Caixa Vida e Previdência - Seguro de Vida - Resultados de Sorteios",
      virtualPageURL: "/segurodevida/resultado-de-sorteios",
    };
    trackVidaSorteios(vidaSorteiosEvent);
  }, [trackVidaSorteios]);

  return (
    <Page {...props} images={imagensVida} banner={BannerVida}>
      <SeguridadePageLayout.Tab eventKey="resultado-sorteios">
        <Section
          title="Sorteios"
          as={ResultadoSorteios}
          className="pb-5 sorteio-vida"
          id="resultado-sorteios"
          sectionName="sorteios"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default ResultadosSorteiosPage;
