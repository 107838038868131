/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { Container, Navbar, Nav, Image } from "react-bootstrap";
import images from "@asserts/images";
import { LINKS } from "@constants";
import ProductMenu from "./ProductMenu";
import { Button, Link } from "../../shared/actions";
import DesktopSubMenu from "../../shared/layout/header/DesktopSubMenu";

const DefaultHeader = () => {
  const [open, setOpen] = useState(false);

  const customMenuRef = React.createRef();

  return (
    <>
      <Container>
        <Navbar
          expand="lg"
          collapseOnSelect
          className="main-navbar"
          sticky="top"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand as={Link} toRoute="/">
            <Image src={images.brandingLogo} alt="logo" fluid />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="main-navbar-menu">
            <Nav className="mr-auto">
              <Nav.Link
                as={Link}
                label="Produtos"
                className="dropdown-toggle"
                onClick={() => setOpen(!open)}
                onMouseOver={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              />
            </Nav>
            <Nav>
              <Nav.Link as={Link} href="#deets">
                Atendimento
              </Nav.Link>
              <Nav.Link as={Link} href="#deets">
                Chat
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <Button href={LINKS.areaLogada} pill size="sm">
              Login
            </Button>
          </Nav>
        </Navbar>
      </Container>
      <DesktopSubMenu
        ref={customMenuRef}
        collapse={open}
        selectItemHandle={() => setOpen(true)}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <ProductMenu />
      </DesktopSubMenu>
    </>
  );
};

export default DefaultHeader;
