import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";

export const ApresentacaoVida = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.vidaBlocoEntendaInfo} title={sectionTitle}>
      <p>
        O seguro de vida da Caixa Vida e Previdência é para quem sempre está
        pensando em proteger a família. Nos momentos que as pessoas que você ama
        mais precisarem, estaremos prontos para oferecer todo apoio.
      </p>
      <p>
        Despesas extras que chegam de surpresa não são bem-vindas, principalmente
        para quem depende de você financeiramente. Então, conte com as nossas
        soluções e beneficios. Com o seguro de vida da Caixa Vida e Previdência,
        você e a sua família serão sempre amparadas.
      </p>
      <p>
        O valor do seguro depende dos gastos mensais que você tem com a sua
        família e por quanto tempo quer deixá-la confortável. Em caso de doenças
        graves, o seguro paga todo o tratamento e os cuidados necessários.
      </p>
    </Apresentacao>
  );
};

ApresentacaoVida.defaultProps = {};

ApresentacaoVida.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default ApresentacaoVida;