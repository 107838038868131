import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Breadcrumb as BsBreadcrumb, Container } from "react-bootstrap";
import { Link } from "@components/shared/actions";
import { applyKey } from "../../../../utils";

const Breadcrumb = ({ crumbs, children, firstCrumb, lastCrumb, className }) => {
  const [stateItens, setStateItens] = useState(null);

  useEffect(() => {
    const first = firstCrumb ? [firstCrumb] : [];
    const last = lastCrumb ? [lastCrumb] : [];

    const currentItens = applyKey(
      first.concat(crumbs).concat(last),
      ({ label, active, href }) =>
        React.createElement(
          BsBreadcrumb.Item,
          {
            linkAs: Link,
            active: active || !href,
            href,
            linkProps: {
              ignoreBlank: true,
            },
          },
          label
        )
    );

    setStateItens(currentItens);

    return () => {
      setStateItens(null);
    };
  }, [crumbs, firstCrumb, lastCrumb, setStateItens]);

  return (
    <Container fluid>
      <BsBreadcrumb
        listProps={{ className: "background-transparent p-0 pl-sm-2 mb-0" }}
        className={`cvp-breadcrumb make-section ${className}`}
        label="cvp-breadcrumb-page"
        data-section="breadcrumb - navegacao"
      >
        {stateItens}
        {children}
      </BsBreadcrumb>
    </Container>
  );
};

Breadcrumb.Item = BsBreadcrumb.Item;

const crumbType = PropTypes.shape({
  label: PropTypes.string.isRequired,
});

Breadcrumb.defaultProps = {
  firstCrumb: null,
  lastCrumb: null,
  className: null,
};

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(crumbType).isRequired,
  children: PropTypes.element.isRequired,
  firstCrumb: crumbType,
  lastCrumb: crumbType,
  className: PropTypes.string,
};

export default Breadcrumb;
