import { useMemo, useState } from "react";
import { roundMoney } from "../../../utils";
import {
  calcularContribuicaoInss,
  calcularFatorAnuidade,
  calcularValorDeducaoFiscal,
  somarDeducoesFiscais,
} from "./funcoes";
import getMensagens from "./mensagens";
import variaveis from "./data/simulador.json";

const useMotorCalculo = () => {
  const {
    aliquotaDeducaoPrevidencia,
    aliquotasINSS,
    aliquotasIR,
    valorDeducaoDenpendente,
    valorMaximaDeducaoEducacao,
  } = { ...variaveis };

  const [fatorPeriodicidade, setFatorPeriodicidade] = useState(1);
  const [rendaBruta, setRendaBruta] = useState(0);
  const [contribuicaoInss, setContribuicaoInss] = useState(0);
  const [previdenciaPBGL, setPrevidenciaPBGL] = useState(0);
  const [previdenciaComplementar, setPrevidenciaComplementar] = useState(0);
  const [despesasMedicas, setDespesasMedicas] = useState(0);
  const [pensaoAlimenticia, setPensaoAlimenticia] = useState(0);
  const [quantidadeDependentes, setQuantidadeDependentes] = useState(0);
  const [totDependentesEstudantes, setTotDependentesEstudantes] = useState(0);
  const [investEducacaoPropria, setInvestEducacaoPropria] = useState(0);
  const [investEducacaoDependentes, setInvestEducacaoDependentes] = useState(0);

  const fatorAnualidade = useMemo(
    () => calcularFatorAnuidade(fatorPeriodicidade),
    [fatorPeriodicidade]
  );

  const isentoIR = useMemo(() => {
    return rendaBruta > 0 &&
      aliquotasIR &&
      rendaBruta <= aliquotasIR[0]?.rendaLimite
      ? true
      : null;
  }, [rendaBruta, aliquotasIR]);

  const valorMaximoDeducaoPrevidencia = useMemo(() => {
    return !isentoIR
      ? calcularValorDeducaoFiscal(
          rendaBruta,
          aliquotaDeducaoPrevidencia,
          fatorAnualidade
        )
      : 0;
  }, [rendaBruta, fatorAnualidade, aliquotaDeducaoPrevidencia, isentoIR]);

  const deducaoFiscalPrevidencia = useMemo(
    () =>
      Math.min(
        previdenciaPBGL + previdenciaComplementar,
        valorMaximoDeducaoPrevidencia
      ),
    [
      valorMaximoDeducaoPrevidencia,
      previdenciaPBGL,
      previdenciaComplementar,
      Math.min,
    ]
  );

  const deducaoInssTabela = useMemo(
    () =>
      roundMoney(
        calcularContribuicaoInss(
          rendaBruta / fatorPeriodicidade,
          aliquotasINSS
        ) * fatorPeriodicidade
      ),
    [
      calcularContribuicaoInss,
      roundMoney,
      rendaBruta,
      fatorPeriodicidade,
      aliquotasINSS,
    ]
  );

  const deducaoDependentesIR = useMemo(
    () => quantidadeDependentes * valorDeducaoDenpendente * fatorPeriodicidade,
    [quantidadeDependentes, valorDeducaoDenpendente, fatorPeriodicidade]
  );

  const deducaoEducacaoDependentes = useMemo(
    () =>
      Math.min(
        investEducacaoDependentes,
        valorMaximaDeducaoEducacao *
          totDependentesEstudantes *
          fatorPeriodicidade
      ),
    [
      investEducacaoDependentes,
      valorMaximaDeducaoEducacao,
      totDependentesEstudantes,
      fatorPeriodicidade,
    ]
  );

  const deducaoInss = useMemo(
    () => Math.min(deducaoInssTabela, contribuicaoInss),
    [deducaoInssTabela, contribuicaoInss]
  );

  const deducaoEducacaoPropria = useMemo(
    () =>
      Math.min(
        investEducacaoPropria,
        valorMaximaDeducaoEducacao * fatorPeriodicidade
      ),
    [investEducacaoPropria, valorMaximaDeducaoEducacao, fatorPeriodicidade]
  );

  const deducoesFiscaisSemPrev = useMemo(() => {
    return somarDeducoesFiscais(
      deducaoInss,
      despesasMedicas,
      pensaoAlimenticia,
      deducaoDependentesIR,
      deducaoEducacaoDependentes,
      deducaoEducacaoPropria
    );
  }, [
    deducaoInss,
    despesasMedicas,
    pensaoAlimenticia,
    deducaoDependentesIR,
    deducaoEducacaoDependentes,
    deducaoEducacaoPropria,
  ]);

  const deducoesFiscaisComPrev = useMemo(
    () => deducoesFiscaisSemPrev + deducaoFiscalPrevidencia,
    [deducoesFiscaisSemPrev, deducaoFiscalPrevidencia]
  );

  const mensagensUsuario = useMemo(
    () =>
      getMensagens({
        deducaoInss: deducaoInssTabela,
        rendaBruta,
        isentoIR,
        investEducacaoPropria,
        valorMaximaDeducaoEducacao,
        fatorPeriodicidade,
        investEducacaoDependentes,
        totDependentesEstudantes,
      }),
    [
      rendaBruta,
      isentoIR,
      investEducacaoPropria,
      investEducacaoDependentes,
      valorMaximaDeducaoEducacao,
      fatorPeriodicidade,
      deducaoInssTabela,
      totDependentesEstudantes,
    ]
  );

  return {
    ...{ fatorPeriodicidade, setFatorPeriodicidade },
    ...{ rendaBruta, setRendaBruta },
    ...{ contribuicaoInss, setContribuicaoInss },
    ...{ previdenciaPBGL, setPrevidenciaPBGL },
    ...{ previdenciaComplementar, setPrevidenciaComplementar },
    ...{ despesasMedicas, setDespesasMedicas },
    ...{ pensaoAlimenticia, setPensaoAlimenticia },
    ...{ quantidadeDependentes, setQuantidadeDependentes },
    ...{ totDependentesEstudantes, setTotDependentesEstudantes },
    ...{ investEducacaoPropria, setInvestEducacaoPropria },
    ...{ investEducacaoDependentes, setInvestEducacaoDependentes },
    ...mensagensUsuario,
    valorMaximoDeducaoPrevidencia,
    fatorAnualidade,
    deducaoInssTabela,
    deducaoInss,
    deducaoEducacaoDependentes,
    ...{ deducoesFiscaisSemPrev, deducoesFiscaisComPrev },
    deducaoFiscalPrevidencia,
    deducaoEducacaoPropria,
    deducaoDependentesIR,
    ...{ variaveis },
  };
};

export default useMotorCalculo;
