import "./style.css";
import React from "react";
import AreaRoute from "../../components/shared/routes";
import HomePage from "./pages";
import { envs } from "../../config";

export const Informe = () => {
  const pageTitle = "Informe de Rendimentos";

  return (
    <AreaRoute
      main={HomePage}
      title={pageTitle}
      chatId={envs.previdenciaChatId}
    />
  );
};

export default Informe;
