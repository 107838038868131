/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import styled from "styled-components";
import FormularioSinistro from "./FormularioSinistro";
import Form from "../../../components/shared/forms";
import {
  cpfCnpjRegex,
  emailRegex,
  nomeRegex,
  dateRegex,
  isValidCpfCnpj,
  isValidateDate,
  validDateBR,
} from "../../../utils/inputUtils";
import useSinistro from "../../../hooks/requests/useSinistro";
import { SinisterContext } from "../../../context/SinisterContext";

const AbrirSinistro = ({ sectionTitle, setErrorSinistro }) => {
  const [formProps, setFormProps] = useState({
    statusPossuiProduto: false,
    exibirSemProduto: false,
    exibirSucesso: false,
    falhaFluxo: false,
  });

  const [exibirTitulo, setExibirTitulo] = useState(true);

  const nomeInvalido = "Nome é inválido.";
  const cpfInvalido = "Insira um CPF válido.";
  const dateInvalidMessage = "Digite uma data válida.";
  const pessoaFisica = "PessoaFisica";
  const pessoaJuridica = "PessoaJuridica";
  const titular = "Titular";
  const DateFutureMessageIn = "Is-Date-Future-Message";
  const DateFutureMessageOut = "A data não pode ser futura.";

  const {
    verificarProduto,
    abrirComunidadoSinistro,
    enviarAnexo,
    loading: isLoadingSinistro,
    error: errorSinistro,
  } = useSinistro();

  const handleProduct = (product) => {
    const produto = [];
    product?.forEach((item) => {
      const { segmento } = item;
      if (segmento === "VIDA") {
        produto.push("VIDA");
      } else if (segmento === "PREVIDENCIA") {
        produto.push("PREV");
      } else if (segmento === "PRESTAMISTA") {
        produto.push("PREST");
      }
    });
    return produto;
  };

  const { setExibirSemProduto } = useContext(SinisterContext);

  useEffect(() => {
    if (!isLoadingSinistro) {
      setErrorSinistro(errorSinistro);
      setFormProps((state) => ({ ...state, errorSinistro, isLoadingSinistro }));
    }
  }, [isLoadingSinistro]);

  return (
    <Container>
      <>
        {exibirTitulo && (
          <>
            <Title>{sectionTitle}</Title>
            <Subtitle>
              Antes de começar, tenha em mãos o CPF/CNPJ do(a) titular do
              contrato de seguro / plano de previdência.
            </Subtitle>
          </>
        )}

        <Form
          formProps={formProps}
          form={FormularioSinistro}
          formId="form-sinistro"
          validationSchema={{
            segurado: Yup.object()
              .shape({
                tipoPessoa: Yup.string().required("O campo é obrigatório."),

                cpfSegurado: Yup.string()
                  .matches(cpfCnpjRegex, "CPF é inválido.")
                  .required("O campo CPF é obrigatório.")
                  .test("is-cpf-valid.", cpfInvalido, isValidCpfCnpj),

                nomeSegurado: Yup.string()
                  .matches(nomeRegex, nomeInvalido)
                  .required("O campo Nome é obrigatório."),

                dthNascimentoSegurado: Yup.string()
                  .matches(dateRegex, dateInvalidMessage)
                  .required("O campo Data de nascimento é obrigatório.")
                  .test(
                    DateFutureMessageIn,
                    DateFutureMessageOut,
                    isValidateDate
                  ),

                sexoSegurado: Yup.string().required(
                  "O campo Sexo é obrigatório."
                ),

                cnpjSegurado: Yup.string().when(["tipoPessoa"], {
                  is: (tipoPessoa) => tipoPessoa === pessoaJuridica,
                  then: Yup.string()
                    .matches(cpfCnpjRegex, "CNPJ é inválido.")
                    .required("O campo CNPJ é obrigatório.")
                    .test(
                      "is-cnpj-valid",
                      "Insira um CNPJ válido.",
                      isValidCpfCnpj
                    ),
                  otherwise: Yup.string().default("").optional(),
                }),

                razaoSocialSegurado: Yup.string().when(["tipoPessoa"], {
                  is: (tipoPessoa) => tipoPessoa === pessoaJuridica,
                  then: Yup.string()
                    .matches(nomeRegex, "Razão Social é inválida.")
                    .required("O campo Razão Social é obrigatório."),
                  otherwise: Yup.string().default("").optional(),
                }),
              })
              .required(),

            reclamante: Yup.object()
              .shape({
                nomeReclamante: Yup.string().when(["grauParentesco"], {
                  is: (grauParentesco) => grauParentesco === titular,
                  then: Yup.string().optional(),
                  otherwise: Yup.string()
                    .matches(nomeRegex, nomeInvalido)
                    .required("O nome do reclamante é obrigatório."),
                }),
                cpfReclamante: Yup.string().when(["grauParentesco"], {
                  is: (grauParentesco) => grauParentesco === titular,
                  then: Yup.string().optional(),
                  otherwise: Yup.string()
                    .required("O CPF do reclamante é obrigatório.")
                    .test("is-cpf-valid", cpfInvalido, isValidCpfCnpj),
                }),
                grauParentesco: Yup.string().required(
                  "O grau de parentesco do reclamante é obrigatório."
                ),
                telefoneResidencial: Yup.string(),
                telefoneCelular: Yup.string().required(
                  "O telefone celular é obrigatório."
                ),
                email: Yup.string()
                  .matches(emailRegex, "Digite um e-mail válido.")
                  .required("O campo e-mail é obrigatório."),
                emailValid: Yup.string()
                  .oneOf(
                    [Yup.ref("email"), null],
                    "Os e-mails devem ser iguais."
                  )
                  .matches(emailRegex, "Digite um e-mail válido.")
                  .required("O campo de confirmação e-mail é obrigatório."),
                cep: Yup.string().required("O campo CEP é obrigatório."),
                endereco: Yup.string().required(
                  "O campo endereço é obrigatório."
                ),
                cidade: Yup.string().required("O campo cidade é obrigatório."),
                bairro: Yup.string().required("O campo bairro é obrigatório."),
                complemento: Yup.string(),
                numero: Yup.string().required("O campo número é obrigatório."),
                estado: Yup.string().required("O campo Estado é obrigatório."),
              })
              .required(),
            dadosSinistro: Yup.object()
              .shape({
                evento: Yup.string().required("O campo evento é obrigatório."),
                causa: Yup.string().required("O campo causa é obrigatório."),
                pessoa: Yup.string().when(["evento"], {
                  is: (evento) => evento === "Morte",
                  then: Yup.string().required("O campo pessoa é obrigatório."),
                  otherwise: Yup.string().optional(),
                }),

                nomeConjugeFilho: Yup.string().when(["pessoa"], {
                  is: (pessoa) => pessoa === "Conjuge" || pessoa === "Filho",
                  then: Yup.string()
                    .matches(nomeRegex, nomeInvalido)
                    .required("O nome do cônjuge ou filho(a) é obrigatório."),
                  otherwise: Yup.string().optional(),
                }),

                cpfConjugeFilho: Yup.string().when(["pessoa"], {
                  is: (pessoa) => pessoa === "Conjuge" || pessoa === "Filho",
                  then: Yup.string()
                    .matches(cpfCnpjRegex, "CPF é inválido.")
                    .required(
                      "O campo CPF do cônjuge ou filho(a) é obrigatório."
                    )
                    .test("is-cpf-valid", cpfInvalido, isValidCpfCnpj),
                  otherwise: Yup.string().optional(),
                }),

                dthNascimentoConjugeFilho: Yup.string().when(["pessoa"], {
                  is: (pessoa) => pessoa === "Conjuge" || pessoa === "Filho",
                  then: Yup.string()
                    .matches(dateRegex, dateInvalidMessage)
                    .required("Data de nascimento é obrigatória.")
                    .test(
                      DateFutureMessageIn,
                      DateFutureMessageOut,
                      isValidateDate
                    ),
                  otherwise: Yup.string().optional(),
                }),
                dthSinistro: Yup.string().when(["evento"], {
                  is: (evento) => evento === "Gravidez",
                  then: Yup.string()
                    .matches(dateRegex, dateInvalidMessage)
                    .required("Data do parto é obrigatória.")
                    .test(
                      DateFutureMessageIn,
                      DateFutureMessageOut,
                      isValidateDate
                    ),
                  otherwise: Yup.string()
                    .matches(dateRegex, dateInvalidMessage)
                    .required("Data do sinistro é obrigatória.")
                    .test(
                      DateFutureMessageIn,
                      DateFutureMessageOut,
                      isValidateDate
                    ),
                }),

                dthAcidente: Yup.string().when(["evento", "causa"], {
                  is: (evento, causa) =>
                    (evento === "Morte" ||
                      evento === "Invalidez" ||
                      evento === "AfastamentoMedico") &&
                    causa === "Acidental",
                  then: Yup.string()
                    .matches(dateRegex, dateInvalidMessage)
                    .required("Data do acidente é obrigatória.")
                    .test(
                      DateFutureMessageIn,
                      DateFutureMessageOut,
                      isValidateDate
                    )
                    .test(
                      DateFutureMessageIn,
                      "A data do acidente não pode ser maior que a data do sinistro.",
                      (value, context) => {
                        return (
                          new Date(validDateBR(value)) <=
                          new Date(validDateBR(context.parent.dthSinistro))
                        );
                      }
                    ),
                  otherwise: Yup.string().optional(),
                }),
                historicoSinistro: Yup.string().required(
                  "O campo histórico do sinistro é obrigatório."
                ),
              })
              .required(),
            reCaptcha: Yup.string()
              .required("O campo reCAPTCHA é obrigatório.")
              .nullable(),
          }}
          initialValues={{
            segurado: {
              tipoPessoa: pessoaFisica,
              cpfSegurado: "",
              cnpjSegurado: "",
              nomeSegurado: "",
              razaoSocialSegurado: "",
              dthNascimentoSegurado: "",
              sexoSegurado: "",
            },
            reclamante: {
              nomeReclamante: "",
              cpfReclamante: "",
              grauParentesco: "",
              telefoneResidencial: "",
              telefoneCelular: "",
              email: "",
              emailValid: "",
              cep: "",
              endereco: "",
              cidade: "",
              bairro: "",
              complemento: "",
              numero: "",
              estado: "",
            },
            dadosSinistro: {
              evento: "",
              causa: "",
              pessoa: "",
              nomeConjugeFilho: "",
              cpfConjugeFilho: "",
              dthNascimentoConjugeFilho: "",
              dthSinistro: "",
              dthAcidente: "",
              historicoSinistro: "",
            },
            reCaptcha: "",
          }}
          onSubmit={async (data) => {
            const cpfSegurado = data.segurado.cpfSegurado.replace(/\D/gi, "");
            const cnpjSegurado = data.segurado.cnpjSegurado.replace(/\D/gi, "");
            const docSegurado =
              data.segurado.tipoPessoa === pessoaFisica
                ? cpfSegurado
                : cnpjSegurado;

            const cpfReclamante = data.reclamante.cpfReclamante.replace(
              /\D/gi,
              ""
            );
            const telefoneResidencial = data.reclamante.telefoneResidencial.replace(
              /\D/gi,
              ""
            );
            const dddResidencial = telefoneResidencial.replace(
              /^(\d{2})(9?\d{8})$/gi,
              "$1"
            );
            const telefoneCelular = data.reclamante.telefoneCelular
              .replace(/\D/gi, "")
              .replace(/^\d{2}/, "");
            const dddCelular = data.reclamante.telefoneCelular.match(
              /\((\d{2})\)/
            )[1];
            const cep = data.reclamante.cep.replace(/\D/gi, "");
            const localReCaptcha = data.reCaptcha;

            const jsonData = {
              segurado: {
                tipoPessoa: data.segurado.tipoPessoa,
                docSegurado,
                cpfSegurado,
                nomeSegurado: data.segurado.nomeSegurado,
                dataNascimentoSegurado: data.segurado.dthNascimentoSegurado,
                sexoSegurado: data.segurado.sexoSegurado,
                cnpjSegurado:
                  data.segurado.tipoPessoa === pessoaJuridica
                    ? cnpjSegurado
                    : "",
                razaoSocialSegurado:
                  data.segurado.tipoPessoa === pessoaJuridica
                    ? data.segurado.razaoSocialSegurado
                    : "",
              },
              reclamante: {
                nomeReclamante:
                  data.reclamante.grauParentesco === titular
                    ? data.segurado.nomeSegurado
                    : data.reclamante.nomeReclamante,
                cpfReclamante:
                  data.reclamante.grauParentesco === titular
                    ? cpfSegurado
                    : cpfReclamante,
                grauParentesco: data.reclamante.grauParentesco,
                dddResidencial,
                telefoneResidencial,
                dddCelular,
                telefoneCelular,
                email: data.reclamante.email,
                cep,
                endereco: data.reclamante.endereco,
                cidade: data.reclamante.cidade,
                bairro: data.reclamante.bairro,
                complemento: data.reclamante.complemento,
                numero: data.reclamante.numero,
                estado: data.reclamante.estado,
              },
              dadosSinistro: {
                evento: data.dadosSinistro.evento,
                causa: data.dadosSinistro.causa,
                pessoa: data.dadosSinistro.pessoa,

                nomeConjugeFilho: data.dadosSinistro.nomeConjugeFilho,
                cpfConjugeFilho: data.dadosSinistro.cpfConjugeFilho,
                dthNascimentoConjugeFilho:
                  data.dadosSinistro.dthNascimentoConjugeFilho,

                dthSinistro: data.dadosSinistro.dthSinistro,
                dthAcidente: data.dadosSinistro.dthAcidente,
                historicoSinistro: data.dadosSinistro.historicoSinistro,
              },
            };
            const returnPossuiProduto = await verificarProduto(
              jsonData,
              localReCaptcha
            );

            const productNotFoundMessage =
              "Nenhum dado encontrado para este CPF.";
            const isTrueSet =
              returnPossuiProduto.dados !== productNotFoundMessage;

            let product = [];

            if (isTrueSet) {
              product = handleProduct(returnPossuiProduto.dados);
            }

            setExibirSemProduto(!isTrueSet);

            let returnAberturaSinistro = {};
            let returnAnexo = {};

            if (isTrueSet)
              returnAberturaSinistro = await abrirComunidadoSinistro(
                jsonData,
                localReCaptcha,
                product
              );

            if (returnAberturaSinistro.dados.sucesso)
              returnAnexo = await enviarAnexo(
                jsonData,
                returnAberturaSinistro,
                localReCaptcha,
                product
              );

            const sucessoAberturaEAnexo =
              returnAberturaSinistro.dados.sucesso &&
              returnAnexo.dados.sucesso &&
              !errorSinistro;

            const falhaNoFluxo =
              !isTrueSet ||
              returnAberturaSinistro.status === "Sucesso" ||
              !sucessoAberturaEAnexo;

            setFormProps((state) => ({
              ...state,
              exibirSemProduto: !isTrueSet,
              numeroProtocolo:
                returnAberturaSinistro.dados.entidade.numeroProtocolo,
              exibirSucesso: sucessoAberturaEAnexo,
              falhaFluxo: falhaNoFluxo,
            }));
            setExibirTitulo(!sucessoAberturaEAnexo);
          }}
        />
      </>
    </Container>
  );
};

AbrirSinistro.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  setErrorSinistro: PropTypes.func.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;
const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;

export default AbrirSinistro;
