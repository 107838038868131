/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import InputPrice from "../../../../components/shared/InputPrice";
import sanitize from '../../../../utils/sanitize';

const FormularioDeducoes = ({
  despesasMedicas,
  setDespesasMedicas,
  pensaoAlimenticia,
  setPensaoAlimenticia,
  quantidadeDependentes,
  setQuantidadeDependentes,
  totDependentesEstudantes,
  setTotDependentesEstudantes,
  investEducacaoPropria,
  setInvestEducacaoPropria,
  investEducacaoDependentes,
  setInvestEducacaoDependentes,
  errors,
}) => {
  const [checkedRadio, setCheckedRadio] = useState("");

  useEffect(() => {
    if (checkedRadio !== "sim") {
      setQuantidadeDependentes(0);
      setTotDependentesEstudantes(0);
    }
  }, [checkedRadio, setQuantidadeDependentes, setTotDependentesEstudantes]);

  const [
    msgLimitePropria,
    msgLimitePropriaCompl,
  ] = errors?.limiteEducacaoPropria || ["", ""];
  const [
    msgLimiteDepend,
    msgLimiteDependCompl,
  ] = errors?.limiteEducacaoDependentes || ["", ""];

  return (
    <>
      <Row>
        <Col xs="12">
          <h3>Deduções</h3>
          <p className="mb-5">
            As informações abaixo são <b>opcionais</b>. Se preenchidas trazem
            maior fidelidade à sua declaração do Imposto de Renda
          </p>
        </Col>
        <Col className="mb-4" xs="12" lg="6">
          {" "}
          <InputPrice
            label="Qual o valor investido em educação própria?"
            placeholder="R$ 0,00"
            value={investEducacaoPropria}
            onChange={setInvestEducacaoPropria}
            info={
              <>
                {msgLimitePropria} <br />
                {msgLimitePropriaCompl}
              </>
            }
            error
          />
        </Col>
        <Col className="mb-4" xs="12" lg="6">
          <InputPrice
            label="Qual o valor com despesas médicas?"
            placeholder="R$ 0,00"
            value={despesasMedicas}
            onChange={setDespesasMedicas}
          />
        </Col>
        <Col className="mb-4" xs="12" lg="6">
          <InputPrice
            label="Qual o valor com pensão alimentícia?"
            placeholder="R$ 0,00"
            value={pensaoAlimenticia}
            onChange={setPensaoAlimenticia}
          />
        </Col>
        <Col className="mb-4" xs="12" lg="6">
          <Row>
            <Col xs="12" lg="5">
              <div className="inline-radio">
                <Form.Label className="text-left d-block">
                  Possui dependentes?
                </Form.Label>
                <Form.Check
                  id="possui-previdencia-control"
                  type="radio"
                  label="Sim"
                  checked={checkedRadio === "sim"}
                  onClick={() => setCheckedRadio("sim")}
                  inline
                />
                <Form.Check
                  id="nao-possui-previdencia-control"
                  type="radio"
                  label="Não"
                  checked={checkedRadio === "nao"}
                  onClick={() => setCheckedRadio("nao")}
                  inline
                />
              </div>
            </Col>
            <Collapse in={checkedRadio === "sim"}>
              <Col xs="12" lg="6">
                <Form.Group>
                  <Form.Label className="text-left d-block">
                    Quantidade de dependentes
                  </Form.Label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="0"
                    value={quantidadeDependentes}
                    onChange={(evt) => {
                      const value = sanitize(evt);
                      setQuantidadeDependentes(
                        Number.parseInt(value, 10)
                      )
                    }}
                  />
                </Form.Group>
              </Col>
            </Collapse>
          </Row>
        </Col>
      </Row>

      <Collapse in={quantidadeDependentes > 0}>
        <Row>
          <Col className="mb-4" xs="12" lg="6">
            <div className="form-group">
              <Form.Label className="text-left d-block">
                Quantos dependentes estudam?
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="0"
                value={totDependentesEstudantes || ""}
                onChange={(evt) => {
                  const value = Number.parseInt(evt.currentTarget.value, 10);
                  setTotDependentesEstudantes(
                    value > quantidadeDependentes
                      ? quantidadeDependentes
                      : value
                  );
                }}
              />
            </div>
          </Col>
          <Col className="mb-4" xs="12" lg="6">
            <InputPrice
              label="Qual o valor investido na educação dos dependentes?"
              placeholder="R$ 0,00"
              value={investEducacaoDependentes}
              onChange={setInvestEducacaoDependentes}
              info={
                <>
                  {msgLimiteDepend} <br />
                  {msgLimiteDependCompl}
                </>
              }
              error
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

FormularioDeducoes.defaultProps = {
  despesasMedicas: 0,
  pensaoAlimenticia: 0,
  quantidadeDependentes: 0,
  totDependentesEstudantes: 0,
  investEducacaoPropria: 0,
  investEducacaoDependentes: 0,
  errors: {},
};

FormularioDeducoes.propTypes = {
  despesasMedicas: PropTypes.number,
  pensaoAlimenticia: PropTypes.number,
  quantidadeDependentes: PropTypes.number,
  totDependentesEstudantes: PropTypes.number,
  investEducacaoPropria: PropTypes.number,
  investEducacaoDependentes: PropTypes.number,
  errors: PropTypes.objectOf(Object),
  setDespesasMedicas: PropTypes.func.isRequired,
  setPensaoAlimenticia: PropTypes.func.isRequired,
  setQuantidadeDependentes: PropTypes.func.isRequired,
  setTotDependentesEstudantes: PropTypes.func.isRequired,
  setInvestEducacaoPropria: PropTypes.func.isRequired,
  setInvestEducacaoDependentes: PropTypes.func.isRequired,
};

export default FormularioDeducoes;
