import React from "react";
import { Button } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

export const MicroSeguroAmparo = ({ sectionTitle }) => {
  return (
    <div className="div-secao">
      <h4>{sectionTitle}</h4>
      <div className="div-cards">
        <div className="div-card border">
          <div className="div-line-a">
            <p>
              <strong>Pagamento Único </strong>(12 meses)
            </p>
          </div>
          <div className="div-line-b">
            <div className="div-line-b--a">
              <h1>R$ 199</h1>
            </div>
          </div>
          <div className="div-line-c">
            <p>
              <strong>Plano Anual</strong>
            </p>
          </div>
          <div className="div-line-d">
            <Button
              className="base-btn btn-secondary"
              href={`${LINKS.autoCompraBemEstar}/1`}
            >
              Contratar Agora
            </Button>
          </div>
        </div>

        <div className="div-card border">
          <div className="div-line-a">
            <p>
              <strong>Pagamento Único </strong>(36 meses)
            </p>
          </div>
          <div className="div-line-b">
            <div className="div-line-b--a">
              <h1>R$ 500</h1>
            </div>
            <div className="div-line-b--b">
              <p>
                <strong>
                  Economia
                  <br />
                  de 19,4%
                </strong>
              </p>
            </div>
          </div>
          <div className="div-line-c">
            <p>
              <strong>Plano Trienal</strong>
            </p>
          </div>
          <div className="div-line-d">
            <Button
              className="base-btn btn-secondary"
              href={`${LINKS.autoCompraBemEstar}/2`}
            >
              Contratar Agora
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicroSeguroAmparo;
