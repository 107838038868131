import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import useGiRequest, { POST } from "./useGiRequest";
import usePerformGiRequest from "./usePerformGiRequest";

/**
 * This service hook will call an API to create a new ombudsman request based on input data
 *
 * @component
 * @return  {UseOuvidoriaHook}
 *
 * @typedef    {Object}    UseOuvidoriaHook
 *  @property  {function}  createOuvidoria    Function used for API call
 *  @property  {string}    newProtocol        The protocol for the newly created request
 *  @property  {Date}      newProtocolDate    Expected fulfillment date for the newly created request
 *  @property  {number}    status             HTTP status code
 *  @property  {Object}    data               Raw response data
 *  @property  {boolean}   loading            Loading indicator
 *  @property  {boolean}   error              Error indicator
 */
const useOuvidoria = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const [newProtocol, setNewProtocol] = useState("");
  const [newProtocolDate, setNewProtocolDate] = useState(null);
  const { performGiRequest, errorGi } = useGiRequest();
  const { getAccessToken } = usePerformGiRequest();

  /**
   * Creates new ombudsman request
   * @param   {Object}   formData         Input data for request creation
   * @param   {string}   reCaptchaToken   reCaptcha solved challenge token for validation
   */
  const createOuvidoria = async (formData, reCaptchaToken) => {
    setLoading(true);

    const shaRsaKey = await getAccessToken('00000000000', endPoints.criarOuvidoria.split('/').pop());
    const payload = {
      UserName: "00000000000",
      SHArsaKey: shaRsaKey,
      reCaptcha: reCaptchaToken,
      payload: formData
    }

    const response = await performGiRequest({
      method: POST,
      url: endPoints.criarOuvidoria,
      data: payload
    });

    setStatus(response.status);
    setData(response.data.dados.result);

    if (response.status === 200 && response.data?.dados?.result?.protocolo) {
      setNewProtocol(response.data.dados.result.protocolo);
      setNewProtocolDate(
        response.data.dados.result.dataPrevista
          ? new Date(response.data.dados.result.dataPrevista)
          : null
      );
    } else {
      setNewProtocol("");
      setNewProtocolDate(null);
    }
    setLoading(false);
  }

    return {
      createOuvidoria,
      newProtocol,
      newProtocolDate,
      status,
      data,
      loading,
      error: errorGi,
    };
  };

  useOuvidoria.propTypes = {
    createOuvidoria: PropTypes.func,
    newProtocol: PropTypes.string,
    newProtocolDate: PropTypes.instanceOf(Date),
    status: PropTypes.number,
    data: PropTypes.objectOf(Object),
    loading: PropTypes.bool,
    error: PropTypes.bool,
  };

  export default useOuvidoria;
