import { useEffect } from "react";

export const App = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  useEffect(() => {
    function DetectAndRedirect() {
      if (/android/g.test(userAgent)) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=br.com.cvpb&hl=pt_BR&mtm_campaign=MaisFuturo-Play-Store";
      }
      if (/iphone|ipad|ipod/g.test(userAgent)) {
        window.location.href =
          "https://apps.apple.com/br/app/caixa-previd%C3%AAncia/id1491541705?mtm_campaign=MaisFuturo-APP-Store";
      }
    }
    DetectAndRedirect();
  }, []);
  return null;
};

export default App;
