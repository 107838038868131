import { useState } from "react";
import PropTypes from "prop-types";
import {
  CriarToken,
  ValidarToken,
} from '../config/endpoints';
import useGiRequest, { POST } from "./requests/useGiRequest";
import usePerformGiRequest from "./requests/usePerformGiRequest";

const usePhoneToken = () => {
  const { performGiRequest } = useGiRequest();
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = usePerformGiRequest();

  const getPhoneToken = async (json) => {
    setLoading(true);
    const response = await performGiRequest({
      method: POST,
      url: CriarToken,
      data: {
        UserName:json.cpf,
        SHArsaKey: await getAccessToken(
          json.cpf,
          "GBE_criaTokenEnviaSMS"
        ),
        ApiID: "",
        destinatario:`55${json.telefoneToken}`,
        mensagem: "CAIXA Vida e Previdencia: voce solicitou a desvinculacao de um acompanhamento de sinistro. Seu codigo de validacao:  ",
        tamanho: 5,
        sistema: 'salesforce',
        telefone: `55${json.telefoneToken}`,
        userinfo: json.cpf,
        tempotoken:5
      },
    });
    setLoading(false);

    return response?.data;
  }

  const validateToken = async (json) => {
    setLoading(true);

    const responseToken = await performGiRequest({
      method: POST,
      url: ValidarToken,
      data: {
        UserName: json.cpf,
        SHArsaKey: await getAccessToken(
          json.cpf,
          "GBE_ValidaTokenSMS"
        ),
        ApiID: "",
        sistema:"salesforce",
        telefone: json.telefoneToken,
        tokenbase: json.token
      },
    });
    setLoading(false);
    return responseToken?.data;
  };
    
  return {
    loading,
    getPhoneToken,
    validateToken,
    };
    
};

usePhoneToken.propTypes = {
loading: PropTypes.bool,
getPhoneToken: PropTypes.func,
validateToken: PropTypes.func,
}

export default usePhoneToken;
