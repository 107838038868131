import { buildTelefone } from './buildTelefone';

export function buildReclamante(reclamante) {
  return {
    nome: reclamante?.nomeReclamante ?? '',
    cpf: reclamante?.cpfReclamante ?? '',
    grauParentesco: reclamante?.grauParentesco ?? '',
    email1: reclamante?.email ?? '',
    email2: '',
    endereco: `${reclamante?.endereco} ${reclamante?.complemento} ${reclamante?.numero}`,
    cidade: reclamante?.cidade ?? '',
    bairro: reclamante?.bairro ?? '',
    cep: reclamante?.cep ?? '',
    uf: reclamante?.estado ?? '',
    telefoneResidencial: buildTelefone(
      reclamante?.dddResidencial,
      reclamante?.telefoneResidencial
    ),
    telefoneComercial: buildTelefone(
      reclamante?.dddComercial,
      reclamante?.telefoneComercial
    ),
    telefoneCelular: buildTelefone(
      reclamante?.dddCelular,
      reclamante?.telefoneCelular
    ),
  };
}

export default buildTelefone;
