import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Banner } from '../shared/layout/banners';
import { Button } from '../shared/actions';

const BannerIr = (props) => {
  const { text, buttonLink, labelBotao } = props;

  return (
    <Banner
      {...props}
      className="text-white banner-ir-2021"
      right
      customPros={{
        xs: 12,
        md: 12,
      }}
    >
      <Row className="pt-2 text-right">
        {/* <Col lg={{ span: 7, offset: 5 }} > */}
        <Col lg={12}>
          <p className="text-body-md mt-0 mt-md-2">{text}</p>
          <Button
            className="informe-banner-button btn-blue-banner"
            ignoreBlank
            primary
            href={buttonLink}
          >
            {labelBotao}
          </Button>
        </Col>
      </Row>
    </Banner>
  );
};

BannerIr.defaultProps = {
  text: 'O seu informe de rendimentos já está disponível.',
  labelBotao: 'Acessar',
};

BannerIr.propTypes = {
  text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  backgroundImage: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  labelBotao: PropTypes.string,
};

export default BannerIr;
