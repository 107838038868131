import { useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import * as _ from "lodash";
import { endPoints } from "../../config";
import useStaticRequest from "./useStaticRequest";

/**
 * This service hook will fetch regulation data from a remote location for use in product pages
 *
 * @component
 * @return  {UseRegulamentosHook}
 *
 * @typedef    {Object}        UseRegulamentosHook
 *  @property  {function}      getRegulamentos         Function used for API call
 *  @property  {Array}         regulamentos            Array containing the found regulations
 *  @property  {number}        status                  HTTP status code
 *  @property  {Object}        data                    Raw response data
 *  @property  {boolean}       loading                 Loading indicator
 *  @property  {boolean}       error                   Error indicator
 */
const useRegulamentos = () => {
  const { performStaticRequest, error } = useStaticRequest();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const [regulamentos, setRegulamentos] = useState([]);

  /**
   * Fetches regulation data
   */
  const getRegulamentos = () => {
    setLoading(true);
    const response = performStaticRequest(endPoints.getRegulamentosEndPoint);
    response.then((responseData) => {
      setStatus(responseData?.status);
      setData(responseData?.data);
      setRegulamentos(
        (Array.isArray(responseData?.data) ? responseData?.data : []).map(
          (regulamento) => ({
            ...regulamento,
            id: nanoid(),
            tipoProduto: _.capitalize(regulamento.tipoProduto),
          })
        )
      );
      setLoading(false);
    });
  };

  return { getRegulamentos, regulamentos, status, data, loading, error };
};

useRegulamentos.propTypes = {
  getRegulamentos: PropTypes.func,
  regulamentos: PropTypes.objectOf(Object),
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useRegulamentos;
