const pessoaOptions = {
  PF: {
    TRUE: [
      { value: "Titular", label: "Titular" },
      { value: "Conjuge", label: "Cônjuge" },
      { value: "Filho", label: "Filho" },
    ],
    FALSE: [{ value: "-", label: "-" }],
  },
  PJ: {
    TRUE: [
      { value: "Titular", label: "Titular" },
      { value: "Conjuge", label: "Cônjuge" },
    ],
    FALSE: [{ value: "-", label: "-" }],
  },
};

export default pessoaOptions;
