import bannerVidaSenior from "./banner-vida-senior.png";
import seguranca from "./seguranca.png";
import melhorIdade from "./melhoridade.png";
import cabeOrcamento from "./cabeorcamento.png";
import beneficios from "./beneficios.png";

const imagensVidaSenior = {
  bannerVidaSenior,
  seguranca,
  melhorIdade,
  cabeOrcamento,
  beneficios,
};

export default imagensVidaSenior;
