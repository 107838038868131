import React from "react";
import { PropTypes } from "prop-types";
import { PhoneLink } from "@components/shared/actions";
import ContentWithStep from "../../../components/blocosCustomizados/ContentWithStep";

export const PassoAssistencia = ({ sectionTitle }) => {
  return (
    <ContentWithStep title={sectionTitle} className="mb-4">
      <>
        Entrar em contato com a Central Assistência Dia & Noite e Sinistros pelo{" "}
        <PhoneLink phone="08007222492" /> e informar os dados do titular do
        seguro.
      </>
      <>
        Para acionar o Serviço de Assistência Funeral, o segurado titular,
        beneficiário ou reclamante também deve ligar para{" "}
        <PhoneLink phone="08007222492" />.
      </>
    </ContentWithStep>
  );
};

PassoAssistencia.defaultProps = {};

PassoAssistencia.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default PassoAssistencia;
