export function capitalize(val = '') {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

export default function trimStart(str) {
  if (str === null) return str;
  return str.replace(/^\s+/g, '');
}

export const emptyIfNull = (
  value,
  callback = argument => argument.toString()
) => (value != null && callback(value)) || '--';

export const isNullOrEmpty = value => !value;

export const addPercentToValue = value => (value ? `${value}%` : '--');

export const addPercentToValueFixed = value =>
  value != null ? `${value.toFixed(2)}%` : '--';

export const toLowerCase = value => (value ? value.toLowerCase() : '');

export const toUpperCase = value => (value ? value.toUpperCase() : '');

export const replaceCharacterWitEmptySpace = (character, value) =>
  value && value.replace(character, ' ');

export const phoneToHref = phone => `tel:+55${phone.replace(/ /g, '')}`;

export const uniqueString = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array);
}
