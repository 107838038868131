import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  WarningParagraph,
  CEPContainer,
  FormTwoColumnsGeneric,
  FormTreeColumnsGeneric,
  FormOneColumnGeneric,
  FormOneColumnGenericInline,
  TooltipCard,
  TooltipBox,
} from './styles';
import SubmitButton from './ConditionalSubmitButton';
import { cepMask, readProperty } from '../../../../utils';
import {
  GenericTextInput,
  GenericSelectInput,
  GenericPhoneInput,
  EmailInput,
  EmailInputValid,
  CepInput,
  AddressInput,
  AddressNumberInput,
  AddressComplementInput,
  StateInput,
  CityInput,
  BairroInput,
  GenericTextareaInput,
  TipoPessoaSelect,
} from './FormInputs';
import { ReCaptchaControl } from '../../../../components/shared/forms';
import SpinnerLoading from '../../../../components/shared/layout/SpinnerLoading';
import {
  GENDER_OPTIONS,
  KINSHIP_OPTIONS,
  TIPO_PESSOA,
} from '../../../../constants';
import { Section } from '../../../../components/shared/pages';
import SinistroSucesso from '../SinistroSucesso';
import { useCEP } from '../../../../hooks';
import Mensagens from './Mensagens';
import { SinisterContext } from '../../../../context/SinisterContext';

import {
  handleCepData,
  renderErrorMessage,
  useNomeEvento,
  getDocumento,
  getCausasOptions,
  getReclamanteCpfValue,
} from './utils';
import EventoDiagnosticoDoencaGrave from './EventoDiagnosticoDoencaGrave';
import {
  getLabelNome,
  getLabelCPF,
  getLabelDataNascimento,
  getSelectInputOptionsPessoa,
  getEventoMorteEisCausaAcidentalOuCausaNatural,
  getOptionsEvento,
} from './CondicionaisSinistro';
import PessoaSinistradaInput from './PessoaSinistradaInput';
import ConjugeFilhoInput from './ConjugeFilhoInput';

const FormularioSinistro = ({
  isLoadingSinistro,
  errorSinistro,
  exibirSucesso,
  numeroProtocolo,
  falhaFluxo,
  values: {
    segurado: {
      tipoPessoa,
      cnpjSegurado,
      razaoSocialSegurado,
      cpfSegurado,
      nomeSegurado,
      dthNascimentoSegurado,
      sexoSegurado,
    },
    reclamante: {
      grauParentesco,
      nomeReclamante,
      cpfReclamante,
      telefoneResidencial,
      telefoneCelular,
      email,
      emailValid,
      cep,
      endereco,
      cidade,
      bairro,
      complemento,
      numero,
      estado,
    },
    dadosSinistro: {
      evento,
      causa,
      pessoa,
      nomeConjugeFilho,
      cpfConjugeFilho,
      dthNascimentoConjugeFilho,
      dthSinistro,
      dthAcidente,
      historicoSinistro,
    },
  },
  handleBlur,
  handleChange,
  isValid,
  errors,
  dirty,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setValues,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldError,
    setValues,
  };
  const CEP_INPUT_NAME = 'reclamante.cep';

  const { getAddress, foundCep, data: dataCep, loading: loadingCep } = useCEP();

  const setValuesCep = (dataCEP) => (prevState) => ({
    ...prevState,
    reclamante: {
      ...prevState.reclamante,
      endereco: dataCEP?.dados.INDTPLOG ?? '',
      estado: dataCEP?.dados.CODUF ?? '',
      cidade: dataCEP?.dados.NOMLOC ?? '',
      bairro: dataCEP?.dados.NOMEBAIR ?? '',
    },
  });

  const ND = 'Não Disparar';
  const tipoPessoaFisica = 'PessoaFisica';
  const tipoPessoaJuridica = 'PessoaJuridica';

  const eventoAfastamentoMedico = 'AfastamentoMedico';
  const eventoDiariaInternacao = 'DiariaInternacao';
  const eventoDiagnosticoDoencaGrave = 'DiagnosticoDoencaGrave';
  const eventoInvalidez = 'Invalidez';
  const eventoPerdaEmprego = 'PerdaEmprego';
  const eventoReembolso = 'Reembolso';

  const causaAcidental = 'Acidental';
  const causaDoenca = 'Doenca';
  const causaNatural = 'Natural';

  const pessoaConjuge = 'Conjuge';
  const pessoaFilho = 'Filho';
  const pessoaTitular = 'Titular';
  const messageErro = 'Erro: Preencher campos obrigatórios.';

  const [documento, setDocumento] = useState(ND);
  const [isTooltipe, setIsTooltipe] = useState(false);
  const { exibirSemProduto } = useContext(SinisterContext);

  const ShowTooltip = () => {
    setIsTooltipe(evento === 'Gravidez');
  };
  const {
    eventoGravidez,
    isEventoMorte,
    isCausaAcidentalOuCausaNatural,
  } = useNomeEvento(
    pessoa,
    evento,
    causa,
    nomeConjugeFilho,
    cpfConjugeFilho,
    dthNascimentoConjugeFilho
  );

  const isTipoPessoaPessoaFisica = tipoPessoa === tipoPessoaFisica;
  const isTipoPessoaPessoaJuridica = tipoPessoa === tipoPessoaJuridica;

  const isGrauParentescoPessoaTitular = grauParentesco === pessoaTitular;

  const isEventoGravidez = evento === eventoGravidez;
  const isEventoInvalidezAfastamentoMedicoDiariaInternacao = [
    eventoInvalidez,
    eventoAfastamentoMedico,
    eventoDiariaInternacao,
  ].includes(evento);
  const isEventoDiagnosticoDoencaGravePerdaEmpregoReembolsoGravidez = [
    eventoDiagnosticoDoencaGrave,
    eventoPerdaEmprego,
    eventoReembolso,
    eventoGravidez,
  ].includes(evento);

  const isPessoaFilho = pessoa === pessoaFilho;

  const isPessoaConjugeOuFilho = [pessoaConjuge, pessoaFilho].includes(pessoa);
  const isCausaNatural = causa === causaNatural;
  const isCausaAcidentall = causa === causaAcidental;
  const isCausaDoenca = causa === causaDoenca;

  const isEventoMorteEisCausaAcidentalOuCausaNatural = getEventoMorteEisCausaAcidentalOuCausaNatural(
    isEventoMorte,
    isCausaAcidentalOuCausaNatural
  );

  const optionsEvento = getOptionsEvento(isTipoPessoaPessoaFisica);

  const optionCausa = getCausasOptions(isTipoPessoaPessoaFisica, evento);

  const ValueReclamanteCpf = getReclamanteCpfValue(
    isGrauParentescoPessoaTitular,
    cpfSegurado,
    cpfReclamante
  );

  const labelNome = getLabelNome(isPessoaFilho);
  const LabelCPF = getLabelCPF(isPessoaFilho);
  const LabelDataNascimento = getLabelDataNascimento(isPessoaFilho);
  const selectInputOptionsPessoa = getSelectInputOptionsPessoa(
    isTipoPessoaPessoaFisica,
    isEventoMorteEisCausaAcidentalOuCausaNatural,
    isTipoPessoaPessoaJuridica
  );

  useEffect(() => {
    const doc = getDocumento(tipoPessoa, evento, causa, pessoa);
    setDocumento(doc);
    ShowTooltip();
  }, [tipoPessoa, evento, causa, pessoa]);

  useEffect(() => {
    handleCepData(
      loadingCep,
      dataCep,
      foundCep,
      setValues,
      setValuesCep,
      formikBag,
      CEP_INPUT_NAME
    );
  }, [loadingCep, dataCep]);

  return (
    <>
      <SpinnerLoading isLoading={isLoadingSinistro} />
      {exibirSucesso && (
        <Section
          id="sinistro-abertura-sucesso"
          as={SinistroSucesso}
          title=""
          sectionLayout={null}
          protocolo={numeroProtocolo}
          documento={documento}
        />
      )}
      {!exibirSucesso && (
        <>
          <WarningParagraph>1 - Dados do Segurado</WarningParagraph>
          <WarningParagraph>
            O seguro/plano foi contratado por pessoa física ou por pessoa
            jurídica?
          </WarningParagraph>

          <FormOneColumnGenericInline>
            <TipoPessoaSelect
              controlId="formSinistro.segurado.tipoPessoa"
              name="segurado.tipoPessoa"
              value={tipoPessoa}
              onChange={tipoPessoa}
              options={TIPO_PESSOA}
              formikBag={formikBag}
            />
          </FormOneColumnGenericInline>

          {isTipoPessoaPessoaJuridica && (
            <FormTwoColumnsGeneric>
              <GenericTextInput
                controlId="formSinistro.segurado.cnpjSegurado"
                name="segurado.cnpjSegurado"
                label="Qual o CNPJ da empresa titular? *"
                placeHolder="Digite o CNPJ da empresa titular"
                value={cnpjSegurado}
                typeField="cnpj"
                length="18"
                formikBag={formikBag}
              />
              <GenericTextInput
                controlId="formSinistro.segurado.razaoSocialSegurado"
                name="segurado.razaoSocialSegurado"
                label="Qual a razão social da empresa titular? *"
                placeHolder="Digite a razão social da empresa titular"
                value={razaoSocialSegurado}
                typeField="text"
                length="60"
                formikBag={formikBag}
              />
            </FormTwoColumnsGeneric>
          )}
          <FormTwoColumnsGeneric>
            <GenericTextInput
              controlId="formSinistro.segurado.cpfSegurado"
              name="segurado.cpfSegurado"
              label="Qual o CPF do segurado? *"
              placeHolder="Digite o CPF do segurado"
              value={cpfSegurado}
              typeField="cpf"
              length="14"
              formikBag={formikBag}
            />
            <GenericTextInput
              controlId="formSinistro.segurado.nomeSegurado"
              name="segurado.nomeSegurado"
              label="Qual o nome completo do segurado? *"
              placeHolder="Digite o nome completo do segurado"
              value={nomeSegurado}
              typeField="text"
              length="60"
              formikBag={formikBag}
            />
          </FormTwoColumnsGeneric>
          <FormTwoColumnsGeneric>
            <GenericTextInput
              controlId="formSinistro.segurado.dthNascimentoSegurado"
              name="segurado.dthNascimentoSegurado"
              label="Qual a data de nascimento do segurado? *"
              placeHolder="Digite a data de nascimento do segurado"
              value={dthNascimentoSegurado}
              typeField="date"
              length="10"
              formikBag={formikBag}
            />
            <GenericSelectInput
              controlId="formSinistro.segurado.sexoSegurado"
              name="segurado.sexoSegurado"
              label="Qual o sexo do segurado? *"
              placeHolder="Escolha o sexo do segurado"
              value={sexoSegurado}
              options={GENDER_OPTIONS}
              formikBag={formikBag}
            />
          </FormTwoColumnsGeneric>

          <WarningParagraph>
            2 - Dados do Reclamante (pessoa que está abrindo este comunicado de
            sinistro)
          </WarningParagraph>

          <FormTreeColumnsGeneric>
            <GenericSelectInput
              controlId="formSinistro.reclamante.grauParentesco"
              name="reclamante.grauParentesco"
              label="Qual seu grau de parentesco com o segurado? *"
              placeHolder="Selecione o grau de parentesco"
              value={grauParentesco}
              options={KINSHIP_OPTIONS}
              formikBag={formikBag}
            />
          </FormTreeColumnsGeneric>

          <FormTwoColumnsGeneric>
            <GenericTextInput
              controlId="formSinistro.reclamante.nomeReclamante"
              name="reclamante.nomeReclamante"
              label="Qual o nome completo do reclamante? *"
              placeHolder="Digite o nome completo do reclamante"
              value={
                isGrauParentescoPessoaTitular ? nomeSegurado : nomeReclamante
              }
              typeField="text"
              length="60"
              disabled={isGrauParentescoPessoaTitular}
              formikBag={formikBag}
            />
            <GenericTextInput
              controlId="formSinistro.reclamante.cpfReclamante"
              name="reclamante.cpfReclamante"
              label="Qual o CPF do reclamante? *"
              placeHolder="Digite o CPF do reclamante"
              value={ValueReclamanteCpf}
              typeField="cpf"
              length="14"
              disabled={isGrauParentescoPessoaTitular}
              formikBag={formikBag}
            />
          </FormTwoColumnsGeneric>

          <FormTreeColumnsGeneric>
            <GenericPhoneInput
              controlId="formSinistro.reclamante.telefoneCelular"
              name="reclamante.telefoneCelular"
              label="Qual seu telefone celular? *"
              placeHolder="Digite o DDD e o seu telefone celular"
              value={telefoneCelular}
              formikBag={formikBag}
            />
            <GenericPhoneInput
              controlId="formSinistro.reclamante.telefoneResidencial"
              name="reclamante.telefoneResidencial"
              label="Qual seu telefone residencial?"
              placeHolder="Digite o DDD e o seu telefone residencial"
              value={telefoneResidencial}
              formikBag={formikBag}
            />
          </FormTreeColumnsGeneric>

          <FormTwoColumnsGeneric>
            <EmailInput email={email} formikBag={formikBag} />
            <EmailInputValid emailValid={emailValid} formikBag={formikBag} />
          </FormTwoColumnsGeneric>

          <CEPContainer>
            <CepInput
              label="CEP *"
              cep={cep}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(e) => {
                e.target.value = cepMask(e.target.value);
                formikBag.onChange(e);
              }}
              // eslint-disable-next-line react/jsx-no-bind
              onBlur={(e) => {
                formikBag.onBlur(e);
                if (!readProperty(formikBag.errors, CEP_INPUT_NAME)) {
                  getAddress(cep);
                }
              }}
              formikBag={formikBag}
            />
          </CEPContainer>

          <FormTreeColumnsGeneric>
            <AddressInput
              disabled
              label="Endereço *"
              endereco={endereco}
              formikBag={formikBag}
            />
            <CityInput
              disabled
              label="Cidade *"
              cidade={cidade}
              formikBag={formikBag}
            />
            <BairroInput
              disabled
              label="Bairro *"
              bairro={bairro}
              formikBag={formikBag}
            />
          </FormTreeColumnsGeneric>

          <FormTreeColumnsGeneric>
            <AddressComplementInput
              label="Complemento"
              complemento={complemento}
              formikBag={formikBag}
            />
            <AddressNumberInput
              label="Número *"
              numero={numero}
              formikBag={formikBag}
            />
            <StateInput
              disabled
              label="Estado *"
              state={estado}
              formikBag={formikBag}
            />
          </FormTreeColumnsGeneric>

          <WarningParagraph>3 - Dados do Sinistro</WarningParagraph>

          <FormTreeColumnsGeneric>
            {!isTooltipe ? (
              <GenericSelectInput
                controlId="formSinistro.dadosSinistro.evento"
                name="dadosSinistro.evento"
                label="Evento Ocorrido *"
                placeHolder="Selecione o evento ocorrido"
                value={evento}
                options={optionsEvento}
                formikBag={formikBag}
              />
            ) : (
              <TooltipCard>
                <TooltipBox>
                  Cobertura exclusiva para produto de previdência.
                </TooltipBox>
                <GenericSelectInput
                  controlId="formSinistro.dadosSinistro.evento"
                  name="dadosSinistro.evento"
                  label="Evento *"
                  placeHolder="Selecione o evento ocorrido"
                  value={evento}
                  options={optionsEvento}
                  formikBag={formikBag}
                />
              </TooltipCard>
            )}
            <GenericSelectInput
              controlId="formSinistro.dadosSinistro.causa"
              name="dadosSinistro.causa"
              label="Causa *"
              placeHolder="Selecione a causa"
              value={causa}
              options={optionCausa}
              formikBag={formikBag}
            />
            <PessoaSinistradaInput
              isEventoMorte={isEventoMorte}
              pessoa={pessoa}
              selectInputOptionsPessoa={selectInputOptionsPessoa}
              formikBag={formikBag}
            />
          </FormTreeColumnsGeneric>

          {/* MORTE */}
          <ConjugeFilhoInput
            isEventoMorte={isEventoMorte}
            isPessoaConjugeOuFilho={isPessoaConjugeOuFilho}
            labelNome={labelNome}
            LabelCPF={LabelCPF}
            LabelDataNascimento={LabelDataNascimento}
            nomeConjugeFilho={nomeConjugeFilho}
            cpfConjugeFilho={cpfConjugeFilho}
            dthNascimentoConjugeFilho={dthNascimentoConjugeFilho}
            formikBag={formikBag}
          />

          {isEventoMorte && isCausaAcidentall && (
            <FormTwoColumnsGeneric>
              <GenericTextInput
                controlId="formSinistro.dadosSinistro.dthSinistro"
                name="dadosSinistro.dthSinistro"
                label="Qual a data do sinistro? *"
                placeHolder="Digite a data do sinistro"
                value={dthSinistro}
                typeField="date"
                length="10"
                formikBag={formikBag}
              />
              <GenericTextInput
                controlId="formSinistro.dadosSinistro.dthAcidente"
                name="dadosSinistro.dthAcidente"
                label="Qual a data do acidente? *"
                placeHolder="Digite a data do acidente"
                value={dthAcidente}
                typeField="date"
                length="10"
                formikBag={formikBag}
              />
            </FormTwoColumnsGeneric>
          )}
          {isEventoMorte && isCausaNatural && (
            <FormTwoColumnsGeneric>
              <GenericTextInput
                controlId="formSinistro.dadosSinistro.dthSinistro"
                name="dadosSinistro.dthSinistro"
                label="Qual a data do sinistro *"
                placeHolder="Digite a data do sinistro"
                value={dthSinistro}
                typeField="date"
                length="10"
                formikBag={formikBag}
              />
            </FormTwoColumnsGeneric>
          )}

          {/* INVALIDEZ, AFASTAMENTO MÉDICO e DIÁRIA DE INTERNAÇÃO */}
          {isEventoInvalidezAfastamentoMedicoDiariaInternacao &&
            isCausaAcidentall && (
              <FormTwoColumnsGeneric>
                <GenericTextInput
                  controlId="formSinistro.dadosSinistro.dthSinistro"
                  name="dadosSinistro.dthSinistro"
                  label="Qual a data do sinistro *"
                  placeHolder="Digite a data do sinistro"
                  value={dthSinistro}
                  typeField="date"
                  length="10"
                  formikBag={formikBag}
                />
                <GenericTextInput
                  controlId="formSinistro.dadosSinistro.dthAcidente"
                  name="dadosSinistro.dthAcidente"
                  label="Qual a data do acidente? *"
                  placeHolder="Digite a data do acidente"
                  value={dthAcidente}
                  typeField="date"
                  length="10"
                  formikBag={formikBag}
                />
              </FormTwoColumnsGeneric>
            )}
          {isEventoInvalidezAfastamentoMedicoDiariaInternacao && isCausaDoenca && (
            <FormTwoColumnsGeneric>
              <GenericTextInput
                controlId="formSinistro.dadosSinistro.dthSinistro"
                name="dadosSinistro.dthSinistro"
                label="Qual a data do sinistro *"
                placeHolder="Digite a data do sinistro"
                value={dthSinistro}
                typeField="date"
                length="10"
                formikBag={formikBag}
              />
            </FormTwoColumnsGeneric>
          )}

          {/* DIAGNÓSTICO DE DOENÇA GRAVE */}
          <EventoDiagnosticoDoencaGrave
            isEventoDiagnosticoDoencaGravePerdaEmpregoReembolsoGravidez={
              isEventoDiagnosticoDoencaGravePerdaEmpregoReembolsoGravidez
            }
            isEventoGravidez={isEventoGravidez}
            dthSinistro={dthSinistro}
            formikBag={formikBag}
          />

          <FormOneColumnGeneric>
            <GenericTextareaInput
              controlId="formSinistro.dadosSinistro.historicoSinistro"
              name="dadosSinistro.historicoSinistro"
              label="Histórico do sinistro (BREVE RELATO) *"
              placeholder="Por favor, escreva um resumo do que aconteceu."
              value={historicoSinistro}
              rows="10"
              formikBag={formikBag}
            />
          </FormOneColumnGeneric>

          <div id="recaptcha">
            <ReCaptchaControl
              size="normal"
              label="ReCaptcha *"
              {...formikBag}
            />
          </div>
          {renderErrorMessage(errors, touched, errors.reCaptcha)}

          <MarginBase />
          <SubmitButton
            isLoading={isLoadingSinistro}
            label="Abrir comunicado de sinistro"
            exibirSemProduto={exibirSemProduto}
            falhaFluxo={falhaFluxo}
            formikBag={formikBag}
          />

          {renderErrorMessage(errors, touched, messageErro)}
          <SpinnerLoading isLoading={isSubmitting} />
        </>
      )}
      <Mensagens
        errorSinistro={errorSinistro}
        exibirSemProduto={exibirSemProduto}
      />

      <MarginTop />
    </>
  );
};

FormularioSinistro.defaultProps = {
  errorSinistro: false,
  isLoadingSinistro: false,
  exibirSucesso: false,
  numeroProtocolo: '',
  falhaFluxo: false,
};

FormularioSinistro.propTypes = {
  values: PropTypes.objectOf(Object).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(Array).isRequired,
  dirty: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoadingSinistro: PropTypes.bool,
  errorSinistro: PropTypes.bool,
  exibirSucesso: PropTypes.bool,
  numeroProtocolo: PropTypes.number,
  falhaFluxo: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};

const MarginTop = styled.div`
  margin-top: 50px;
`;
const MarginBase = styled.div`
  margin-top: 20px;
`;

export default FormularioSinistro;
