/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { CharactersLeft } from "../styles";
import { TextAreaControl } from "../../../../../components/shared/forms";

const GenericTextareaInput = ({ id, name, label, placeholder, value, rows, formikBag }) => {
  const handlePedido = (e) => {
    if (e.target.value.length <= 5000) {
      formikBag.onChange(e);
    }
  };
  return (
    <>
      <TextAreaControl
        style={{ maxWidth: "920px", marginBottom: 0 }}
        controlId={id}
        name={name}
        label={label}
        placeHolder={placeholder}
        value={value}
        rows={rows}
        {...formikBag}
        onChange={handlePedido}
      />
      <CharactersLeft>
        {5000 - value.length} caracteres restantes
      </CharactersLeft>
    </>
  );
};

GenericTextareaInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default GenericTextareaInput;
