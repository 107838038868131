import amparoBanner from "./banner-bg-amparo.jpg";
import amparoImagemApresentacao from "./amparo-imagem-apresentacao.svg";
import amparoOqueEstaIncluso from "./amparo-o-que-esta-incluso.svg";
import amparoImagemVerMais from "./amparo-imagem-ver-mais.svg";

const imagensAmparo = {
  amparoBanner,
  amparoImagemApresentacao,
  amparoOqueEstaIncluso,
  amparoImagemVerMais,
};

export default imagensAmparo;
