import envs from "../config/envs";

export const generateBase64 = (file, setAnexo) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setAnexo({
      nome: file.name,
      conteudo: reader.result,
      tamanho: file.size,
    });
  };
};
export const acceptedFiles = envs.acceptedFiles.split(", ");

export const validateFile = (file, setError) => {
  let isValid = true;
  const ext = `.${/[^.]+$/.exec(file.name)[0]?.toUpperCase()}`;
  if (file.size > 600000) {
    setError(
      "Você anexou um arquivo maior que 600kb. Por favor, redimensione o arquivo."
    );
    isValid = false;
  } else if (!acceptedFiles.includes(ext)) {
    setError(
      `Você anexou um arquivo com a extensão diferente de: ${envs.acceptedFiles}.`
    );
    isValid = false;
  }
  return isValid;
};
