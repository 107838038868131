import React from 'react';
import { PropTypes } from 'prop-types';
import Content from '../../../components/blocosCustomizados/Content';

export const ApresentacaoAcoesPromocionais = ({ sectionTitle }) => {
  return (
    <Content title={sectionTitle} className="mb-4">
      <>Selecione a ação promocional ou a campanha e confira o regulamento.</>
    </Content>
  );
};

ApresentacaoAcoesPromocionais.defaultProps = {};

ApresentacaoAcoesPromocionais.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoAcoesPromocionais;
