import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import useStaticRequest from "./useStaticRequest";

/**
 * This service hook will fetch data from a remote location containing information
 *  on which promotional items to display for use in product pages.
 *
 * @component
 * @return  {UseCampanhaHook}
 *
 * @typedef    {Object}    UseCampanhaHook
 *  @property  {function}  getCampanhas        Function used for API call
 *  @property  {Array}     campanhas            Array containing promotion data from response
 *  @property  {number}    status              HTTP status code
 *  @property  {Object}    data                Raw response data
 *  @property  {boolean}   loading             Loading indicator
 *  @property  {boolean}   error               Error indicator
 */
const useCampanhas = () => {
  const { performStaticRequest, error } = useStaticRequest();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const [campanhas, setCampanhas] = useState([]);

  /**
   * Fetches promotion data from URL
   * @param    {string}    initialProduct    Product key for data lookup
   */
  const getCampanhas = (initialProduct) => {
    setLoading(true);
    let response;
    if(initialProduct === "vida"){
      response = performStaticRequest(endPoints.getCampanhasEndPoint);
    }
    else if (initialProduct === "previdencia"){
      response = performStaticRequest(endPoints.getRegulamentosAcoesPromocionaisEndPoint);
    }
    response.then((responseData) => {
      setStatus(responseData?.status);
      setData(responseData?.data);
      setCampanhas(
        (Array.isArray(responseData?.data) ? responseData?.data : []).filter(
          ({ produto }) => initialProduct === produto
        )
      );
      setLoading(false);
    });
  };

  return {
    getCampanhas,
    campanhas,
    status,
    data,
    loading,
    error,
  };
};

useCampanhas.propTypes = {
  getCampanhas: PropTypes.func,
  campanhas: PropTypes.arrayOf(Object),
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useCampanhas;
