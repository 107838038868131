import { useState, useEffect } from 'react';

const TimerState = {
  STOPPED: 'stopped',
  RUNNING: 'running',
};

const formatTimer = timer => {
  const minutes = `00${Math.floor(timer / 60)}`.slice(-2);
  const seconds = `00${timer % 60}`.slice(-2);

  return `${minutes}:${seconds}`;
};

const useCountDown = initialTime => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timerState, setTimerState] = useState(TimerState.STOPPED);
  const [timerInstance, setTimerInstance] = useState();

  useEffect(() => {
    if (timerState === TimerState.RUNNING) {
      setTimerInstance(
        setTimeout(() => {
          if (timeRemaining === 0) {
            setTimerState(TimerState.STOPPED);
            return;
          }

          if (timerState === TimerState.RUNNING) {
            setTimeRemaining(Math.max(timeRemaining - 1, 0));
            return;
          }

          if (timerState === TimerState.RESTART) {
            setTimeRemaining(initialTime * 60);
            setTimerState(TimerState.RUNNING);
          }
        }, 1000)
      );
    }
  }, [timeRemaining, timerState]);

  const startTimer = () => {
    clearTimeout(timerInstance);
    setTimeRemaining(initialTime * 60);
    setTimerState(TimerState.RUNNING);
  };

  const stopTimer = () => {
    setTimerState(TimerState.STOPPED);
  };

  const clearTimer = () => {
    setTimeRemaining(0);
  };

  const timer = formatTimer(timeRemaining);
  return { timer, startTimer, stopTimer, clearTimer };
};

export default useCountDown;
