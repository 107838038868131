const ND = 'Não Disparar';
const causaAcidental = 'Acidental';
const causaCancer = 'Cancer';
const causaDespesasMedicasHospitalares = 'DespesasMedicasHospitalares';
const causaDoenca = 'Doenca';
const causaDoencaProfissional = 'DoencaProfissional';
const causaGamelarNatural = 'GamelarNatural';
const causaNatural = 'Natural';
const causaOutrasDoencas = 'OutrasDoencas';
const causaSemJustaCausa = 'SemJustaCausa';
const tipoPessoaFisica = 'PessoaFisica';
const eventoAfastamentoMedico = 'AfastamentoMedico';

const eventoDiariaInternacao = 'DiariaInternacao';

const eventoDiagnosticoDoencaGrave = 'DiagnosticoDoencaGrave';
const eventoGravidez = 'Gravidez';
const eventoInvalidez = 'Invalidez';
const eventoMorte = 'Morte';
const eventoPerdaEmprego = 'PerdaEmprego';
const eventoReembolso = 'Reembolso';
const pessoaConjuge = 'Conjuge';
const pessoaFilho = 'Filho';

const documentMap = {
  [`${eventoMorte}-${causaNatural}-${pessoaConjuge}`]: 'DocCKL43',
  [`${eventoMorte}-${causaNatural}-${pessoaFilho}`]: 'DocCKL45',
  [`${eventoMorte}-${causaAcidental}-${pessoaConjuge}`]: 'DocCKL50',
  [`${eventoMorte}-${causaAcidental}-${pessoaFilho}`]: 'DocCKL52',
  [`${eventoInvalidez}-${causaAcidental}`]: 'DocCKL53',
  [`${eventoInvalidez}-${causaDoenca}`]: 'DocCKL61',
  [`${eventoDiagnosticoDoencaGrave}-${causaCancer}`]: 'DocCKL66',
  [`${eventoDiagnosticoDoencaGrave}-${causaOutrasDoencas}`]: 'DocCKL20',
  [`${eventoDiagnosticoDoencaGrave}-${causaDoencaProfissional}`]: 'DocCKL59',
  [`${eventoPerdaEmprego}-${causaSemJustaCausa}`]: 'DocCKL67',
  [`${eventoReembolso}-${causaDespesasMedicasHospitalares}`]: 'DocCKL77',
  [`${eventoAfastamentoMedico}-${causaDoenca}`]: 'DocCKL79',
  [`${eventoAfastamentoMedico}-${causaAcidental}`]: 'DocCKL79',
  [`${eventoDiariaInternacao}-${causaDoenca}`]: ND,
  [`${eventoDiariaInternacao}-${causaAcidental}`]: 'DocCKL78',
  [`${eventoGravidez}-${causaGamelarNatural}`]: 'DocFOP359',
};

const ReturnDocumentoPF = (tipoPessoa, evento, causa, pessoa) => {
  if (tipoPessoa !== tipoPessoaFisica) {
    return ND;
  }

  return (
    documentMap[`${evento}-${causa}-${pessoa}`] ||
    documentMap[`${evento}-${causa}`] ||
    ND
  );
};

export default ReturnDocumentoPF;
