import { useState } from 'react';
import axios from 'axios';

export const POST = "post";

/**
 * Higher order component used for calling API endpoints from Gateway de Integracao (GI).
 * This should not be used outside service hooks.
 *
 * @component
 * @return  {UseGiRequestHook}
 *
 * @typedef   {Object}    UseGiRequestHook
 * @property  {function}  performGiRequest    Function used for API call
 * @property  {boolean}   error               Error indicator
 */
const useGiRequest = () => {
  const [errorGi, setErrorGi] = useState(false);
  /**
   * Performs actual request. Adds interceptors for access token handling.
   * @param   {Object}   requestConfig    Request configuration (axios)
   * @return  {Object}                    Raw response data for success and failure scenarios
   */
  const performGiRequest = async (requestConfig, userName) => {
    let axiosResponse = null;
    try {
      const axiosInstance = axios.create();
      axiosInstance.interceptors.request.use(
        async (config) => {
          // eslint-disable-next-line no-param-reassign
          config.data = {
            UserName: userName,
            ...config.data,
          };
          return config;
        },
        (err) => Promise.reject(err)
      );
      const instanceResponse = await axiosInstance.request({
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          "Content-type": "application/json",
        },
      });
      setErrorGi(false);
      axiosResponse = instanceResponse;
    } catch (err) {
      setErrorGi(true);
      axiosResponse = err.response;
    }
    return axiosResponse;
  };

  return { performGiRequest, errorGi };
};

export default useGiRequest;
