/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReCaptchaControl } from "../../../../components/shared/forms";
import { Button } from "../../../../components/shared/actions";
import {
  ContactDataFormTitle,
  FormRowAddress2,
  FormRowNameAndCpf,
  FormRowPhoneAndEmail,
  WarningParagraph,
  CEPContainer,
  SubmitButton,
} from "./styles";
import { cepMask, readProperty } from "../../../../utils";
import { useCEP, useProtocol } from "../../../../hooks";
import { OuvidoriaApiError, ProtocolStatusError } from "..";
import {
  AddressComplementInput,
  AddressInput,
  AddressNumberInput,
  AddressNeighborhoodInput,
  CepInput,
  CityInput,
  CpfCnpjInput,
  DetailsInput,
  EmailInput,
  OuvidoriaFileInput,
  NameInput,
  PhoneInput,
  StateSelect,
  ProtocolInput,
  ProductSelect,
} from "./FormInputs";
import SpinnerLoading from "../../../../components/shared/layout/SpinnerLoading";
import { handleCepData } from "../../../sinistro/sections/FormularioSinistro/utils";

const CEP_INPUT_NAME = "ouvidoria.contato.cep";
const TAMANHO_MAXIMO_PROTOCOLO = 20;
const TAMANHO_MINIMO_PROTOCOLO = 4;

const FormularioOuvidoria = ({
  isLoadingOuvidoria,
  isLoadingProductActive,
  productExists,
  errorOuvidoria,
  setAnexo,
  anexo,
  values: {
    produto,
    protocolo,
    ouvidoria: {
      nome,
      cpfCnpj,
      pedido,
      contato: {
        telefone,
        email,
        cep,
        endereco,
        numero,
        bairro,
        complemento,
        estado,
        cidade,
      },
    },
  },
  handleBlur,
  handleChange,
  errors,
  dirty,
  touched,
  isSubmitting,
  isValid,
  setFieldValue,
  setFieldError,
  setValues,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    dirty,
    touched,
    isSubmitting,
    isValid,
    setFieldValue,
    setFieldError,
    setValues,
  };
  const {
    checkProtocol,
    isProtocolFound,
    isProtocolValid,
    canUseProtocolForOmbudsman,
    status: protocolStatus,
    loading: loadingProtocol,
    error: errorProtocol,
  } = useProtocol(); 

  const showForm =
    produto &&
    !loadingProtocol &&
    !errorProtocol &&
    isProtocolValid &&
    isProtocolFound &&
    canUseProtocolForOmbudsman;
  const hasGeneralError = errorProtocol && protocolStatus >= 500;


  const { getAddress, foundCep, data: dataCep, loading: loadingCep,  isCepUnico } = useCEP();


  const setValuesCep = (dataCEP) => (prevState) => ({
    ...prevState,
    ouvidoria: {
      ...prevState.ouvidoria,
      contato: {
        ...prevState.ouvidoria.contato,
        endereco: dataCEP?.dados.INDTPLOG ?? "",
        estado: dataCEP?.dados.CODUF ?? "",
        cidade: dataCEP?.dados.NOMLOC ?? "",
        bairro: dataCEP?.dados.NOMEBAIR ?? "",
      },
    },
  });
  const [displayError, setDisplayError] = useState();
  const ConditionSubmitButton = () => {
    return (
      <>
        {isLoadingProductActive ||
        productExists === false ? (
          <>
            <SubmitButton
              label="Abrir ocorrência"
              isLoading={isLoadingProductActive}
              isValid={false}
            />
            <SpinnerLoading isLoading={isLoadingProductActive} />
          </>
        ) : (
          <>
            <SubmitButton
              isLoading={isLoadingOuvidoria}
              label="Abrir ocorrência"
              {...formikBag}
            />
            <SpinnerLoading isLoading={isLoadingOuvidoria} />
          </>
        )}
      </>
    );
  };


  useEffect(() => {
    if (errorOuvidoria){
      setDisplayError(true);
    }
    handleCepData(
      loadingCep,
      dataCep,
      foundCep,
      setValues,
      setValuesCep,
      formikBag,
      CEP_INPUT_NAME
    );
  }, [loadingCep, dataCep, errorOuvidoria]);

  return (
    <>
      <ProductSelect
        produto={produto}
        isLoadingProtocol={loadingProtocol}
        formikBag={formikBag}
      />
      {produto && (
        <>
          <ProtocolInput
            protocolo={protocolo}
            isLoadingProtocol={loadingProtocol}
            formikBag={formikBag}
            maxLength={TAMANHO_MAXIMO_PROTOCOLO}
            minLength={TAMANHO_MINIMO_PROTOCOLO}
          />
          <ProtocolStatusError
            isLoadingProtocol={loadingProtocol}
            errorProtocol={errorProtocol}
            isProtocolValid={isProtocolValid}
            isProtocolFound={isProtocolFound}
            hasGeneralError={hasGeneralError}
            canUseProtocolForOmbudsman={canUseProtocolForOmbudsman}
          />
          <Button
            onClick={async () => checkProtocol(protocolo, produto)}
            disabled={
              loadingProtocol ||
              protocolo.length < TAMANHO_MINIMO_PROTOCOLO ||
              protocolo.length > TAMANHO_MAXIMO_PROTOCOLO
            }
          >
            Verificar protocolo
          </Button>
        </>
      )}

      <SpinnerLoading isLoading={loadingProtocol} />
      {hasGeneralError && <OuvidoriaApiError />}
      {showForm && (
        <>
          <WarningParagraph>
            ATENÇÃO: Os dados pessoais solicitados aqui serão utilizados única e
            exclusivamente para a análise e devolutiva da sua ocorrência de
            ouvidoria.
          </WarningParagraph>
          <FormRowNameAndCpf>
            <NameInput nome={nome} formikBag={formikBag} />
            <CpfCnpjInput cpfCnpj={cpfCnpj} formikBag={formikBag} />
          </FormRowNameAndCpf>
          <DetailsInput pedido={pedido} formikBag={formikBag} />
          <OuvidoriaFileInput
            setAnexo={setAnexo}
            name="ouvidoria.anexo"
            anexo={anexo}
          />
          <ContactDataFormTitle>
            Para resolver a sua solicitação, é importante preencher todos os
            dados abaixo:
          </ContactDataFormTitle>
          <FormRowPhoneAndEmail>
            <PhoneInput telefone={telefone} formikBag={formikBag} />
            <EmailInput email={email} formikBag={formikBag} />
          </FormRowPhoneAndEmail>
          <CEPContainer>
            <CepInput
              cep={cep}
              disabled={loadingCep}
              onChange={(e) => {
                e.target.value = cepMask(e.target.value);
                formikBag.onChange(e);
              }}
              onBlur={async (e) => {
                formikBag.onBlur(e);
                if (!readProperty(formikBag.errors, CEP_INPUT_NAME)) {
                  await getAddress(cep);
                }
              }}
              formikBag={formikBag}
            />
            <SpinnerLoading isLoading={loadingCep} />
          </CEPContainer>
          <FormRowAddress2>
            <AddressInput
              endereco={endereco}
              disabled={loadingCep || (foundCep && !isCepUnico)}
              formikBag={formikBag}
            />
            <AddressNumberInput numero={numero} formikBag={formikBag} />
            <AddressNeighborhoodInput
              bairro={bairro}
              disabled={loadingCep || foundCep}
              formikBag={formikBag}
            />
          </FormRowAddress2>
          <FormRowAddress2>
            <AddressComplementInput
              complemento={complemento}
              formikBag={formikBag}
            />
            <StateSelect
              estado={estado}
              disabled={loadingCep || foundCep}
              formikBag={formikBag}
            />
            <CityInput
              cidade={cidade}
              disabled={loadingCep || foundCep}
              formikBag={formikBag}
            />
          </FormRowAddress2>
          <ReCaptchaControl size="normal" {...formikBag} />
          {ConditionSubmitButton()}
        </>
      )}
      {displayError && <OuvidoriaApiError />}
    </>
  );
};

FormularioOuvidoria.defaultProps = {
  errorOuvidoria: false,
  isLoadingOuvidoria: false,
};

FormularioOuvidoria.propTypes = {
  values: PropTypes.objectOf(Object).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(Array).isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoadingOuvidoria: PropTypes.bool,
  errorOuvidoria: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setAnexo: PropTypes.func.isRequired,
  anexo: PropTypes.objectOf(Object).isRequired,
};

export default FormularioOuvidoria;
