import React from "react";
import { Section } from "@components/shared/pages";

const FooterSection = ({ children, className }) => (
  <>
    <Section className={`footer-section ${className}`}>{children}</Section>
  </>
);

FooterSection.propTypes = {
  ...Section.propTypes,
};

export default FooterSection;
