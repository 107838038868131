import { isDate, parse } from "date-fns";
import { parseISO as dfParseISO } from "date-fns/esm";
import { envs } from "../config";

const defaultPatter = "dd/MM/yyyy";

export const parseDateString = (strValue, pattern) => {
  if (!strValue) {
    return null;
  }
  return isDate(strValue)
    ? strValue
    : parse(strValue, pattern || defaultPatter, new Date());
};

export const parseStringDate = (date = new Date()) => {
  return isDate(date)
    ? date.toLocaleDateString(envs.locale, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : null;
};

export const yupTransformDateString = (...[, originalValue]) => {
  return parseDateString(originalValue);
};

export const parseISO = (data) => {
  if (!data) {
    return null;
  }
  return dfParseISO(data);
};
