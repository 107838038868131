/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const GenericTextInput = ({
  id,
  name,
  label,
  placeHolder,
  value,
  length,
  typeField,
  disabled,
  formikBag,
}) => {
  let formFieldType = "";

  const maskField = (fieldValue, type) => {
    let fieldFormatted = "";

    switch (type) {
      case "cpf":
        formFieldType = "text";
        fieldFormatted = fieldValue
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2");
        break;

      case "cnpj":
        formFieldType = "text";
        fieldFormatted = fieldValue
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d)/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
        break;

      case "date":
        formFieldType = "text";
        fieldFormatted = fieldValue
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d{1,2})/, "$1/$2");
        break;
        
      default:
        formFieldType = "text";
        fieldFormatted = fieldValue;
    }

    return fieldFormatted;
  };

  const handleField = (e) => {
    const maskedValue = e.target.value
      .replaceAll(".", "")
      .replace("-", "")
      .replace("/", "");
    e.target.value = maskField(maskedValue, typeField);
    formikBag.onChange(e);
  };

  return (
    <InputControl
      inputStyle={{ minHeight: "50px" }}
      controlId={id}
      name={name}
      label={label}
      placeHolder={placeHolder}
      value={value}
      type={formFieldType}
      disabled={disabled}
      maxLength={length}
      {...formikBag}
      onChange={handleField}
    />
  );
};

GenericTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  typeField: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default GenericTextInput;
