import React, { useState, useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensPrevidencia from "@asserts/images/ouvidoria";
import {
  BannerOuvidoria,
  OuvidoriaContato,
  AbrirOuvidoria,
  OuvidoriaSucesso,
} from '../sections';
import { Page, Section } from '../../../components/shared/pages';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';

export const HomePage = (props) => {
  const [newProtocol, setNewProtocol] = useState('');
  const [newProtocolDate, setNewProtocolDate] = useState(null);
  const [errorOuvidoria, setErrorOuvidoria] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackOuvidoria = useTrackEvent(appInsights, "AA_Ouvidoria");

  useEffect(() => {
    const ouvidoriaEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Ouvidoria",
      virtualPageURL: "/ouvidoria",
    };
    trackOuvidoria(ouvidoriaEvent);
  }, [trackOuvidoria]);

  return (
    <Page {...props} images={imagensPrevidencia} banner={BannerOuvidoria}>
      <SeguridadePageLayout.Tab eventKey="ouvidoria">
        {!newProtocol && (
          <>
            <Section
              id="ouvidoria-abertura-chamado"
              as={AbrirOuvidoria}
              title="Abra aqui a sua ocorrência para a Ouvidoria"
              sectionLayout={null}
              setNewProtocol={setNewProtocol}
              setNewProtocolDate={setNewProtocolDate}
              setErrorOuvidoria={setErrorOuvidoria}
            />
            <Section
              id="ouvidoria-contato"
              as={OuvidoriaContato}
              title="Se preferir, você pode abrir uma ocorrência para a Ouvidoria por telefone:"
              sectionName="solicitar protocolo"
            />
          </>
        )}
        {newProtocol && !errorOuvidoria && (
          <Section
            id="ouvidoria-abertura-sucesso"
            as={OuvidoriaSucesso}
            title=""
            sectionLayout={null}
            newProtocol={newProtocol}
            newProtocolDate={newProtocolDate}
          />
        )}
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
