import "./style.css";
import React from "react";
import { useSegmentRoute } from "@hooks";
import AreaRoute from "../../components/shared/routes";
import HomePage from "./pages";
import { envs } from "../../config";

export const Prestamista = () => {
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
  ]);
  return (
    <AreaRoute
      {...routerContext}
      title="Seguro Dívida Zero"
      chatId={envs.prestamistaChatId}
    />
  );
};

export default Prestamista;
