/* eslint-disable import/prefer-default-export */
import {
  blue,
  blueHover,
  orange,
  graySecondaryDark,
  grayDividerSecondary,
  grayDividerLighter,
  grayDisabled,
  orangeLight,
  grayBorder,
  red,
  white,
  transparent,
  orangeHover,
  blueSecondaryLight,
  grayInformativeText,
  orangeDark,
  bluePrimaryLight,
  graySecondary,
  bluePrimaryDark,
  orangeShiny,
  orangeGradientLight,
  grayTextSecondaryDark,
  grayTextSecondaryLight,
  grayDividerLight,
  grayIcon,
  blueBannerLight,
  bluePrimary,
} from "./colors";

const buttonTheme = {
  buttonBgColor: transparent,
  buttonBgColorLight: white,
  buttonBgColorInverse: blue,
  buttonBgColorInverseLight: blueSecondaryLight,
  buttonBgHover: blueHover,
  buttonBgDisabled: grayDisabled,
  buttonTextColor: blue,
  buttonTextColorInverse: white,
  buttonTextColorHoverInverse: graySecondaryDark,
  buttonTextColorHover: blueHover,
  buttonTextColorDisabled: white,
  buttonBorderColor: blue,
  buttonBorderHoverColor: blueHover,
  buttonBorderColorDisabled: white,
  buttonArrowColor: blue,
  buttonArrowColorInverse: white,
  buttonIconColor: orange,
  buttonIconColorInverse: graySecondaryDark,
  buttonIconColorHover: orangeHover,
};

const tabsTheme = {
  itemTabColor: bluePrimary,
  borderTabColor: bluePrimary,
  iconTabColor: bluePrimary,
};

const modalTheme = {
  modalBgColor: transparent,
  modalTitleColor: graySecondaryDark,
  modalOverlayColcor: white,
};

const inputTheme = {
  inputArrowColor: blue,
  validInputBorder: grayBorder,
  inputTextColor: blue,
};
const placeholderTheme = {
  placeholderColor: graySecondaryDark,
  placeholderActiveColor: graySecondaryDark,
  placeholderDisabledColor: graySecondary,
};
const BannerTheme = {
  bannerTextColor: white,
  bannerBorderColor: orangeDark,
  bannerBgColor: orangeHover,
  inlineBannerBgColor: orangeHover,
};

const selectTheme = {
  selectTextColor: blue,
};

const chatTheme = {
  chatBgColor: orange,
  chatTextColor: white,
};

const footerTheme = {
  footerBgColor: bluePrimaryDark,
};
const navigationInfoTheme = {
  navigationInfoButtonBgColorDefault: white,
  navigationInfoButtonBgColorCurrent: orangeShiny,
  navigationInfoButtonBgColorChecked: blue,
  navigationInfoButtonTextColorDefault: blue,
  navigationInfoButtonTextColorCurrent: white,
  navigationInfoButtonTextColorChecked: white,
  navigationInfoBgColor: white,
};
export const themes = {
  default: {
    ...buttonTheme,
    ...modalTheme,
    ...BannerTheme,
    ...inputTheme,
    ...selectTheme,
    ...chatTheme,
    ...placeholderTheme,
    ...footerTheme,
    ...navigationInfoTheme,
    ...tabsTheme,
    colorText: grayTextSecondaryDark,
    colorTextLight: grayTextSecondaryLight,
    smallPrintColor: grayInformativeText,
    primaryBgColor: white,
    invalidColor: red,
    dividerColor: grayDividerSecondary,
    radioIconColor: blue,
    iconColor: grayIcon,
  },
  prevTheme: {
    primaryColor: blue,
    icon: "prev",
  },
  lifeTheme: {
    primaryColor: white,
    secondaryColor: orangeShiny,
    thirdColor: orangeGradientLight,
    dividerColor: grayDividerLight,
    bannerBgColor: blueBannerLight,
    bannerTextColor: bluePrimary,
    editIconColor: bluePrimary,
    deleteIconColor: red,
    borderColor: bluePrimary,
    buttonPrimaryColor: bluePrimary,
    buttonBlankColor: white,
  },
  capTheme: {
    primaryColor: orange,
    secondaryColor: orangeLight,
    hoverColor: orangeHover,
    borderColor: grayDividerLighter,
    divider: grayDividerSecondary,
    icon: "cap",
  },
  ibcTheme: {
    primaryColor: bluePrimaryLight,
    secondaryColor: bluePrimaryLight,
    bannerBorderColor: bluePrimaryLight,
    inlineBannerBgColor: bluePrimaryLight,
  },
};
