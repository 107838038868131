/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormularioAcompanhamento } from "./FormularioAcompanhamento";
import { SinistroContext } from "../../../context";
import AcompanhamentoSucesso from "./AcompanhamentoSucesso";

const Acompanhamento = ({ sectionTitle }) => {
  const [stepUpload, setStepUpload] = useState(0);

  return (
    <SinistroContext.Provider
      value={{
        stepUpload,
        setStepUpload,
      }}
    >
      <Container>
        {stepUpload === 0 && (
          <>
            <Title>{sectionTitle}</Title>
            <Subtitle>
              Ao desvincular o CPF do segurado do CPF do beneficiário / herdeiro
              / consultante, o cadastro será <b>desativado</b>.
              <br /> Para fazer consultas a processo de sinistro, será
              necessário efetuar um <b>novo cadastro</b>.
            </Subtitle>
            <FormularioAcompanhamento />
          </>
        )}
        {stepUpload === 1 && (
          <>
            <AcompanhamentoSucesso />
          </>
        )}
      </Container>
    </SinistroContext.Provider>
  );
};

Acompanhamento.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding-bottom: 50px;
`;
const Title = styled.h3`
  margin-top: 60px;
  margin-bottom: 50px;
`;
const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;

export default Acompanhamento;
