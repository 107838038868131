import React from 'react';
import Apresentacao from '../../../components/blocosCustomizados/Apresentacao';
import { Section } from '../../../components/shared/pages';
import imagensSinistro from '../../../asserts/images/sinistro';
import { Link } from '../../../components/shared/actions';

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroAcompanharProcesso}
      title={sectionTitle}
      imageColumn={4}
      right
    >
      <p>
        A pessoa que é beneficiária / herdeira pode acompanhar as etapas do
        processo de sinistro aqui pelo site ou pelo nosso{' '}
        <strong>aplicativo Caixa Previdência,</strong> em qualquer dia e
        horário.
      </p>
      <p>
        Para isso, basta fazer um pequeno cadastro, que ficará ativo por três
        meses, no <strong>aplicativo da Caixa Previdência.</strong>
      </p>
      <p>
        Para se cadastrar basta ter em mãos o próprio CPF e o CPF do titular do
        seguro/plano de previdência.
      </p>
      <p>
        <Link blank href="/sinistro/login" label="Clique aqui" /> para
        acompanhar um processo de sinistro.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
