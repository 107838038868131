import React from 'react';
import { FormTwoColumnsGeneric } from './styles';
import { GenericTextInput } from './FormInputs';

const EventoDiagnosticoDoencaGrave = ({
  isEventoDiagnosticoDoencaGravePerdaEmpregoReembolsoGravidez,
  isEventoGravidez,
  dthSinistro,
  formikBag,
}) => {
  return (
    <>
      {isEventoDiagnosticoDoencaGravePerdaEmpregoReembolsoGravidez && (
        <FormTwoColumnsGeneric>
          <GenericTextInput
            controlId="formSinistro.dadosSinistro.dthSinistro"
            name="dadosSinistro.dthSinistro"
            label={
              isEventoGravidez
                ? 'Qual a data do parto *'
                : 'Qual a data do sinistro *'
            }
            placeHolder={
              isEventoGravidez
                ? 'Digite a data do parto'
                : 'Digite a data do sinistro'
            }
            value={dthSinistro}
            typeField="date"
            length="10"
            formikBag={formikBag}
          />
        </FormTwoColumnsGeneric>
      )}
    </>
  );
};

export default EventoDiagnosticoDoencaGrave;
