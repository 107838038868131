import React from "react";
import GeneralConditions from "../../../components/blocosCustomizados/GeneralConditions";
import produtos from "../data/produtos.json";

const CondicoesGeraisVida = () => {
  const subProdutosVida = produtos.map((produto) => ({
    ...produto,
  }));

  return (
    <GeneralConditions
      mainProduct="Vida"
      subProducts={subProdutosVida}
      title="Condições Gerais e Regulamentos"
    />
  );
};

export default CondicoesGeraisVida;
