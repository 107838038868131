import PlanejamentoSVG from "./planejamento.svg";
import MoneySVG from "./money.svg";
import BannerIvestidor from "./banner-prev-investidor.jpg";

const imagensPrevInvestidor = {
  PlanejamentoSVG,
  MoneySVG,
  BannerIvestidor,
};

export default imagensPrevInvestidor;
