import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@components/shared/pages';
import Campaign from '../../../components/blocosCustomizados/Campaign';
import { Link } from '../../../components/shared/actions';
import { LINKS } from '../../../constants';

const regulamento = 'o regulamento';

const conteudoCampanha = [
  {
    label: 'Campanha Dia dos Pais 2023',
    content: (
      <p>
        Durante o período de 11/08/2023 a 14/08/2023, disponibilizaremos 10% de
        desconto, para os clientes que contratarem os planos Mais e Especial nos
        produtos Vida da Gente, Vida Mulher, Vida Mulher Total, Vida
        Multipremiado, Vida Multipremiado Total e Vida Sênior por toda a
        vigência do seguro. Para mais informações, confira{' '}
        <Link href={LINKS.guiaMesDosPais} label="aqui" /> {regulamento}.
      </p>
    ),
    idCampanha: 'mes-dos-pais',
  },
  {
    label: 'Aniversário Caixa 2023',
    content: (
      <p>
        Durante o período de 09/01/2023 a 13/01/2023, disponibilizaremos 15% de
        desconto para os clientes que contratarem os produtos Vida da Gente,
        Vida Mulher, Vida Mulher Total, Vida Multipremiado Super, Vida
        Multipremiado Total e Vida Sênior por toda a vigência do seguro. Para
        mais informações, confira{' '}
        <Link href={LINKS.guiaAniversarioCaixa2023} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'aniversario-caixa-2023',
  },

  {
    label: 'Campanha Mês da Mulher 2023',
    content: (
      <p>
        Em homenagem às mulheres e em celebração ao Dia Internacional da Mulher,
        para as clientes que contratarem os Seguros Vida Mulher e Vida Mulher
        Total, entre 07/03 e 31/03/2022, terão o benefício adicional de
        consultas e exames na rede particular credenciada com preços reduzidos.
        Confira <Link href={LINKS.guiaMesDaMulher2023} label="aqui" />{' '}
        {regulamento}
      </p>
    ),
    idCampanha: 'mes-da-mulher',
  },

  {
    label: 'Quinzena do Consumidor 2023',
    content: (
      <p>
        Durante o período 13/03/2023 a 24/03/2023, disponibilizaremos 10% de
        desconto para os clientes que contratarem os planos Mais e Especial nos
        produtos Vida da Gente, Vida Mulher, Vida Mulher Total, Vida
        Multipremiado, Vida Multipremiado Total e Vida Sênior por toda a
        vigência do seguro. Para mais informações, confira{' '}
        <Link href={LINKS.guiaQuinzenaDoConsumidor2023} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'quinzena-do-consumidor',
  },

  {
    label: 'Campanha Blue Friday 2022',
    content: (
      <p>
        Durante o período de 21/11/2022 a 30/11/2022, disponibilizaremos 15% de
        desconto para os clientes que contratarem os planos Mais e Especial nos
        produtos Vida da Gente, Vida Mulher, Vida Mulher Total, Vida
        Multipremiado, Vida Multipremiado Total e Vida Sênior por toda a
        vigência do seguro. Para mais informações, confira
        <Link href={LINKS.guiaBlueFriday2022} label="aqui" /> {regulamento}.
      </p>
    ),
    idCampanha: 'blue-friday-2022',
  },
  {
    label: 'Campanha Blue Friday 2023',
    content: (
      <p>
        Durante o período de 13/11/2023 a 24/11/2023, disponibilizaremos 15% de
        desconto, para os clientes que contratarem novos certificados para os
        produtos Vida da Gente, Vida Mulher, Vida Mulher Total, Vida
        Multipremiado, Vida Multipremiado Total, Vida Sênior e Vida Empresarial
        Global por toda a vigência do seguro. Para mais informações, confira
        <Link href={LINKS.guiaBlueFriday} label="aqui" /> {regulamento}.
      </p>
    ),
    idCampanha: 'blue-friday',
  },
];

export const CampanhaPassada = ({ sectionTitle, campanhas }) => {
  return (
    <Campaign
      title={sectionTitle}
      contentCampaign={conteudoCampanha}
      campanhas={campanhas}
      right
    />
  );
};

CampanhaPassada.defaultProps = {
  campanhas: [],
};

CampanhaPassada.propTypes = {
  ...Section.specializedProps,
  campanhas: PropTypes.arrayOf(Object),
};

export default CampanhaPassada;
