/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Form from "../shared/forms/Form";
import { mapToOptions, parseStringDate, distinctArray } from "../../utils";
import { SelectControl } from "../shared/forms";
import DrawResultData from "./DrawResultData";

function getDistinctYears(response) {
  return distinctArray(response?.map((gc) => gc?.data?.getFullYear()))
    ?.sort()
    .reverse()
    .map((y, idx) => ({
      label: y.toString(),
      value: y.toString(),
      selected: idx === 0,
    }));
}

function getDistinctMonths(response, year) {
  const filterByYear = (gc) =>
    gc?.data?.getFullYear() === Number.parseInt(year, 10);

  return distinctArray(response?.filter(filterByYear)?.map(({ data }) => data))
    .map((m, idx) => ({
      value: parseStringDate(m),
      selected: idx === 0,
      label: m.toLocaleDateString("pt-br", {
        month: "2-digit",
        day: "2-digit",
      }),
    }))
    .reverse();
}

const DrawResultPanel = ({
  productList,
  loading,
  setSelectedProductCode,
  drawResults,
  defaultResultAs,
  defaultProductCode,
  displayResultLabel,
  title,
  values: { date, result },
  handleChange,
  handleBlur,
  submitForm,
  setFieldValue,
}) => {
  const [product, setProduct] = useState("");
  const [yearsOptions, setYearsOptions] = useState([]);
  const [dayMonthsOptions, setDayMonthsOptions] = useState([]);
  const [year, setYear] = useState("");
  const [resultAs, setResultAs] = useState(defaultResultAs);
  const [loadingResults, setLoadingResults] = useState(false);

  const isViewProducts = productList?.length > 0;
  const disableInputs = loading || loadingResults;

  function productChangeHandle(evt) {
    setLoadingResults(true);
    const selectedProduct = productList.find(
      (obj) => obj.value === evt.currentTarget?.value
    );
    setProduct(selectedProduct?.value ?? "");
    setResultAs(selectedProduct?.resultAs ?? defaultResultAs);
    setSelectedProductCode(selectedProduct?.plano ?? defaultProductCode);
    setLoadingResults(false);
  }

  useEffect(() => {
    const years = getDistinctYears(drawResults);
    if (!isViewProducts || (isViewProducts && product)) {
      setYear(years[0]?.value ?? "");
      setYearsOptions(years);
    }
    return () => {
      setYearsOptions([]);
      setYear("");
    };
  }, [drawResults, product]);

  useEffect(() => {
    const listDayMonth = getDistinctMonths(drawResults, year);

    setFieldValue("date", listDayMonth[0]?.value ?? "");
    setDayMonthsOptions(listDayMonth);

    return () => {
      setDayMonthsOptions([]);
    };
  }, [drawResults, product, year]);

  useEffect(() => {
    setFieldValue(
      "result",
      drawResults?.find((draw) => parseStringDate(draw.data) === date) || null
    );
  }, [drawResults, date]);

  useEffect(() => {
    if (result) {
      submitForm();
    }
  }, [result]);

  return (
    <>
      <div className="mx-0 my-3 cvp-draw-result-controls">
        {title ? <h6 className="text-center">{title}</h6> : null}
        <Row className="justify-content-center align-items-center">
          {isViewProducts ? (
            <Col xs={12} md={3}>
              <SelectControl
                controlId="formProduct"
                label="Produto"
                placeHolder="Selecione um produto"
                name="product"
                onChange={productChangeHandle}
                onBlur={handleBlur}
                value={product}
                options={mapToOptions(productList)}
                disabled={disableInputs}
              />
            </Col>
          ) : null}
          <Col xs={12} md={3}>
            <SelectControl
              controlId="formYears"
              label="Ano"
              name="year"
              onChange={(e) => setYear(e.currentTarget.value)}
              onBlur={handleBlur}
              value={year}
              options={mapToOptions(yearsOptions)}
              disabled={disableInputs}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectControl
              controlId="formDayMonths"
              label="Data"
              name="date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={date}
              options={mapToOptions(dayMonthsOptions)}
              disabled={disableInputs}
            />
          </Col>
        </Row>
        <DrawResultData
          isViewProducts={isViewProducts}
          displayResultLabel={displayResultLabel}
          loading={loading}
          loadingResults={loadingResults}
          result={result}
          resultAs={resultAs}
        />
      </div>
    </>
  );
};

DrawResultPanel.defaultProps = {
  ...Form.defaultProps,
  title: null,
  defaultProduct: null,
};

DrawResultPanel.propTypes = {
  ...Form.propTypes,
  productList: PropTypes.arrayOf(Object).isRequired,
  defaultProductCode: PropTypes.string,
  title: PropTypes.string,
  displayResultLabel: PropTypes.string.isRequired,
};
export default DrawResultPanel;
