import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Form, {
  RadioControl,
  SelectControl,
} from "../../../components/shared/forms";
import { distinctArray, mapToOptions } from "../../../utils";
import { Button, Link } from "../../../components/shared/actions";
import { envs } from "../../../config";
import ShowIf from "../../../components/shared/common/conditional";
import { useRegulamentos } from "../../../hooks";

const getConditionalArray = (items, condition, distinct = false) => {
  if (!Array.isArray(items) && !condition) {
    return [];
  }
  return distinct ? distinctArray(items) : items;
};

const filterinProdutoOptions = (tipoProduto) => ({
  produto: produtoKey,
  tipoProduto: tipoKey,
}) => produtoKey && tipoProduto === tipoKey;

const filteringModalidateOptions = (produto, tipoProduto) => ({
  produto: produtoKey,
  tipoProduto: tipoKey,
  modalidade: modalidadeKey,
}) => produtoKey === produto && tipoProduto === tipoKey && modalidadeKey;

const showFundos = (tipoProduto, produto, modalidade, coberturaRenda) =>
  (tipoProduto && produto && modalidade) || (!coberturaRenda && tipoProduto);

const RegulamentosForm = ({
  handleChange,
  handleBlur,
  setFieldValue,
  values,
  submitForm,
  errors,
  isValid,
  dirty,
  touched,
  isSubmitting,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    submitForm,
  };
  const { getRegulamentos, regulamentos } = useRegulamentos();
  useEffect(() => {
    getRegulamentos();
  }, []);

  const { cobertura, tipoProduto, produto, modalidade, fundo } = values;

  const coberturaOptions = useMemo(
    () =>
      getConditionalArray(
        regulamentos.map(({ cobertura: value }) => value),
        cobertura,
        true
      ).map((value) => ({ value, label: value })),
    [cobertura, regulamentos]
  );
  const coberturaRenda = useMemo(() => cobertura === "Renda", [cobertura]);
  const dataAdesaoOptions = useMemo(
    () =>
      getConditionalArray(
        regulamentos.map(({ tipoProduto: value }) => value),
        cobertura,
        true
      ),
    [regulamentos, cobertura]
  );
  const produtoOptions = useMemo(() => {
    return getConditionalArray(
      regulamentos
        .filter(filterinProdutoOptions(tipoProduto))
        .map(({ produto: value }) => value),
      tipoProduto && coberturaRenda,
      true
    );
  }, [regulamentos, tipoProduto, coberturaRenda]);
  const modalidadeOptions = useMemo(() => {
    return getConditionalArray(
      regulamentos
        .filter(filteringModalidateOptions(produto, tipoProduto))
        .map(({ modalidade: value }) => value),
      produto && coberturaRenda,
      true
    );
  }, [regulamentos, produto, tipoProduto, coberturaRenda]);

  const filtarCoberturaRenda = useCallback(() => {
    return regulamentos.filter(
      ({
        cobertura: coberturaKey,
        tipoProduto: tipoProdutoKey,
        produto: produtoKey,
        modalidade: modalidadeKey,
      }) =>
        modalidade &&
        coberturaKey === cobertura &&
        tipoProdutoKey === tipoProduto &&
        produtoKey === produto &&
        modalidadeKey === modalidade
    );
  }, [regulamentos, cobertura, tipoProduto, produto, modalidade]);
  const filtrarCuidadoExtra = useCallback(() => {
    return regulamentos.filter(
      ({ cobertura: coberturaKey, tipoProduto: tipoProdutoKey }) =>
        coberturaKey === cobertura && tipoProdutoKey === tipoProduto
    );
  }, [regulamentos, cobertura, tipoProduto]);

  const listFundos = useMemo(
    () => (coberturaRenda ? filtarCoberturaRenda() : filtrarCuidadoExtra()),
    [coberturaRenda, filtarCoberturaRenda, filtrarCuidadoExtra]
  );
  const fundosOptions = useMemo(
    () => listFundos.map(({ id: value, fundo: label }) => ({ label, value })),
    [listFundos]
  );
  const regulamentoUrl = useMemo(
    () => listFundos.find(({ id }) => fundo === id)?.url,
    [listFundos, fundo]
  );

  useEffect(() => {
    if (cobertura) {
      [
        { name: "tipoProduto", value: "" },
        { name: "produto", value: "" },
        { name: "modalidade", value: "" },
        { name: "fundo", value: "" },
      ].forEach(({ name, value }) => setFieldValue(name, value));
    }
  }, [cobertura, setFieldValue]);
  return (
    <>
      <Row>
        <Col className="text-center py-3">
          <RadioControl
            controlId="formGeneralConditions.cobertura"
            label="Cobertura"
            name="cobertura"
            value={cobertura}
            options={coberturaOptions}
            {...formikBag}
          />
        </Col>
      </Row>
      <Row className="p-0 p-sm-16">
        <ShowIf condition={cobertura}>
          <Col sm="12" md="4" lg="3">
            <SelectControl
              controlId="formGeneralConditions.tipoProduto"
              label="Data de Adesão"
              name="tipoProduto"
              value={tipoProduto}
              options={mapToOptions(dataAdesaoOptions)}
              {...formikBag}
            />
          </Col>
        </ShowIf>
        <ShowIf condition={tipoProduto && coberturaRenda}>
          <Col sm="12" md="4" lg="3">
            <SelectControl
              controlId="formGeneralConditions.produto"
              label="Produto"
              name="produto"
              value={produto}
              options={mapToOptions(produtoOptions)}
              {...formikBag}
            />
          </Col>
        </ShowIf>
        <ShowIf condition={coberturaRenda && produto && tipoProduto}>
          <Col sm="12" md="4" lg="3">
            <SelectControl
              controlId="formGeneralConditions.modalidade"
              label="Modalidade"
              name="modalidade"
              value={modalidade}
              options={mapToOptions(modalidadeOptions)}
              {...formikBag}
            />
          </Col>
        </ShowIf>
        <ShowIf condition={showFundos(tipoProduto, produto, modalidade, coberturaRenda)}>
          <Col sm="12" md="4" lg="3">
            <SelectControl
              controlId="formGeneralConditions.fundo"
              label={coberturaRenda ? "Fundos de investimentos" : "Cuidado Extra"}
              name="fundo"
              value={fundo}
              options={mapToOptions(fundosOptions)}
              {...formikBag}
            />
          </Col>
        </ShowIf>
      </Row>
      <ShowIf condition={regulamentoUrl}>
        <Row>
          <Col xs="12" className="text-right">
            <Button
              href={`${envs.hostStaticCdn}/areaaberta${regulamentoUrl}`}
              blank
              label="Gerar regulamento"
            />
          </Col>
          <Col>
            <p className="text-center mt-3">
              Caso você não consiga visualizar o arquivo em PDF, faça o download
              do Adobe Acrobat Reader{" "}
              <Link href="http://get.adobe.com/br/reader/" blank label="aqui" />.
            </p>
          </Col>
        </Row>
      </ShowIf>
    </>
  );
};

RegulamentosForm.propTypes = {
  ...Form.requiredProps,
};

export default RegulamentosForm;
