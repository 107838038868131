import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import { Button } from "../actions";
import { useMakeClass } from "../../../hooks";
import { VARIANT_COLORS } from "../../../constants";

const CardToggleButtonImage = (props) => {
  const {   
    eventKey,
    label,
    analitycsValue,
    iconShow,
    callback,
    variant,
    iconHide,       
  } = props;

  const currentEventKey = useContext(AccordionContext);

  const toggleRef = useRef(null);

  const clickHandle = useAccordionToggle(eventKey, () => {
    return callback && callback(eventKey);
  });

  const isCurrentEventKeyImage = currentEventKey === eventKey;

  const classNamesImages = useMakeClass(
    "accordion-link-header",
    "d-flex justify-content-between align-items-center text-left p-3",
    isCurrentEventKeyImage && "active"
  );

  return (
    <Button
      ref={toggleRef}
      transparent
      analitycsValue={analitycsValue}
      variant={variant}
      block
      onClick={clickHandle}
      className={classNamesImages}      
      label={label}     
    >
      {label}
      <FontAwesomeIcon
        className="ml-2 c-primary accordion-link-header-icon"
        icon={isCurrentEventKeyImage ? iconHide : iconShow}
      />
    </Button>
  );
};

CardToggleButtonImage.defaultProps = {
  callback: null,
  variant: "primary",
  analitycsValue: null,
};

CardToggleButtonImage.propTypes = {
  label: PropTypes.string.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  callback: PropTypes.func,
  iconShow: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  iconHide: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)])
    .isRequired,
  variant: PropTypes.oneOf([...VARIANT_COLORS, "seguridade-light"]),
  analitycsValue: PropTypes.string,
};

CardToggleButtonImage.propTypes = {};

export default CardToggleButtonImage;
