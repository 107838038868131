import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import useGiRequest, { POST } from "./useGiRequest";
import usePerformGiRequest from "./usePerformGiRequest";

/**
 * This service hook will call an API to validate whether a previous customer service request
 * can be used for ombudsman request creation
 *
 * @component
 * @return  {UseProtocolHook}
 *
 * @typedef    {Object}    UseProtocolHook
 *  @property  {function}  checkProtocol               Function used for API call
 *  @property  {boolean}   isProtocolValid             Indicates whether the protocol is valid
 *  @property  {boolean}   isProtocolFound             Indicates whether the protocol was found
 *  @property  {boolean}   canUseProtocolForOmbudsman  Indicates whether the protocol can be used
 *  @property  {number}    status                      HTTP status code
 *  @property  {Object}    data                        Raw response data
 *  @property  {boolean}   loading                     Loading indicator
 *  @property  {boolean}   error                       Error indicator
 */
const useProtocol = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [isProtocolValid, setIsProtocolValid] = useState(false);
  const [isProtocolFound, setIsProtocolFound] = useState(false);
  const [error, setError] = useState(false);
  const [canUseProtocolForOmbudsman, setCanUseProtocolForOmbudsman] = useState(
    false
  );
  const { performGiRequest, errorGi } = useGiRequest();
  const { getAccessToken } = usePerformGiRequest();

  /**
   * Validates the received protocol
   * @param   {string}   protocolNumber    The protocol to verify
   * @param   {string}   product           Product key
   */
  const checkProtocol = async (protocolNumber, product) => {
    setLoading(true);
    
    const shaRsaKey = await getAccessToken('00000000000',endPoints.checarProtocoloAberturaOuvidoriaEndPoint.split('/').pop());
    const payload = { 
      UserName: "00000000000",  
      SHArsaKey: shaRsaKey,  
      produto: product,
      numeroProtocolo: protocolNumber 
    }

    const response = await performGiRequest({
      method: POST,
      url: endPoints.checarProtocoloAberturaOuvidoriaEndPoint,
      data: payload
    });

    setStatus(response.status);
    const {result} = response.data.dados;
    setIsProtocolValid(result.ehValido);
    setIsProtocolFound(result.protocoloEncontrado);
    setCanUseProtocolForOmbudsman(result.podeSerUsadoPeloOmbudsman);
    setError(errorGi || !result.ehValido || !result.protocoloEncontrado || !result.podeSerUsadoPeloOmbudsman)
    setLoading(false);
  };

  return {
    checkProtocol,
    isProtocolValid,
    isProtocolFound,
    canUseProtocolForOmbudsman,
    status,
    loading,
    error,
  };
};

useProtocol.propTypes = {
  checkProtocol: PropTypes.func,
  isProtocolValid: PropTypes.bool,
  isProtocolFound: PropTypes.bool,
  canUseProtocolForOmbudsman: PropTypes.bool,
  status: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useProtocol;
