import bannerIrLandingPage from './banner-bg-ir-2024.jpg';
import bannerPrevidencia from './banner-bg-previdencia.jpg';
import bannerPrevidenciaApps from './banner-bg-apps.png';
import bannerAppleApp from './banner-apple-botao.png';
import bannerAndroidApp from './banner-android-botao.png';
import previdenciaInterrogacao from './previdencia-interrogacao.svg';
import previdenciaOQueE from './previdencia-o-que-e.svg';
import apresentacaoFundosInvestimentos from './previdencia-apresentacao-fundos-investimentos-ilustracao.svg';
import previdenciaAssistenciaGratuitaCriancas from './previdencia-assistencia-gratuita-criancas.svg';
import previdenciaTaxasDiferenciadas from './previdencia-taxas-diferenciadas.svg';
import previdenciaVantagemParaMulheres from './previdencia-vantagem-para-mulheres.svg';
import previdenciaCoberturaAssistencia from './previdencia-cobertura-assistencia.svg';
import previdenciaExclusivoMulheres from './previdencia-exclusivo-mulheres.svg';
import bannerIncentivoAporte from './banner-bg-previdencia-incentivo-aporte.png';
import declareIr from './previdencia-apresentacao-declare-ir-ilustracao.svg';
import baixarInforme from './baixar-informe-ilustracao.svg';
import ServicoSinistro from './sinistro.svg';
import bannerCashback from './banner-cashback.png';

const imagensPrevidencia = {
  bannerIrLandingPage,
  bannerPrevidencia,
  bannerPrevidenciaApps,
  bannerAppleApp,
  bannerAndroidApp,
  previdenciaOQueE,
  previdenciaInterrogacao,
  previdenciaAssistenciaGratuitaCriancas,
  previdenciaTaxasDiferenciadas,
  previdenciaVantagemParaMulheres,
  previdenciaCoberturaAssistencia,
  previdenciaExclusivoMulheres,
  apresentacaoFundosInvestimentos,
  bannerIncentivoAporte,
  declareIr,
  baixarInforme,
  ServicoSinistro,
  bannerCashback,
};

export default imagensPrevidencia;
