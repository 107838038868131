import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useFundosInvestimentos } from "../requests";
import { parseStringDate } from "../../utils";

export const rendaFixaPosFixado = {
  id: "1",
  name: "Renda Fixa Pós Fixado",
  descricao:
    "Renda Fixa Pós Fixado - Carteira composta por até 100% em LFT (Tesouro Selic).",
};

export const rendaFixa = {
  id: "2",
  name: "Renda Fixa",
  descricao:
    "Renda fixa - Carteira composta por cerca de 90% dos recursos aplicados em títulos públicos e 10% em títulos privados.",
};

export const rendaFixaModerado = {
  id: "3",
  name: "Renda Fixa Moderado",
  descricao:
    "Renda Fixa Moderado - Carteira composta por cerca de 90% dos recursos aplicados em títulos públicos e 10% em títulos privados.",
};

export const creditoPrivado = {
  id: "4",
  name: "Crédito Privado",
  descricao:
    "Renda fixa Crédito Privado - Carteira composta por até 80% dos recursos aplicados em títulos privados e 20% em títulos públicos.",
};

export const indicePrecos = {
  id: "5",
  name: "Índice de Preços",
  descricao:
    "Índice de preços - Carteira composta, pelas NTN-B (título público federal indexado ao IPCA) até 5 anos.",
};

export const inflacaoAtiva = {
  id: "6",
  name: "Inflação Ativa",
  descricao:
    `Inflação Ativa - Carteira composta por títulos públicos federais (pré, pós e indexados à inflação), títulos 
    privados, moedas e investimentos no exterior, sem limite de concentração em um fator específico.`,
};

export const multiMercadoEstrategiaLivreConservador = {
  id: "7",
  name: "Multimercado Estratégia Livre Conservador",
  descricao: `Multimercado Estratégia Livre Conservador - Carteira dinâmica com alocações em Títulos Públicos Federais (pré, pós e índice de
    preços), títulos privados, moedas e renda variável, sem compromisso de concentração em nenhum
    fator de risco específico. Os fundos de multimercado estratégia livre se diferenciam pelo
    nível de volatilidade da carteira.`,
  filter: (fundo) =>
    !fundo.tipo || fundo.tipo === multiMercadoEstrategiaLivreConservador.id,
};

export const multiMercadoEstrategiaLivreModerado = {
  id: "8",
  name: "Multimercado Estratégia Livre Moderado",
  descricao: `Multimercado Estratégia Livre Moderado - Carteira dinâmica com alocações em Títulos Públicos Federais (pré, pós e índice de
    preços), títulos privados, moedas e renda variável, sem compromisso de concentração em nenhum
    fator de risco específico. Os fundos de multimercado estratégia livre se diferenciam pelo
    nível de volatilidade da carteira.`,
  filter: (fundo) =>
    !fundo.tipo || fundo.tipo === multiMercadoEstrategiaLivreModerado.id,
};

export const multiMercadoEstrategiaLivreArrojado = {
  id: "9",
  name: "Multimercado Estratégia Livre Arrojado",
  descricao: `Multimercado Estratégia Livre Arrojado - Carteira dinâmica com alocações em Títulos Públicos Federais (pré, pós e índice de
    preços), títulos privados, moedas e renda variável, sem compromisso de concentração em nenhum
    fator de risco específico. Os fundos de multimercado estratégia livre se diferenciam pelo
    nível de volatilidade da carteira.`,
  filter: (fundo) =>
    !fundo.tipo || fundo.tipo === multiMercadoEstrategiaLivreArrojado.id,
};

export const multiMercadoRendaVariavel = {
  id: "10",
  name: "Multimercado com renda variável",
  descricao: "",
  filter: (fundo) => fundo.tipo === multiMercadoRendaVariavel.id,
};

export const multiGestorMultiEstrategia = {
  id: "12",
  name: "Multigestor Multiestratégia Fic Multimercado Previdenciário",
  descricao: "",
  filter: (fundo) => fundo.tipo === multiGestorMultiEstrategia.id,
};

export const rendaVariavel15 = {
  id: "10-rv-15",
  name: "Renda variável 15",
  descricao:
    "Renda Variável 15 - Carteira composta de até 15% dos recursos aplicados em renda variável e 85% em títulos públicos e privados",
  filter: (fundo) =>
    fundo.tipo === multiMercadoRendaVariavel.id && fundo.nome.includes("RV 15"),
};

export const rendaVariavel30 = {
  id: "10-rv-30",
  name: "Renda variável 30",
  descricao:
    "Renda Variável 30 - Carteira composta de até 30% dos recursos aplicados em renda variável e 70% em títulos públicos e privados",
  filter: (fundo) =>
    fundo.tipo === multiMercadoRendaVariavel.id && fundo.nome.includes("RV 30"),
};

export const rendaVariavel49 = {
  id: "10-rv-49",
  name: "Renda variável 49",
  descricao:
    "Renda Variável 49 - Carteira composta de até 49% dos recursos aplicados em renda variável e 51% em títulos públicos e privados",
  filter: (fundo) =>
    fundo.tipo === multiMercadoRendaVariavel.id && fundo.nome.includes("RV 49"),
};

export const rendaVariavel70 = {
  id: "10-rv-70",
  name: "Renda Variável 70",
  descricao:`Renda Variável 70 - Carteira composta de até 66% dos recursos aplicados em renda variável e 44% em títulos públicos e privados.`,
  filter: (fundo) =>
    fundo.tipo === multiMercadoRendaVariavel.id && fundo.nome.includes("RV 70"),
};

export const rendaVariavelAcoesLivres = {
  id: "11",
  name: "Renda Variável Ações Livres",
  descricao:"Renda Variável Ações Livres - Carteira composta de até 66% em ações livres e 44% em títulos públicos e privados.",
};

export const tiposFundos = [
  rendaFixaPosFixado,
  rendaFixa,
  rendaFixaModerado,
  creditoPrivado,
  indicePrecos,
  inflacaoAtiva,
  multiMercadoEstrategiaLivreConservador,
  multiMercadoEstrategiaLivreModerado,
  multiMercadoEstrategiaLivreArrojado,
  multiMercadoRendaVariavel,
  multiGestorMultiEstrategia,
  rendaVariavel15,
  rendaVariavel30,
  rendaVariavel49,
  rendaVariavel70,
  rendaVariavelAcoesLivres,
];

function getTipoFundo(fundo, tipos) {
  if (fundo.tipo && fundo.tipo !== multiMercadoRendaVariavel.id) {
    return tipos.find((tipoFundo) => tipoFundo.id === fundo.tipo);
  }

  return tipos
    .filter((t) => t.filter)
    .find(({ filter: handle }) => handle(fundo));
}

function mapTipoFundo(items, agrupamento) {
  if (!Array.isArray(items) || !Array.isArray(agrupamento)) {
    return [];
  }

  const tabelaFundos = items?.map((fundo) => ({
    ...fundo,
    tipoFundo: getTipoFundo(fundo, agrupamento),
  }));

  return (
    agrupamento
      .map((tipo) => ({
        ...tipo,
        fundos: tabelaFundos?.filter(
          (fundo) => fundo?.tipoFundo?.id === tipo.id
        ),
      }))
  );
}

function useTabelaFundoInvestimento(agrupamentoInicial) {
  const {
    data: fundos,
    loading,
    error,
    getDataTable,
  } = useFundosInvestimentos();
  const [tabelaFundos, setTabelaFundos] = useState([]);
  const [dataConsulta, setDataConsulta] = useState(parseStringDate(new Date()));
  const [agrupamento, setAgrupamento] = useState(
    agrupamentoInicial || tiposFundos
  );

  const fundosRef = useRef(fundos);
  if (!_.isEqual(fundosRef?.current, fundos)) {
    fundosRef.current = fundos;
  }

  useEffect(() => {
    getDataTable(dataConsulta);
  }, [dataConsulta]);

  useEffect(() => {
    if (Array.isArray(fundos)) {
      const dataResult = fundos.map(
        ({
          dataCota: data,
          dataInicioFundo: dataInicio,
          nomeFundo: nome,
          rentabilidadeAno: anual,
          rentabilidadeDia: diario,
          rentabilidadeMes: mensal,
          rentabilidadeUltimos12Meses: ultimosDozeMeses,
          taxaAdmFundo: taxaAdmin,
          valorCota: valor,
          tipoRendaFundo: tipo,
        }) => ({
          data,
          dataInicio,
          nome,
          taxaAdmin,
          valor,
          tipo,
          rentabilidade: {
            anual,
            mensal,
            diario,
            ultimosDozeMeses,
          },
        })
      );
      setTabelaFundos(mapTipoFundo(dataResult, agrupamento));
    }
  }, [fundosRef.current]);

  return {
    fundos,
    tabelaFundos,
    setAgrupamento,
    setDataConsulta,
    loading,
    error,
  };
}

useTabelaFundoInvestimento.propTypes = {
  tabelaFundos: PropTypes.objectOf(Object),
  setTabelaFundos: PropTypes.func,
  dataConsulta: PropTypes.string,
  setDataConsulta: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useTabelaFundoInvestimento;
