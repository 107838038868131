import React from "react";
import { Page, Section } from "@components/shared/pages";
import FloatBanner from "@components/shared/floatBanner/index.jsx";
import imagensPrestamista from "@asserts/images/acidentespessoais";

import {
  BannerAcidentesPessoais,
  ApresentacaoAcidentesPessoais,
  VantagensAcidentesPessoais,
  BemEstarSeguroAcidentesPessoais,
  BeneficiosBemEstarSeguroAcidentesPessoais,
  ValoresBemEstarSeguroAcidentesPessoais,
  MicroSeguroAmparoAcidentesPessoais,
  BeneficiosAmparoSeguroAcidentesPessoais,
  AssistenciaFuneralAcidentesPessoais,
  PagamentoUnicoAcidentesPessoais,
  TemAlgumaDuvida,
} from "../sections";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";
import { ParaSeuNegocio } from "../../../components/shared/blocks/ParaSeuNegocio";

const HomePage = (props) => {

  return (
    <Page
      images={imagensPrestamista}
      {...props}
      banner={BannerAcidentesPessoais}
    >
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoAcidentesPessoais}
          title="Conheça nossos seguros de acidentes pessoais"
          id="apresentacao"
          className=""
          sectionName=""
          sectionLayout={null}
        />
        <Section
          as={VantagensAcidentesPessoais}
          title="Vantagens"
          id="vantagens"
          className=""
          sectionName="vantagens"
        />
        <Section
          as={BemEstarSeguroAcidentesPessoais}
          title="Acidentes Pessoais Bem-Estar"
          id="bem-estar"
          className=""
          sectionName=""
          sectionLayout={null}
        />
        <Section
          as={BeneficiosBemEstarSeguroAcidentesPessoais}
          title="Benefícios"
          titleBrand
          anchorConfig={{ ajustment: -190 }}
          id="beneficios-bem-estar"
          className="bloco-tem-alguma-duvida background-image-none-sm "
          sectionName="beneficios"
          sectionLayout={null}
        />
        <Section
          as={ValoresBemEstarSeguroAcidentesPessoais}
          title="Confira os valores imperdíveis!"
          id="valores-bem-estar"
          className="mt-5"
          sectionName=""
          sectionLayout={null}
        />
        <Section
          as={MicroSeguroAmparoAcidentesPessoais}
          title="Microsseguro Amparo"
          id="amparo"
          className=""
          sectionName=""
          sectionLayout={null}
        />
        <Section
          as={BeneficiosAmparoSeguroAcidentesPessoais}
          title="Benefícios"
          titleBrand
          anchorConfig={{ ajustment: -190 }}
          id="beneficios-amparo"
          className="bloco-tem-alguma-duvida background-image-none-sm "
          sectionName="beneficios-amparo"
          sectionLayout={null}
        />
        <Section
          as={AssistenciaFuneralAcidentesPessoais}
          title="Confira o que está incluso na assistência funeral:"
          id="assistencia-funeral-acidentes-pessoais"
          className="mt-5"
          sectionName=""
        />
        <Section
          as={PagamentoUnicoAcidentesPessoais}
          title="Pagamento único que cabe no seu bolso, válido por 12 meses."
          id="pagamento-unico"
          className=""
          sectionName=""
          sectionLayout={null}
        />
        <Section
          as={TemAlgumaDuvida}
          title="Tem alguma dúvida?"
          titleBrand
          anchorConfig={{ ajustment: -190 }}
          id="tem-alguma-duvida"
          className="bloco-tem-alguma-duvida background-image-none-sm border-top"
          sectionName="duvidas"
          sectionLayout={null}
        />
      </SeguridadePageLayout.Tab>
      <SeguridadePageLayout.Tab label="Para sua empresa" eventKey="negocio">
        <ParaSeuNegocio />
      </SeguridadePageLayout.Tab>
      <FloatBanner />
    </Page>
  );
};

export default HomePage;
