import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { readProperty } from "../../../utils";

const FormControl = ({
  controlId,
  label,
  type,
  placeHolder,
  name,
  value,
  options,
  multiple,
  disabled,
  as,
  children,
  rows,
  errors,
  touched,
  style,
  inputStyle,
  maxLength,
  minLength,
  onChange,
  onBlur,
  onClick,
  onKeyUp,
  onFocus,
  autocomplete,
  inputmode,
  pattern,
}) => {
  const isTouched = !!readProperty(touched, name);
  const isInvalid = !!readProperty(errors, name);
  return (
    <Form.Group
      style={style}
      controlId={controlId}
      className="cvp-form-control"
    >
      <Form.Label className="text-left d-block">{label}</Form.Label>
      <Form.Control
        as={as}
        name={name}
        type={type}
        placeholder={placeHolder}
        value={value}
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        rows={rows}
        isInvalid={isTouched && isInvalid}
        maxLength={maxLength}
        minLength={minLength}
        style={inputStyle}
        autocomplete={autocomplete}
        inputmode={inputmode}
        pattern={pattern}
      >
        {options || children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FormControl.defaultProps = {
  type: "text",
  placeHolder: "",
  value: "",
  options: null,
  multiple: false,
  disabled: false,
  as: "input",
  onChange: null,
  onBlur: null,
  onClick: null,
  onKeyUp: null,
  onFocus: null,
  rows: null,
  errors: null,
  touched: null,
  style: null,
  inputStyle: null,
  maxLength: 524288,
  minLength: 0,
  autocomplete: null,
  inputmode: null,
  pattern: null,
};

FormControl.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.element),
  multiple: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.objectOf(Object),
  touched: PropTypes.objectOf(Object),
  style: PropTypes.objectOf(Object),
  inputStyle: PropTypes.objectOf(Object),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  autocomplete: PropTypes.string,
  inputmode: PropTypes.string,
  pattern: PropTypes.string,
};

export default FormControl;
