import React from "react";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";

import AvailableAS from "../../../../asserts/images/available-app-store.png";
import AvailableGP from "../../../../asserts/images/available-google-play.png";
import Store from "../../../../asserts/images/app-store.png";
import GoogleQRCode from "../../../../asserts/images/qr-code-google.png";

const ModalLogin = ({ showModal, setShowModal }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <ModalBody>
        <div className="modal-body">
          <div className="mb-5 app-image">
            <span className="title-banner-modal">
              <h4>
                Baixe o aplicativo <br />
                Caixa Previdência
              </h4>

              <p className="mt-4">
                O cadastro para acompanhar um sinistro <br />é feito
                exclusivamente no aplicativo.
              </p>
            </span>
            <p className="qr-code-text">Leia o QR Code e baixe agora</p>
          </div>
          <div className="d-flex-modal">
            <div className="border-login ">
              <div className="app-store">
                <img src={Store} alt="app store" className="qr-code" />
                <img
                  src={AvailableAS}
                  alt="QR Code app store"
                  className="available"
                />
              </div>
            </div>
            <div className="border-login">
              <div className="google-play">
                <img
                  src={GoogleQRCode}
                  alt="QR Code google play"
                  className="qr-code"
                />
                <img
                  src={AvailableGP}
                  alt="google play"
                  className="available"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ModalLogin;
