import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";
import Banner from "./Banner";

const BannerCarousel = ({ children, interval, controls }) => {
  const carrosselItens = React.Children.map(children, (banner) =>
    React.isValidElement(banner) ? (
      <Carousel.Item>{banner}</Carousel.Item>
    ) : null
  );

  return (
    <>
      <Carousel
        controls={controls}
        className="cvp-banners-carousel"
        interval={interval}
      >
        {carrosselItens}
      </Carousel>
    </>
  );
};

BannerCarousel.defaultProps = {
  interval: 5000,
  controls: false,
};

BannerCarousel.propTypes = {
  children: PropTypes.arrayOf(Banner).isRequired,
  interval: PropTypes.number,
  controls: PropTypes.bool,
};

export default BannerCarousel;
