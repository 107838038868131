export const useAnalytcs = (config) => {
  if (!config) {
    return null;
  }

  return Object.keys(config)
    .filter((k) => config[k])
    .map((prop) => ({ [`data-${prop}`]: `${config[prop]}`.toLowerCase() }))
    .reduce((a, b) => ({ ...a, ...b }), {});
};

export default { useAnalytcs };
