import React from "react";
import { Link } from "../components/shared/actions";
import * as LINKS from "./links";

export const guiaSinistroFaqItem = {
  label: "O que devo fazer em caso de sinistro?",
  content: (
    <>
      <p>
      Sabemos que quando ocorre um sinistro, todo apoio é necessário nesse momento.
      </p>
      <p>
      Por isso criamos uma área exclusiva para esse tipo de atendimento, para acessá-la
      <Link href="/sinistro" label="Clique aqui." /> Ou se preferir baixe o nosso  
      <strong> aplicativo Caixa Previdência</strong>.
      </p>
      <p>
      Para informações, preparamos um guia para te ajudar nesse momento difícil. <Link blank href={LINKS.guiaSinistro} label="Acesse aqui" />.
      </p>
    </>
  ),
};
export const comoMeProtegerContraFraudes = {
  label: "Como me proteger contra fraudes e golpes?",
  content: (
    <p>
      É nossa prioridade proteger as suas informações e ajudar você a
      identificar tentativas de fraudes ou golpes. Por isso, leia o guia para
      ficar em dia com as nossas dicas de proteção.{" "}
      <Link blank href={LINKS.guiaContraFraudes} label="Acesse aqui" />.
    </p>
  ),
};

export const faqItemVidaSenior = [
  {
    label: "Quem pode contratar o Seguro Vida Sênior? ",
    content: (
      <>
        <p>
          Pessoas físicas com idade entre <strong>61</strong> e{" "}
          <strong>80</strong> anos.
        </p>
      </>
    ),
  },
  {
    label: "Quais são as Importâncias Seguradas? ",
    content: (
      <>
        <ul>
          <li>R$ 10.000,00</li>
          <li>R$ 15.000,00</li>
          <li>R$ 20.000,00</li>
          <li>R$ 25.000,00</li>
        </ul>
      </>
    ),
  },
  {
    label: "Onde posso contratar?",
    content: (
      <>
        {" "}
        <p>
          Nas agências CAIXA. Para saber onde fica a mais próxima,{" "}
          <Link blank href={LINKS.encontreAgenciaCaixa} label="clique aqui" />.
        </p>
      </>
    ),
  },
  {
    label: "Qual a periodicidade e modalidades de pagamento?",
    content: (
      <>
        {" "}
        <p>
          Os pagamentos são <strong>mensais</strong>, podendo ser por débito em
          conta CAIXA (conta corrente ou poupança) e boleto (somente na adesão).
        </p>
      </>
    ),
  },
  {
    label: "Qual é o valor pago por mês?",
    content: (
      <>
        {" "}
        <p>
          A partir de <strong>R$ 43,70</strong> mensais para o plano de
          assistências Básico;
        </p>
      </>
    ),
  },
  {
    label: "Quais são as coberturas?",
    content: (
      <>
        {" "}
        <p>Morte por causas naturais e acidentais.</p>
      </>
    ),
  },
  {
    label: "Quais são as assistências?",
    content: (
      <>
        {" "}
        <ul>
          <li>Assistência Lar;</li>
          <li>Assistência Farmácia;</li>
          <li>Consultas e Exames com Preços Reduzidos;</li>
          <li>Assistência Sênior;</li>
          <li>Help Desk para Smartphone.</li>
        </ul>
      </>
    ),
  },
  {
    label: "Qual é a carência do seguro?",
    content: (
      <>
        {" "}
        <p>
          A carência é <strong>escalonada</strong>. Para mais detalhes, consulte
          as Condições Gerais do Produto.
        </p>
      </>
    ),
  },
];

export default {
  comoMeProtegerContraFraudes,
  faqItemVidaSenior,
};
