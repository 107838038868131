import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const AddressInput = ({ label, endereco, formikBag, disabled, ...props }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.endereco"
    label={label}
    placeHolder="Digite o seu endereço"
    name="reclamante.endereco"
    value={endereco}
    disabled
    {...formikBag}
    {...props}
  />
);

AddressInput.propTypes = {
  label: PropTypes.string.isRequired,
  endereco: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default AddressInput;
