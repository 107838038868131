import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

const conteudo = [
  {
    label:
      "Progressivo - 15% + ajuste na declaração anual de IR, podendo chegar a 27,5%.",
    content: (
      <>
        <p>
          Nos resgates (totais e parciais), a tributação será retida na fonte,
          sempre na alíquota de 15%, a título de antecipação do imposto, devendo
          o cliente efetuar o ajuste na declaração anual de Imposto de Renda,
          podendo ter que complementar com até mais 12,5%.
        </p>
        <p>
          VGBL: o Imposto de Renda incide somente sobre a rentabilidade positiva
          acumulada.
        </p>
        <p>
          PGBL: o Imposto de Renda incide sobre o valor nominal e sobre a
          rentabilidade apurada no período do investimento.
        </p>
        <p>
          Quando do recebimento de renda, o imposto será inteiramente retido na
          fonte, conforme a tabela progressiva de Imposto de Renda vigente à
          época.
        </p>
      </>
    ),
  },
  {
    label:
      "Regressivo - Alíquota da tabela ou 25%, nesse caso, o que for menor.",
    content: (
      <>
        <p>
          No resgate total, parcial ou no recebimento de renda, o Imposto de
          Renda é retido na fonte, conforme modalidade:
        </p>
        <p>
          VGBL: o Imposto de Renda incide somente sobre a rentabilidade positiva
          acumulada.
        </p>
        <p>
          PGBL: o Imposto de Renda incide sobre o valor nominal e sobre a
          rentabilidade apurada no período do investimento.
        </p>
        <p>
          Alíquota pode ser reduzida a 10%, conforme o tempo de permanência das
          contribuições realizadas no plano.
        </p>
        <p>
          Não há compensação na declaração de ajuste anual, pois o recolhimento
          é definitivo na fonte.
        </p>
      </>
    ),
  },
  {
    label: "Impostos extras",
    content: (
      <>
        <p>
          Em alguns planos pode ser aplicada a cobrança do ITCMD - Imposto de
          Transmissão Causa Mortis ou Doações, que é um imposto cobrado no
          repasse de recursos aplicados em previdência para os beneficiários, em
          caso de falecimento do titular do plano. A regra de aplicação desse
          imposto e a alíquota variam de acordo com cada estado.
        </p>
      </>
    ),
  },
];

export const ModelosTributacao = ({ sectionTitle }) => {
  return <SeguridadeFaq title={sectionTitle} faqItems={conteudo} right />;
};

ModelosTributacao.propTypes = {
  ...Section.specializedProps,
};

export default ModelosTributacao;
