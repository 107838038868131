import { Text } from "@cvp/design-system/react";
import styled from "styled-components";

export const Title = styled(Text)`
  font-family: "Futura Std Bold";
  margin-bottom: 30px;
`;

export const Description = styled(Text)``;

export const Container = styled.div`
  display: block;
  margin: 20px 0 0 0;
  @media (min-width: 1024px) {
    margin: 40px 0 48px 0;
  }
`;
export const ContainerDocumentos = styled.div`
  display: block;
  margin: 70px 0;
 
`;

export const Subtitle = styled.p`
  margin-bottom: 40px !important;
`;
export const FeaturedSubtitle = styled.div`
  margin-bottom: -50px !important;
`;
