export const blue = '#005fc8';
export const blueFooter = '#005ec8';
export const blueHover = '#4187d7';
export const blueVariant = '#0571e8';
export const blueLight = '#2b77cb';
export const blueSecondary = '#006bb6';
export const blueSecondaryLight = '#e2edf9';
export const blueLighter = '#ace9fa';
export const blueProgressBar = '#cfdeee';
export const bluePrimaryLight = '#0369B9';
export const bluePrimaryDark = '#002b5c';
export const blueSecondaryLighter = '#edf4f7';
export const blueSecondaryDark = '#003672';
export const blueCobalt = '#0039ba';
export const blueRoyal = '#2962e4';
export const blueSmalt = '#002f98';

export const blueBannerLight = '#c6def6';
export const bluePrimary = '#0039ba';
export const black = '#000000';

export const gray = '#4d4f66';
export const grayPlaceholder = '#424242';
export const graySubtitle = '#4e575f';
export const grayLighter = '#67697c';
export const grayLight = '#e1e1e1';
export const grayVariant = '#7e7e7e';
export const graySecondary = '#898d8d';
export const graySecondaryDark = '#525252';
export const grayTextSecondaryDark = '#424242';
export const grayTextSecondaryLight = '#6f7c87';
export const grayBG = '#f7f7f7';
export const grayBGLight = '#f5f5f5';
export const grayText = '#494949';
export const grayDivider = '#d1d1d1';
export const grayDividerLighter = '#d8d8d8';
export const grayDividerLight = '#d1dbe3';
export const grayDividerSecondary = '#e6e4e4';
export const grayCheckbox = '#b9b9b9';
export const grayIcon = '#828894';
export const grayDisabled = '#c8caca';
export const graySignature = '#5c5c5c';
export const grayDisabledSecondary = '#858585';
export const grayInformativeText = '#737373';
export const grayTextDark = '#6f7c87';
export const graySimpleRadioButton = '#d1dbe3';
export const grayBorder = '#939393';

export const graphCyano = '#77e4ff';
export const graphYellow = '#ffc700';
export const graphPink = '#ff55a9';
export const graphGreen = '#4fff4b';

export const red = '#f44a4a';

export const green = '#c1d82f';
export const greenSecondary = '#0bbc7f';

export const orange = '#ff6d00';
export const orangeLife = '#f36800';
export const orangeDark = '#f88a39';
export const orangeHover = '#ff9141';
export const orangeLight = '#ff9141';
export const orangeLighter = '#e89800';
export const orangeShiny = '#f36800';
export const orangeGradientDark = '#FFA600';
export const orangeGradientLight = '#FF5900';

export const white = '#ffffff';
export const transparent = 'transparent';
