import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import IconPrev from '../../../asserts/images/icons/icon-prev.svg';
import IconVida from '../../../asserts/images/icons/icon-vida.svg';
import IconPrest from '../../../asserts/images/icons/icon-prest.svg';

import css from './card-produto.module.css';

const CardProduto = ({
  certificateNumber,
  date,
  status,
  productName,
  protocol,
  onShowTimeline,
  anchorClick,
}) => {
  const [useScroll, setUseScroll] = useState(false);

  let colorProduct = '#005fc8';
  const vidaName = 'vida';
  const previdenciaName = 'previdencia';
  const prestamistaName = 'prestamista';
  const productNameCardText = 'product-name-card';

  switch (productName) {
    case vidaName:
      colorProduct = '#00a499';
      break;
    case previdenciaName:
      colorProduct = '#005fc8';
      break;
    case prestamistaName:
      colorProduct = '#00a499';
      break;
    default:
      break;
  }

  const handleLogo = () => {
    switch (productName) {
      case vidaName:
        return (
          <div style={{ display: 'flex' }}>
            <IconVida />{' '}
            <span
              className={css[productNameCardText]}
              style={{
                color: colorProduct,
              }}
            >
              {vidaName.toUpperCase()}
            </span>
          </div>
        );
      case previdenciaName:
        return (
          <div className={css.productBox}>
            <IconPrev />{' '}
            <span
              className={css[productNameCardText]}
              style={{
                color: colorProduct,
              }}
            >
              {previdenciaName.toUpperCase()}
            </span>
          </div>
        );
      case prestamistaName:
        return (
          <div style={{ display: 'flex' }}>
            <IconPrest />{' '}
            <span
              className={css[productNameCardText]}
              style={{
                color: colorProduct,
              }}
            >
              {prestamistaName.toUpperCase()}
            </span>
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (useScroll) {
      anchorClick();
    }
  }, [useScroll]);

  return (
    <div
      className={css.card}
      style={{ borderTop: `5px solid ${colorProduct}` }}
    >
      <div className={css.info}>
        <Row>
          <Col xs={6}>{handleLogo()}</Col>
          <Col xs={6}>
            <div>
              <h6 className={css.title}>Certificado:</h6>
              <p className={css.value}>{certificateNumber}</p>
            </div>
          </Col>

          <Col xs={6}>
            <h6 className={css.title}>Data do Sinistro:</h6>
            <p className={css.value}>{date}</p>
          </Col>

          {protocol && (
            <Col xs={6}>
              <h6 className={css.title}>Sinistro</h6>
              <p className={css.value}>{protocol}</p>
            </Col>
          )}
          {status && (
            <Col xs={{ span: 6, offset: 6 }}>
              <h6 className={css.title}>Status</h6>
              <p className={css.value}>{status}</p>
            </Col>
          )}
        </Row>
      </div>
      <button
        type="button"
        className={css.button}
        onClick={() => {
          onShowTimeline();
          setUseScroll(!useScroll);
        }}
      >
        Exibir andamento
      </button>
    </div>
  );
};

export default CardProduto;
