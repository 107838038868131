import React from "react";
import { Text } from "@cvp/design-system/react";
import styled from "styled-components";
import FeedbackSucesso from "../../../asserts/images/sucesso-sinistro.svg";
import Logo from "../../../asserts/images/icons/icon-pdf.svg";
import { LINKS } from "../../../constants";
import { Link } from "../../../components/shared/actions";

const SCREEN_BREAKPOINT = "860px";

const SinistroSucesso = ({ protocolo, documento }) => {
  const docLink = LINKS[documento];

  const isSucesso = documento === "Não Disparar";

  return (
    <Wrapper>
      <FeedbackSucessoIcon />

      <TitleSinistro variant="headline-04" align="center" />
      <Description
        variant="body01-lg"
        align="center"
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        O seu protocolo de atendimento é{" "}
        <Text style={{ fontWeight: "bold", display: "inline-block" }}>
          {protocolo}
        </Text>
      </Description>
      <Description variant="body01-lg" align="justify">
        <p>
          {" "}
          Em <strong>2 dias úteis</strong> posicionaremos se haverá necessidade
          de enviar documentos para análise da cobertura. Por favor, aguarde
          nosso retorno por e-mail ou telefone.
        </p>
        {!isSucesso && (
          <>
            <p>
              É possível ter uma <strong>prévia</strong> da documentação que
              normalmente é solicitada.
            </p>
            <LineBlock>
              <LineBlockIcon>
                <Logo />
              </LineBlockIcon>
              <p>
                <Link blank href={docLink} label="Clique aqui" /> para fazer o
                download da lista.
              </p>
            </LineBlock>
            <p>
              Lembrando que essa lista é apenas uma <strong>prévia</strong> do
              que poderá ser solicitado.
            </p>
          </>
        )}
        <p>
          Em caso de dúvidas, acesse nosso Guia de Processo de Sinistro.
          <Link blank href={LINKS.guiaSinistro} label="Clique aqui" />.
        </p>
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeedbackSucessoIcon = styled(FeedbackSucesso)`
  margin-top: 32px;
  margin-bottom: 9px;

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    margin-bottom: 44px;
  }
`;

const TitleSinistro = styled(Text)`
  font-family: "Futura Std Bold";
  margin-bottom: 44px;
  font-weight: 900 !important;

  &:after {
    content: "Comunicado de Sinistro registrado com sucesso!";
  }

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    &:after {
      content: "Comunicado de Sinistro registrado com sucesso!";
    }
  }
`;
const LineBlock = styled.div`
  display: inline-flex;
  align-items: center;
`;
const LineBlockIcon = styled.div`
  font-size: 70px;
  height: 100px;
  width: auto;
  padding-left: 30px;
  padding-right: 10px;
`;

const Description = styled(Text)`
  font-size: 24px;
`;

export default SinistroSucesso;
