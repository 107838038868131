import React from "react";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import imagensSinistro from "../../../asserts/images/sinistro";
import { LINKS } from "../../../constants";
import { Link } from "../../../components/shared/actions";

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroGuiaProcesso}
      title={sectionTitle}
      imageColumn={4}
      right
    >
      <p>
        Para mais orientações sobre o procedimento de análise do sinistro,
        preparamos um guia para te ajudar nesse momento difícil.
        <Link blank href={LINKS.guiaSinistro} label="Acesse aqui" />.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
