import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

export const PlanejamentoTributario = (props) => {
  const { sectionTitle } = props;

  return (
    <Row>
      <Col>
        <h3 className=" ml-1 px-0 px-md-4 mt-3 mt-md-4 content-title">
          {sectionTitle}
        </h3>

        <div className="ml-1 px-0 px-md-4">
          <p>
            A Secretaria da Receita Federal determina que, caso o titular de um
            plano de previdência com o modelo de tributação regressiva venha a
            falecer em até 6 anos da contratação do plano, a alíquota máxima é
            fixada em 25%, sem ajuste na declaração anual de IR.
          </p>
          <p>
            Uma boa estratégia é escolher planos com o{" "}
            <strong>modelo regressivo</strong>, pois mesmo que a alíquota inicie
            em 35% - que é mais alta que os 27,5% do progressivo, a cobrança vai
            seguir a regra da Receita Federal e você pagará menos imposto.
          </p>
          <p>
            E, se o titular falecer depois de 6 anos de plano, a alíquota já
            será automaticamente menor, de acordo com a tabela regressiva.
          </p>
        </div>
      </Col>
    </Row>
  );
};

PlanejamentoTributario.defaultProps = {};

PlanejamentoTributario.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default PlanejamentoTributario;
