import React from "react";
import PropTypes from "prop-types";
import { ChatButton } from "../actions";
import { createImageElement } from "../../../asserts/images";
import icons from "../../../asserts/images/icons";

const FloatChat = ({ chatId }) => {
  return (
    <div className="cvp-float-chat">
      <ChatButton
        button
        block
        chatId={chatId}
        variant="info"
        className="cvp-float-chat-button"
      >
        <div className="d-flex align-items-center justify-content-center">
          {createImageElement(icons.chatFloatIcon, {
            className: "cvp-float-chat-icon",
          })}
          Fale no chat
        </div>
      </ChatButton>
    </div>
  );
};

FloatChat.propTypes = {
  chatId: PropTypes.string.isRequired,
};

export default FloatChat;
