import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import images404 from "@asserts/images/page404";
import Info from "../sections/Info";
import { Page, Section } from "../../../components/shared/pages";

const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackNotFound = useTrackEvent(appInsights, "AA_404");

  useEffect(() => {
    const notFoundEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Página Não Encontrada",
      virtualPageURL: "/404",
    };
    trackNotFound(notFoundEvent);
  }, [trackNotFound]);

  return (
    <Page {...props} images={images404} disabledCrumb>
      <Section
        as={Info}
        title="Ops! Não conseguimos encontrar essa página"
        sectionName="nao-conseguimos-encontrar-essa-pagina"
        sectionLayout={null}
        fluid
        className="custom-section"
      />
    </Page>
  );
};

export default HomePage;
