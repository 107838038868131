import React from "react";
import ContentWithFaqBeneficios from "./ContentWithFaqBeneficios";

const SeguridadeFaqBeneficios = (props) => {
  return <ContentWithFaqBeneficios {...props} variant="seguridade-light" />;
};

SeguridadeFaqBeneficios.propTypes = {};

export default SeguridadeFaqBeneficios;
