import IconDOC from "./IconDOC.svg";
import IconDOCX from "./IconDOCX.svg";
import IconPDF from "./IconPDF.svg";
import IconXLS from "./IconXLS.svg";
import IconXLSX from "./IconXLSX.svg";
import IconZIP from "./IconZIP.svg";
import IconIMG from "./IconIMG.svg";
import IconUPLOAD from "./IconUPLOAD.svg";
import MinIconDOC from "./MinIconDOC.svg";
import MinIconDOCX from "./MinIconDOCX.svg";
import MinIconIMG from "./MinIconIMG.svg";
import MinIconPDF from "./MinIconPDF.svg";
import MinIconXLS from "./MinIconXLS.svg";
import MinIconXLSX from "./MinIconXLSX.svg";
import MinIconZIP from "./MinIconZIP.svg";
import ImgLIXO from "./ImgLIXO.svg";


const ImagesfileUpload = {
  IconDOC,
  IconDOCX,
  IconPDF,
  IconXLS,
  IconXLSX,
  IconZIP,
  IconIMG,
  IconUPLOAD,
  MinIconDOC,
  MinIconDOCX,
  MinIconIMG,
  MinIconPDF,
  MinIconXLS,
  MinIconXLSX,
  MinIconZIP,
  ImgLIXO,
};

export default ImagesfileUpload;
