import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensPrevInvestidor from "@asserts/images/previnvestidor";
import {
  Planejamento,
  Investimentos,
  PlanejamentoTributario,
  BannerInvestidor,
  ModelosTributacao,
  FundosComposicoes,
  Interessado,
} from "../sections";
import { Page, Section } from "../../../components/shared/pages";

const PrevInvestidor = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrevidenciaInvestidor = useTrackEvent(
    appInsights,
    "AA_Previdencia_Investidor"
  );

  useEffect(() => {
    const previdenciaInvestidorEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Previdência - Investidor",
      virtualPageURL: "/previdencia/investidor",
    };
    trackPrevidenciaInvestidor(previdenciaInvestidorEvent);
  }, [trackPrevidenciaInvestidor]);

  return (
    <Page {...props} images={imagensPrevInvestidor} banner={BannerInvestidor}>
      <Section
        id="investimentos-prev-investidor"
        as={Investimentos}
        title="A previdência combina com a sua carteira de investimentos"
        sectionName="carteira de investimentos"
      />
      <Section
        id="planejamento-prev-investidor"
        as={Planejamento}
        title="Planejamento sucessório"
        sectionName="planejamento sucessorio"
      />
      <Section
        id="planejamento-tributario-prev-investidor"
        as={PlanejamentoTributario}
        title="Como fazer um planejamento tributário com a previdência privada?"
        sectionName="como fazer planejamento"
      />
      <Section
        id="modelos-de-tributacao"
        as={ModelosTributacao}
        sectionLayout={null}
        title="Entenda os modelos de tributação"
        sectionName="modelos de tribulacao"
      />
      <Section
        id="fundos-composicoes"
        as={FundosComposicoes}
        sectionLayout={null}
        title="Fundos e composições"
        sectionName="fundos e composicoes"
      />
      <Section
        id="interessado"
        as={Interessado}
        sectionLayout={null}
        title="Ficou interessado?"
        sectionName="ficou interessado"
      />
    </Page>
  );
};

export default PrevInvestidor;
