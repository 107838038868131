/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { Text } from "@cvp/design-system/react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import FeedbackSucesso from "../../../asserts/images/sucesso-sinistro.svg";
import { LINKS } from "../../../constants";
import { Link, Button } from "../../../components/shared/actions";
import ModalLogin from "../../login/sections/footer-login/ModalLogin";

const SCREEN_BREAKPOINT = "860px";

const AcompanhamentoSucesso = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  return (
    <>
      <Wrapper>
        <FeedbackSucessoIcon />
        <TitleSinistro variant="headline-04" align="center" />
        <Description align="center">
          O CPF do segurado foi{" "}
          <strong>
            <u>desvinculado</u>
          </strong>{" "}
          do CPF do beneficiário / herdeiro / consultante.
        </Description>
        <Alert align="cente">IMPORTANTE</Alert>
        <Description align="justify">
          <ul>
            <li>
              <p>
                A partir de agora, seu cadastro está desativado e não mais
                vinculado ao CPF do segurado para consultas ao processo de
                sinistro.
              </p>
            </li>
            <li>
              <p>
                Para os futuros acessos, para este ou outro CPF, será necessário
                fazer um novo cadastro.
              </p>
            </li>
            <li>
              <p>
                Em caso de dúvidas, consulte nossa
                <Link
                  blank
                  href={LINKS.guiaSinistro}
                  label="Politica de privacidade"
                />
              </p>
            </li>
          </ul>
        </Description>
        <SpaceButton>
          <Button
            blank
            transparent
            className="mr-3 d-block d-sm-inline-block"
            label="Voltar para a página inicial"
            onClick={() => history.push("/sinistro/login")}
          />
          <Button
            blank
            className="mr-3 d-block d-sm-inline-block"
            label="Fazer novo cadastro"
            onClick={() => setShowModal(true)}
          />
        </SpaceButton>
      </Wrapper>
      <ModalLogin
        showModal={showModal}
        setShowModal={setShowModal}
        backdrop="static"
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SpaceButton = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const FeedbackSucessoIcon = styled(FeedbackSucesso)`
  margin-top: 32px;
  margin-bottom: 9px;

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    margin-bottom: 44px;
  }
`;

const TitleSinistro = styled(Text)`
  font-family: "Futura Std Bold" !important;
  font-weight: bold !important;
  margin-bottom: 44px !important;

  &:after {
    content: "Solicitação concluída com  com sucesso!";
  }

  @media (min-width: ${SCREEN_BREAKPOINT}) {
    &:after {
      content: "Solicitação concluída com sucesso!";
    }
  }
`;

const Description = styled(Text)`
  font-size: 24px !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  font-family: "Futura Std" !important;
`;
const Alert = styled(Text)`
  font-family: "Futura Std" !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 30px !important;
`;
export default AcompanhamentoSucesso;
