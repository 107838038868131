/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";
import { AccordionCardImage, FaqImage } from "../shared/accordionImage";
import { VARIANT_COLORS } from "../../constants";
import { Button } from "../shared/actions";
import { applyKey } from "../../utils";

export const getAsFaqItems = (list) => {
  if (!Array.isArray(list)) {
    return null;
  }

  return applyKey(list, ({ label, content, analitycsValue }) => (
    <AccordionCardImage label={label} analitycsValue={analitycsValue}>
      {content}
    </AccordionCardImage>
  ));
};

const ContentWithFaqBeneficios = ({
  children,
  title,
  faqItems,
  right,
  faqFooterContent,
  className,
  variant,
  numberMaxShowCard,
  faqColumnConfig,
  twoColumn,
}) => {
  const [isOpenShowMore, setIsOpenShowMore] = useState(false);

  const verMenos = useMemo(
    () =>
      !isOpenShowMore &&
      numberMaxShowCard > 0 &&
      numberMaxShowCard < faqItems.length,
    [isOpenShowMore, numberMaxShowCard, faqItems]
  );

  const items = useMemo(
    () => (verMenos ? faqItems.slice(0, numberMaxShowCard) : faqItems),
    [faqItems, verMenos]
  );

  const columnConfig = useMemo(() => ({ md: 12, ...faqColumnConfig }), [
    faqColumnConfig,
  ]);

  const buildColumn = (list) => {
    return (
      <Col lg={6} className="faq-content" {...columnConfig}>
        <FaqImage variant={variant} className={className}>
          {getAsFaqItems(list)}
        </FaqImage>
        {numberMaxShowCard > 0 && numberMaxShowCard < faqItems.length && (
          <div className="faq-fotter-buttons d-block d-lg-flex justify-content-center">
            <Button
              className="mr-3 d-block d-sm-inline-block"
              onClick={() => setIsOpenShowMore(!isOpenShowMore)}
              label={isOpenShowMore ? "Ver menos" : "Ver mais"}
            />
          </div>
        )}
        {faqFooterContent}
      </Col>
    );
  };

  const oneColumnCreate = () => {
    return buildColumn(items);
  };

  const twoColumnCreate = () => {
    const faqPar = items
      .map((m, i) => (i % 2 === 0 ? m : null))
      .filter((f) => f !== null);
    const faqIpar = items
      .map((m, i) => (i % 2 !== 0 ? m : null))
      .filter((f) => f !== null);

    return (
      <>
        {buildColumn(faqPar)}
        {buildColumn(faqIpar)}
      </>
    );
  };

  return (
    <>
      <Row
        className={`cvp-content-faq py-5 ${
          right ? "flex-md-row" : "flex-md-row-reverse"
        }`}
      >
        <Col>
          <h4 className="text-md-left">{title}</h4>
          {children}
        </Col>
      </Row>
      <Row>{twoColumn ? twoColumnCreate() : oneColumnCreate()}</Row>
    </>
  );
};

ContentWithFaqBeneficios.defaultProps = {
  right: false,
  faqFooterContent: null,
  variant: null,
  numberMaxShowCard: 0,
  faqColumnConfig: null,
  twoColumn: false,
};

ContentWithFaqBeneficios.propTypes = {
  title: PropTypes.string.isRequired,
  faqItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  right: PropTypes.bool,
  numberMaxShowCard: PropTypes.number,
  faqFooterContent: PropTypes.element,
  variant: PropTypes.oneOf(VARIANT_COLORS),
  faqColumnConfig: PropTypes.objectOf(propTypes.object),
  twoColumn: propTypes.bool,
};

export default ContentWithFaqBeneficios;
