import axios from 'axios';
import { GI_ACCESS_TOKEN } from '../../config/endpoints';

const usePerformGiRequest = () =>{
/**
   * Calculates GI access token from parameters
   * @return  {string}  New access token
   */
const getDataFromKey = (pgppk, keyRsa) => {
  let dataret = '';
  for (let g = 0; g < keyRsa.length; g += 4) {
    dataret += pgppk.substr(parseInt(keyRsa.substr(g, 4), 10), 1);
  }
  return dataret;
};

/**
 * Generates new GI access token
 * @return  {string}  New access token
 */
const getAccessToken = async (userName, functionName ) => {
  const { data } = await axios.post(
    GI_ACCESS_TOKEN(),
    {
      cpf: userName,
      funcao: functionName,
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    }
  );
  const { Mapdata, keyData } = data?.dados;
  return getDataFromKey(Mapdata, keyData);
};

return { getAccessToken };
};

export default usePerformGiRequest;
