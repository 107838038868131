import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const EmailInputValid = ({ emailValid, formikBag }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.emailValid"
    label="Digite novamente o seu e-mail *"
    placeHolder="Digite novamente o seu e-mail"
    name="reclamante.emailValid"
    type="emailValid"
    value={emailValid}
    {...formikBag}
  />
);

EmailInputValid.propTypes = {
  emailValid: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default EmailInputValid;
