import React, { useState, useEffect } from 'react';
import ReturnDocumentoPF from './ReturnDocumentoPF';
import ReturnDocumentoPJ from './ReturnDocumentoPJ';
import { CAUSA_OPTIONS } from '../../../../constants';

export const useLabelInfo = (isPessoaConjuge, isPessoaFilho) => {
  const [labelNome, setLabelNome] = useState('');
  const [labelCPF, setLabelCPF] = useState('');
  const [labelDataNascimento, setLabelDataNascimento] = useState('');

  useEffect(() => {
    if (isPessoaConjuge) {
      setLabelNome('Qual o nome completo do cônjuge? *');
      setLabelCPF('Qual o CPF do cônjuge? *');
      setLabelDataNascimento('Qual a data de nascimento do cônjuge? *');
    } else if (isPessoaFilho) {
      setLabelNome('Qual o nome completo do filho(a)? *');
      setLabelCPF('Qual o CPF do filho(a)? *');
      setLabelDataNascimento('Qual a data de nascimento do filho(a)? *');
    } else {
      setLabelNome('Qual o nome completo? *');
      setLabelCPF('Qual o CPF? *');
      setLabelDataNascimento('Qual a data de nascimento? *');
    }
  }, [isPessoaConjuge, isPessoaFilho]);

  return { labelNome, labelCPF, labelDataNascimento };
};
export const handleCepData = (
  loadingCep,
  dataCep,
  foundCep,
  setValues,
  setValuesCep,
  formikBag,
  CEP_INPUT_NAME
) => {
  if (!loadingCep && dataCep) {
    setValues(setValuesCep(dataCep));
    if (!foundCep) {
      formikBag.setFieldError(
        CEP_INPUT_NAME,
        'Desculpe, não localizamos o CEP informado, por favor, confirme e tente novamente'
      );
    }
  }
};

export const useNomeEvento = (
  pessoa,
  evento,
  causa,
  nomeConjugeFilho,
  cpfConjugeFilho,
  dthNascimentoConjugeFilho
) => {
  const pessoaConjuge = 'Conjuge';
  const pessoaFilho = 'Filho';
  const causaDoenca = 'Doenca';
  const eventoMorte = 'Morte';
  const eventoGravidez = 'Gravidez';
  const causaAcidental = 'Acidental';
  const causaNatural = 'Natural';
  const eventoAfastamentoMedico = 'AfastamentoMedico';
  const eventoInvalidez = 'Invalidez';
  const isPessoaConjuge = pessoa === pessoaConjuge;
  const isPessoaFilho = pessoa === pessoaFilho;
  const isEventoMorte = evento === eventoMorte;
  const isPessoaConjugeOuFilho = [pessoaConjuge, pessoaFilho].includes(pessoa);
  const isEventoGravidez = evento === eventoGravidez;
  const isCausaDoenca = causa === causaDoenca;
  const isCausaAcidentall = causa === causaAcidental;
  const isCausaAcidentalOuCausaNatural = [
    causaAcidental,
    causaNatural,
  ].includes(causa);
  const isCausaNatural = causa === causaNatural;
  const isEventoInvalidezAfastamentoMedico = [
    eventoInvalidez,
    eventoAfastamentoMedico,
  ].includes(evento);
  const { labelNome, labelCPF, labelDataNascimento } = useLabelInfo(
    isPessoaConjuge,
    isPessoaFilho
  );

  return {
    labelNome,
    labelCPF,
    labelDataNascimento,
    pessoaConjuge,
    pessoaFilho,
    causaDoenca,
    eventoGravidez,
    isPessoaConjuge,
    isPessoaFilho,
    isEventoMorte,
    isPessoaConjugeOuFilho,
    isEventoGravidez,
    isCausaDoenca,
    nomeConjugeFilho,
    cpfConjugeFilho,
    dthNascimentoConjugeFilho,
    isCausaAcidentall,
    isCausaAcidentalOuCausaNatural,
    isCausaNatural,
    isEventoInvalidezAfastamentoMedico,
  };
};

export const getDocumento = (tipoPessoa, evento, causa, pessoa) => {
  return tipoPessoa === 'PessoaFisica'
    ? ReturnDocumentoPF(tipoPessoa, evento, causa, pessoa)
    : ReturnDocumentoPJ(tipoPessoa, evento, causa, pessoa);
};

export const getCausasOptions = (isTipoPessoaPessoaFisica, evento) => {
  return isTipoPessoaPessoaFisica
    ? CAUSA_OPTIONS.PF[`${evento}`]
    : CAUSA_OPTIONS.PJ[`${evento}`];
};

export const getReclamanteCpfValue = (
  isGrauParentescoPessoaTitular,
  cpfSegurado,
  cpfReclamante
) => {
  return isGrauParentescoPessoaTitular ? cpfSegurado : cpfReclamante;
};

export const renderErrorMessage = (errors, touched, messageErro) => {
  if (Object.keys(errors).some((key) => touched[key])) {
    return (
      <div style={{ color: '#dc3545', fontSize: '.875em' }}>{messageErro}</div>
    );
  }
  return null;
};

export default [
  useLabelInfo,
  handleCepData,
  useNomeEvento,
  getCausasOptions,
  getReclamanteCpfValue,
  renderErrorMessage,
];
