import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { BannerContents } from '../../../components/shared/layout/banners';
import { LINKS } from '../../../constants';
import { Button } from '../../../components/shared/actions';
import { BannerAplicativos } from '../../../components/blocosCustomizados';
import AccordionContext from '../../../context/AccordionContext';

export const BannerPrevidencia = (props) => {
  const { images } = props;
  const { openAccordion } = useContext(AccordionContext);

  return (
    <BannerContents {...props}>
      <BannerContents.Banner
        backgroundImage={images.bannerCashback}
        className="text-blue"
        left
      >
        <Row>
          <Col xs={12} className="d-flex align-items-stretch">
          <h3 className="d-md-inline-block title-cashback">CASHBACK</h3>
          </Col>
          <Col xs={12}>
            <p className="text-body-md mt-0 mt-md-2 text-cashback">
            <b>TRAGA SEU INVESTIMENTO<br /> PARA</b> A CAIXA VIDA E <br/> PREVIDÊNCIA
              <b> E GANHE ATÉ <br/>R$2.500 </b> DE CASHBACK <br />DIRETAMENTE EM SEU PLANO
            </p>
            <Button
              className="mt-2"
              blank
              secondary
              href={LINKS.regulamentoCashBack}
            >
              Clique Aqui
            </Button>
          </Col>
        </Row>
      </BannerContents.Banner>
      <BannerContents.Banner
        backgroundImage={images.bannerPrevidencia}
        className="text-white"
        right
      >
        <Row>
          <Col xs={12} className="d-flex align-items-stretch">
            <FontAwesomeIcon
              className="d-none d-md-inline-block cvp-icon-banner"
              icon={faPiggyBank}
            />
            <h3 className="d-md-inline-block">Previdência</h3>
          </Col>
          <Col xs={12}>
            <p className="text-body-md mt-0 mt-md-2">
              Uma decisão para se orgulhar lá na frente.
              <br /> Faça agora sem precisar sair de casa
            </p>
            <Button
              className="mt-3"
              blank
              secondary
              href={LINKS.autoCompraPrevidencia}
            >
              Comprar agora
            </Button>
          </Col>
        </Row>
      </BannerContents.Banner>
      <BannerAplicativos
        backgroundImage={images.bannerPrevidenciaApps}
        text={
          <>
            {/* Baixe agora o app Caixa Previdência! */}
            <p>As informações que você precisa sempre com você.</p>
            <p>
              <a href="#ja-sou-cliente" onClick={openAccordion}>
                Assista ao vídeo
              </a>{' '}
              e descubra as funcionalidades.
            </p>
          </>
        }
        appleBotao={images.bannerAppleApp}
        androidBotao={images.bannerAndroidApp}
      />
    </BannerContents>
  );
};

BannerPrevidencia.defaultProps = { images: {} };

BannerPrevidencia.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerPrevidencia;
