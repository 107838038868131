import React from "react";
import { useSegmentRoute } from "@hooks";
import {
  HomePage,
  AberturaPage,
  AcompanhamentoPage,
  EnviarDocumentosSinistroPage,
} from "./pages";
import AreaRoute from "../../components/shared/routes";
import { SinistroPending } from "./sections";

export const Sinistro = () => {
  const pageTitle = "Sinistro";
  const routeSinistro = "/sinistro";
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      component: AcompanhamentoPage,
      path: "/acompanhamento",
      pageTitle: "Acompanhamento",
      pageProps: {
        updateCrumb: {
          toRoute: routeSinistro,
          label: pageTitle,
        },
      },
    },
    {
      component: AberturaPage,
      path: "/abertura",
      pageTitle: "Abertura",
      pageProps: {
        updateCrumb: {
          toRoute: routeSinistro,
          label: pageTitle,
        },
      },
    }, 
    {
      component: EnviarDocumentosSinistroPage,
      path: "/enviodocumentos",
      pageTitle: "Envio de Documentos",
      pageProps: {
        updateCrumb: {
          toRoute: routeSinistro,
          label: pageTitle,
        },
      },
    },
    {
      component: SinistroPending,
      path: "/pendente",
    },
  ]);

  return <AreaRoute {...routerContext} title="Sinistro" />;
};

export default Sinistro;
