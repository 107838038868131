/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */

import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { SinistroContext } from "../../../../context";
import Form from "../../../../components/shared/forms";
import { cpfCnpjRegex, isValidCpfCnpj } from "../../../../utils/inputUtils";
import { usePhoneToken, useProtocol } from "../../../../hooks";
import SinistroApiError from "../SinistroApiError/SinistroApiError";
import SpinnerLoading from "../../../../components/shared/layout/SpinnerLoading";
import FormularioAcompanhamentoSinistro from "./FormularioAcompanhamentoSinistro";
import ModalToken from "../../Modals/ModalToken";
import useSinisterTimeline from "../../../../hooks/requests/useSinisterTimeline";
import useCountDown from "../../../../hooks/common/useCountDown";

const FormularioAcompanhamento = ({ errorSinistro }) => {
  const {
    status: protocolStatus,
    loading: loadingProtocol,
    error: errorProtocol,
  } = useProtocol();

  const { validateToken,getPhoneToken } = usePhoneToken();
  const { desvincularBeneficiarioSinistro, loading: IsLoadingDelete } = useSinisterTimeline();
  const { stopTimer } = useCountDown(5);
  const { stepUpload, setStepUpload } = useContext(SinistroContext);
  const { formDataUpload } = useContext(SinistroContext);
  const hasGeneralError = errorProtocol && protocolStatus >= 500;
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cpfTitularModal, setCpfTitularModal] = useState();
  const [telefoneTokenFormated, setTelefoneTokenFormated] = useState();
  const [cpfBeneficiarioModal, setCpfBeneficiarioModal] = useState();

  const constRequiredCPF = "O campo CPF é obrigatório.";
  const constInvalidCPF = "CPF é inválido.";
  const constIsTestInvalidCPF = "is-cpf-valid";
  const constTestInvalidCPF = "Insira um CPF válido.";

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showMessage) {
      const timeoutId = setTimeout(() => {
        setShowMessage(false);
        setMessage("");
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showMessage]);

  const handleFormikTokenSend = async () => {
    const payloadToken = {
      cpf: cpfTitularModal,
      telefoneToken: telefoneTokenFormated,
    };
    await getPhoneToken(payloadToken);
    stopTimer();
  }

 
  const handleFormikonSubmit = async (values) => {
    try {
      const payload = {
        cpf: cpfTitularModal,
        telefoneToken: `55${telefoneTokenFormated}`,
        token: values.token,
      };

      const response = await validateToken(payload);
      const status = response?.dados[0].STATUS;

      if (status === "TRUE") {
        
        const resultToken = await desvincularBeneficiarioSinistro({
          CpfTitular: cpfTitularModal,
          CpfBeneficiario: cpfBeneficiarioModal,
        });

        if (
          resultToken.dados.mensagens[0].descricao ===
          "Beneficiario removido com sucesso"
        ) {
          setStepUpload(1);
          setShowModal(false);
          stopTimer();
        } else {
          stopTimer();
          setShowMessageError(true);
          setMessageError(resultToken.dados.mensagens[0].descricao);
        }
      } else {
        setShowModal(false);
        setShowMessage(true);
        stopTimer();
        setMessage("Token inválido.");
      }
    } catch (error) {
      console.error("Erro:", error);
    } finally {
      console.log("Concluído");
    }
  };

  return (
    <>
      {stepUpload === 0 && (
        <>
          {showModal && (
            <ModalToken
              showModal={showModal}
              setShowModal={setShowModal}
              handleFormikonSubmit={handleFormikonSubmit}
              handleFormikTokenSend={handleFormikTokenSend}
              cpfBeneficiarioModal={cpfBeneficiarioModal}
              cpf={cpfTitularModal}
              telefoneToken={telefoneTokenFormated}
              showMessageError={showMessageError}
              messageError={messageError}
              IsLoadingDelete={IsLoadingDelete}
            />
          )}
          <SpinnerLoading isLoading={loadingProtocol} />
          {hasGeneralError && <SinistroApiError />}
          {stepUpload === 0 && (
            <>
              <Form
                formProps={formDataUpload}
                form={FormularioAcompanhamentoSinistro}
                formId="form-acompanhar"
                validationSchema={{
                  cpfIgualSeguradoSinistrado: Yup.bool(),
                  segurado: Yup.object()
                    .shape({
                      cpfSegurado: Yup.string()
                        .matches(cpfCnpjRegex, constInvalidCPF)
                        .required(constRequiredCPF)
                        .test(
                          constIsTestInvalidCPF,
                          constTestInvalidCPF,
                          isValidCpfCnpj
                        ),
                      cpfBeneficiario: Yup.string()
                        .matches(cpfCnpjRegex, constInvalidCPF)
                        .required(constRequiredCPF)
                        .test(
                          constIsTestInvalidCPF,
                          constTestInvalidCPF,
                          isValidCpfCnpj
                        ),
                    })
                    .required(),
                  reCaptcha: Yup.string().required(
                    "O campo reCAPTCHA é obrigatório."
                  ),
                }}
                initialValues={{
                  segurado: {
                    cpfSegurado: "",
                    cpfBeneficiario: "",
                  },
                  reCaptcha: "",
                  message,
                  showMessage,
                }}
                onSubmit={async (data) => {
                  console.log(data);

                  const segurado = data.segurado.cpfSegurado.replace(
                    /\D/gi,
                    ""
                  );
                  const beneficiario = data.segurado.cpfBeneficiario.replace(
                    /\D/gi,
                    ""
                  );
                  const telefone = data.segurado.telefoneToken.replace(
                    /\D/gi,
                    ""
                  );

                  setCpfTitularModal(segurado);
                  setTelefoneTokenFormated(telefone);
                  setCpfBeneficiarioModal(beneficiario);
                  setShowModal(true);
                }}
              />
              {showMessage && <p className="text-danger">{message}</p>}
            </>
          )}
        </>
      )}

      {errorSinistro && <SinistroApiError />}
    </>
  );
};

export default FormularioAcompanhamento;
