import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Link from "./Link";
import { useFormatPhone } from "../../../hooks";

const PhoneLink = (props) => {
  const { phone, button, className, whatsapp } = props;

  const phoneValue = phone.replace(/\D/gi, "");

  const formatedPhone = useFormatPhone(phoneValue);

  const href = `${
    whatsapp ? "https://web.whatsapp.com/send?l=pt&phone=" : "tel:"
  }${phone}`;

  const phoneProps = {
    ...props,
    href,
    className: `${className} d-lg-none`,
  };

  return (
    <>
      {button ? (
        <Button {...phoneProps}>{formatedPhone}</Button>
      ) : (
        <Link {...phoneProps}>{formatedPhone}</Link>
      )}
      <strong className="d-none d-lg-inline">{formatedPhone}</strong>
    </>
  );
};

PhoneLink.defaultProps = {
  ...Button.defaultProps,
  ...Link.defaultProps,
  button: false,
  whatsapp: false,
};

PhoneLink.propTypes = {
  ...Button.propTypes,
  ...Link.propTypes,
  phone: PropTypes.string.isRequired,
  button: PropTypes.bool,
  whatsapp: PropTypes.bool,
};

export default PhoneLink;
