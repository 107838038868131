/* eslint-disable no-unused-vars */
import './style.css';
import React, { useEffect } from 'react';
import { Page, Section } from '@components/shared/pages';
import imagensPrestamista from '@asserts/images/prestamista';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
  ApresentacaoPrestamista,
  BannerPrestamista,
  CondicoesGeraisPrestamista,
  QuemPodeAdquirir,
  ResultadoSorteios,
  SaibaMaisPrestamista,
  VantagensPrestamista,
  CentralSinistro,
} from '../sections';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { ParaSeuNegocio } from '../../../components/shared/blocks/ParaSeuNegocio';

const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrestamista = useTrackEvent(appInsights, 'AA_Prestamista');

  useEffect(() => {
    const prestamistaEvent = {
      event: 'AA.navegacao',
      virtualPageTitle: 'Caixa Vida e Previdência - Seguro Dívida Zero',
      virtualPageURL: '/segurodividazero',
    };
    trackPrestamista(prestamistaEvent);
  }, [trackPrestamista]);

  return (
    <Page images={imagensPrestamista} {...props} banner={BannerPrestamista}>
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoPrestamista}
          id="apresentacao"
          title="Entenda o Seguro Dívida Zero"
          sectionName="entenda"
        />
        <Section
          as={QuemPodeAdquirir}
          id="seguro-prestamista-quem-pode-adquirir"
          title="Quem pode adquirir?"
          sectionName="quem pode adiquirir"
        />
        <Section
          as={VantagensPrestamista}
          title="Vantagens"
          className="vantagens-prestamista"
          sectionName="vantagens"
        />
        <Section
          as={CentralSinistro}
          id="sinitro"
          title="Central de Sinistros"
          className="sinistros-prestamista"
          sectionName="sinistros"
        />
        <Section
          title="Resultados dos sorteios"
          as={ResultadoSorteios}
          className="pb-5 pt-5 border-top"
          id="resultado-sorteios-prestamista"
          sectionLayout={null}
          sectionName="resultado de sorteios"
        />
        <Section
          title="Condições gerais"
          as={CondicoesGeraisPrestamista}
          className="pb-5 pt-5 border-top"
          id="resultado-sorteios"
          sectionLayout={null}
          sectionName="condicoes gerais"
        />
        <Section
          as={SaibaMaisPrestamista}
          title="Tem alguma dúvida?"
          className="bloco-tem-alguma-duvida background-image-none-sm border-top"
          id="tem-alguma-duvida"
          sectionLayout={null}
          sectionName="duvidas"
        />
      </SeguridadePageLayout.Tab>
      <SeguridadePageLayout.Tab label="Para sua empresa" eventKey="negocio">
        <ParaSeuNegocio />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
