const KinshipOptions = [
  { value: "Cônjuge/Companheiro(a)", label: "Cônjuge/Companheiro(a)" },
  { value: "Irmão(ã)", label: "Irmão(ã)" },
  { value: "Filho(a)", label: "Filho(a)" },
  { value: "Pai", label: "Pai" },
  { value: "Mãe", label: "Mãe" },
  { value: "Titular", label: "Titular" },
  { value: "Procurador", label: "Procurador" },
  { value: "Outros", label: "Outros" },
];

export default KinshipOptions;
