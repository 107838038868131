import { PESSOA_OPTIONS, EVENTO_OPTIONS } from '../../../../constants';

export const getLabelNome = (isPessoaFilho) => {
  return isPessoaFilho
    ? 'Qual o nome completo do filho(a)? *'
    : 'Qual o nome completo do cônjuge? *';
};

export const getLabelCPF = (isPessoaFilho) => {
  return isPessoaFilho
    ? 'Qual o CPF do filho(a)? *'
    : 'Qual o CPF do cônjuge? *';
};

export const getLabelDataNascimento = (isPessoaFilho) => {
  return isPessoaFilho
    ? 'Qual a data de nascimento do filho(a)? *'
    : 'Qual a data de nascimento do cônjuge? *';
};

export const getSelectInputOptionsPessoa = (
  isTipoPessoaPessoaFisica,
  isEventoMorteEisCausaAcidentalOuCausaNatural,
  isTipoPessoaPessoaJuridica
) => {
  if (isTipoPessoaPessoaFisica) {
    return isEventoMorteEisCausaAcidentalOuCausaNatural
      ? PESSOA_OPTIONS.PF.TRUE
      : PESSOA_OPTIONS.PF.FALSE;
  }
  if (isTipoPessoaPessoaJuridica) {
    return isEventoMorteEisCausaAcidentalOuCausaNatural
      ? PESSOA_OPTIONS.PJ.TRUE
      : PESSOA_OPTIONS.PJ.FALSE;
  }
  return [];
};

export const getEventoMorteEisCausaAcidentalOuCausaNatural = (
  isEventoMorte,
  isCausaAcidentalOuCausaNatural
) => {
  return isEventoMorte && isCausaAcidentalOuCausaNatural;
};

export const getOptionsEvento = (isTipoPessoaPessoaFisica) => {
  return isTipoPessoaPessoaFisica ? EVENTO_OPTIONS.PF : EVENTO_OPTIONS.PJ;
};

export default [
  getLabelNome,
  getLabelDataNascimento,
  getLabelDataNascimento,
  getSelectInputOptionsPessoa,
  getEventoMorteEisCausaAcidentalOuCausaNatural,
  getOptionsEvento,
];
