import React from "react";
import PropTypes from "prop-types";
import DefaultFooter from "../../../footers/default";

export const Footer = (props) => {
  const { component } = props;

  const footerContent = React.createElement(component, props);

  return (
    <>
      <footer
        className="footer make-section"
        id="main-footer"
        data-section="footer"
      >
        {footerContent}
      </footer>
    </>
  );
};

Footer.defaultProps = {
  component: DefaultFooter,
};

Footer.propTypes = {
  component: PropTypes.elementType,
};
export default Footer;
