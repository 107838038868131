import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

const FazerUmaCompra = ({ sectionTitle, children, info }) => {
  return (
    <Row className="justify-content-center fazer-uma-compra">
      <Col xs={12} className="text-center">
        <h1 className="content-title">{sectionTitle}</h1>
        <div className="fazer-uma-compra-body">{children}</div>
        <p className="text-body-lg">{info}</p>
      </Col>
    </Row>
  );
};

FazerUmaCompra.defaultProps = {
  info:
    "Se preferir, fale com seu gerente de relacionamento ou vá na agência mais próxima de você.",
};

FazerUmaCompra.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default FazerUmaCompra;
