import React from "react";
import PropTypes from "prop-types";
import { Button as BsButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useMakeClass } from "../../../hooks";

export const actionDefaultProps = {
  toRoute: null,
  custom: false,
  variant: "primary",
  outline: false,
  transparent: false,
  phantom: false,
  className: "",
  blank: false,
  label: "",
  rounded: false,
  pill: false,
  href: null,
  onClick: null,
  onBlur: null,
  disabled: false,
  ignoreBlank: false,
};

export const actionPropTypes = {
  toRoute: PropTypes.string,
  custom: PropTypes.bool,
  variant: PropTypes.string,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  phantom: PropTypes.bool,
  className: PropTypes.string,
  blank: PropTypes.bool,
  label: PropTypes.string,
  rounded: PropTypes.bool,
  pill: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  ignoreBlank: PropTypes.bool,
};

export const getActionContent = (content, iconElement, iconRight) => {
  const elements = [iconElement, content];

  return iconRight ? elements.reverse() : elements;
};

const Action = React.forwardRef((props, ref) => {
  const {
    children,
    toRoute,
    custom,
    variant,
    outline,
    transparent,
    phantom,
    className,
    blank,
    label,
    rounded,
    pill,
    href,
    onClick,
    onBlur,
    disabled,
    ignoreBlank,
  } = props;

  const styledClasses = useMakeClass(
    "cvp-action",
    !(rounded || pill) && "rounded-0",
    className
  );
  const to = href && href.startsWith("/") ? href : toRoute;

  const resetProps = {
    ...props,
    className: styledClasses,
    ...(blank || (!ignoreBlank && !href?.startsWith("/"))
      ? {
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : { target: null, rel: null }),
    custom: null,
    toRoute: null,
    transparent: null,
    outline: null,
    phantom: null,
    rounded: null,
    pill: null,
    noAnalitycs: null,
    analitycsValue: null,
    blank: null,
    secondary: null,
    button: null,
    label: null,
    active: null,
    icon: null,
    iconRight: null,
    ignoreBlank: null,
  };

  const useOutLine = outline || transparent || phantom ? "outline-" : "";

  const actionBase = custom ? (
    <a
      className={styledClasses}
      onClick={onClick}
      onBlur={onBlur}
      href={href}
      ref={ref}
      title={label}
      disabled={disabled}
      target={resetProps.target}
      rel={resetProps.rel}
    >
      {children || label}
    </a>
  ) : (
    <BsButton
      ref={ref}
      {...resetProps}
      variant={`${useOutLine}${variant}`}
      title={label}
    >
      {children || label}
    </BsButton>
  );

  return (
    <>
      {" "}
      {to ? <LinkContainer to={to}>{actionBase}</LinkContainer> : actionBase}
    </>
  );
});

Action.defaultProps = actionDefaultProps;
Action.propTypes = actionPropTypes;

export default Action;
