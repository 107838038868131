const causaOptions = {
  PF: {
    AfastamentoMedico: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Doenca', label: 'Doença' },
    ],
    DiariaInternacao: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Doenca', label: 'Doença' },
    ],
    DiagnosticoDoencaGrave: [
      { value: 'Cancer', label: 'Cancer' },
      { value: 'DoencaProfissional', label: 'Doença Profissional' },
      { value: 'OutrasDoencas', label: 'Outras Doenças' },
    ],
    Gravidez: [
      { value: 'GamelarNatural', label: 'Gamelar natural' },
      { value: 'GamelarInVitro', label: 'Gamelar in vitro' },
    ],
    Invalidez: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Doenca', label: 'Doença' },
    ],
    Morte: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Natural', label: 'Natural' },
    ],
    PerdaEmprego: [
      { value: 'SemJustaCausa', label: 'Sem justa causa' },
      { value: 'PorJustaCausa', label: 'Por justa causa' },
    ],
    Reembolso: [
      {
        value: 'DespesasMedicasHospitalares',
        label: 'Despesas Médicas e Hospitalares',
      },
    ],
  },
  PJ: {
    AfastamentoMedico: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Doenca', label: 'Doença' },
    ],
    DiagnosticoDoencaGrave: [
      { value: 'Cancer', label: 'Cancer' },
      { value: 'DoencaProfissional', label: 'Doença Profissional' },
      { value: 'OutrasDoencas', label: 'Outras Doenças' },
    ],
    Invalidez: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Doenca', label: 'Doença' },
    ],
    Morte: [
      { value: 'Acidental', label: 'Acidental' },
      { value: 'Natural', label: 'Natural' },
    ],
    Reembolso: [
      { value: 'ServicosAuxilioFuneral', label: 'Serviços de Auxílio Funeral' },
    ],
  },
};

export default causaOptions;
