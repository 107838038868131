import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoPrestamista = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.oQueEIlustracao} title={sectionTitle}>
      <p>
        O Seguro Dívida Zero realiza o pagamento do seu empréstimo junto a
        Caixa, em caso de morte ou invalidez total por acidente. Uma forma de
        você cuidar da sua família para que ela lide apenas com a saudade.
      </p>

      <p>O Seguro Dívida Zero cobre o seu empréstimo da seguinte forma:</p>

      <ul>
        <li>
          Pagamento parcial ou total do empréstimo <br />{" "}
          <small>
            Realiza o pagamento do seu empréstimo de acordo com o valor
            contratado.
          </small>
        </li>
        <li className="mt-3">
          Indenização aos beneficiários
          <br />
          <small>
            Caso reste saldo após o pagamento do seu empréstimo, o valor será
            pago ao segurado ou aos beneficiários.
          </small>
        </li>
      </ul>
    </Apresentacao>
  );
};

ApresentacaoPrestamista.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

ApresentacaoPrestamista.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoPrestamista;
