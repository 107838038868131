import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "../../../constants";
import { Link } from "../../../components/shared/actions";

export const Interessado = (props) => {
  const { sectionTitle } = props;

  return (
    <div className="content">
      <h3>{sectionTitle}</h3>
      <p>
        Vá a uma{" "}
        <Link blank href={LINKS.encontreAgenciaCaixa} label="agência CAIXA" />,
        fale com um gerente e monte a melhor carteira pro seu perfil de
        investidor.
      </p>
    </div>
  );
};

Interessado.defaultProps = {};

Interessado.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default Interessado;
