import styled from 'styled-components/macro';
import { fontSize } from '../../styles/mixins';
import {
  white,
  orangeGradientLight,
  orangeGradientDark,
  grayDisabledSecondary,
  grayDisabled,
} from '../../styles/colors';
import Icon from '../Icon';
import media from '../../styles/media';

const backgroundImagem = `background-image: linear-gradient(260deg,${orangeGradientDark} -24%,${orangeGradientLight});`
const backgroundColor = `background-color: ${grayDisabled}`;
export const RoundButton = styled.button`
  border-radius: 80px;
  outline: none;
  ${({ noGradient, disabled }) =>
    !noGradient &&
    !disabled &&
    backgroundImagem}
  ${({ disabled }) => disabled && backgroundColor};
  ${fontSize(16)};
  color: ${({ disabled }) => (disabled ? grayDisabledSecondary : white)};
  padding: 10px 0;
  border: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ContentContainer = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
`;

const mediaDesktop = media.desktop`left: 15px;`;
export const RoundButtonIcon = styled(Icon)`
  margin: auto 8px;
  left: 5px;
  padding-top: 3px;
  ${mediaDesktop}
`;

export const RoundButtonImage = styled.img`
  margin-right: 10px;
  height: 22px;
  width: 22px;
`;
