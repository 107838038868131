import React from 'react';
import { Section } from '@components/shared/pages';
import { SeguridadeFaq } from '../../../../components/blocosCustomizados';

const conteudoInformacoesImportantes = [
  {
    label: 'Prazo',
    content: (
      <div>
        <p>
          O prazo para análise inicia somente quando recebermos todos os
          documentos solicitados.
        </p>
      </div>
    ),
  },
  {
    label: 'Documentação complementar',
    content: (
      <div>
        <p>
          Além dos documentos básicos solicitados, poderemos solicitar outros,
          caso sejam necessários para que possamos analisar corretamente o
          processo.
        </p>
        <p>
          Na hipótese de solicitarmos documentos complementares, o prazo
          regulamentar será suspenso, voltando a contar a partir do recebimento
          pela Seguradora dessa documentação, conforme previsto nas condições
          gerais do produto contratado e regulamentado pela SUSEP.
        </p>
      </div>
    ),
  },
  {
    label: 'Autorização de crédito',
    content: (
      <div>
        <p>
          - A conta para recebimento de indenização deverá ser de titularidade
          própria;
          <br />- A indenização não poderá ser efetuada em conta de terceiros;
          <br />- Não são aceitas conta conjunta, em razão da existência de mais
          de um vínculo;
          <br />- Não são aceitas conta salário, pois não recebe depósito de
          indenização securitária.
        </p>
      </div>
    ),
  },
  {
    label: 'Beneficiário Residente Exterior – Previdência',
    content: (
      <div>
        <p>- Informar Número do NIF/CRS</p>
        <p>
          - Encaminhar a Declaração de Saída Definitiva feita de forma
          particular ou através da Declaração do Imposto de Renda, ou a
          Comunicação da Saída Definitiva.
        </p>
        <p>- Informar o tipo de tributação que deseja que realizamos:</p>
        <p>
          <strong>° Tributação normal,</strong> onde o imposto de renda no ano
          seguinte deverá ser declarado no Brasil;
        </p>
        <p>
          <strong>° Tributação diferenciada</strong> (dedução de 15% ou 25%,
          conforme a modalidade PGBL ou VGBL, em cima do valor total que seria
          recebido), onde será necessário a realização do imposto de renda no
          país em que reside, no ano posterior.
        </p>
        <p>
          - Apresentar dados bancários brasileiros para recebimento (poderá ser
          conta digital).
        </p>
        <p>
          <strong>ATENÇÃO!</strong> Caso não seja assumida a responsabilidade
          sobre a saída definitiva, não podemos efetuar a tributação
          diferenciada.
        </p>
      </div>
    ),
  },
  {
    label: 'Política de privacidade',
    content: (
      <div>
        <p>
          Os dados pessoais coletados são necessários para a análise do sinistro
          e pagamento das indenizações previstas no contrato.
        </p>
        <p>
          Podemos utilizar também esses dados para atender nossos reguladores,
          outras exigências legais e nossos legítimos interesses.
        </p>
        <p>
          Os detalhes de como a <strong>Caixa Vida e Previdência</strong>{' '}
          utiliza os seus dados pessoais podem ser consultados em nossa Política
          de Privacidade, disponível em www.caixavidaeprevidencia.com.br
        </p>
      </div>
    ),
  },
];

export const InformacoesImportantes = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={conteudoInformacoesImportantes}
    />
  );
};

InformacoesImportantes.propTypes = {
  ...Section.specializedProps,
};

export default InformacoesImportantes;
