import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { BannerContents } from "../../../components/shared/layout";

const BannerCanalDenuncias = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerSinistro}
          className="text-white  ouvidoria-banner"
        >
          <Row>
            <Col>
              <TitleBanner>Canal de Denúncias</TitleBanner>
              <p>
                Canal exclusivo para registro e acompanhamento de denúncias
                relacionadas à Caixa Vida e Previdência.
              </p>
            </Col>
          </Row>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

const TitleBanner = styled.h4`
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

BannerCanalDenuncias.defaultProps = { images: {} };

BannerCanalDenuncias.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerCanalDenuncias;
