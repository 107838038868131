import React, {  useEffect } from "react";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import imagensSinistro from "@asserts/images/sinistro";
import { BannerCanalDenuncias, CanalDenuncias } from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {

  const appInsights = useAppInsightsContext();
  const trackSinistro = useTrackEvent(appInsights, "AA_CanalDenuncias");

  useEffect(() => {
    const canalDenunciasEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Canal de Denúncias",
      virtualPageURL: "/denuncia",
    };
    trackSinistro(canalDenunciasEvent);
  }, [trackSinistro]);

  return (
    <Page {...props} images={imagensSinistro} banner={BannerCanalDenuncias}>
      <SeguridadePageLayout.Tab eventKey="denuncia">
        <>
          <Section
            id="denuncia"
            as={CanalDenuncias}
            title="Um canal independente e seguro para receber e tratar sua denúncia"
            sectionLayout={null}
          />
        </>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
