import { css } from 'styled-components';

export const breakpoints = {
  tablet: 960,
  desktop: 1080,
  mobile: 550,
};

export const slickBreakpoints = {
  iphone5: 320,
  mobile: 550,
  tablet: 960,
};

export const mediaMobile = Object.keys(slickBreakpoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${slickBreakpoints[label] / 16}em) {
        ${css(...args)}
      }
    `;
    return acc;
  },
  {}
);

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
