import { useCallback, useEffect, useMemo } from "react";
import { roundMoney } from "../../../utils";
import {
  calcularIrBase,
  recalcularValor,
  somarDeducoesFiscais,
} from "./funcoes";
import useMotorCalculo from "./useMotorCalculo";

const useSimuladorBeneficioFiscal = () => {
  const states = useMotorCalculo();
  const {
    rendaBruta,
    fatorPeriodicidade,
    deducoesFiscaisSemPrev,
    fatorAnualidade,
    previdenciaPBGL,
    previdenciaComplementar,
    pensaoAlimenticia,
    despesasMedicas,
    deducaoEducacaoPropria,
    investEducacaoDependentesBase,
    deducaoFiscalPrevidencia,
    valorMaximoDeducaoPrevidencia,
    deducaoDependentesIR,
    deducaoEducacaoDependentes,
    deducaoInssTabela,
    deducaoInss,
    setContribuicaoInss,
    setFatorPeriodicidade,
    setRendaBruta,
    setPrevidenciaPBGL,
    setPrevidenciaComplementar,
    setDespesasMedicas,
    setPensaoAlimenticia,
    setInvestEducacaoPropria,
    setInvestEducacaoDependentes,
    setQuantidadeDependentes,
    setTotDependentesEstudantes,
    variaveis: { aliquotasIR },
  } = states;

  const rendaBaseIR = useMemo(() => rendaBruta - deducoesFiscaisSemPrev, [
    rendaBruta,
    deducoesFiscaisSemPrev,
  ]);

  const rendaBaseIRSemPrev = useMemo(
    () => roundMoney(rendaBaseIR - deducaoFiscalPrevidencia),
    [roundMoney, rendaBaseIR, deducaoFiscalPrevidencia]
  );

  const calcularIR = useCallback(
    (...valoresDedutores) => {
      const renda = rendaBruta / fatorPeriodicidade;
      const deducoes =
        somarDeducoesFiscais(...valoresDedutores) / fatorPeriodicidade;
      return roundMoney(
        calcularIrBase(renda, aliquotasIR, deducoes) * fatorPeriodicidade
      );
    },
    [
      calcularIrBase,
      somarDeducoesFiscais,
      roundMoney,
      rendaBruta,
      aliquotasIR,
      fatorPeriodicidade,
    ]
  );

  const impostoRendaBase = useMemo(() => calcularIR(deducoesFiscaisSemPrev), [
    calcularIR,
    deducoesFiscaisSemPrev,
  ]);

  const impostoRendaDeduzidoPrevBase = useMemo(
    () => calcularIR(deducoesFiscaisSemPrev, deducaoFiscalPrevidencia),
    [calcularIR, deducoesFiscaisSemPrev, deducaoFiscalPrevidencia]
  );

  const impostoRenda = useMemo(
    () => ({
      impostoRenda: impostoRendaBase * fatorAnualidade,
      impostoRendaDeduzidoPrev: impostoRendaDeduzidoPrevBase * fatorAnualidade,
      rendaBaseIR: rendaBaseIR * fatorAnualidade,
      rendaBaseIRSemPrev: rendaBaseIRSemPrev * fatorAnualidade,
    }),
    [
      impostoRendaBase,
      fatorAnualidade,
      impostoRendaDeduzidoPrevBase,
      rendaBaseIR,
    ]
  );

  const economiaComInvestimentoPGBL = useMemo(
    () => (impostoRendaBase - impostoRendaDeduzidoPrevBase) * fatorAnualidade,
    [impostoRendaBase, impostoRendaDeduzidoPrevBase, fatorAnualidade]
  );

  const totalDeducoes = useMemo(
    () => ({
      totalDeducoes: deducoesFiscaisSemPrev * fatorAnualidade,
      totalDeducoesComPrev:
        (deducoesFiscaisSemPrev + deducaoFiscalPrevidencia) * fatorAnualidade,
      deducaoDependentesIR,
      despesasMedicas,
      investEducacaoPropria: deducaoEducacaoPropria,
      investEducacaoDependentes: deducaoEducacaoDependentes,
      pensaoAlimenticia,
      previdenciaComplementar,
      previdenciaPBGL,
    }),
    [
      deducoesFiscaisSemPrev,
      deducaoDependentesIR,
      despesasMedicas,
      fatorAnualidade,
      fatorPeriodicidade,
      investEducacaoDependentesBase,
      previdenciaComplementar,
      previdenciaPBGL,
      deducaoFiscalPrevidencia,
      fatorPeriodicidade,
    ]
  );

  const invistaMais = useMemo(
    () => Math.max(valorMaximoDeducaoPrevidencia - deducaoFiscalPrevidencia, 0),
    [valorMaximoDeducaoPrevidencia, deducaoFiscalPrevidencia]
  );

  const resetarSimulador = useCallback(() => {
    [
      () => setFatorPeriodicidade(1),
      setRendaBruta,
      setContribuicaoInss,
      setPrevidenciaPBGL,
      setPrevidenciaComplementar,
      setDespesasMedicas,
      setPensaoAlimenticia,
      setQuantidadeDependentes,
      setTotDependentesEstudantes,
      setInvestEducacaoPropria,
      setInvestEducacaoDependentes,
    ].forEach((handle) => handle(0));
  }, []);

  const setFatorPeriodicidadeHandle = useCallback(
    (fator) => {
      if (fatorPeriodicidade !== fator) {
        setFatorPeriodicidade(fator);
        [
          setRendaBruta,
          setPrevidenciaPBGL,
          setPrevidenciaComplementar,
          setDespesasMedicas,
          setPensaoAlimenticia,
          setInvestEducacaoPropria,
          setInvestEducacaoDependentes,
        ].forEach((handle) => handle((valor) => recalcularValor(valor, fator)));
      }
    },
    [
      fatorPeriodicidade,
      setFatorPeriodicidade,
      setRendaBruta,
      setPrevidenciaPBGL,
      setPrevidenciaComplementar,
      setDespesasMedicas,
      setPensaoAlimenticia,
      setInvestEducacaoPropria,
      setInvestEducacaoDependentes,
    ]
  );

  useEffect(() => {
    setContribuicaoInss(deducaoInssTabela);
  }, [deducaoInssTabela, setContribuicaoInss]);

  return {
    ...states,
    setFatorPeriodicidade: setFatorPeriodicidadeHandle,
    resetarSimulador,
    results: {
      deducaoInss,
      valorDeducaoFiscal: valorMaximoDeducaoPrevidencia,
      investimentosDedutiveis: deducaoFiscalPrevidencia,
      invistaMais,
      ...impostoRenda,
      economiaComInvestimentoPGBL,
      ...totalDeducoes,
    },
  };
};

export default useSimuladorBeneficioFiscal;
