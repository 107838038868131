/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Title,
  Subtitle,
  ContainerDocumentos,
  FeaturedSubtitle,
} from './styles';
import { Button } from '../../../../components/shared/actions';
import ModalLimiteArquivo from '../../../../components/ModalLimiteArquivo';
import FileUpload from '../../../../components/file-upload/file-upload';
import { SinisterContext } from '../../../../context/SinisterContext';
import { InformacoesImportantes } from './InformacoesImportantes';
import SpinnerLoading from '../../../../components/shared/layout/SpinnerLoading';
import useSinistro from '../../../../hooks/requests/useSinistro';

const RelacaoDocumentos = () => {
  const [showModalAviso, setShowModalCodigoFop] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    arquivos,
    arquivos64,
    setStepSinister,
    produtos,
    formulario,
    localReCaptcha,
    setProtocolNumber,
  } = useContext(SinisterContext);
  const { anexarDocumentosSinistro, abrirComunidadoSinistro } = useSinistro();

  useEffect(() => {
    if (
      arquivos?.length !== 0 &&
      arquivos64?.length !== 0 &&
      arquivos?.length === arquivos64?.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [arquivos]);

  const envioDocumentos = async () => {
    setDisabled(true);
    setLoading(true);
    let returnAberturaSinistro = {};

    returnAberturaSinistro = await abrirComunidadoSinistro(
      formulario,
      localReCaptcha,
      produtos,
      true
    );
    const sucessoAberturaEAnexo =
      returnAberturaSinistro.dados.entidade.numeroProtocolo;

    if (sucessoAberturaEAnexo) {
      setProtocolNumber(returnAberturaSinistro.dados.entidade.numeroProtocolo);

      // eslint-disable-next-line sonarjs/no-unused-collection
      const protocoloAnexo =
        returnAberturaSinistro.dados.entidade.codigoSolicitacoes[0];
      const result = [];

      arquivos64.map((m, i) =>
        anexarDocumentosSinistro({
          base64: m,
          file: arquivos[i],
          protocoloAnexo,
        }).then((x) => {
          result.push(x);

          const ultimaChamada = result.length === arquivos64.length;
          setDisabled(!ultimaChamada);

          if (ultimaChamada) {
            setStepSinister(2);
          }
        })
      );
    }
  };

  return (
    <Container>
      <Title align="left">
        <h3>Envio de documentos</h3>
      </Title>
      <Subtitle>
        <p>Faça aqui o envio dos documentos solicitados no CHECKLIST.</p>
        <p>
          Você pode fazer o envio de duas formas: selecione vários arquivos ou
          enviado uma pasta compactada/zipada.
        </p>
        <p>
          O tamanho máximo por arquivo é de 5 MB e as extensões permitidas são:
          ZIP, JPG, JPEG, PNG, GIF, BMP, PDF, DOC, DOCX, XLS ou XLSX.
          <br />
        </p>
        <p>
          <strong>ATENÇÃO:</strong> após anexar o(s) arquivo(s), vá até o final
          da página e clique no botão {`"Enviar e Avançar".`}
        </p>
      </Subtitle>
      <ContainerDocumentos>
        <p>
          <FileUpload />
        </p>
      </ContainerDocumentos>

      <FeaturedSubtitle>
        <h2>Informações importantes:</h2>
      </FeaturedSubtitle>
      <InformacoesImportantes />
      <ModalLimiteArquivo
        show={showModalAviso}
        onClick={() => setShowModalCodigoFop(false)}
      />
      <p>
        <br /> <strong>IMPORTANTE:</strong> Após clicar em{' '}
        <strong>Enviar e Avançar</strong> não feche essa página até que todos os
        arquivos sejam enviados e um novo <strong>protocolo</strong> seja
        gerado. Isso pode demorar alguns minutos dependendo da velocidade da
        internet ou quantidade de arquivos.
      </p>
      <Button transparent onClick={() => setStepSinister(0)} disabled={loading}>
        Voltar
      </Button>
      <Button
        isLoading={loading}
        onClick={() => envioDocumentos()}
        disabled={disabled}
        style={{
          background: disabled ? '#ccc' : '',
          border: disabled ? 'none' : '',
        }}
      >
        Enviar e Avançar
      </Button>

      <SpinnerLoading isLoading={loading} />
    </Container>
  );
};

export default RelacaoDocumentos;
