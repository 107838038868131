import React from "react";
import PropTypes from "prop-types";
import ContentWithImage from "../shared/contents/ContentWithImage";
import { createImageElement } from "../../asserts/images";

const Apresentacao = ({
  children,
  image,
  imageColumn,
  right,
  title,
  titleCenter,
  contentColumn,
}) => {
  const titleCentered = titleCenter ? " text-center" : "";

  return (
    <ContentWithImage
      imageColumn={{
        xs: imageColumn,
        className: "d-none d-lg-flex justify-content-center align-items-center",
      }}
      image={createImageElement(image, { alt: title })}
      imageRight={right}
      contentColumn={{ className: "px-0 px-md-4", ...contentColumn }}
      className="bloco-apresentacao"
    >
      {title ? (
        <h3 className={`content-title${titleCentered}`}>{title}</h3>
      ) : null}
      {children}
    </ContentWithImage>
  );
};

Apresentacao.defaultProps = {
  imageColumn: 3,
  right: false,
  title: "",
  titleCenter: false,
  contentColumn: null,
};

Apresentacao.propTypes = {
  image: PropTypes.oneOf([PropTypes.string]).isRequired,
  imageColumn: PropTypes.number,
  right: PropTypes.bool,
  title: PropTypes.string,
  titleCenter: PropTypes.bool,
  contentColumn: PropTypes.objectOf(Object),
};

export default Apresentacao;
