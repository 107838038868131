const eventoOptions = {
  PJ: [
    { value: 'AfastamentoMedico', label: 'Afastamento Médico' },
    { value: 'DiagnosticoDoencaGrave', label: 'Diagnóstico de Doença Grave' },
    { value: 'Invalidez', label: 'Invalidez' },
    { value: 'Morte', label: 'Morte' },
    { value: 'Reembolso', label: 'Reembolso' },
  ],
  PF: [
    { value: 'AfastamentoMedico', label: 'Afastamento Médico' },
    { value: 'DiariaInternacao', label: 'Diária de Internação' },
    { value: 'DiagnosticoDoencaGrave', label: 'Diagnóstico de Doença Grave' },
    { value: 'Gravidez', label: 'Gravidez' },
    { value: 'Invalidez', label: 'Invalidez' },
    { value: 'Morte', label: 'Morte' },
    { value: 'PerdaEmprego', label: 'Perda de Emprego' },
    { value: 'Reembolso', label: 'Reembolso' },
  ],
};

export default eventoOptions;
