import styled from "styled-components";
import { Text } from "@cvp/design-system/react";
import Exclamacao from "../../../../asserts/images/exclamacao.svg";

export const Title = styled(Text)`
  font-family: "Futura Std Bold";
  margin-bottom: 20px;
`;

export const Description = styled(Text)`
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  padding: 0 20px;
  @media (min-width: 1024px) {
    margin: 50px 0;
  }
`;

export const ExclamacaoIcon = styled(Exclamacao)`
  margin-bottom: 40px;
`;
