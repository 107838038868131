import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import images from "@asserts/images/acidentespessoais";
import { Button } from "../../../components/shared/actions";
import Card from "../../../components/shared/cards";
import Section from "../../../components/shared/pages/section";
import { applyKey } from "../../../utils";
import { LINKS } from "../../../constants";

import opcoes from "../data/opcoes.json";

const PagamentoUnicoAcidentesPessoais = ({ sectionTitle }) => {
  return (   
      <Section.Content className="pt-4 pl-4 margem-base">
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 5, offset: 0 }}
          >
            <h2>{sectionTitle}</h2>
            <p>
             A contratação é feita exclusivamente em uma <strong>Lotérica</strong> ou <strong>Correspondente CAIXA Aqui</strong> (CCA).
             O pagamento pode ser feito com cartão de débito CAIXA*, boleto ou débito em conta CAIXA.
            </p>
            <p><i>*exclusivo na Lotérica</i></p>
            
              <Image src={images.logoLoterica} style={{ width: "40%", height: "auto", margin: "30px"}} />
              <Image src={images.logoCca} style={{ width: "40%", height: "auto", padding : "20px"}} />
             
            
          </Col>
          <Col xs={12} md={11} lg={{ span: 6, offset: 0 }}>
            {applyKey(opcoes, ({ valorAnual1, valorAnual2, valorAnual3 }) => (
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 12, offset: 2 }}
              >
                <Card
                  className=" border mx-auto text-center mb-4 mb-md-0 text-card"
                  header={
                    <div className="title">
                      <p>
                        <strong>Pagamento Único </strong>
                        (12 meses)
                      </p>
                    </div>
                  }
                >                  
                  <div><h1 className="text text-brand " style={{display:"inline"}}>R$ {valorAnual1}</h1><span className=" separador"> | </span>
                  <h1 className="text text-brand" style={{display:"inline"}}
                  >R$ {valorAnual2}</h1><span className=" separador"> | </span>
                  <h1 className="text text-brand " style={{display:"inline"}}
                  >R$ {valorAnual3} </h1></div>                  
                  <p className="plano">
                    <strong>Plano Anual</strong>
                  </p>
                  <Button className="base-btn"
                    href={LINKS.linkEncontrarLoterica}
                    >
                    Encontrar Lotérica e CCA
                  </Button>
                </Card>
              </Col>
            ))}
          </Col>
        </Row>
      </Section.Content>

  );
};

PagamentoUnicoAcidentesPessoais.propTypes = {
  ...Section.specializedProps,
};

export default PagamentoUnicoAcidentesPessoais;
