import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaqBeneficios } from "../../../components/blocosCustomizados";
import IconAssistenciaFuneral from "../../../asserts/images/acidentespessoais/icon-assistencia-funeral.svg";
import IconIndenizacaoMorteAcidental from "../../../asserts/images/acidentespessoais/icon-indenizacao-morte-acidental.svg";
import IconSorteios from "../../../asserts/images/acidentespessoais/icon-sorteios.svg";
import IconAssistenciaCestaBasica from "../../../asserts/images/acidentespessoais/icon-assistencia-cesta-basica.svg";

const faqItems = [
  {
    label: (
      <>
        <IconAssistenciaFuneral />
        <p className="text-label">Assistência Funeral</p>
      </>
    ),
    content: (
      <>
        <p>
          Prestação dos Serviços de Assistência Funeral em caso de falecimento
          do{" "}
          <strong>
            Segurado titular, por qualquer que tenha sido a causa da morte.
          </strong>
        </p>
      </>
    ),
  },
  {
    label: (
      <>
        <IconIndenizacaoMorteAcidental />{" "}
        <p className="text-label">Indenização por morte acidental</p>
      </>
    ),
    content: (
      <p>
        Em caso de morte por acidente do(a) segurado(a), garantimos o pagamento
        de<strong> R$ 5 mil </strong>para o(s) beneficiário(s).
      </p>
    ),
  },
  {
    label: (
      <>
        <IconSorteios /> <p className="text-label">Sorteios</p>
      </>
    ),
    content: (
      <p>
        Com sorteios mensais de <strong>R$ 60 mil.</strong>
      </p>
    ),
  },
  {
    label: (
      <>
        <IconAssistenciaCestaBasica />{" "}
        <p className="text-label">Assistência Cesta básica</p>
      </>
    ),
    content: (
      <p>
        Durante <strong>3 meses,</strong> o(s) beneficiário(s) recebe(m) uma
        cesta alimentícia, em caso de morte por acidente do(a){" "}
        <strong>segurado(a) titular.</strong>
      </p>
    ),
  },
];

const BeneficiosAmparoSeguroAcidentesPessoais = ({ sectionTitle }) => {
  return (
    <SeguridadeFaqBeneficios
      title={sectionTitle}
      faqItems={faqItems}
      variant="seguridade-light"
      left
      columnContent
      twoColumn
    />
  );
};

BeneficiosAmparoSeguroAcidentesPessoais.propTypes = {
  ...Section.specializedProps,
};

export default BeneficiosAmparoSeguroAcidentesPessoais;
