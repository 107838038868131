import React, { useEffect } from 'react';
import imagensSinistro from '@asserts/images/sinistro';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
  BannerSinistro,
  SelecionarSinistro,
  BlocoB,
  BlocoC,
  BlocoD,
  BlocoE,
  BlocoF,
  BlocoG,
  BlocoH,
  BlocoI,
} from '../sections';
import { Page, Section } from '../../../components/shared/pages';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';

export const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackSinistro = useTrackEvent(appInsights, 'AA_Sinistro');

  useEffect(() => {
    const sinistroEvent = {
      event: 'AA.navegacao',
      virtualPageTitle: 'Caixa Vida e Previdência - Sinistro',
      virtualPageURL: '/sinistro',
    };
    trackSinistro(sinistroEvent);
  }, [trackSinistro]);

  return (
    <Page {...props} images={imagensSinistro} banner={BannerSinistro}>
      <SeguridadePageLayout.Tab eventKey="sinistro">
        <>
          <Section
            className="cvp-vida-comprar-online"
            sectionLayout={null}
            as={SelecionarSinistro}
            title="Selecione a opção desejada."
            info=""
            sectionName="Sinistro"
          />
          <Section
            id="entenda-vida"
            as={BlocoB}
            title="Como comunicar um sinistro?  "
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoC}
            title="O que é CHECKLIST? Qual é a sua função?"
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoD}
            title="Como eu faço o envio da documentação para a análise do sinistro?"
            sectionName="entenda"
          />

          <Section
            id="entenda-vida"
            as={BlocoE}
            title="O que devo me atentar ao encaminhar os documentos de sinistro pelos canais digitais?"
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoF}
            title="Qual é o prazo regulamentar da análise?"
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoG}
            title="Como faço para acompanhar um processo de sinistro? "
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoH}
            title="O que fazer se o produto não for localizado ou o procedimento online der erro?"
            sectionName="entenda"
          />
          <Section
            id="entenda-vida"
            as={BlocoI}
            title="Guia de Processo de Sinistro"
            sectionName="entenda"
          />
        </>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
