import React from 'react';
import { Link } from '../../../components/shared/actions';
import Apresentacao from '../../../components/blocosCustomizados/Apresentacao';
import { Section } from '../../../components/shared/pages';
import imagensSinistro from '../../../asserts/images/sinistro';

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao
      image={imagensSinistro.sinistroEnvioDocumento}
      title={sectionTitle}
      imageColumn={4}
    >
      <p>
        Para a análise do sinistro você precisa apresentar os documentos
        solicitados no CHECKLIST. Se estes já tiverem sido providenciados{' '}
        <strong>
          tenha em mãos os dados do titular do contrato de seguro/plano de
          previdência.
        </strong>
      </p>
      <p>
        Selecione a opção{' '}
        <Link
          blank
          href="/sinistro/enviodocumentos"
          label="enviar documentos"
        />{' '}
        ou <strong>utilize</strong> o nosso{' '}
        <strong>aplicativo Caixa Previdência.</strong>
      </p>
      <p>
        Temos também a opção de envio através do{' '}
        <strong>WhatsApp pelo número (11) 3080 0257.</strong>
      </p>
      <p>
        <strong>Importante!</strong> É imprescindível que a documentação seja
        encaminhada de forma legível e preferencialmente colorida.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;
