import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import usePerformGiRequest from "./usePerformGiRequest";
import useGiRequest, {POST} from "./useGiRequest";

/**
 * This service hook will fetch investment funds data from an API endpoint.
 *
 * @component
 * @return  {UseFundosInvestimentosHook}
 *
 * @typedef    {Object}    UseFundosInvestimentosHook
 *  @property  {function}  getDataTable                Function used for API call
 *  @property  {number}    status                      HTTP status code
 *  @property  {Object}    data                        Raw response data
 *  @property  {boolean}   loading                     Loading indicator
 *  @property  {boolean}   error                       Error indicator
 */
const useFundosInvestimentos = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();
  const { getAccessToken } = usePerformGiRequest();
  const {performGiRequest, error} = useGiRequest();

  /**
   * Fetches investment funds data from API
   * @param   {string}   dataConsulta     Date for lookup in format DD/MM/YYYY
   */
  const getDataTable = async (dataConsulta) => {
    setLoading(true);
    const shaRsaKey = await getAccessToken('00000000000',endPoints.getTabelaRendimentosEndPoint.split('/').pop());
    const payload = { 
      UserName: "00000000000",
      SHArsaKey: shaRsaKey,  
      data: dataConsulta 
    };
    const response = await performGiRequest({
      method: POST,
      url: endPoints.getTabelaRendimentosEndPoint,
      data: payload
    });

    setStatus(response.status);
    setData(response.data.dados.result);
    setLoading(false);

  };

  return { getDataTable, status, data, loading, error };
};

useFundosInvestimentos.propTypes = {
  getDataTable: PropTypes.func,
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useFundosInvestimentos;
