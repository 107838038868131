import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const AddressNeighborhoodInput = ({ bairro, formikBag, ...props }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formOuvidoria.ouvidoria.contato.bairro"
    label="Bairro"
    placeHolder="Digite o bairro"
    name="ouvidoria.contato.bairro"
    value={bairro}
    {...formikBag}
    {...props}
  />
);

AddressNeighborhoodInput.propTypes = {
  bairro: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default AddressNeighborhoodInput;
