/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form as BsForm } from "react-bootstrap";
import * as Yup from "yup";

const Form = (props) => {
  const {
    form: CustomForm,
    formId,
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur,
    onResetForm,
    formProps,
  } = props;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() => Yup.object().shape({ ...validationSchema })}
        onSubmit={(values, { setSubmitting }) => {
          (async (data) => {
            if (onSubmit) {
              await onSubmit(data);
            }
          })(values).finally(() => setSubmitting(false));
        }}
        validateOnBlur={validateOnBlur}
      >
        {(formikBag) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            submitForm,
            isSubmitting,
            isValid,
            dirty,
            resetForm,
            setFieldError,
            setTouched,
            setValues,
          } = formikBag;

          function handleReset(evt) {
            resetForm(evt);

            if (onResetForm) {
              onResetForm(...[{ ...values }, { ...props, ...formikBag }, evt]);
            }
          }

          return (
            <BsForm onSubmit={handleSubmit} id={formId} onReset={handleReset}>
              {React.createElement(CustomForm, {
                ...formProps,
                formId,
                initialValues,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                submitForm,
                isSubmitting,
                isValid,
                dirty,
                setFieldError,
                setTouched,
                setValues,
              })}
            </BsForm>
          );
        }}
      </Formik>
    </>
  );
};

Form.defaultProps = {
  initialValues: {},
  onSubmit: null,
  onResetForm: null,
  validateOnBlur: false,
  validationSchema: {},
  formProps: null,
};

Form.propTypes = {
  form: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  initialValues: PropTypes.objectOf(Object),
  onSubmit: PropTypes.func,
  onResetForm: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  validationSchema: PropTypes.instanceOf(Object),
  formProps: PropTypes.instanceOf(Object),
};

Form.requiredProps = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(Object).isRequired,
  submitForm: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(Object).isRequired,
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  touched: PropTypes.objectOf(Object).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
