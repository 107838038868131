import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import { PhoneLink } from "../../../components/shared/actions";

const BeneficioDeReducaoSeguroPrestamista = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.quantoCustaFiesIlustracao} title={sectionTitle}>
      <p>
        Acesso a consultas eletivas, exames laboratoriais e de imagem, em rede particular, com preços
        reduzidos que podem chegar a até 65%. Com validade durante o período de vigência do seguro, desde
        que ele permaneça ativo e com abrangência em todo o território nacional.
      </p>
      <p>
        Benefício exclusivo para a Pessoa Física titular do seguro e, no caso de Pessoa Jurídica, para os
        sócios aceitos no momento da contratação.
      </p>
      <p>
        Para ter acesso ao benefício, é só acionar o Serviço de Concierge para agendamento por meio do
        telefone <PhoneLink phone = "0800 591 3135" />, de segunda a sexta, das 9h às 18h (horário de Brasília), após 5 (cinco) dias úteis do início de vigência do seguro.
      </p>
    </Apresentacao>
  );
};

BeneficioDeReducaoSeguroPrestamista.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

BeneficioDeReducaoSeguroPrestamista.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default BeneficioDeReducaoSeguroPrestamista;
