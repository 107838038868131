import React from 'react';
import LoginPage from './pages/LoginPage';
import AreaRoute from '../../components/shared/routes';
import './style.css';

export const Login = () => {
  return <AreaRoute main={LoginPage} title="Página" />;
};

export default Login;
