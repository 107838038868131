import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { ContentWithCardList } from "@components/blocosCustomizados";
import { createImageElement } from "@asserts/images";

export const Assistencias = ({ images, sectionTitle }) => {
  const conteudoAssistencias = [
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Check-up Lar</h6>
          <p className="text-left mt-0">
            Uma vez a cada 12 meses conte com serviço de fixação de quadros,
            prateleiras e persianas, instalação de olho mágico, limpeza de caixa
            d’água, lubrificação de fechaduras e dobradiças, revisão na
            instalação elétrica, serviço de troca de lâmpadas e luzes,
            verificação de vazamentos, troca de vidro e retirada de entulho.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Assistência Educacional</h6>
          <p className="text-left mt-0">
            Estudantes sob sua responsabilidade podem contar com transporte para
            o hospital em sessões de fisioterapia. Para a família, transporte e
            estadia em caso de internamento. Além de aulas com professor
            particular para repor matérias e até cobertura de despesas médicas
            no exterior.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Assistência Farmácia</h6>
          <p className="text-left mt-0">
            Oferece desconto de até 40% em medicamentos de marca e até 60% nos
            medicamentos genéricos da Medley. Orientação farmacêutica de segunda
            à sexta, das 09h às 18h. Apresente seu CPF nas farmácias
            credenciadas para garantir seu desconto.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Recolocação Profissional</h6>
          <p className="text-left mt-0">
            Se você for demitido, sem justa causa ou por término de contrato,
            nossa equipe irá disponibilizar seu currículo, gratuitamente, para
            empresas durante três meses. É preciso estar empregado no momento da
            adesão para que esse serviço esteja disponível.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Serviços de Assistência Funeral Individual (Seguro Amparo)
          </h6>
          <p className="text-left mt-0">
            Garante, em caso de falecimento do segurado principal, por qualquer
            que tenha sido a causa da morte, a prestação dos Serviços de
            Assistência Funeral (SAF).
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Assistência Funeral Familiar</h6>
          <p className="text-left mt-0">
            Se você falecer, seu cônjuge e filhos com até 24 anos, por qualquer
            que tenha sido a causa, conta com a prestação dos Serviços de
            Assistência Funeral (SAF).
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Cesta Alimentícia</h6>
          <p className="text-left mt-0">
            Os beneficiários recebem uma cesta básica com 50 kg de alimentos, no
            caso de Morte Acidental do Segurado.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Orientação Nutricional</h6>
          <p className="text-left mt-0">
            Sempre que quiser, você conta com atendimento por telefone para
            tirar dúvidas e melhorar seus hábitos alimentares.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Help desk</h6>
          <p className="text-left mt-0">
            Você pode solicitar um profissional especializado para receber
            orientações e soluções de problemas relacionados ao seu
            microcomputador.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <ContentWithCardList
        listItens={conteudoAssistencias}
        className="text-left"
      >
        <Row>
          <Col xs={12}>
            <h3 className="content-title text-center mt-4">{sectionTitle}</h3>
          </Col>
          <Col xs={12}>
            {createImageElement(images.vidaImagemAssistencia, {
              alt: "Cobertura",
              className: "mb-4",
            })}
          </Col>
        </Row>
      </ContentWithCardList>
    </>
  );
};

Assistencias.defaultProps = {};

Assistencias.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default Assistencias;
