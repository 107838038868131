import React from "react";
import {
  ContentWithTimeline,
  SeguridadeFaq,
} from "../../../components/blocosCustomizados";
import { Section } from "../../../components/shared/pages";
import {
  contentQuaisSaoTaxasCobradas,
  contentBeneficioExclusivoMulheres,
} from "./JaSouClientePrevidencia";
import { Link, PhoneLink } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const dataTimeline = [
  {
    dataTop: ["Até", "2 anos"],
    dataBottom: "35%",
  },
  {
    dataTop: ["De 2 a", "4 anos"],
    dataBottom: "30%",
  },
  {
    dataTop: ["De 4 a", "6 anos"],
    dataBottom: "25%",
  },
  {
    dataTop: ["De 6 a", "8 anos"],
    dataBottom: "20%",
  },
  {
    dataTop: ["De 8 a", "10 anos"],
    dataBottom: "15%",
  },
  {
    dataTop: ["Mais de", "10 anos"],
    dataBottom: "10%",
  },
];

const faqItems = [
  {
    label: "Entenda o quanto quer ou pode investir",
    content: (
      <>
        <p>
          O melhor plano sempre será o que cabe no seu bolso e atende as suas
          necessidades. Então pense no valor que pode investir hoje e lembre-se:
          você pode aumentar, diminuir e fazer contribuições adicionais (aporte)
          sempre que tiver uma grana extra.
        </p>
        <p>
          O valor mínimo para o pagamento mensal é de R$ 35,00 e para o
          pagamento único, o valor mínimo é de R$ 1.000,00.
        </p>

        <p>
          Em caso de imprevistos, você também pode suspender o pagamento da sua
          previdência e retomar assim que puder.
        </p>
      </>
    ),
  },
  {
    label: "Afinal, o que é PGBL e VGBL?",
    content: (
      <>
        <p>
          Não se preocupe em entender o significado das siglas, o importante é
          saber o que cada modalidade faz pelo seu dinheiro e como isso se
          encaixa na sua vida.
        </p>

        <p>
          <strong>PGBL</strong>
          <br />É paa quem contribui com o INSS ou outro tipo de previdência e
          faz declaração completa de Imposto de Renda (IR).
        </p>

        <p>
          O valor investido pode ser declarado no seu IR para pagar menos
          imposto ou ter uma restituição maior. Assim como você faz com as
          despesas médicas e escolares. Isso é o benefício fiscal.
        </p>

        <p>
          Na hora de resgatar o dinheiro, o tributo do governo será cobrado
          sobre o que você depositou mais o valor que rendeu.
        </p>

        <p>
          <strong>VGBL</strong>
          <br />É indicado para quem não faz declaração de IR ou declara o IR na
          forma simplificada.
        </p>

        <p>
          Esse modelo não oferece o benefício fiscal, mas ao retirar seu
          dinheiro, o imposto será cobrado apenas sobre o valor do rendimento do
          plano.
        </p>

        <p>
          <strong>DICA</strong>
          <br />
          Se você faz a declaração de IR completa e quer investir mais de 12% da
          sua renda bruta anual na previdência, aplique 12% em um plano PGBL. O
          que ultrapassar os 12%, invista num plano VGBL e pague nessa parte
          apenas o imposto sobre os rendimentos.
        </p>
      </>
    ),
  },
  {
    label: "Os modelos de tributação",
    content: (
      <>
        <p>
          Tudo que compramos tem impostos do governo, certo? Mas, quando você
          compra uma previdência, o tempo que o seu dinheiro fica investido
          influencia no valor da tributação que vai pagar para o governo. Veja
          os dois modelos:
        </p>
        <p>
          <strong>Modelo progressivo</strong>
          <br />É para quem ainda não tem certeza de quanto tempo vai deixar o
          dinheiro investido ou sabe que vai sacar o dinheiro em menos de 10
          anos. Nesse modelo, sempre que você resgata seu dinheiro, paga 15% de
          imposto de renda e precisa fazer o ajuste na declaração de IRPF.
        </p>
        <p>
          <strong>Modelo regressivo</strong>
          <br />É para quem quer deixar o dinheiro investido por mais de 10
          anos. Nesse modelo, quanto mais tempo o dinheiro ficar aplicado, menor
          será o imposto cobrado. Veja o gráfico abaixo:
        </p>

        <ContentWithTimeline data={dataTimeline} title="" />
      </>
    ),
  },
  {
    label: "Quais são as taxas cobradas",
    content: contentQuaisSaoTaxasCobradas,
  },
  {
    label: "Como trazer minha previdência para Caixa Vida e Previdência?",
    content: (
      <>
        <p>
          Se você já tem um plano de previdência em outra instituição, é possível transferi-lo a qualquer momento
          para a Caixa Vida e Previdência, sem pagar nada e sem desconto de Imposto de Renda, fazendo a
          portabilidade. Nossas taxas são reduzidas e temos planos sob medida para cada perfil de cliente.
        </p>
        <p>
          Compareça a uma agência CAIXA, tenha em mãos os dados do seu plano de previdência atual e solicite sua portabilidade.
        </p>
        <p>
          <Link
            href={LINKS.encontreAgenciaCaixa}
            label="Clique aqui"
          /> para localizar a agência CAIXA mais próxima.
        </p>
      </>
    ),
  },
  {
    label: "Quais são os tipos de renda?",
    content: (
      <>
        <p>
          Quando o tempo de contribuição chega ao fim, você pode retirar todo
          seu dinheiro de uma só vez ou converter o valor em uma renda mensal
          pelo tempo que você escolher. Veja as opções de renda:
        </p>

        <p>
          <strong>Renda vitalícia:</strong> nesse caso, você recebe uma renda
          mensal enquanto viver;
        </p>

        <p>
          <strong>Renda por prazo determinado:</strong> você escolhe por quanto
          tempo quer receber a renda(1 a 35 anos).No caso do seu falecimento
          antes desse prazo, seus beneficiários continuam recebendo essa renda;
        </p>

        <p>
          <strong>Renda temporária: </strong>você escolhe por quanto tempo quer
          receber a renda(5 a 35 anos). Mas, no caso do seu falecimento antes
          desse prazo, a renda deixa de ser paga.
        </p>
      </>
    ),
  },
  {
    label: "Como funciona o resgate?",
    content: (
      <p>
        O resgate é semelhante a um saque. Você pode retirar o dinheiro
        investido, observando o prazo de carência. Mas, fique atento: se você
        tirar seu dinheiro com pouco tempo de plano, pode ter que pagar algumas
        taxas e impostos. Os resgates podem ser parciais ou totais, sendo que o
        resgate total encerra o plano de previdência. O intervalo entre cada
        resgate deve ser de 60 dias.
      </p>
    ),
  },
  {
    label: "O que é o benefício fiscal?",
    content: (
      <>
        <p>
          O benefício fiscal permite que o valor investido no PGBL possa ser
          declarado no IR, assim como você faz com suas despesas médicas e
          escolares. Dessa forma, você paga menos impostos ou tem uma
          restituição maior.
        </p>
        <p>
          Se você faz a declaração de IR completa, pode deduzir até 12% da sua
          renda bruta anual com o PGBL. Uma dica: se você quer investir mais de
          12% da sua renda anual, aplique até 12% em um plano PGBL. O que
          ultrapassar essa porcentagem, você investe num plano VGBL e paga nessa
          parte apenas o imposto sobre os rendimentos.
        </p>
        <p>
          <Link
            href="/previdencia/simulador-beneficio-fiscal"
            label="Simule aqui seu benefício fiscal"
          />
        </p>
      </>
    ),
  },
  {
    label: "O que é e como funciona o Cuidado Extra?",
    content: (
      <>
        <p>
          Ao contratar um plano de previdência, você pode contratar também o
          Cuidado Extra. O que é isso?
        </p>
        <p>
          Além do dinheiro que você investe e fica lá rendendo, caso você venha
          a falecer durante o tempo de contribuição do plano, sua família ou
          quem você escolher recebe um valor extra de indenização. Você pode
          escolher se os beneficiários vão receber a indenização de uma só vez
          ou mensalmente, em forma de pensão. É você quem determina esse valor,
          dependendo do quanto quer ou pode pagar.
        </p>
        <p>
          Não importa o tempo que tiver a previdência, você conseguirá dar apoio
          financeiro para a família caso venha a falecer. Essa cobertura tem uma
          carência de 18 meses para casos de morte por causas naturais.
        </p>
        <p>
          <strong>Importante saber:</strong> em alguns planos do tipo VGBL, se
          você deixar de contribuir, uma parte do seu saldo será usada para que
          a sua família continue contando com o Cuidado Extra. É simples: se
          você não fizer sua contribuição em algum mês, automaticamente o valor
          dessa cobertura é debitado do valor que estava guardado, para dar
          tranquilidade a sua família.
        </p>
        <p>
          Como faço para usar o Cuidado Extra?
          <br /> Em caso de falecimento do titular do plano, a família deve
          entrar em contato com a nossa Central de Serviços e Relacionamento:{" "}
          <PhoneLink phone="0800 702 4000" />
        </p>
      </>
    ),
  },
  {
    label: "Como funcionam os benefícios exclusivos para mulheres?",
    content: contentBeneficioExclusivoMulheres,
  },
  {
    label: "Como funcionam os benefícios e assistências do Prev Crescer?",
    content: (
      <>
        <p>
          Acompanhamos sua criança de perto. O Prev Crescer tem assistências que
          podem fazer a diferença na segurança, desenvolvimento e no sucesso
          profissional de quem você mais ama.
        </p>
        <p>
          <strong>Inspeção Kids (até 6 anos de idade): </strong> Mais segurança
          para seu filho. Enviamos um profissional ao seu imóvel para instalação
          de protetores nos principais pontos de risco e prevenção de acidentes
          domésticos. Consulte <Link href={LINKS.inspecaoKids} label="aqui" />{" "}
          os serviços inclusos nesta assistência.
        </p>
        <p>
          <strong>Assistência Bike (idade entre 7 e 16 anos): </strong> O pneu
          da bike furou? Imprevistos acontecem. Enviamos um profissional para
          realização de pequenos reparos na bicicleta do menor. Veja{" "}
          <Link href={LINKS.assistenciaBike} label="aqui" /> os itens cobertos.
        </p>
        <p>
          <strong>Orientação Profissional (a partir de 17 anos): </strong> Apoio
          profissional na preparação do seu filho para enfrentar o mercado de
          trabalho e orientação vocacional. Consulte as regras{" "}
          <Link href={LINKS.profissionalUniversitario} label="aqui" />
        </p>
        <p>
          Para acionar as assistências exclusivas do Prev Crescer, ligue para
          <PhoneLink phone="0800 722 2492" />
        </p>
      </>
    ),
  },
];

export const TudoQueVoceQueriaSaber = ({ sectionTitle }) => {
  return <SeguridadeFaq title={sectionTitle} faqItems={faqItems} right />;
};

TudoQueVoceQueriaSaber.defaultProps = {
  ...Section.defaultProps,
};

TudoQueVoceQueriaSaber.propTypes = {
  ...Section.propTypes,
};

export default TudoQueVoceQueriaSaber;
