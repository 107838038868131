import bannerSinistro from "./sinistro-banner.png";
import sinistroSolicitarProtocolo from "./sinistro-solicitar-protocolo.svg";
import sinistroComunicar from "./sinistro-comunicar.svg";
import sinistroChecklist from "./sinistro-checklist.svg";
import sinistroEnvioDocumento from "./sinistro-envio-documento.svg";
import sinistroAtencao from "./sinistro-atencao.svg";
import sinistroPrazo from "./sinistro-prazo.svg";
import sinistroAcompanharProcesso from "./sinistro-acompanhar-processo.svg";
import sinistroErroProcedimento from "./sinistro-erro-procedimento.svg";
import sinistroGuiaProcesso from "./sinistro-guia-processo.svg";
import sinistroAlerta from "./alerta.svg";

const imagensSinistro = {
  bannerSinistro,
  sinistroComunicar,
  sinistroSolicitarProtocolo,
  sinistroChecklist,
  sinistroEnvioDocumento,
  sinistroAtencao,
  sinistroPrazo,
  sinistroAcompanharProcesso,
  sinistroErroProcedimento,
  sinistroGuiaProcesso,
  sinistroAlerta
};

export default imagensSinistro;
