import envs from '../envs';

export const verificarProdutoUri = () =>
  `${envs.giHost}/api/GenericBackEnd/GBE_ConsultaContratosResumido_SF`;

export const GI_ACCESS_TOKEN = () =>
  `${envs.giHost}/api/Autenticar/ObterTokenInfo`;

export const ConsultarSinistroCpfCnpjVida = () =>
  `${envs.giHost}/api/Vida/ConsultarSinistroCpfCnpj`;

export const ConsultarSinistroVida = () =>
  `${envs.giHost}/api/Vida/ConsultarSinistro`;

export const ConsultarSinistroPrevidencia = () =>
  `${envs.giHost}/api/PortalPJ/PJ_TimelineSinistro`;

export const ConsultarSinistroCpfCnpjPrestamista = () =>
  `${envs.giHost}/api/Vida/PrestRecuperaDadosSinistro`;

export const ConsultarSinistroPrestamista = () =>
  `${envs.giHost}/api/Vida/TimelineSinistrosPrestamista`;

export const gerarProtocoloGi = () =>
  `${envs.giHost}/api/AreaLogada/ALOG_SinistroGerarProtocoloSolicitacao`;

export const enviarAnexo = () =>
  `${envs.giHost}/api/AreaLogada/ALOG_SinistroAnexarArquivo`;

export const ConsultarBeneficiarioSinistro = () =>
  `${envs.giHost}/api/AreaLogada/ALOG_SinistroConsultarBeneficiario`;

export const RemoverBeneficiarioSinistro = () =>
  `${envs.giHost}/api/AreaLogada/ALOG_SinistroRemoverBeneficiario`;


export const buscarCep = () => `${envs.giHost}/api/Vida/BuscarCep`;

export const EnviarDocumentos = () =>
  `${envs.giHost}/api/AreaLogada/ALOG_AnexarArquivoSolicitacao`;
