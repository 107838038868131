import React from "react";
import { Section } from "../pages";
import FazerUmaCompra from "./FazerUmaCompra";
import { SuaEmpresaPodeContar } from "./SuaEmpresaPodeContar";

export const ParaSeuNegocio = () => {
  return (
    <>
      <Section
        sectionName="empresa pode contar"
        className="cvp-sua-empresa-pode-contar-com-gente"
        title="Sua empresa pode contar com a gente"
        as={SuaEmpresaPodeContar}
      />
      <Section
        sectionName="quer comprar produto empresa"
        className="cvp-comprar-um-de-nossos-produtos mt-4 mb-5 pb-5"
        sectionLayout={null}
        title="Quer comprar um de nossos produtos?"
        as={FazerUmaCompra}
      />
    </>
  );
};

export default ParaSeuNegocio;
