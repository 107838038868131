import React from "react";
import { BenefitsBlocks } from "@components/blocosCustomizados";
import { Section } from "@components/shared/pages";
import { Button } from "../../../components/shared/actions";

export const BeneficiosBemEstar = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.bemEstarBlocoA,
      title: "",
      description: (
        <p>
          Redução de preço de até 65%<br />em consultas e exames médicos<br />e
          odontológicos.
        </p>
      ),
    },
    {
      image: images.bemEstarBlocoB,
      title: "",
      description: <p>Redução de preço de até<br />80% em medicamentos.</p>,
    },
    {
      image: images.bemEstarBlocoC,
      title: "",
      description: (
        <p>
          Assistência funeral em caso de<br />falecimento do(a) segurado(a)<br />titular,
          cônjuge e filhos.
        </p>
      ),
    },
  ];

  return (
    <>
      <Section.Content className="text-center text-sm-center title-size">
        <p>
         Conheça os nossos Seguros de{" "}
          <strong>Acidentes Pessoais, Bem-Estar e Amparo.</strong>
        </p>
        </Section.Content>
        <Section.Content className="text-center text-sm-center title-size">
        <p className="basic-color-tangerine">
          Saiba mais sobre o seguro de{" "}
          <strong>Acidentes Pessoais Bem Estar</strong>
        </p>
      </Section.Content>
      <BenefitsBlocks sectionTitle={sectionTitle} items={conteudoVantagem} />
      <Section.Content className="text-center text-sm-center btn-espaco">
        <Button
          blank
          rounded
          secondary
          transparent
          toRoute="/acidentespessoais"
        >
          Saiba Mais!
        </Button>
      </Section.Content>
    </>
  );
};

BeneficiosBemEstar.propTypes = {
  ...Section.specializedProps,
};

export default BeneficiosBemEstar;
