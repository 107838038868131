import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { parseDateString, parseStringDate } from "../../../utils";
import images, { createImageElement } from "../../../asserts/images";
import { InputControl } from ".";

const DatePickerControlInput = ({
  onClick,
  inputProps,
  datePickerId,
  isValid,
  onKeyUp,
  inputmode,
  pattern,
  maxLength,
}) => (
  <>
    <Form.Label htmlFor={datePickerId} className="cvp-date-picker">
      {isValid &&
        createImageElement(images.calendarIcon, {
          className: "cvp-date-picker-icon",
        })}
      <InputControl
        onClick={onClick}
        {...inputProps}
        controlId={datePickerId}
        autocomplete="off"
        onKeyUp={onKeyUp}
        inputmode={inputmode}
        pattern={pattern}
        maxLength={maxLength}
      />
    </Form.Label>
  </>
);

DatePickerControlInput.defaultProps = {
  onClick: null,
};

DatePickerControlInput.propTypes = {
  datePickerId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  inputProps: PropTypes.objectOf(PropTypes.object).isRequired,
  isValid: PropTypes.bool.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  inputmode: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

const DatePickerControl = (props) => {
  const {
    controlId,
    minDate,
    maxDate,
    dateFormat,
    value: currentValue,
    name,
    setFieldValue,
    onChange,
    placeHolder,
    isValid,
    onKeyUp,
    inputmode,
    pattern,
    maxLength,
  } = props;

  const [startDate, setStartDate] = useState(currentValue);
  function selectedDateHandle(date, evt) {
    const strDate = parseStringDate(date);
    setFieldValue(name, strDate);

    setStartDate(date);

    if (onChange) {
      onChange(evt);
    }
  }

  return (
    <DatePicker
      id={controlId}
      customInput={
        <DatePickerControlInput
          inputProps={props}
          datePickerId={`${controlId}.input`}
          isValid={isValid}
          onKeyUp={onKeyUp}
          inputmode={inputmode}
          pattern={pattern}
          maxLength={maxLength}
        />
      }
      selected={startDate}
      dateFormat={dateFormat}
      onChange={selectedDateHandle}
      minDate={parseDateString(minDate)}
      maxDate={parseDateString(maxDate)}
      placeholderText={placeHolder}
      shouldCloseOnSelect
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      popperPlacement="top-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "5px, 10px",
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport",
        },
      }}
    />
  );
};

DatePickerControl.defaultProps = {
  dateFormat: "dd/MM/yyyy",
  minDate: null,
  maxDate: null,
  placeHolder: parseStringDate(new Date()),
  onChange: null,
  isValid: true,
  onKeyUp: null,
  inputmode: null,
  pattern: null,
  maxLength: 10,
};

DatePickerControl.propTypes = {
  controlId: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  onKeyUp: PropTypes.func,
  inputmode: PropTypes.string,
  pattern: PropTypes.string,
  maxLength: PropTypes.number,
};

export default DatePickerControl;
