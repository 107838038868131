/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Form from "../shared/forms/Form";
import DrawResultPanel from "./DrawResultPanel";
import {
  parseDateString,
  tryGetListOrEmpty,
  yupTransformDateString,
} from "../../utils";
import useDrawResult from "../../hooks/requests/useDrawResult";

export const combinacaoSeis = "combinacaoSeis";
export const combinacaoSete = "combinacaoSete";

const parseResult = (drawResult) => {
  if (Array.isArray(drawResult) && drawResult.length) {
    return drawResult
      .reduce((x, y) => x.concat(y.itens), [])
      .map(
        (item) => ({
          combinacaoSeis: item.combinacaoSorteada.combinacaoSeis,
          combinacaoSete: item.combinacaoSorteada.combinacaoSete,
          id: item.resultadoSorteio.numeroExtracao,
          data: parseDateString(item.resultadoSorteio.dataExtracao)
        })
      );
  }
  return [];
};

const getFormattedResults = (
  defaultProductCode,
  staticResults,
  dynamicResults
) => {
  let staticPreviousResults = staticResults[defaultProductCode] || [];
  if (dynamicResults) {
    staticPreviousResults = staticPreviousResults.concat(
      parseResult(dynamicResults)
    );
  }
  return tryGetListOrEmpty(staticPreviousResults).map((obj) => ({
    ...obj,
    data: parseDateString(obj.data),
  }));
};

const DrawResult = ({
  productList,
  defaultResultAs,
  defaultProductCode,
  staticResults,
  displayResultLabel,
  title,
}) => {
  const [selectedProductCode, setSelectedProductCode] = useState(
    defaultProductCode
  );
  const [result, setResult] = useState([]);
  const { getDrawResults, loading, data } = useDrawResult();
  const [drawResults, setDrawResults] = useState(
    getFormattedResults(defaultProductCode, staticResults)
  );

  useEffect(() => {
    if (selectedProductCode) {
      (async () => {
        await getDrawResults(selectedProductCode)
      })();
    }
  }, [selectedProductCode]);

  useEffect(() => {
    setDrawResults(
      getFormattedResults(defaultProductCode, staticResults, data)
    );
  }, [data]);

  return (
    <Form
      form={DrawResultPanel}
      formId="draw-result-form"
      validationSchema={{
        date: Yup.date().transform(yupTransformDateString).required(),
      }}
      onSubmit={({ result: selectedResult }) => {
        setResult(selectedResult);
      }}
      initialValues={{ product: "" }}
      formProps={{
        productList,
        loading,
        setSelectedProductCode,
        drawResults,
        result: !loading && result,
        defaultResultAs,
        defaultProductCode,
        displayResultLabel,
        title,
      }}
    />
  );
};

DrawResult.defaultProps = {
  productList: [],
  defaultProductCode: 0,
  staticResults: [],
  displayResultLabel: "1º Sorteio",
  title: null,
};

DrawResult.propTypes = {
  productList: PropTypes.arrayOf(Object),
  defaultProductCode: PropTypes.string,
  defaultResultAs: PropTypes.string.isRequired,
  staticResults: PropTypes.arrayOf(Object),
  displayResultLabel: PropTypes.string,
  title: PropTypes.string,
};

export default DrawResult;
