import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const Sorteios = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.sorteiosIlustracaoFies} title={sectionTitle}>
      <p>
        Ao contratar o seguro, você participa de sorteios mensais no valor de{" "}
        <strong>R$ 5 mil</strong>, com base na extração da Loteria Federal.
      </p>
      <p>
        Mesmo que você seja sorteado uma vez, você continua participando dos
        próximos sorteios. ;)
      </p>
    </Apresentacao>
  );
};

Sorteios.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

Sorteios.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default Sorteios;
