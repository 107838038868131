/* eslint-disable react/jsx-no-bind */
import React from "react";

import CurrencyInput from "react-currency-input";

import PropTypes from "prop-types";

import { Form } from "react-bootstrap";

import { convertPriceInFloat } from "../../../utils/numbers";

const InputPrice = ({
  id,
  name,
  label,
  error,
  value,
  onChange,
  info,
  ...rest
}) => {
  const valueMask = (number) =>
    number ? String(number).replace(".", ",") : "";

  return (
    <>
      <div className="form-group">
        <Form.Label className="text-left d-block">{label}</Form.Label>
        <CurrencyInput
          id={id}
          name={name}
          className="form-control"
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          precision="2"
          allowNegative={false}
          value={valueMask(value)}
          onChange={(currrency) => onChange(convertPriceInFloat(currrency))}
          {...rest}
        />
      </div>
      {info && <p className={`info ${error && "error"}`}>{info}</p>}
    </>
  );
};

InputPrice.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  info: PropTypes.string,
  error: PropTypes.bool,
};

InputPrice.defaultProps = {
  id: "",
  name: "",
  label: "",
  value: 0,
  onChange: () => {
    /* evento padrão sem efeito */
  },
  info: "",
  error: false,
};

export default InputPrice;
