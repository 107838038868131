import styled from "styled-components";
import images from "../../../../asserts/images";

export default styled.section`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  &.page-banner {
    background-attachment: scroll;
  }

  @media all and (max-width: 767px) {
    &.page-banner {
      background-image: url("${images.seguridadeBannerMobile}");
    }
  }
`;
