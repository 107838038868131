import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { LINKS } from "@constants";
import { BannerContents } from "../../../components/shared/layout";
import { Button } from "../../../components/shared/actions";

export const BannerVidaSenior = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerVidaSenior}
          className="text-white"          
        >
          {" "}
          <Row>
            <div id="banner-seguro-vida-senior">
              <Col xs={12} className="d-flex align-items-stretch">
                <h1 className="d-md-inline-block">
                  Seguro
                  <br />
                  Vida Sênior
                </h1>
              </Col>
              <Col xs={12}>
                <p className="text-body-md ">
                  Diversas assistências para cuidar de você.
                </p>
              </Col>{" "}
              <Col xs={12}>
                <Button
                  className="btn-senior"               
                  href={LINKS.encontreAgenciaCaixa}
                  style={{  fontSize:'18px', paddingLeft:"40px", paddingRight:"40px"}}
                >
                  Fale com a gente!
                </Button>
              </Col>{" "}
            </div>
          </Row>         
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannerVidaSenior.defaultProps = { images: {} };

BannerVidaSenior.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerVidaSenior;
