import React from "react";
import { PropTypes } from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import { Link } from "../../../components/shared/actions";

export const ApresentacaoPrevidencia = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao
      image={images.previdenciaOQueE}
      title={sectionTitle}
      imageColumn={3}
    >
      <p>
        Você contribui com uma quantia em dinheiro por um determinado período e
        esse valor fica rendendo. Os pagamentos podem ser mensais ou de uma só
        vez e você ainda pode fazer contribuições adicionais sempre que tiver
        algum dinheiro sobrando.
      </p>

      <p>
        Você pode fazer o resgate quando precisar. Mas, quanto mais tempo o
        dinheiro ficar investido, mais ele vai render. Consulte o{" "}
        <Link
          href="/previdencia/rendimento-dos-fundos"
          label="rendimento dos fundos de investimento"
        />
        .
      </p>

      <p>
        Use a previdência para objetivos que você quer alcançar depois de uns 3
        ou 5 anos. Ela também pode ser feita para uma criança. Já pensou cuidar
        do futuro do seu filho ou sobrinho?
      </p>
    </Apresentacao>
  );
};

ApresentacaoPrevidencia.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

ApresentacaoPrevidencia.propTypes = {
  images: PropTypes.objectOf(Object),
  ...Section.propTypes,
};

export default ApresentacaoPrevidencia;
