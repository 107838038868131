/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { createImageElement } from "../../../asserts/images";
import icons from "../../../asserts/images/icons";
import { Button } from "../actions";
import { LINKS } from "../../../constants";

const FloatBanner = () => {
  const [checked, setChecked] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [icon, setIcon] = useState(false);
  const style = !checked ? "float-banner-opened" : "float-banner-closed";
  const floatBanner = checkedMobile
    ? "cvp-float-banner-mobile"
    : "cvp-float-banner";
  const styleIcon = !icon
    ? "cvp-float-banner-icon"
    : "cvp-float-banner-icon-closed";
  function toogle() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setCheckedMobile(!checkedMobile);
    } else {
      setCheckedMobile(checkedMobile);
    }
    setChecked(!checked);
    setIcon(!icon);
  }

  return (
    <div className={floatBanner}>
      <div onClick={toogle} className="cvp-float-banner-button">
        <div className="d-flex align-items-center justify-content-center">
          Aproveite Plano Ideal
          {createImageElement(icons.chatFloatIcon, {
            className: styleIcon,
          })}
        </div>
      </div>
      <div className={style}>
        <div className="float-plano">
          <p className="float-plano-titulo">Plano Anual (12 meses)</p>
          <p className="float-plano-valor">R$199</p>
          <Button
            className="base-btn btn-secondary float-banner-btn"
            href={`${LINKS.autoCompraBemEstar}/1`}
          >
            Contratar Agora
          </Button>
          <div className="float-plano-icone" onClick={toogle}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
        <div className="float-plano">
          <p className="float-plano-titulo">Plano Trienal (36 meses)</p>
          <p className="float-plano-valor">R$500</p>
          <Button
            className="base-btn btn-secondary float-banner-btn"
            href={`${LINKS.autoCompraBemEstar}/2`}
          >
            Contratar Agora
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FloatBanner;
