import React, { useState, useEffect } from 'react';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import imagensSinistro from '@asserts/images/sinistro';
import { BannerEnviar, AcompanharDocumentos } from '../sections';
import { Page, Section } from '../../../components/shared/pages';
import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { SinisterContextProvider } from '../../../context/SinisterContext';

export const EnviarDocumentosSinistroPage = (props) => {
  const [errorSinistro, setErrorSinistro] = useState(false);
  const appInsights = useAppInsightsContext();
  const trackSinistro = useTrackEvent(appInsights, 'AA_Sinistro');

  useEffect(() => {
    const sinistroEvent = {
      event: 'AA.navegacao',
      virtualPageTitle: 'Caixa Vida e Previdência - Sinistro',
      virtualPageURL: '/sinistro',
    };
    trackSinistro(sinistroEvent);
  }, [trackSinistro]);

  return (
    <Page {...props} banner={BannerEnviar} images={imagensSinistro}>
      <SeguridadePageLayout.Tab eventKey="sinistro">
        <SinisterContextProvider>
          <Section
            as={AcompanharDocumentos}
            id="sinistro-abertura-sinistro"
            title="Envio de documentos - Pesquisar"
            sectionLayout={null}
            setErrorSinistro={setErrorSinistro}
            errorSinistro={errorSinistro}
          />
        </SinisterContextProvider>
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default EnviarDocumentosSinistroPage;
