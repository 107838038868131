import React from 'react';
import AppRoutes from './AppRoutes';
import MainRouter from './routers';

const Bootstrap = () => {
  return (
    <MainRouter>
      <AppRoutes />
    </MainRouter>
  );
};

export default Bootstrap;
