import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { createImageElement } from "../../../asserts/images";

const FazerUmaCompraImage = ({ sectionTitle, children, info, image }) => {
  return (
    <Row className="justify-content-center fazer-uma-compra">
      <Col xs={12} className="text-center">
        <h3 className="content-title">{sectionTitle}</h3>
        <div className="fazer-uma-compra-body">{children}</div>
       
        <div className="assistente">
        <p className="text-body-lg">{info}</p>
          {createImageElement(image, { alt: { sectionTitle } })}
        </div>
      </Col>
    </Row>
  );
};

FazerUmaCompraImage.defaultProps = {
  info:""
};

FazerUmaCompraImage.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
   info: PropTypes.string,
};

export default FazerUmaCompraImage;
