import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import _ from "lodash";
import GeneralConditionsPanel from "./GeneralConditionsPanel";
import Form from "../shared/forms/Form";
import useGeneralConditionsMap from "../../hooks/requests/useGeneralConditionsMap";
import { Section } from "../shared/pages";
import { distinctArray, parseDateString } from "../../utils";

const GeneralConditions = ({ title, mainProduct, frequency }) => {
  const { getGeneralConditions, data: conditions } = useGeneralConditionsMap();
  const [yearOptions, setYearOptions] = useState([]);
  const [productsByYear, setProductsByYear] = useState([]);

  useEffect(() => {
    getGeneralConditions(mainProduct);
  }, []);

  const conditionsRef = useRef(conditions);
  if (!_.isEqual(conditionsRef?.current, conditions)) {
    conditionsRef.current = conditions;
  }

  const mapResult = useMemo(
    () =>
      conditions &&
      conditions.map((obj) => ({
        ...obj,
        date: parseDateString(obj.data, "yyyy-MM-dd"),
      })),
    [conditionsRef.current]
  );

  const onYearSelect = (year) => {
    const productsList = distinctArray(
      mapResult
        .filter((obj) => obj.date.getFullYear().toString() === year)
        .map(({ subProduto }) => subProduto)
    );
    setProductsByYear(productsList);
  };

  const subTituloCondicoesGerais = (subTituloVida) => {
    if(subTituloVida) {
    return "Selecione o ano de adesão, produto, mês e periodicidade de pagamento para baixar as condições gerais e os regulamentos do seu seguro.";
    } 
    return "Selecione o ano de adesão, produto e mês para baixar as condições gerais e os regulamentos do seu seguro."; 
  };

  useEffect(() => {
    if (Array.isArray(mapResult) && mapResult.length) {
      const years = distinctArray(
        mapResult
          ?.map(({ data }) => parseDateString(data, "yyyy-MM-dd").getFullYear())
          .sort((a, b) => b - a) 
      );

      setYearOptions(years);
    }
  }, [mapResult, setYearOptions]);

  const productsByYearOptions = productsByYear.map((subProduto) => ({
    value: subProduto,
    label: subProduto,
  }));

  return (
    <Section.Content className="cvp-general-conditions pb-5 py-5">
      <h3 className="text-center">{title}</h3>
      <p className="text-center">
        {subTituloCondicoesGerais(frequency)}
      </p>
      <Form
        form={GeneralConditionsPanel}
        formId="general-condition-form"
        validationSchema={{
          product: Yup.string(),
          year: Yup.number().positive().integer(),
          month: Yup.number().moreThan(-1).integer(),
          condition: Yup.number().positive().integer(),
        }}
        formProps={{
          yearOptions,
          onYearSelect,
          productsByYearOptions,
          frequency,
          conditions: mapResult,
        }}
      />
    </Section.Content>
  );
};

GeneralConditions.defaultProps = {
  title: "Condições Gerais",
  mainProduct: "",
  frequency: true,
};

GeneralConditions.propTypes = {
  title: PropTypes.string,
  mainProduct: PropTypes.string,
  frequency: PropTypes.bool,
};

export default GeneralConditions;
