import React from "react";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";
import imagensSinistro from "../../../asserts/images/sinistro";

export const ApresentacaoSinistro = ({ sectionTitle }) => {
  return (
    <Apresentacao image={imagensSinistro.sinistroErroProcedimento} title={sectionTitle} imageColumn={4} >
     <p>
        Não se preocupe! Entre em contato conosco através da nossa Central de
        Atendimento Dia & Noite e Sinistros no 0800 722 2492 (das 8h às 18 h, de
        segunda a sexta-feira, exceto feriados nacionais) que auxiliaremos na
        solução.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSinistro.defaultProps = {
  ...Section.defaultProps,
  images: {},
};
export default ApresentacaoSinistro;