import React from "react";
import { Benefits } from "@components/blocosCustomizados";
import { Section } from "../../../components/shared/pages";

export const VantagemPrevidencia = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.previdenciaAssistenciaGratuitaCriancas,
      title: "Assistências gratuitas para crianças",
      description:
        "Quem contrata um plano para a criança que ama, conta com assistências gratuitas que acompanham as fases da vida dela.",
    },
    {
      image: images.previdenciaVantagemParaMulheres,
      title: "Vantagens para mulheres",
      description:
        "Com a nossa previdência, mulheres tem benefícios exclusivos como Gravidez Premiada e Prevenção Ginecológica.",
    },
    {
      image: images.previdenciaTaxasDiferenciadas,
      title: "Nossas taxas são diferenciadas",
      description:
        "Não cobramos taxa de entrada e só cobramos a taxa de saída se você retirar seu dinheiro antes de completar 3 anos de plano.",
    },
  ];

  return <Benefits sectionTitle={sectionTitle} items={conteudoVantagem} />;
};

VantagemPrevidencia.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

VantagemPrevidencia.propTypes = {
  ...Section.specializedProps,
};

export default VantagemPrevidencia;
