import React from "react";
import PropTypes from "prop-types";
import { Button } from "../actions";

const SubmitControl = (props) => {
  const { dirty, isSubmitting, isValid, className, isLoading } = props;
  return (
    <div className={`d-flex ${className || ""}`}>
      <Button
        type="submit"
        {...props}
        disabled={!dirty || isSubmitting || !isValid || isLoading}
      />
    </div>
  );
};

SubmitControl.defaultProps = {
  dirty: true,
  isSubmitting: false,
  isValid: true,
  isLoading: false,
};

SubmitControl.propTypes = {
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default SubmitControl;
