import React from "react";
import PropTypes from "prop-types";

const InfoDuvida = ({ content, className }) => {
  return <div className={className}>{content}</div>;
};

InfoDuvida.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string.isRequired,
};

export default InfoDuvida;
