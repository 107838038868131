import React from "react";
import PropTypes from "prop-types";
import { Table as BsTable } from "react-bootstrap";

const Table = ({
  children,
  header,
  footer,
  responsive,
  striped,
  bordered,
  hover,
  className,
}) => {
  return (
    <BsTable
      responsive={responsive}
      striped={striped}
      bordered={bordered}
      hover={hover}
      className={className}
    >
      <thead>{header}</thead>
      <tbody>{children}</tbody>
      <tfoot>{footer}</tfoot>
    </BsTable>
  );
};

Table.defaultProps = {
  footer: null,
  responsive: false,
  striped: true,
  bordered: true,
  hover: true,
};

Table.propTypes = {
  header: PropTypes.element.isRequired,
  footer: PropTypes.element,
  responsive: PropTypes.bool,
  striped: PropTypes.bool,
  bordered: PropTypes.bool,
  hover: PropTypes.bool,
};

export default Table;
