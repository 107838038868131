import React from "react";
import PropTypes from "prop-types";
import { mapToOptions } from "../../../../../utils";
import {
  ContainerRadio,
  RadioButtonTipo,
  LabelRadioButton,

} from "../styles";

// Radio group
const RadioButton = ({
  value,
  checkedValue,
  label,
  onChange,
  formikBag,
  name,
  ...props
}) => (
  <ContainerRadio>
    <LabelRadioButton>
      <RadioButtonTipo
        type="radio"
        id={name}
        name={name}
        defaultChecked={checkedValue === value}
        value={value}
        onChange={onChange}
        {...formikBag}
        {...props}
      />{" "}
      {label}
    </LabelRadioButton>
  </ContainerRadio>
);

const TipoPessoaSelect = ({
  value,
  onChange,
  options,
  formikBag,
  ...props
}) => {
  return (
    <>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          name="tipo"
          value={option.value}
          label={option.label}
          checkedValue={value}
          onChange={onChange}
          options={mapToOptions(options)}
          {...formikBag}
          {...props}
        />
      ))}
    </>
  );
};
TipoPessoaSelect.propTypes = {
  formikBag: PropTypes.objectOf(Object).isRequired,
  value: PropTypes.string.isRequired,
};
export default TipoPessoaSelect;
