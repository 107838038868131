import React from "react";
import PropTypes from "prop-types";
import IconWhats from "../../../asserts/images/whatsapp/icon-whatsapp.svg";
import LogoCaixaVidaePrevidencia from "../../../asserts/images/Logo-Caixa-Vida-e-Previdencia.svg";
import { Link } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const Info = ({ sectionTitle, subTitle, notifyMessage }) => {
  return (
    <div className="redirect-whatsapp-box">
      <LogoCaixaVidaePrevidencia className="logo-caixa-vida-e-previdencia" />
      <div className="line-whatsapp">
        <div className="content-whatsapp">
          <IconWhats className="icon-whats" />
          <h1>{sectionTitle}</h1>
          <h1>{subTitle}</h1>
          <div className="loader">
            <div className="loaderBar" />
          </div>
          <h2>
            {notifyMessage}{" "}
            <Link
              blank
              href={LINKS.whatsApp}
              className="link-whatsapp"
              label="clique aqui"
            />
          </h2>
        </div>
      </div>
    </div>
  );
};

Info.defaultProps = {};

Info.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  subTitle: PropTypes.element.isRequired,
};

export default Info;
