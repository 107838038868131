import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import * as Styled from "./styles";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import { ButtonLink, ButtonPrimary } from "../../../components/Button";
import { ErrorMessage, Formik } from "formik";
import { emptyIfNull } from "../../../utils";
import { usePhoneToken } from "../../../hooks";
import useCountDown from "../../../hooks/common/useCountDown";

const ModalToken = ({
  showModal,
  setShowModal,
  cpf,
  telefoneToken,
  handleFormikonSubmit,
  handleFormikTokenSend,
  showMessageError,
  messageError,
  IsLoadingDelete,
}) => {
  const { getPhoneToken, loading } = usePhoneToken();
  const { timer, startTimer, stopTimer } = useCountDown(5);

  const SignupSchema = Yup.object().shape({
    token: Yup.string()
      .min(2, "Muito curto!")
      .max(6)
      .required("Campo obrigatório"),
  });

  useEffect(() => {
    (async () => {
      const payloadGetToken = {
        cpf,
        telefoneToken,
      };
      await getPhoneToken(payloadGetToken);
      startTimer();
    })();
  }, [cpf, telefoneToken]);
  return (
    <Modal
      show={showModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setShowModal(false);
        stopTimer();
      }}
      size="lg"
    >
      <Modal.Header
        style={{
          border: "none",
        }}
        closeButton
      />
      <Modal.Body>
        <Container>
          <Formik
            initialValues={{
              token: "",
            }}
            onSubmit={(values) => handleFormikonSubmit(values)}
            validationSchema={SignupSchema}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Styled.ColModal>
                  <Styled.ModalTitle>
                    Por medidas de segurança, enviamos um código por SMS para o
                    telefone {emptyIfNull(telefoneToken)}, insira no campo
                    abaixo.
                  </Styled.ModalTitle>
                  <Styled.InsertCodeInput
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.token}
                    name="token"
                    autoFocus
                    maxLength="6"
                  />
                  <ErrorMessage name="token" />
                  <ButtonPrimary type="submit">
                    {IsLoadingDelete ? "Carregando..." : "Validar"}
                  </ButtonPrimary>
                  {showMessageError && (
                    <p className="text-danger">{messageError}</p>
                  )}
                </Styled.ColModal>
              </form>
            )}
          </Formik>
        </Container>
        <Styled.Footer>
          <ButtonLink onClick={handleFormikTokenSend}>
            {loading ? "Carregando..." : "Não recebi o código"}
          </ButtonLink>
          <Styled.Countdown>
            <p>O código tem validade de: {timer}</p>
          </Styled.Countdown>
        </Styled.Footer>
      </Modal.Body>
    </Modal>
  );
};
export default ModalToken;
