import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Page, Section } from "@components/shared/pages";
import imagensPrestamista from "@asserts/images/prestamista";
import {
  BannerPrestamistaProdutorRural,
  ApresentacaoSeguroProdutorRural,
  BeneficioDeReducaoSeguroProdutorRural,
  SorteiosSeguroProdutorRural,
} from "../sections";
import { SaibaMaisPrestamista } from "../../prestamista/sections"
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

export const HomePage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrestamistaSeguroProdutorRural = useTrackEvent(appInsights, "AA_PrestamistaSeguroProdutorRural");

  useEffect(() => {
    const prestamistaCreditoRuralEvent = {
      event: "AA.navegacao",
      virtualPageTitle: "Caixa Vida e Previdência - Seguro Vida Produtor Rural",
      virtualPageURL: "/segurovidaprodutorrural",
    };
    trackPrestamistaSeguroProdutorRural(prestamistaCreditoRuralEvent);
  }, [trackPrestamistaSeguroProdutorRural]);

  return (
    <Page
      images={imagensPrestamista}
      {...props}
      banner={BannerPrestamistaProdutorRural}
      pageTitle="Seguro Vida Produtor Rural"
      analyticsPage="segurovidaprodutorrural"
    >
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          as={ApresentacaoSeguroProdutorRural}
          id="apresentacao"
          title="Por que contratar o Seguro Vida Produtor Rural?"
          sectionName="porque contratar"
        />
        <Section
          as={BeneficioDeReducaoSeguroProdutorRural}
          id="beneficio-de-reducao"
          title="Benefício de redução em consultas e exames"
          sectionName="beneficios de reducao"
        />
        <Section
          as={SorteiosSeguroProdutorRural}
          id="sorteios-seguro-produtor-rural"
          title="Sorteios"
          sectionName="sorteios"
        />
        <Section
          as={SaibaMaisPrestamista}
          title="Tem alguma dúvida?"
          className="bloco-tem-alguma-duvida background-image-none-sm border-top"
          id="tem-alguma-duvida"
          sectionLayout={null}
          sectionName="duvidas1"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
