import React from "react";
import css from "./timeline-event.module.css";

import IconCheck from '../../asserts/images/icons/icon-check.svg';
import IconCirculo from '../../asserts/images/icons/icon-circulo.svg';

const TimelineEvent = ({ date, title, status, isLast, toolTip }) => {
  const renderIcon = () => {
    return status ? <IconCheck /> : <IconCirculo />;
  };

  return (
    <div className={css.timelineEvent} style={{ marginTop: isLast ? '8px' : '' }}>
      {isLast ? (
        <div style={{ margin: "0 10px" }}>{renderIcon()} </div>
      ) : (
        <div
          className={`${css.icon}`}
        >
          {renderIcon()}
        </div>
      )}

      <div className={css.content}>
        <div className="d-flex">
          <div>
            <div className={css.date}>{date}</div>
            <div className={css.title}>{title}</div>
          </div>
          <i data-tooltip={toolTip} className="cs-icon icon-info-g" style={{ fontSize: "28px" }} />
        </div>
      </div>
    </div>
  );
};

export default TimelineEvent;
