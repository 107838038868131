/* eslint-disable */

function unmask(mask) {
  return mask.replace(/(\.)?(-)?([/])?/g, '');
}

const compareString = (text, string) => {
  if (string === '') {
    return false;
  }
  if (text === string) {
    return true;
  }
  return compareString(text, string.slice(0, -1));
};

const masks = {
  cpf: {
    mask(cpf = '') {
      return cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    },
    unmask,
  },
  cnpj: {
    mask(cnpj) {
      return cnpj
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    },
    unmask,
  },
  rg: {
    mask(rg) {
      return rg
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
    },
    unmask,
  },
  orgaoExpedidor: {
    mask(value) {
      return value.replace(/\d|\$|\@|\%|\#|\!|\&|\*|\(|\)/g, '').substr(0, 5);
    },
    unmask,
  },
  cep: {
    mask(cep) {
      return cep
        .replace(/\D/g, '')
        .replace(/(.{8})(.)/g, '$1')
        .replace(/^(\d{5})(\d)/, '$1-$2');
    },
    unmask,
  },
  date: {
    mask(dateMask) {
      return dateMask
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2');
    },
    unmask: text => text,
  },
  phone: {
    mask(phoneMask) {
      return phoneMask
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text, 11),
  },
  phoneDDI: {
    mask(phoneMask) {
      return phoneMask
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{1})/, '+$1 ($2')
        .replace(/(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{5})(\d{1,4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text, 13),
  },
  name: {
    mask(value) {
      return value.replace(/\d|\$|\@|\%|\#|\!|\&|\*|\(|\)/g, '').substr(0, 40);
    },
    unmask,
  },
  uf: {
    mask(value) {
      return value.replace(/\d|\$|\@|\%|\#|\!|\&|\*|\(|\)/g, '').substr(0, 2);
    },
    unmask,
  },
  email: {
    mask(value) {
      return value.substr(0, 50);
    },
    unmask(value) {
      return value;
    },
  },
  relative: {
    mask(value) {
      return value.replace(/\d|\$|\@|\%|\#|\!|\&|\*|\(|\)/g, '').substr(0, 20);
    },
    unmask,
  },
  address: {
    mask(value) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:/g, '')
        .substr(0, 50);
    },
    unmask,
  },
  number: {
    mask(value) {
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substr(0, 15);
    },
    unmask: value => masks.numberOnly.mask(value, 15),
  },
  addition: {
    mask(value) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substr(0, 100);
    },
    unmask,
  },
  neighborhood: {
    mask(value) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substr(0, 30);
    },
    unmask,
  },
  city: {
    mask(value) {
      return value
        .replace(/\$|\@|\%|\#|\!|\&|\*|\(|\)|\>|\<|\?|\/|\|\;|\:\¨\¨¨/g, '')
        .substr(0, 35);
    },
    unmask,
  },
  homePhone: {
    mask(phoneMask) {
      return phoneMask
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text, 10),
  },
  commercialPhone: {
    mask(phoneMask) {
      return phoneMask
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d{4,5})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    },
    unmask: text => masks.numberOnly.mask(text, 11),
  },
  numberOnly: {
    mask(value, length = 200) {
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substr(0, length);
    },
    unmask: text => text.replace(/\D/g, ''),
  },
  letter: {
    mask(value) {
      return value.replace(/\d/g, '');
    },
    unmask,
  },
  agency: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return value.replace(/\D/g, '').replace(/(\d{4})(\d)/g, '$1-$2');
    },
    unmask: text => masks.numberOnly.mask(text, 4),
  },
  op: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return value.replace(/\D/g, '').substr(0, 4);
    },
    unmask: text => masks.numberOnly.mask(text, 4),
  },
  account: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return value.replace(/\D/g, '').substr(0, 12);
    },
    unmask: text => masks.numberOnly.mask(text, 12),
  },
  dv: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return value.replace(/\D/g, '').substr(0, 1);
    },
    unmask: text => masks.numberOnly.mask(text, 1),
  },
  day: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return value.replace(/\D/g, '').substr(0, 2);
    },
    unmask: text => masks.numberOnly.mask(text, 2),
  },
  year: {
    mask(value) {
      if (value === '') {
        return value;
      }

      value = value.replace(/\D/g, '');

      if (value.length >= 2) {
        value = value.substring(0, 2);
      }

      return `${value} anos`;
    },
    unmask(value) {
      const onlyNumber = value.replace(/\D/g, '');
      if (value.length > 3 && value.substr(2).trim() === 'ano') {
        return onlyNumber.substr(0, onlyNumber.length - 1);
      }
      return onlyNumber.substr(0, 2);
    },
  },
  currency: {
    mask(value) {
      if (value === '') {
        return value;
      }
      return parseFloat(value)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\d(?=(\d{3})+,)/g, '$&.');
    },
    unmask(mask) {
      return mask.replace(/\D/g, '');
    },
  },
  currencyInput: {
    mask(value) {
      value = `${value}`.replace(/\R\$/g, '');
      value = value
        .replace(',', '')
        .split('.')
        .join('');
      return value === '' ? '' : `R$ ${masks.currency.mask(value / 100)}`;
    },
    unmask(mask) {
      return mask.replace(/\D/g, '');
    },
  },
  none: {
    mask(text) {
      return text;
    },
    unmask(text) {
      return text;
    },
  },
  monthsToYears: {
    mask(months) {
      const years = Math.floor(months / 12);
      const calcMonths = months % 12;
      let yearText = '';
      let monthText = '';

      if (years === 1) {
        yearText = '1 ano';
      }
      if (years >= 2) {
        yearText = `${years} anos`;
      }

      if (calcMonths === 1) {
        if (years > 0) {
          monthText = 'e 1 mês';
        } else {
          monthText = '1 mês';
        }
      }
      if (calcMonths >= 2) {
        if (years > 0) {
          monthText = `e ${calcMonths} meses`;
        } else {
          monthText = `${calcMonths} meses`;
        }
      }

      return `${yearText} ${monthText}`;
    },
    unmask,
  },
  title: {
    mask(text) {
      if (text === '') {
        return text;
      }

      text = `${text}`.replace(/\D/g, '');

      if (text.length >= 2) {
        text = text.substring(0, 2);
      }
      if (text === '1') {
        return `${text} titulo`;
      }
      return `${text} titulos`;
    },
    unmask(text) {
      const onlyNumber = text.replace(/\D/g, '');
      if (text.length > 3 && compareString(text.substr(2).trim(), 'titulo')) {
        return onlyNumber.substr(0, onlyNumber.length - 1);
      }
      return onlyNumber.substr(0, 2);
    },
  },
  code: {
    mask(value) {
      if (!value) {
        return '';
      }
      return value.replace(/\D/g, '').substr(0, 6);
    },
    unmask: value => masks.numberOnly.mask(value, 6),
  },
  percentage: {
    mask(text) {
      if (!text) {
        return '';
      }
      return text.replace(/\D/g, '').substr(0, 3);
    },
    unmask: text => text.replace(/\D/g, ''),
  },
};

export default masks;
