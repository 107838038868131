import React from "react";
import { Benefits } from "@components/blocosCustomizados";
import { Section } from "../../../components/shared/pages";

export const VantagemSeguroVidaSenior = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.melhorIdade,
      title: "Perfeito para a melhor idade",
      description:
        "Pessoas físicas com idade entre 61 e 80 anos já podem ter um Seguro Vida Sênior.",
    },
    {
      image: images.cabeOrcamento,
      title: "Cabe no seu orçamento",
      description:
        "A partir de RS 43,70 por mês você já pode contratar um plano.",
    },
    {
      image: images.beneficios,
      title: "Benefícios",
      description:
        "Conte com assistências, descontos em consultas médicas, odontológicas e exames, help desk para smartphone e muito mais!",
    },
  ];

  return <Benefits sectionTitle={sectionTitle} items={conteudoVantagem} />;
};

VantagemSeguroVidaSenior.defaultProps = {
  ...Section.defaultProps,
  images: {},
};

VantagemSeguroVidaSenior.propTypes = {
  ...Section.specializedProps,
};

export default VantagemSeguroVidaSenior;
