import "./style.css";
import React from "react";
import HomePage from "./pages/HomePage";
import AreaRoute from "../../components/shared/routes";
import { useSegmentRoute } from "../../hooks";

export const WhatsApp = () => {
  const pageTitle = "Aguarde!";
  const subTitle = "Estamos abrindo o WhatsApp...";
  const routerContext = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
  ]);

  return <AreaRoute {...routerContext} title={pageTitle} subtitle={subTitle} />;
};

export default WhatsApp;
