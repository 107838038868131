import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Link } from "../../../components/shared/actions";
import { createImageElement } from "../../../asserts/images";
import { Apresentacao } from "../../../components/blocosCustomizados";

const QuemPodeAdquirir = ({ sectionTitle, images }) => {
  return (
    <>
      <Row className="pt-4 pl-3">
        <Col xs={12} lg={{ span: 10, offset: 1 }}>
          <div className="content-title text-center text-md-left">
            <h3>{sectionTitle}</h3>
            <div className="d-lg-none text-md-center">
              {createImageElement(images.quemPodeAdquirirIlustracao, {
                fluid: true,
                className: "my-3",
              })}
            </div>
          </div>
          <p>
            O cliente entre 18 e 79 anos que contratar um empréstimo junto à
            Caixa pode adquirir o Seguro Dívida Zero na hora de fechar o
            contrato.
          </p>
          <p>
            Está disponível para várias linhas de crédito da Caixa, veja as
            principais:
          </p>
        </Col>
      </Row>
      <Apresentacao
        image={images.quemPodeAdquirirIlustracao}
        right
        contentColumn={{
          className: "d-flex align-items-center",
          xs: 12,
          lg: { span: 8, offset: 1 },
        }}
      >
        <Row>
          <Col xs={12} md={4}>
            <ul className="list">
              <li>Crédito Consignado;</li>
              <li>Crédito Pessoal;</li>
              <li>Crédito Direto Caixa (CDC);</li>
              <li>Construcard;</li>
            </ul>
          </Col>
          <Col xs={12} md={4}>
            <ul className="list">
              <li>Cheque Especial;</li>
              <li>
                <Link
                  href="/segurovidaprodutorrural"
                  toRoute="/segurovidaprodutorrural"
                  label="Crédito Rural"
                  className="text-brand"
                />
                ;
              </li>
              <li>Microcrédito;</li>
              <li>Financiamento de veículo;</li>
            </ul>
          </Col>
          <Col xs={12} md={4}>
            <ul>
              <li>Consórcio e;</li>
              <li>
                <Link
                  href="/segurodividazerofies"
                  toRoute="/segurodividazerofies"
                  label="Financiamento Estudantil (FIES)"
                  className="text-brand"
                />
                .
              </li>
            </ul>
          </Col>
        </Row>
      </Apresentacao>
    </>
  );
};

QuemPodeAdquirir.defaultProps = {
  images: {},
};

QuemPodeAdquirir.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.instanceOf(Object),
};

export default QuemPodeAdquirir;
