import Axios from "axios";
import { useState } from "react";
import PropTypes from "prop-types";

/**
 * Higher order component used for fetching data from remote locations.
 * Supports GET verb only.
 * This should not be used outside service hooks.
 *
 * @component
 * @return  {UseStaticRequestHook}
 *
 * @typedef   {Object}    UseStaticRequestHook
 * @property  {function}  performStaticRequest  Function used for request
 * @property  {boolean}   error                 Error indicator
 */
const useStaticRequest = () => {
  const [error, setError] = useState(false);

  /**
   * Performs actual request.
   * @param   {string}   endPoint    The URL to call
   * @return  {Object}               Raw response data for success and failure scenarios
   */
  const performStaticRequest = async (endPoint) => {
    let axiosResponse = null;
    try {
      const staticResponse = await Axios.get(endPoint);
      setError(false);
      axiosResponse = staticResponse;
    } catch (err) {
      setError(true);
      axiosResponse = err.response;
    }
    return axiosResponse;
  };

  return { performStaticRequest, error };
};

useStaticRequest.propTypes = {
  performStaticRequest: PropTypes.func,
  error: PropTypes.bool,
};

export default useStaticRequest;
