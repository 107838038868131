import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AccordionComponent from "./AccordionImage";

const FaqImage = (props) => {
  return (
    <>
      <AccordionComponent
        {...props}
        name="faq"
        iconShow={faAngleDown}
        iconHide={faAngleUp}
      />
    </>
  );
};

export default FaqImage;
