import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { Banner } from "../shared/layout/banners";
import { Button } from "../shared/actions";

const BannerIncentivoAporte = (props) => {
  const { text, buttonLink, labelBotao } = props;

  return (
    <Banner
      {...props}
      className="text-white banner-ir-2021"
      right
      customPros={{
        xs: 12,
        md: 12,
      }}
    >
      <Row className="pt-2 ">
        <Col  >
            <h2 style={{color: "#f39200"}}  >Invista no seu futuro!</h2>
          <p className="text-body-md mt-0 mt-md-2">{text}</p>
          <Button className="mt-3" ignoreBlank primary href={buttonLink}>
            {labelBotao}
          </Button>
        </Col>
      </Row>
    </Banner>
  );
};

BannerIncentivoAporte.defaultProps = {
  text: "Faça um aporte ou aumente sua contribuição mensal no plano de Previdência Privada e construa a sua tranquilidade financeira.",
  labelBotao: "Acesse agora!",
};

BannerIncentivoAporte.propTypes = {
  text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  backgroundImage: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  labelBotao: PropTypes.string,
};

export default BannerIncentivoAporte;
