import React, { useEffect } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import imagensPrevidencia from "@asserts/images/previdencia/index";
import {
  BannerPrevidencia,
  ApresentacaoFundosInvestimento,
  TabelaRendimentosFundos,
} from "../sections";
import { Page, Section } from "../../../components/shared/pages";
import SeguridadePageLayout from "../../../components/shared/layout/pageLayouts/seguridade";

const FundosInvestimentosPage = (props) => {
  const appInsights = useAppInsightsContext();
  const trackPrevidenciaRendimento = useTrackEvent(
    appInsights,
    "AA_Previdencia_Rendimento"
  );

  useEffect(() => {
    const previdenciaRendimentoEvent = {
      event: "AA.navegacao",
      virtualPageTitle:
        "Caixa Vida e Previdência - Previdência - Rendimento dos Fundos",
      virtualPageURL: "/previdencia/rendimento-dos-fundos",
    };
    trackPrevidenciaRendimento(previdenciaRendimentoEvent);
  }, [trackPrevidenciaRendimento]);

  return (
    <Page images={imagensPrevidencia} {...props} banner={BannerPrevidencia}>
      <SeguridadePageLayout.Tab eventKey="fundos-rendimento">
        <Section
          as={ApresentacaoFundosInvestimento}
          id="apresentacao-fundos-investimentos"
          title="Rendimento dos fundos de investimento"
          sectionName="apresentacao fundos de investimento"
        />
        <Section
          as={TabelaRendimentosFundos}
          title="Pesquise os percentuais de rentabilidade por data"
          className="pt-5"
          sectionLayout={null}
          sectionName="tabela de rendimento fundos"
        />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default FundosInvestimentosPage;
