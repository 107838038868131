import React from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { ContentWithCardList } from "@components/blocosCustomizados";
import { createImageElement } from "@asserts/images";

export const Coberturas = ({ images, sectionTitle }) => {
  const conteudoCobertura = [
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Morte por causas naturais</h6>
          <p className="text-left mt-0">
            Se você falecer, sua família recebe o valor da indenização que
            contratou.
          </p>
        </>
      ),
    },

    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Morte por causas acidentais</h6>
          <p className="text-left mt-0">
            Se você falecer por acidente, sua família recebe o valor da
            indenização que contratou.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Invalidez permanente total ou parcial por acidente
          </h6>
          <p className="text-left mt-0">
            Se você sofrer um acidente e ficar totalmente ou parcialmente em
            invalidez, recebe até 100% do valor que contratou.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Cobertura opcional para cônjuge ou companheiro(a)
          </h6>
          <p className="text-left mt-0">
            Se o cônjuge ou companheiro(a) falecer, você pode receber até 50% da
            quantia em dinheiro do valor contratado.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Doenças crônicas graves em estágio avançado
          </h6>
          <p className="text-left mt-0">
            O seguro cobre as oito doenças que mais matam no Brasil. Caso você
            apresente estágio avançado de qualquer uma delas, recebe o valor de
            R$ 20 mil.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Indenização especial por morte acidental
          </h6>
          <p className="text-left mt-0">
            Em caso de morte por acidente, a família recebe 100% da indenização
            contratada para a cobertura de morte.
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Indenização em caso de diagnóstico de câncer feminino
          </h6>
          <p className="text-left mt-0">
            Se confirmado o câncer de mama, ovário e útero, é garantido o
            pagamento em vida de 50% do valor da indenização contratada.
            Limitado a R$ 50.000,00 (cinquenta mil reais).
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">
            Isenção de pagamento em caso de desemprego
          </h6>
          <p className="text-left mt-0">
            Se você perder involuntariamente o emprego, pode deixar de pagar
            pelo seguro durante seis meses e ainda conta com as coberturas e
            assistências contratadas
          </p>
        </>
      ),
    },
    {
      conteudo: (
        <>
          <h6 className="text-left m-0">Auxílio-alimentação</h6>
          <p className="text-left mt-0">
            Se você falecer, sua família receberá auxílio-alimentação de R$ 1 mil.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <ContentWithCardList listItens={conteudoCobertura} className="text-left">
        <Row>
          <Col xs={12}>
            <h3 className="content-title text-center mt-4">{sectionTitle}</h3>
          </Col>
          <Col xs={12}>
            {createImageElement(images.vidaImagemCobertura, {
              alt: "Cobertura",
              className: "mb-4",
            })}
          </Col>
        </Row>
      </ContentWithCardList>
    </>
  );
};

Coberturas.defaultProps = {};

Coberturas.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default Coberturas;
