import React from "react";
import { Section } from "@components/shared/pages";
import FaqButtonAreaCliente from "../../../components/shared/common/FaqButtonAreaCliente";
import {
  ChatButton,
  Link,
  PhoneLink,
} from "../../../components/shared/actions";
import { CONTENTS, LINKS } from "../../../constants";
import { envs } from "../../../config";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";

const conteudoJaSouClienteSinistro = [
  {
    label: "Resolva rápido. Confira os serviços que você faz on-line.",
    content: (
      <div>
        <ul>
          <li>Visualiza seu número no sorteio e os resultados dos sorteios</li>
          <li>Consulta os dados do segurado e dos beneficiários</li>
          <li>Visualiza apólice, pagamentos e certificado</li>
          <li>Imprime a Declaração de Quitação Anual de Débitos</li>
          <li>Saiba tudo sobre os sorteios</li>
          <li>Conheça as suas coberturas e assistências</li>
          <li>Baixe a Condições Gerais do seu seguro</li>
        </ul>
        <p>
          Acesse agora a{" "}
          <Link blank href={LINKS.areaLogada} label="área do cliente" />.
        </p>
      </div>
    ),
  },
  {
    label: "Como conferir o resultado dos sorteios?",
    content: (
      <div>
        <p>
          Acesse a{" "}
          <Link
            href="/segurodevida/resultado-de-sorteios"
            label="página de resultados de sorteios"
          />{" "}
          e confira se você foi contemplado.
        </p>
      </div>
    ),
  },
  {
    label: "Como você ou sua família podem acionar o seguro?",
    content: (
      <div>
        <p>Para acionar as coberturas, você ou alguém da sua família deve:</p>
        <p>
          Ligar para <PhoneLink phone="0800 722 2492" /> e informar os dados do
          titular do seguro e do beneficiário.
          <br />
          Enviar a documentação necessária, no prazo informado.
          <br />
          Aguardar enquanto analisamos o processo (isso pode demorar de 10 a 30
          dias).
        </p>
        <p>Para acionar as assistências, você precisa:</p>

        <p>
          Entrar em contato com a Central Assistência Dia & Noite e Sinistros
          pelo <PhoneLink phone="0800 722 2492" /> e informar os dados do
          titular do seguro.
          <br />
          Para acionar o Serviço de Assistência Funeral, o segurado titular,
          beneficiário ou reclamante também deve ligar para{" "}
          <PhoneLink phone="0800 722 2492" />.
        </p>
      </div>
    ),
  },
  {
    label: "O que é apólice e sinistro no seguro de vida?",
    content: (
      <div>
        <p>
          Apólice é o contrato do seguro e descreve os direitos e obrigações do
          segurado e da seguradora, firmando um acordo entre as duas partes. Na
          apólice estão dados como o valor da indenização e tipo das coberturas,
          mensalidades e nomes do segurado e beneficiários.
        </p>

        <p>
          No seguro de vida, o sinistro é a morte do segurado, uma doença
          temporária ou permanente ou um acidente que o torne inválido, por
          exemplo.
        </p>
      </div>
    ),
  },
  {
    label: "Quem tem direito ao seguro de vida do falecido?",
    content: (
      <div>
        <p>
          Ao contratar um seguro de vida, você escolhe quem serão as pessoas e o
          quanto irão receber do valor da indenização. Essas pessoas são os
          beneficiários do seguro. Caso quem contratou o seguro não tenha
          indicado uma ou mais pessoas específicas como beneficiários, o
          dinheiro fica para os herdeiros legais.
        </p>
      </div>
    ),
  },
  {
    label: "Como declarar o pagamento de seguro de vida no imposto de renda?",
    content: (
      <>
        <p>
          O <strong>pagamento que você faz</strong> exclusivamente pelo seguro
          de vida não precisa ser declarado no Imposto de Renda porque não vai
          ser abatido. Além disso, os formulários não costumam ter o campo para
          preenchimento desse valor sozinho.
        </p>
        <p>
          Agora, se você for o beneficiário de alguém e <strong>receber</strong>{" "}
          algum dinheiro do seguro, deve declará-lo. O valor é preenchido no
          campo de <strong>Rendimentos Isentos e Não Tributáveis</strong>, com o
          código referente às apólices de seguro de vida. Vale lembrar que essa
          quantia não gera imposto extra para ser pago.
        </p>
      </>
    ),
  },
  {
    label: "Como cancelar meu seguro?",
    content: (
      <div>
        <p>
          Para cancelar o seu seguro você pode ligar para a nossa Central de
          Atendimento:
        </p>
        <p>
          <PhoneLink phone="0800 702 4000" />
        </p>
        <p>
          Se comprou seu seguro pelo site, cancele pelo{" "}
          <ChatButton chatId={envs.vidaChatId} label="chat" />.
        </p>
      </div>
    ),
  },
  CONTENTS.guiaSinistroFaqItem,
];

export const JaSouClienteSinistro = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={conteudoJaSouClienteSinistro}
      right
      faqFooterContent={<FaqButtonAreaCliente />}
    />
  );
};

JaSouClienteSinistro.propTypes = {
  ...Section.specializedProps,
};

export default JaSouClienteSinistro;
