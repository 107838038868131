import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Section } from "../../../components/shared/pages";

const ApresentacaoSeguroProdutorRural = (props) => {
  const { images, sectionTitle } = props;

  return (
    <Apresentacao image={images.porqueContratarIlustracao} title={sectionTitle}>
      <p>
        Você, produtor rural, pode ter ainda mais segurança e tranquilidade para seus negócios no
        presente e no futuro. O Seguro Vida Produtor Rural pode ser contratado junto à adesão do seu
        crédito e garante a liquidação de sua dívida em caso de imprevistos.
      </p>
    </Apresentacao>
  );
};

ApresentacaoSeguroProdutorRural.defaultProps = {
  ...Apresentacao.defaultProps,
  ...Section.defaultProps,
};

ApresentacaoSeguroProdutorRural.propTypes = {
  ...Apresentacao.propTypes,
  ...Section.propTypes,
  sectionTitle: PropTypes.element.isRequired,
};

export default ApresentacaoSeguroProdutorRural;
