import React from "react";
import { Section } from "../../../components/shared/pages";
import { Button, ChatButton } from "../../../components/shared/actions";
import FazerUmaCompra from "../../../components/shared/blocks/FazerUmaCompra";
import { LINKS } from "../../../constants";
import { envs } from "../../../config";

export const FacaPrevidencia = ({ sectionTitle }) => {
  return (
    <FazerUmaCompra sectionTitle={sectionTitle}>
      <Button blank secondary href={LINKS.autoCompraPrevidencia}>
        Comprar online
      </Button>
      <ChatButton
        className="text-white"
        secondary
        chatId={envs.comprarPrevidenciaChatId}
        label="Comprar pelo Chat"
        button
      />
    </FazerUmaCompra>
  );
};

FacaPrevidencia.defaultProps = {
  ...Section.defaultProps,
};

FacaPrevidencia.propTypes = {
  ...Section.propTypes,
};

export default FacaPrevidencia;
