import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Button } from "../../../components/shared/actions";
import Card from "../../../components/shared/cards";
import { Section } from "../../../components/shared/pages";
import { applyKey } from "../../../utils";
import  imagensHome  from '../../../asserts/images/home/index';
import './style.css';

export const NossosProdutos = () => {
  const produtos = [
    {
      title: "Seguro de Vida",
      image: imagensHome.cardVida,
      conteudo:
        "Um seguro pra você cuidar de quem realmente importa e deixar sua família com mais apoio.",
      link: "/segurodevida?mtm_campaign=Card_Pagina_Inicial_Vida",
    },
    {
      title: "Previdência",
      image: imagensHome.cardPrev,
      conteudo:
        "Conheça nossa Previdência Privada, comece a juntar dinheiro e use como quiser no futuro.",
      link: "/previdencia?mtm_campaign=Card_Pagina_Inicial_Previdencia",
    },
    {
      title: "Seguro Acidentes Pessoais",
      image: imagensHome.cardAcidentesPessoais,
      conteudo:
        "Proteção para os beneficiários com pagamento de indenização e benefícios em vida para a pessoa segurada.",
      link: "/acidentespessoais?mtm_campaign=Card_Pagina_Inicial_Acidentes_Pessoais",
    },
    {
      title: "Seguro Dívida Zero",
      image: imagensHome.cardPrestamista,
      conteudo:
        "Uma forma de você cuidar da sua família para que ela lide apenas com a saudade.",
      link: "/segurodividazero?mtm_campaign=Card_Pagina_Inicial_Seguro_Divida_Zero",
    },
    
  ];

  return (
    <>
      <Container className="mb-5">
        
        <Row className="px-4 justify-content-center">
          {applyKey(produtos, ({ title, conteudo, image, link }) => (
            <Col  md={6} xl={4} className="mx-5 mb-4"
              
            >
              <Card className="cvp-card-produto">
             <div className="card-img">

              <img src={image} alt={title} />
             </div>
             <div className="info-card">
                <h3 className="text-left cvp-card-tilte">
                  {title}
                </h3>
                <p className="text-left text-body-sm">{conteudo}</p>
                </div>
                <div className="btn-card">
                  <Button
                    href={link}
                    className="mr-3 d-block cvp-btn-acessar-area-cliente d-sm-inline-block"
                  >
                    Saiba mais
                  </Button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

NossosProdutos.defaultProps = { images: {} };

NossosProdutos.propTypes = {
  ...Section.specializedProps,
};

export default NossosProdutos;
