import { useState } from "react";
import PropTypes from "prop-types";
import { endPoints } from "../../config";
import useGiRequest, { POST } from "./useGiRequest";

const useSinisterTimeline = () => {
  const { performGiRequest } = useGiRequest();
  const [loading, setLoading] = useState(false);

  const verificarBeneficiarioSinistro = async (payload) => {
    setLoading(true);
    const response = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarBeneficiarioSinistro(),
      data: {
        ...payload,
        cpfTitular: payload.cpfSegurado,
      },
    });

    setLoading(false);

    return response?.data;
  };

  const desvincularBeneficiarioSinistro = async (payload) => {
    setLoading(true);
    const response = await performGiRequest({
      method: POST,
      url: endPoints.RemoverBeneficiarioSinistro(),
      data: {
        ...payload,
        cpfTitular: payload.cpfSegurado,
      },
    });

    setLoading(false);

    return response?.data;
  };

  return {
    verificarBeneficiarioSinistro,
    desvincularBeneficiarioSinistro,
    loading,
  };
};

useSinisterTimeline.propTypes = {
  verificarBeneficiarioSinistro: PropTypes.func,
  loading: PropTypes.bool,
};

export default useSinisterTimeline;
