/* eslint-disable react/jsx-no-bind */
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { applyKey } from "../../../utils";

const RedirectRoutes = ({ redirects }) => {
  return applyKey(redirects, ([de, para]) => (
    <Route
      path={de}
      render={() => {
        window.location.href = para;
      }}
    />
  ));
};

RedirectRoutes.defaultProps = {
  redirects: [],
};

RedirectRoutes.propTypes = {
  redirects: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default RedirectRoutes;
