import React from "react";
import { PropTypes } from "prop-types";
import { LINKS } from "@constants";
import { Link } from "@components/shared/actions";
import DrawResult, {
  combinacaoSete,
} from "../../../components/blocosCustomizados/DrawResult";
import produtos from "../data/produtos.json";
import staticResults from "../data/resultadoSorteios.json";
import CsIcon, { download } from "../../../components/shared/icons/csicons";

const downloadSistematicaSorteio = (search) => search;
const produtoVida = "858";

const ResultadoSorteios = ({ sectionTitle }) => {
  function onSubmitHandle(search) {
    downloadSistematicaSorteio(search);
  }

  return (
    <>
      <h3 className="text-center mt-4">{sectionTitle}</h3>
      <p className="text-center mt-4">
        Aqui, você confere os resultados dos sorteios do seu seguro de vida.
        <br />
        Quer saber se hoje é o seu dia de sorte? Selecione o produto, o ano e a
        data do sorteio para conferir os resultados.
      </p>
      <DrawResult
        productList={produtos}
        defaultResultAs={combinacaoSete}
        defaultProductCode={produtoVida}
        staticResults={staticResults}
        displayResultLabel="1º sorteio"
        onSubmit={onSubmitHandle}
      />

      <div className="d-flex flex-column">
        <div className="text-brand  mb-3">
          <Link
            href={LINKS.sistematicaSorteiosVida}
            blank
            className="cvp-download-link d-flex justify-content-md-center text-md-center  align-items-center"
          >
            <span className="mr-2">
              <CsIcon icon={download} className="cvp-download-link-icon" />
            </span>
            <span className="cvp-download-link-label">
              Baixe aqui a sistemática dos sorteios de Vida
            </span>
          </Link>
        </div>
        <p className="text-center">
          Caso você não consiga visualizar o arquivo em PDF, faça o download do
          Adobe Acrobat Reader{" "}
          <Link href="http://get.adobe.com/br/reader/" blank label="aqui" />.
        </p>
      </div>
    </>
  );
};

ResultadoSorteios.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default ResultadoSorteios;
