import { roundMoney } from "../../../utils";

export const calcularFatorAnuidade = (fatorPeriodicidade) =>
  12 / fatorPeriodicidade;

export const calcularValor = (valor, fatorPeriodicidade, fatorAnuidade) =>
  (valor * fatorPeriodicidade) / fatorAnuidade;

export const calcularContribuicaoInss = (rendaBruta, aliquotas) => {
  if (!rendaBruta || !Array.isArray(aliquotas) || !aliquotas.length) {
    return 0;
  }

  const resultadoFinal = aliquotas
    .filter(({ range: [min, max] }) => {
      return max < rendaBruta || rendaBruta - min >= 0;
    })
    .map(({ range: [min, max], aliquota }) => {
      const fatorCalculo = rendaBruta >= max ? max : rendaBruta;

      return roundMoney((fatorCalculo - min) * aliquota);
    })
    .reduce((a, b) => a + b, 0);

  return Number.parseFloat(resultadoFinal.toFixed(2));
};

export const calcularValorDeducaoFiscal = (
  rendaBruta,
  aliquotaDeducao,
  fatorAnuidade
) => (rendaBruta * aliquotaDeducao * fatorAnuidade) / fatorAnuidade;

export const somarDeducoesFiscais = (...deducoes) =>
  deducoes.reduce((total, deducao) => total + deducao, 0);

export const calcularIrBase = (rendaBruta, aliquotas, deducoes = 0) => {
  if (!rendaBruta || !Array.isArray(aliquotas) || !aliquotas.length) {
    return 0;
  }

  const rendaBase = rendaBruta - deducoes;

  return []
    .concat(aliquotas)
    .filter(({ rendaLimite }) => rendaBruta >= rendaLimite)
    .map(({ aliquota, parcela }) => rendaBase * aliquota - parcela)
    .reduce((a, b) => Math.max(a, b), 0);
};

export const recalcularValor = (valor, fator) => {
  const fatorAnuidadeNova = calcularFatorAnuidade(fator);
  return calcularValor(valor, fator, fatorAnuidadeNova);
};
