import React from "react";
import PropTypes from "prop-types";
import { Col, Image, Row } from "react-bootstrap";
import images from "../../asserts/images";
import Alert from "../shared/alerts";

export const SuperDica = (props) => {
  const { children, title, image } = props;

  return (
    <Alert {...props} className="cvp-super-dica">
      <Row>
        <Col
          xs={12}
          md={2}
          className="d-md-flex text-center justify-content-end align-items-center m-0 p-0 mr-md-3"
        >
          {image && <Image src={image} className="d-none d-md-block mr-3" />}
          <h6 className="m-0 cpv-super-dica-title">{title}</h6>
        </Col>
        <Col className="d-flex align-items-center text-center text-md-left p-2">
          <div className="cpv-super-dica-content">{children}</div>
        </Col>
      </Row>
    </Alert>
  );
};

SuperDica.defaultProps = {
  ...Alert.defaultProps,
  variant: "secondary",
  title: "Super dica!",
  image: images.imagemExclamacao,
};

SuperDica.propTypes = {
  ...Alert.propTypes,
  title: PropTypes.string,
  image: PropTypes.string,
};

export default Alert;
