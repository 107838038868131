import { useState } from 'react';
import PropTypes from 'prop-types';
import { endPoints } from '../../config';
import useGiRequest, { POST } from './useGiRequest';
import usePerformGiRequest from './usePerformGiRequest';

const useSinisterTimelineVida = () => {
  const { performGiRequest, errorGi } = useGiRequest();
  const [loadingPrest, setLoading] = useState(false);
  const { getAccessToken } = usePerformGiRequest();

  const getAllSinisterOpened = async (cpf, reCaptchaToken1) => {
    setLoading(true);

    const responseGi = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarSinistroCpfCnpjVida(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        Usuario: 'TE62253',
        CpfCnpj: cpf,
        UserName: cpf,
        SHArsaKey: await getAccessToken(cpf, 'ConsultarSinistroCpfCnpj'),
      },
    });

    setLoading(false);

    return responseGi?.data;
  };

  const getTimelineSinister = async (jsonData, cpf, reCaptchaToken1) => {
    setLoading(true);

    const responseGi = await performGiRequest({
      method: POST,
      url: endPoints.ConsultarSinistroVida(),
      headers: {
        'g-captcha-response': reCaptchaToken1,
      },
      data: {
        Usuario: 'TE62253',
        CpfCnpj: cpf,
        NumeroCertificado: jsonData.certificateNumber,
        NumeroProtocolo: jsonData.protocolNumber,
        UserName: 'admkpvsol',
        SHArsaKey: await getAccessToken(cpf, 'ConsultarSinistro'),
      },
    });

    setLoading(false);

    return responseGi?.data;
  };

  return {
    getAllSinisterOpened,
    getTimelineSinister,
    loadingPrest,
    errorGi,
  };
};

useSinisterTimelineVida.propTypes = {
  getAllSinisterOpened: PropTypes.func,
  getTimelineSinister: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorGi: PropTypes.bool,
};

export default useSinisterTimelineVida;
