import React from 'react';
import { FormTreeColumnsGeneric } from './styles';
import { GenericTextInput } from './FormInputs';

const ConjugeFilhoInput = ({
  isEventoMorte,
  isPessoaConjugeOuFilho,
  labelNome,
  LabelCPF,
  LabelDataNascimento,
  nomeConjugeFilho,
  cpfConjugeFilho,
  dthNascimentoConjugeFilho,
  formikBag,
}) => {
  return (
    <>
      {isEventoMorte && isPessoaConjugeOuFilho && (
        <FormTreeColumnsGeneric>
          <>
            <GenericTextInput
              controlId="formSinistro.dadosSinistro.nomeConjugeFilho"
              name="dadosSinistro.nomeConjugeFilho"
              label={labelNome}
              placeHolder="Digite o nome completo"
              value={nomeConjugeFilho}
              typeField="text"
              length="60"
              formikBag={formikBag}
            />
            <GenericTextInput
              controlId="formSinistro.dadosSinistro.cpfConjugeFilho"
              name="dadosSinistro.cpfConjugeFilho"
              label={LabelCPF}
              placeHolder="Digite o CPF"
              value={cpfConjugeFilho}
              typeField="cpf"
              length="14"
              formikBag={formikBag}
            />
            <GenericTextInput
              controlId="formSinistro.dadosSinistro.dthNascimentoConjugeFilho"
              name="dadosSinistro.dthNascimentoConjugeFilho"
              label={LabelDataNascimento}
              placeHolder="Digite a data de nascimento"
              value={dthNascimentoConjugeFilho}
              typeField="date"
              length="10"
              formikBag={formikBag}
            />
          </>
        </FormTreeColumnsGeneric>
      )}
    </>
  );
};

export default ConjugeFilhoInput;
