import React from "react";
import { PropTypes } from "prop-types";
import { PhoneLink } from "@components/shared/actions";
import ContentWithStep from "../../../components/blocosCustomizados/ContentWithStep";

export const PassoCoberturas = ({ sectionTitle }) => {
  return (
    <ContentWithStep
      classNameStep="ml-0 ml-md-4"
      title={sectionTitle}
      widthCol={8}
    >
      <>
        Ligar para <PhoneLink phone="08007222492" /> e informar os dados do
        titular do seguro e do beneficiário.
      </>
      <>Enviar a documentação necessária, no prazo informado.</>
      <>
        Aguardar enquanto analisamos o processo (isso pode demorar de 10 a 30
        dias).
      </>
    </ContentWithStep>
  );
};

PassoCoberturas.defaultProps = {};

PassoCoberturas.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
};

export default PassoCoberturas;
