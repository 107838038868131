import "./style.css";
import React from "react";
import { HomePage } from "./pages";
import AreaRoute from "../../components/shared/routes";
import { envs } from "../../config";

export const PrestamistaCreditoRural = () => {
  return (
    <AreaRoute
      main={HomePage}
      title="Seguro Vida Produtor Rural"
      chatId={envs.prestamistaChatId}
    />
  );
};

export default PrestamistaCreditoRural;
