import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const disabledStyles = `
  color: #e1e1e1;
  background-color: #fff;
  border-color: #e1e1e1;
  &:hover {
    color: #e1e1e1;
    border-color: #e1e1e1;
  }
`;

const BtnLink = styled(Button)`
  color: #005fc8;
  background-color: inherit;
  border: none;
  width: auto;
  min-width: fit-content;
  font-weight: normal;
  font-size: 14px;
  text-decoration: ${({ underline }) => underline ? 'underline' : 'none'};
  // font-weight: 100;
  &:hover {
    color: #0039ba;
    border-color: #0039ba;
  }
  ${(props) => (props.disabled ? disabledStyles : '')}
 padding: ${({ noPadding }) => noPadding ? '1px 0px 0px 0px' : 'initial'};
`;

const ButtonLink = props => <BtnLink {...props} />;

export default ButtonLink;