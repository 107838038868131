import React from "react";
import { Section } from "@components/shared/pages";
import { Benefits } from "../../../components/blocosCustomizados";

const VantagensPrestamista = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.pagamentoParcialIlustracao,
      title: "Pagamento parcial ou total do empréstimo",
      description:
        "Realiza o pagamento do seu empréstimo de acordo com o valor contratado.",
    },
    {
      image: images.indenizacaoBeneficiariosIlustracao,
      title: "Indenização aos beneficiários",
      description:
        "Caso reste saldo após o pagamento do seu empréstimo, o valor será pago ao segurado ou aos beneficiários.",
    },
    {
      image: images.sorteiosIlustracao,
      title: "Sorteios",
      description:
        "Ao contratar o Seguro Dívida Zero, todo mês você concorre a prêmios de até R$200.000,00.",
    },
  ];

  return <Benefits sectionTitle={sectionTitle} items={conteudoVantagem} />;
};

VantagensPrestamista.propTypes = {
  ...Section.specializedProps,
};

export default VantagensPrestamista;
