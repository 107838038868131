import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const BairroInput = ({label, bairro, formikBag, disabled }) => (
  <InputControl
    disabled={disabled}
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.bairro"
    label={label}
    placeHolder="Digite o bairro"
    name="reclamante.bairro"
    value={bairro}
    {...formikBag}
  />
);

BairroInput.propTypes = {
  bairro: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default BairroInput;
