import envs from '../config/envs';

export const areaLogada = envs.hostAreaLogada;
export const cadastroAreaLogada = `${areaLogada}/perfil/criar`;

export const autoCompraVida = `${envs.hostAutoCompra}/segurovida/simulacao`;
export const campanhaBemEstar = `${envs.hostAutoCompra}/bemestar?mtm_campaign=Banner%20Rotativo%20Bem%20Estar`;

export const autoCompraAmparo = `${envs.hostAutoCompra}/amparo`;
export const autoCompraPrevidencia = `${envs.hostAutoCompra}/previdencia`;
export const autoCompraBemEstar = `${envs.hostAutoCompra}/bemestar`;

export const acompanharDenuncia = `https://siscompliance.com.br/caixavidaeprevidencia/#/home`;

export const cvm = 'https://www.gov.br/cvm/pt-br';
export const canalDenuncia = `https://contatoseguro.com.br/caixavidaprevidencia`;
export const caixa = 'https://www.caixa.gov.br';
export const encontreAgenciaCaixa = `${caixa}/atendimento/Paginas/encontre-a-caixa.aspx`;
export const caixaCorretora =
  'https://www.caixaseguridade.com.br/Paginas/Caixacorretora.aspx';
export const politicaPrivacidadeVidaPrevidencia = `${envs.hostPoliticaPrivacidade}`;

export const remoteImages = `${envs.hostStaticCdn}/areaaberta/asserts/images`;
export const linkQuemSomos = `${envs.hostSeguridade}/Paginas/QuemSomos.aspx`;
export const sacOuvidoria = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const relacaoInvestidores = `${envs.hostRi}`;
export const queroComprar = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const canaisAtendimento = `${envs.hostSeguridade}/Paginas/CanaisDeAtendimento.aspx`;
export const areaCliente = `${envs.hostSeguridade}/Paginas/AreaDoCliente.aspx`;
export const linkSeguroResidencial = `https://www.caixaresidencial.com.br/`;
export const linkCapitalizacao = `${envs.hostSeguridade}/Paginas/Capitalizacao.aspx`;
export const linkCaixaConsorcios = `${envs.hostCaixaConsorcio}/consorcio`;
export const faleComSeguridade = 'link-faleSeguridade';
export const linkAssistencia = `https://www.caixaassistencia.com.br/`;
export const linkSeguroAuto = 'https://www.autocontratar.com.br/seguroauto';
export const linkSaudeOdonto =
  'https://www.caixasaudeeodonto.com.br/saudeodonto';
export const linkEncontrarLoterica =
  'https://caixa.gov.br/atendimento/Paginas/encontre-a-caixa.aspx';

export const sistematicaSorteiosVida = `${envs.hostStaticCdn}/areaaberta/Vida/Sistemática%20de%20Sorteios_Seguros%20de%20Vida.pdf`;
export const guiaAniversarioCaixa2023 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Aniversario_Caixa_2023.pdf`;
export const guiaAniversarioCaixa2024 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Aniversario-Caixa-2024.pdf`;
export const guiaDiaDosPaisCaixa2024 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Caixa-Vida-e-Previdencia-Regulamento-Dia-dos-Pais-2024.pdf`;
export const guiaMesDosPais = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Campanha-Dia-dos-Pais.pdf`;
export const guiaMesDaMulher2023 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Mes-da-Mulher.pdf`;
export const guiaMesDaMulher2024 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Mes-da-Mulher-2024.pdf`;
export const guiaQuinzenaDoConsumidor2023 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Quinzena-do-Consumidor-2023.pdf`;
export const guiaQuinzenaDoConsumidor2024 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-Quinzena-do-Consumidor-2024.pdf`;
export const guiaBlueFriday2022 = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-da-Campanha-Blue-Friday-2022.pdf`;
export const guiaBlueFriday = `${envs.hostStaticCdn}/areaaberta/Vida/Regulamentos/Regulamento-da-Campanha-Blue-Friday.pdf`;

export const termoPortabilidadePrevidencia = `${envs.hostStaticCdn}/areaaberta/Termos/Previdencia/termo_portabilidade.doc`;
export const assistenciaBike = `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/Previdencia/Condicoes-Gerais-Servico-de-Assitencia-Bike.pdf`;
export const inspecaoKids = `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/Previdencia/Condicoes-Gerais-Servico-de-Inspecao-Kids.pdf`;
export const profissionalUniversitario = `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/Previdencia/Condicoes-Gerais-Servico-de-Orientacao-Profissional-Universitario.pdf`;
export const guiaSinistro = `${envs.hostStaticCdn}/areaaberta/Guias/Guia_de_Sinistro.pdf`;
export const guiaInformeRendimentos = `${envs.hostStaticCdn}/areaaberta/Guias/Previdencia/Guia-Informe-Rendimentos-Previdencia.pdf`;
export const guiaContraFraudes = `${envs.hostStaticCdn}/areaaberta/Guias/Ebook_dicas_de_seguranca.pdf`;
export const linkVideoGuia = `${envs.hostStaticCdn}/areaaberta/Video/Video-APP-Previdencia.mp4`;
export const propostaFies = `${envs.hostStaticCdn}/areaaberta/Propostas/Prestamista/PrestamistaFies/PREST29_Venda_Proposta_de_Adesao_ao_Seguro_Prestamista_FIES.pdf`;
export const condicoesGeraisFies = `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/Prestamista/PF/PrestamistaFies/2021/CG%20Prestamista%20FIES%20(7769).pdf`;
export const guiaProtecaoEBemEstar = `${envs.hostStaticCdn}/areaaberta/Prestamista/Regulamentos/Regulamento-Campanha-Protecao-e-Bem-Estar.pdf`;
export const guiaQuinzenaDoAposentado = `${envs.hostStaticCdn}/areaaberta/Prestamista/Regulamentos/Regulamento-Quinzena-do-Aposentado.pdf`;
export const guiaInclusaoBeneficiarios = `${envs.hostStaticCdn}/areaaberta/Guias/Previdencia/Guia-Inclusao-de-Beneficiarios-Caixa-Vida-e-Previdencia.pdf`;
export const guiaIHP = `${envs.hostStaticCdn}/areaaberta/Sinistro/Correios/Instrumento-de-Habilitacao-de-Postagem-IHP.pdf`;
export const regulamentoCashBack = `${envs.hostStaticCdn}/areaaberta/Previdencia/Regulamentos/Cashback.pdf?mtm_campaign=Campanha_Invista_no_Futuro_Cashback_no_Presente`;

export const whatsApp = `${envs.hostApiWhatsapp}`;

export const aCaixa = `${envs.hostCaixa}/sobre-a-caixa/Paginas/default.aspx`;
export const caixaNoticias = `${envs.hostCaixaNoticia}`;
export const relatorioTransparencia = `${envs.hostStaticCdn}/areaaberta/Guias/relatorio-transparencia.pdf`;
export const politicaDePrivacidadeSeguridade = `${envs.hostSeguridade}/Paginas/PoliticadePrivacidade.aspx`;
export const sustentabilidade = `${envs.hostSustentabilidade}`;

export const linkDocs =`${envs.hostStaticCdn}/areaaberta/Sinistro`;
// CKL
export const DocCKL05 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL05.pdf`;
export const DocCKL20 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL20.pdf`;
export const DocCKL29 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL29.pdf`;
export const DocCKL43 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL43.pdf`;
export const DocCKL45 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL45.pdf`;
export const DocCKL50 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL50.pdf`;
export const DocCKL52 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL52.pdf`;
export const DocCKL53 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL53.pdf`;
export const DocCKL54 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL54.pdf`;
export const DocCKL58 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL58.pdf`;
export const DocCKL59 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL59.pdf`;
export const DocCKL61 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL61.pdf`;
export const DocCKL66 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL66.pdf`;
export const DocCKL67 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL67.pdf`;
export const DocCKL70 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL70.pdf`;
export const DocCKL72 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL72.pdf`;
export const DocCKL76 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL76.pdf`;
export const DocCKL77 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL77.pdf`;
export const DocCKL78 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL78.pdf`;
export const DocCKL79 = `${linkDocs}/CKL/Caixa-Vida-e-Previdencia-CKL79.pdf`;
// FOP
export const DocFOP359 = `${linkDocs}/FOP/Caixa-Vida-e-Previdencia-FOP359.pdf`;
