/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";
import { AccordionCard, Faq } from "../shared/accordion";
import { VARIANT_COLORS } from "../../constants";
import { Button } from "../shared/actions";
import { applyKey } from "../../utils";

export const getAsFaqItems = (list, defaultActiveKey) => {
  if (!Array.isArray(list)) {
    return null;
  }

  return applyKey(list, ({ label, content, analitycsValue }) => (
    <AccordionCard
      label={label}
      defaultActiveKey={defaultActiveKey}
      analitycsValue={analitycsValue}
    >
      {content}
    </AccordionCard>
  ));
};

const ContentWithFaq = ({
  children,
  title,
  faqItems,
  right,
  faqFooterContent,
  className,
  variant,
  numberMaxShowCard,
  faqColumnConfig,
  defaultActiveKey,
}) => {
  const [isOpenShowMore, setIsOpenShowMore] = useState(false);

  const verMenos = useMemo(
    () =>
      !isOpenShowMore &&
      numberMaxShowCard > 0 &&
      numberMaxShowCard < faqItems.length,
    [isOpenShowMore, numberMaxShowCard, faqItems]
  );

  const items = useMemo(
    () => (verMenos ? faqItems.slice(0, numberMaxShowCard) : faqItems),
    [faqItems, verMenos]
  );

  const columnConfig = useMemo(() => ({ md: 7, ...faqColumnConfig }), [
    faqColumnConfig,
  ]);

  return (
    <Row
      className={`cvp-content-faq py-5 ${
        right ? "flex-md-row" : "flex-md-row-reverse"
      }`}
    >
      <Col>
        <h1 className="text-brand text-center text-md-left">{title}</h1>
        {children}
      </Col>
      <Col className="faq-content" {...columnConfig}>
        <Faq variant={variant} className={className}>
          {getAsFaqItems(items, defaultActiveKey)}
        </Faq>
        {numberMaxShowCard > 0 && numberMaxShowCard < faqItems.length && (
          <div className="faq-fotter-buttons d-block d-lg-flex justify-content-center">
            <Button
              className="mr-3 d-block d-sm-inline-block"
              onClick={() => setIsOpenShowMore(!isOpenShowMore)}
              label={isOpenShowMore ? "Ver menos" : "Ver mais"}
            />
          </div>
        )}
        {faqFooterContent}
      </Col>
    </Row>
  );
};

ContentWithFaq.defaultProps = {
  right: false,
  faqFooterContent: null,
  variant: null,
  numberMaxShowCard: 0,
  faqColumnConfig: null,
  defaultActiveKey: null,
};

ContentWithFaq.propTypes = {
  title: PropTypes.string.isRequired,
  faqItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  right: PropTypes.bool,
  numberMaxShowCard: PropTypes.number,
  defaultActiveKey: PropTypes.number,
  faqFooterContent: PropTypes.element,
  variant: PropTypes.oneOf(VARIANT_COLORS),
  faqColumnConfig: PropTypes.objectOf(propTypes.object),
};

export default ContentWithFaq;
