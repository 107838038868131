import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  WarningParagraph,
  FormTwoColumnsGeneric,
  FormTreeColumnsGeneric,
  FormOneColumnGenericInline,
} from './styles';
import SubmitButton from './ConditionalSubmitButton';

import {
  GenericTextInput,
  GenericPhoneInput,
  EmailInput,
  EmailInputValid,
  TipoPessoaSelect,
} from './FormInputs';
import { ReCaptchaControl } from '../../../../components/shared/forms';
import SpinnerLoading from '../../../../components/shared/layout/SpinnerLoading';
import { TIPO_PESSOA } from '../../../../constants';
import Mensagens from './Mensagens';
import { SinisterContext } from '../../../../context/SinisterContext';

const FormularioEnviar = ({
  isLoadingSinistro,
  errorSinistro,
  exibirSucesso,
  falhaFluxo,
  values: {
    segurado: {
      tipoPessoa,
      cnpjSegurado,
      razaoSocialSegurado,
      cpfSegurado,
      nomeSegurado,
    },
    reclamante: { telefoneCelular, email, emailValid },
  },
  handleBlur,
  handleChange,
  isValid,
  errors,
  dirty,
  touched,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setValues,
}) => {
  const formikBag = {
    onBlur: handleBlur,
    onChange: handleChange,
    errors,
    isValid,
    dirty,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldError,
    setValues,
  };

  const { exibirSemProduto } = useContext(SinisterContext);
  const tipoPessoaJuridica = 'PessoaJuridica';
  const isTipoPessoaPessoaJuridica = tipoPessoa === tipoPessoaJuridica;
  const messageErro = 'Erro: Preencher campos obrigatórios.';
  const messageWarning =
    'O seguro/plano foi contratado por pessoa física ou por pessoa jurídica?';

  return (
    <>
      <SpinnerLoading isLoading={isLoadingSinistro} />

      {!exibirSucesso && (
        <>
          <WarningParagraph>1 - Dados do Segurado</WarningParagraph>
          <WarningParagraph>{messageWarning}</WarningParagraph>

          <FormOneColumnGenericInline>
            <TipoPessoaSelect
              controlId="formSinistro.segurado.tipoPessoa"
              name="segurado.tipoPessoa"
              value={tipoPessoa}
              onChange={tipoPessoa}
              options={TIPO_PESSOA}
              formikBag={formikBag}
            />
          </FormOneColumnGenericInline>

          {isTipoPessoaPessoaJuridica && (
            <FormTwoColumnsGeneric>
              <GenericTextInput
                name="segurado.cnpjSegurado"
                controlId="formSinistro.segurado.cnpjSegurado"
                label="Qual o CNPJ da empresa titular? *"
                placeHolder="Digite o CNPJ da empresa titular"
                value={cnpjSegurado}
                typeField="cnpj"
                length="18"
                formikBag={formikBag}
              />
              <GenericTextInput
                name="segurado.razaoSocialSegurado"
                controlId="formSinistro.segurado.razaoSocialSegurado"
                label="Qual a razão social da empresa titular? *"
                placeHolder="Digite a razão social da empresa titular"
                value={razaoSocialSegurado}
                typeField="text"
                length="60"
                formikBag={formikBag}
              />
            </FormTwoColumnsGeneric>
          )}
          <FormTwoColumnsGeneric>
            <GenericTextInput
              name="segurado.cpfSegurado"
              controlId="formSinistro.segurado.cpfSegurado"
              label="Qual o CPF do segurado? *"
              placeHolder="Digite o CPF do segurado"
              value={cpfSegurado}
              typeField="cpf"
              length="14"
              formikBag={formikBag}
            />
            <GenericTextInput
              name="segurado.nomeSegurado"
              controlId="formSinistro.segurado.nomeSegurado"
              label="Qual o nome completo do segurado? *"
              placeHolder="Digite o nome completo do segurado"
              value={nomeSegurado}
              typeField="text"
              length="60"
              formikBag={formikBag}
            />
          </FormTwoColumnsGeneric>

          <WarningParagraph>
            2 - Dados do Reclamante (pessoa que irá enviar os documentos)
          </WarningParagraph>

          <FormTreeColumnsGeneric>
            <GenericPhoneInput
              controlId="formSinistro.reclamante.telefoneCelular"
              name="reclamante.telefoneCelular"
              label="Qual seu telefone celular? *"
              placeHolder="Digite o DDD e o seu telefone celular"
              value={telefoneCelular}
              formikBag={formikBag}
            />

            <EmailInput email={email} formikBag={formikBag} />
            <EmailInputValid emailValid={emailValid} formikBag={formikBag} />
          </FormTreeColumnsGeneric>

          <div id="recaptcha">
            <ReCaptchaControl
              size="normal"
              label="ReCaptcha *"
              {...formikBag}
            />
          </div>
          {errors.reCaptcha && (
            <div
              style={{
                color: '#dc3545',
                fontSize: '.875em',
              }}
            >
              {errors.reCaptcha}
            </div>
          )}
          <MarginBase />

          <SubmitButton
            isLoading={isLoadingSinistro}
            label="Abrir comunicado de sinistro"
            exibirSemProduto={exibirSemProduto}
            falhaFluxo={falhaFluxo}
            formikBag={formikBag}
          />
          {Object.keys(errors).some((key) => touched[key]) && (
            <div style={{ color: '#dc3545', fontSize: '.875em' }}>
              {messageErro}
            </div>
          )}
          <SpinnerLoading isLoading={isSubmitting} />
        </>
      )}
      <Mensagens
        errorSinistro={errorSinistro}
        exibirSemProduto={exibirSemProduto}
      />
    </>
  );
};

FormularioEnviar.defaultProps = {
  errorSinistro: false,
  isLoadingSinistro: false,
};

FormularioEnviar.propTypes = {
  values: PropTypes.objectOf(Object).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(Array).isRequired,
  dirty: PropTypes.bool.isRequired,
  touched: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoadingSinistro: PropTypes.bool,
  errorSinistro: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};
const MarginBase = styled.div`
  margin-top: 20px;
`;
export default FormularioEnviar;
