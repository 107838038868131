export const convertFloatInPrice = (price) => {
  return Number(price).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const convertFloatDecimal = (numb) => {
  const decimal = String(numb).indexOf(".");

  if (decimal > 0) {
    const numbSplit = String(numb).split(".");

    return parseFloat(`${numbSplit[0]}.${numbSplit[1].substring(0, 2)}`);
  }

  return numb;
};

export const convertPriceInFloat = (str) => {
  if (!str) {
    return 0;
  }

  const array = str.trim().split("R$")[1].split(".");

  const strReplace = { value: "" };

  array.forEach((item) => {
    strReplace.value += item;
  });

  return parseFloat(strReplace.value.replace(",", "."));
};

export const roundMoney = (number, decimals = 2) => {
  if (!number) {
    return 0;
  }

  const casasDecimais = decimals <= 8 ? decimals : 8; // corte maximo de casas decimais

  const og = 10 ** casasDecimais; // exponencial usa-se **
  return Math.floor(number * og) / og;
};
