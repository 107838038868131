/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";
import { Button } from "../../../components/shared/actions";
import { createElementListItems } from "../../../utils";
import { Section } from "../../../components/shared/pages";

export const AssistenciaFuneral = (props) => {
  const { images, sectionTitle } = props;
  const [itemAberto, setItemAberto] = useState(false);
 
  return (
    <>
      <Apresentacao
        image={images.acidentesPessoaisOQueEstaIncluso}
        right
        imageColumn={3}
        title={sectionTitle}
      >
        <Row>
          <Col xs={12} md={7}>
          <ul className="li-marcador-laranja" >
              {createElementListItems({
                list: [
                  "Cremação;",
                  "Organização do velório;",
                  "Translado para o município de residência;",
                  "Capela para velório;",                           
                ],
              })}
            </ul>
          </Col>
          <Col xs={12} md={5}>
          <ul className="li-marcador-laranja" >
              {createElementListItems({
                list: [                  
                  "Carro fúnebre;",
                  "Registro em cartório;",
                  "Coroa de flores e muito mais.",
                ],
              })}
            </ul>
          </Col>
        </Row>
      </Apresentacao>
      <Row >
        <Col xs={12}  style={{outline:0}}>
          <Button 
          className={`text-brand toggle-link d-flex ${
            itemAberto ? "flex-column-reverse" : "flex-column"
          } align-items-center`}                 
            block
            variant="link"
            onClick={()=>setItemAberto(!itemAberto)}            
          >
            <div>{itemAberto ? "Ver menos" : "Ver mais"}</div>
            <FontAwesomeIcon
             className="toggle-icon"
              icon={itemAberto ? faAngleUp : faAngleDown}             
            />
          </Button>
        </Col>
      </Row>
      <Collapse in={itemAberto}>
        <Section.Content className="py-3">
          <Row className="mx-3 pb-3">
            <Col xs={12}>
              <h2 className="content-title">
                Além do funeral, o seguro organiza a cerimônia. Confira:
              </h2>
            </Col>
            <Col xs={12} md={6}>
              <ul className="li-marcador-laranja" >
                {createElementListItems({
                  list: [
                    "Capela para velório;",
                    "Carro fúnebre;",
                    "Cremação — envio de cinzas à família;",
                    "Jazigo da família;",
                    "Jogo de paramentos;",
                    "Livro de presença;",
                    "Ornamentação de urna;",
                    "Registro em cartório;",
                    "Transmissão de mensagens urgentes (telefonemas, mensagens ou telegramas);",
                  ],
                })}
              </ul>
            </Col>
            <Col xs={12} md={6}>
            <ul className="li-marcador-laranja" >
                {createElementListItems({
                  list: [
                    "Transporte do corpo até o Brasil (domicílio), caso o falecimento tenha sido no exterior;",
                    "Traslado até o domicílio;",
                    "Tratamento das formalidades para liberação do corpo e registro em cartório como, por exemplo, registro do óbito em cartório;",
                    "Coroa de flores;",
                    "Urna básica com ou sem visor;",
                    "Véu.",
                  ],
                })}
              </ul>
            </Col>
          </Row>
          <Apresentacao image={images.acidentesPessoaisVerMais} imageColumn={2}>
            <h6>É bom saber:</h6>
            <p>
              <br />
              Todas as despesas que cobrimos estão sob nossa responsabilidade,
              sem limite mínimo ou máximo.
            </p>
            <p>
              Entraremos em contato com a funerária parceira para organizar tudo e, por
              isso, não é permitido realizar o funeral por meio de empresas
              particulares.
            </p>
            <p className="text-brand">
              <strong>
                Religião e ética são respeitadas conforme o pedido dos
                familiares.
              </strong>
            </p>
          </Apresentacao>
        </Section.Content>
      </Collapse>
    </>
  );
};

AssistenciaFuneral.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default AssistenciaFuneral;
