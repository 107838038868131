import React, { useEffect } from 'react';
import imagensVida from '@asserts/images/vida';
import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
  BannerVida,
  ApresentacaoVida,
  VantagemVida,
  BeneficiosBemEstar,
  BeneficiosAmparo,
  FacaSeguroVida,
  CentralSinistro,
  CondicoesGeraisVida,
  JaSouClienteVida,
  Campanha,
} from '../sections';
import { Page, Section } from '../../../components/shared/pages';

import SeguridadePageLayout from '../../../components/shared/layout/pageLayouts/seguridade';
import { ParaSeuNegocio } from '../../../components/shared/blocks';
import { useCampanhas } from '../../../hooks';

const HomePage = (props) => {
  const { getCampanhas, campanhas } = useCampanhas();
  const appInsights = useAppInsightsContext();
  const trackVida = useTrackEvent(appInsights, 'AA_Vida');

  useEffect(() => {
    const vidaEvent = {
      event: 'AA.navegacao',
      virtualPageTitle: 'Caixa Vida e Previdência - Seguro de Vida',
      virtualPageURL: '/segurodevida',
    };
    trackVida(vidaEvent);
  }, [trackVida]);

  useEffect(() => {
    getCampanhas('vida');
  }, []);

  return (
    <Page {...props} images={imagensVida} banner={BannerVida}>
      <SeguridadePageLayout.Tab label="Para você" eventKey="voce">
        <Section
          id="entenda-vida"
          as={ApresentacaoVida}
          title="Entenda a importância do seguro de vida"
          sectionName="entenda"
          sectionLayout={null}
        />
        <Section
          as={VantagemVida}
          title="Aproveite as vantagens"
          sectionName="vantagens"
          sectionLayout={null}
          id="Aproveite-as-vantagens"
        />
        <Section
          as={BeneficiosBemEstar}
          title="Principais benefícios"
          sectionName="vantagens"
          sectionLayout={null}
          id="principais-beneficios"
        />
        <Section
          as={BeneficiosAmparo}
          title="Principais benefícios"
          sectionName="vantagens"
          sectionLayout={null}
          id="principais-beneficios"
        />
        <Section
          className="cvp-vida-comprar-online"
          as={FacaSeguroVida}
          title="Ter um seguro de vida é fácil, rápido e online"
          sectionName="ter um seguro e facil"
          id="faca-seguro-vida"
        />
        <Section
          as={CentralSinistro}
          id="sinitro"
          title="Central de Sinistros"
          className="sinistros-prestamista"
          sectionName="sinistros"
          sectionLayout={null}
        />
        <Section
          className="mt-3"
          sectionLayout={null}
          title="Condições Gerais"
          as={CondicoesGeraisVida}
          id="condicoes-gerais"
          sectionName="condicoes gerais"
        />
        <Section
          as={JaSouClienteVida}
          title="Já sou cliente"
          className="bloco-ja-sou-cliente-vida background-image-none-sm border-top"
          id="ja-sou-cliente"
          sectionLayout={null}
          sectionName="ja sou cliente"
        />
        <Section
          as={Campanha}
          title="Ações promocionais"
          className={`bloco-campanha${campanhas.length ? ' border-top' : ''}`}
          id="acoes-promocionais"
          sectionLayout={null}
          sectionName="acoes promocionais"
          campanhas={campanhas}
        />
      </SeguridadePageLayout.Tab>
      <SeguridadePageLayout.Tab label="Para sua empresa" eventKey="negocio">
        <ParaSeuNegocio />
      </SeguridadePageLayout.Tab>
    </Page>
  );
};

export default HomePage;
