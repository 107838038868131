/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import { Button } from "../../../../components/shared/actions";
import InputPrice from "../../../../components/shared/InputPrice";

const FormularioRenda = ({
  setFatorPeriodicidade,
  rendaBruta,
  setRendaBruta,
  contribuicaoInss,
  setContribuicaoInss,
  previdenciaPBGL,
  setPrevidenciaPBGL,
  previdenciaComplementar,
  setPrevidenciaComplementar,
  errors,
  infos,
}) => {
  const [typeCalculo, setTypeCalculo] = useState("mensal");

  const selectFatorPeriodicidadeHandle = (valor) => {
    setTypeCalculo(valor === 12 ? "anual" : "mensal");
    setFatorPeriodicidade(valor);
  };

  const [
    showPrevidenciaComplementar,
    setShowPrevidenciaComplementar,
  ] = useState(false);

  const [checkedRadio, setCheckedRadio] = useState("");

  useEffect(() => {
    setShowPrevidenciaComplementar(checkedRadio === "sim");
  }, [setShowPrevidenciaComplementar, checkedRadio]);

  useEffect(() => {
    if (!showPrevidenciaComplementar) {
      setPrevidenciaComplementar(0);
    }
  }, [setPrevidenciaComplementar, showPrevidenciaComplementar]);

  return (
    <>
      <div>
        <h4>Como deseja fazer seu cálculo?</h4>
        <div className="d-flex flex-column flex-lg-row mb-5">
          <Button
            transparent={typeCalculo !== "mensal"}
            className="mr-lg-4"
            onClick={() => selectFatorPeriodicidadeHandle(1)}
          >
            Contribuição mensal
          </Button>
          <Button
            transparent={typeCalculo !== "anual"}
            onClick={() => selectFatorPeriodicidadeHandle(12)}
          >
            Contribuição anual
          </Button>
        </div>
      </div>

      <h3>Renda</h3>
      <p className="mb-5">
        As informações abaixo são <b>obrigatórias</b>. Elas são necessárias para
        diminuir o valor da declaração do seu Imposto de Renda
      </p>

      <Row>
        <Col xs="12" lg="6" className="mb-4">
          <InputPrice
            label="Qual sua renda bruta?"
            placeholder="R$ 0,00"
            value={rendaBruta}
            onChange={setRendaBruta}
            info={errors?.isento}
            error
          />
        </Col>
        <Col xs="12" lg="6" className="mb-4">
          <InputPrice
            label="Qual sua contribuição para INSS ou RPPS?"
            placeholder="R$ 0,00"
            value={contribuicaoInss}
            onChange={setContribuicaoInss}
            info={infos?.contribuicaoInssLimite}
          />
        </Col>
        <Col xs="12" lg="6" className="mb-4">
          <InputPrice
            label="Qual a contribuição em plano PGBL na Caixa Vida e Previdência?"
            placeholder="R$ 0,00"
            value={previdenciaPBGL}
            onChange={setPrevidenciaPBGL}
          />
        </Col>
        <Col xs="12" lg="6" className="mb-4">
          <div className="inline-radio">
            <Form.Label className="text-left d-block">
              Possui previdência em outra instituição?
            </Form.Label>
            <Form.Check
              id="possui-previdencia-control"
              type="radio"
              label="Sim"
              checked={checkedRadio === "sim"}
              onClick={() => setCheckedRadio("sim")}
              inline
            />
            <Form.Check
              id="nao-possui-previdencia-control"
              type="radio"
              label="Não"
              checked={checkedRadio === "nao"}
              onClick={() => setCheckedRadio("nao")}
              inline
            />
          </div>
          {showPrevidenciaComplementar && (
            <InputPrice
              placeholder="R$ 0,00"
              value={previdenciaComplementar}
              onChange={setPrevidenciaComplementar}
              info={infos?.previdenciaComplementar}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

FormularioRenda.defaultProps = {
  rendaBruta: 0,
  contribuicaoInss: 0,
  previdenciaPBGL: 0,
  previdenciaComplementar: 0,
  errors: {},
  infos: {},
};

FormularioRenda.propTypes = {
  rendaBruta: PropTypes.number,
  contribuicaoInss: PropTypes.number,
  previdenciaPBGL: PropTypes.number,
  previdenciaComplementar: PropTypes.number,
  errors: PropTypes.objectOf(Object),
  infos: PropTypes.objectOf(Object),
  setFatorPeriodicidade: PropTypes.func.isRequired,
  setRendaBruta: PropTypes.func.isRequired,
  setContribuicaoInss: PropTypes.func.isRequired,
  setPrevidenciaPBGL: PropTypes.func.isRequired,
  setPrevidenciaComplementar: PropTypes.func.isRequired,
};

export default FormularioRenda;
